import React from 'react';
import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';
import { provideDyteDesignSystem } from '@dytesdk/react-ui-kit';
import { useEffect } from 'react';
import Room from './Room';

export const AudioRoom = () => {
  const { meeting } = useDyteMeeting();
  const roomJoined = useDyteSelector((meeting) => meeting.self.roomJoined);
  
  useEffect(() => {
    meeting.joinRoom();
    meeting.self.on('roomLeft', () => {
      // handle navigation to other screen here after user has left the room.
      // alert("You've left the room");
    });

    provideDyteDesignSystem(document.body, {
      theme: 'light',
    });
  }, []);

  if (!roomJoined) {
    // Show a page before joining the room, which allows you to join
    // the meeting
    return (
      <>Call is being intialized</>
    );
  }

  return <Room />;
}
