import { gql } from "@apollo/client";

const GET_PROFILE = gql`
query GetProfile($contact_id: Int!) {
  core_contact_profile (
    where: {
      contact_id: { _eq: $contact_id }
    }
  ) {
    id
    name
    display_name
    image
    email
    language
    country
    phone_numbers
    address
  }
}`;

const GET_PROFILE_IMAGE = gql`
query GetProfileImage($contact_id: Int!) {
  core_contact_profile (
    where: {
      contact_id: { _eq: $contact_id }
    }
  ) {
    name
    image
    display_name
  }
  core_contact_meta (
    where: {
      contact_id: { _eq: $contact_id }
    }
  ) {
    id
    status
  }
}`;

const UPDATE_AGENT_STATUS = gql`
mutation SubmitBroadcastRun($contact_meta_id: Int!, $status: status_actor!) {
  update_core_contact_meta_by_pk (
    pk_columns: { id: $contact_meta_id },
    _set: {
      status: $status
    }
  ) {
    id
    status
  }
}`;

const GET_PROFILE_NOTIFICATIONS = gql`
query GetProfileNotification($contact_id: Int!) {
  core_contact_meta(
    where: {
      contact_id: { _eq: $contact_id },
    }
  ) {
    id
    notifications_email
    notifications_sound
  }
}`;

const UPDATE_PROFILE = gql`
mutation UpdateAgent(
  $contact_id: Int!,  
  $contact_profile_data: core_contact_profile_set_input!
) {  
  update_core_contact_profile(
    where: {contact_id: {_eq: $contact_id}},
    _set: $contact_profile_data
  ) {
    returning {
      id
      name
      display_name
      image
      email
      language
      country
      phone_numbers
      address
      dob
    }
    affected_rows
  }
}`;

const UPDATE_NOTIFICATION_SETTING = gql`
mutation UpdateNotificationSettings(
  $contact_id: Int!,  
  $contact_meta_data: core_contact_meta_set_input!
) {  
  update_core_contact_meta(
    where: {contact_id: {_eq: $contact_id}},
    _set: $contact_meta_data
  ) {
    returning {
      id
      notifications_email
      notifications_sound
    }
    
    affected_rows
  }
}`

export { GET_PROFILE, GET_PROFILE_IMAGE, GET_PROFILE_NOTIFICATIONS, UPDATE_PROFILE, UPDATE_NOTIFICATION_SETTING, UPDATE_AGENT_STATUS };