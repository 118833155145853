export const formatDate = (inputDate: string): string => {
    const date = new Date(inputDate);
    const options = { year: 'numeric', month: 'short', day: 'numeric' };
    return date.toLocaleDateString('en-US', options);
}

export const isNonEmptyArray = (array: unknown): array is unknown[] => (
    Array.isArray(array) && array.length > 0
)

export const enum LANG {
    AR = 'ar',
    EN = 'en'
}