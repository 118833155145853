import { gql } from "@apollo/client";
import message from '../fragments/message.fragment';
import contact from '../fragments/contact.fragment';

const GET_CHATS = gql`
query getChannelMsgs($to_id: String!) {
  core_message(where: { to_id: { _eq: $to_id } }, distinct_on: from_id, order_by: [ { from_id: desc }, { created_at: desc } ]) {
    body
    business_id
    channel
    contact_id
    direction
    forwarded
    from_id
    id
    level
    mentions
    message_id
    parent_message_id
    spam
    thread_id
    timestamp
    to_id
    type
    created_at
    updated_at
  }
}`;

const GET_CONTACTS = gql`
query GetContacts($filter: core_contact_bool_exp!) {
  core_contact(
    where: $filter,
    order_by: { updated_at: desc }
  ) {
    id
    platform_id
    contact_profiles {
      display_name
    }
  }
}`;

const UPDATE_CONTACT_META = gql`
mutation UpdateContactMeta(
  $contact_id: Int!,
  $meta_data: core_contact_meta_set_input!
) {
  response: update_core_contact_meta(
    where: {contact_id: {_eq: $contact_id}},
    _set: $meta_data
  ) {

    returning{
      id
      is_chat_muted
      is_chat_pinned
      unread_msgs_count
    }
  }
}`;

const GET_AGENTS_TEAMS = gql`
query GetAgentsTeams($business_id: uuid!) {
  core_contact(
    where: {
      business_id: { _eq: $business_id },
      is_agent: { _eq: true },
      contact_meta: {
        status: {
          _neq: "deleted"
        }
      } 
    },
    order_by: { created_at: desc }
  ) {
    id
    contact_profiles {
      display_name
      email
      image
    }
  }

  core_team(
    where: {
      business_id: { _eq: $business_id }
    },
    order_by: { created_at: desc }
  ) {
    id
    name
  }
}`;

const GET_ASSIGNATION = gql`
query GetAssignation($contact_id: Int!) {
  core_contact_agent(
    where: {
      contact_id: { _eq: $contact_id }
    }
  ) {
    is_team
    agent_id
    team_id
  }
}`;
const ASSIGN_CHAT = gql`
mutation AssignChat($contact_id: Int!, $business_id: uuid!, $agent_id: bigint, $team_id: Int, $is_team: Boolean, $timestamp: timestamp, $system_msg: core_message_insert_input!) {
  assignation: insert_core_contact_agent_one(
    object: {
      contact_id: $contact_id,
      business_id: $business_id,
      agent_id: $agent_id,
      team_id: $team_id,
      valid: true,
      last_assigned_timestamp: $timestamp,
      is_team: $is_team
    },
    on_conflict: {constraint: unique_contact_business, update_columns: [agent_id, team_id, valid, last_assigned_timestamp, is_team]}) {
    assignation_id: id
    last_assigned_timestamp
    assigned_to_agent: agent {
      agent_id: id
      platform_id
      contact_profiles {
        name
        display_name
      }
    }

    assigned_to_team: team {
      id
      name
    }
  }
  contact: update_core_contact(where:{id: {_eq: $contact_id}}, _set: {chat_status: "assigned"}){
    details: returning {
      platform_id
      chat_status
      contact_profiles {
        display_name
      }
    }
  }

  system_msg: insert_core_message_one(object: $system_msg) {
    id
    body
  }
}`;

const UPDATE_CHAT_STATUS = gql`
mutation CloseChat($contact_id: Int!, $system_msg: core_message_insert_input!, $chat_status: status_conversation!) {
  closure: update_core_contact(
    where: {
      id: { _eq: $contact_id }
    },
    _set: { chat_status: $chat_status }
  ) {
    returning {
      id
      updated_at
      chat_status
    }
    affected_rows
  }

  system_msg: insert_core_message_one(object: $system_msg) {
    id
    body
  }
}`;

const GET_CONTACT_PROFILE = gql`
query GetContactDetails($contact_id: Int!) {
  response: core_contact(where: {id: {_eq: $contact_id}}) {
    contact_id: id
    platform_id
    chat_status
    contact_profiles {
      profile_id: id
      image
      channel_name: name
      profile_name: display_name
      email
      custom_fields
    }
    meta: contact_meta {
      id
      is_chat_pinned
      is_chat_muted
      is_verified
      unread_msgs_count
      last_incoming_message_at
      last_incoming_message_id
    }
    attached_tags: tags_contacts {
      tag {
        id
        tag
        colour
      }
    }
    images: messages(where: {type: {_eq: "image"}}) {
      file: message_media {
        mime_type
        filename
        filename_url
        caption
      }
    }
    videos: messages(where: {type: {_eq: "video"}}) {
      file: message_media {
        mime_type
        filename
        filename_url
      }
    }
    documents: messages(where: {type: {_eq: "document"}}) {
      file: message_media {
        mime_type
        filename
        filename_url
      }
    }
  }
}`;

const GET_PROFILE_CHAT_CLOSE_STATUS = gql`
query GetContactDetails($contact_id: Int!) {
  response: core_contact(where: {id: {_eq: $contact_id}}) {
    chat_status
  }
}`;

const GET_CUSTOM_FIELDS = gql`
query GetAllCustomFields($business_id: uuid!, $search: String = "") {
  apps_custom_field(
    where: {
      business_id: { _eq: $business_id },
      name: { _iregex: $search },
    },
    order_by: { created_at: desc }
  ) {
    id
    name
    key
    type
  }
}`;

const ATTACH_CUSTOM_FIELD = gql`
mutation AttachCustomFieldToContact($contact_id: Int!, $contact_profile_data: core_contact_profile_set_input!) {
  update_core_contact_profile(
    where: {
      contact_id: { _eq: $contact_id }
    },
    _set: $contact_profile_data
  ) {
    returning {
      id
      custom_fields
    }
    affected_rows
  }
}`;

const SEND_PRIVATE_MESSAGE = gql`
mutation SendPrivateMessage($private_msg: core_message_insert_input!) {
  response: insert_core_message_one(object: $private_msg) {
    id
    body
    message_mentions {
      agent: contact {
        id
        platform_id
        details: contact_profiles {
          name
        }
      }
    }
  }
}`;

const GET_QUICK_REPLIES  = gql`
query GetQuickRepliesForAgent($business_id: uuid!, $agent_id:bigint!, $keywords: [String] = [], $search: String = "", , $attachment_type: type_msg_comparison_exp = {}) {
  apps_quick_reply(
    where: {
      business_id: { _eq: $business_id },
      keywords: { _contains: $keywords },
      attachment_type: $attachment_type,
      _and: [
        {
      _or: [
            { short_code: { _iregex: $search } },
            { body_text: { _iregex: $search } }
          ],
        }
        {
          _or: [
        { available_to: { _eq: "all" } },
        {
          available_to: { _eq: "creator" },
          created_by: { _eq: $agent_id }
        },
      ]},
      ],
    },
    limit: 25,
    order_by: { created_at: desc }
  ) {
    id
    attachment_url
    attachment_type
    body_text
  }
}`;

const GET_QUICK_REPLY_BY_SHORT_CODE  = gql`
query GetQuickReplyByShortCode($business_id: uuid!, $agent_id:bigint!, $short_code: String = "") {
  apps_quick_reply(
    where: {
      business_id: { _eq: $business_id },
      short_code: { _eq: $short_code },
      _or: [
        { available_to: { _eq: "all" } },
        { available_to: { _eq: "creator" } },
      ],
      _and: { created_by: { _eq: $agent_id } }
    },
  ) {
    short_code
    attachment_url
    attachment_type
    body_text
  }
}`;

const GET_TEMPLATES = gql`
query GetTemplatesByBusiness($business_id: uuid!, $search: String = "") {
  apps_template(
    where: {
      business_id: { _eq: $business_id },
      template_id: { _iregex: $search },
      category: { _neq: "AUTHENTICATION" },
      is_deleted: { _eq: false },
      status: { _eq: "approved" },
      show_in_chat: { _eq: true },
    },
    order_by: { created_at: desc }
  ) {
    id
    template_id
    namespace_id
    description
    language
    status    
    category
    type
    components_ui
    media_type
    samples
  }
}
`;

const GET_AGENTS = gql`
query GetAgentsByBusiness($business_id: uuid!, $search: String = "", $status: status_actor_comparison_exp = {}) {
  agents: core_contact(
    where:{
      business_id: { _eq: $business_id },
      contact_profiles: {
        display_name: { _iregex: $search }
      },
      _and: {
        is_agent: { _eq: true }
      }
      contact_meta: {
        status: $status
      }
    }
  ) {
    id
    platform_id
    contact_profiles {
      display_name
      image
      email
    }
  }
}`;

const GET_TEAMS = gql`
query GetTeamsForChat($business_id: uuid!, $search: String = "") {
  teams: core_team(
    where:{
      business_id: { _eq: $business_id },
      name: { _iregex: $search }
    }
  ) {
    team_id: id
    name
  }
}`;

const NEW_CONVERSATION_LISTING = gql`
query GetContactsForNewConversation(
  $business_id: uuid!,
  $channel: type_platform = "whatsapp",
  $name_like: String = "%",
  $platform_id_like: String = "%")
{
  contacts: core_contact(
    limit: 25
    where: { business_id: {_eq: $business_id}
    _and: {
      is_agent: {_eq: false}
      platform: {_eq: $channel}
      _and: {
        contact_profiles: {
          name: {
            _ilike: $name_like
          }
        }
        _or: {
          platform_id: {
            _like: $platform_id_like
          }
        }
      }
    }
  })
  {
    id
    platform_id
    contact_profiles {
      display_name
      image
    }
  }
}`;

const RESOLVE_MENTION = gql`
mutation ResolveMentionMessage(
  $mention_id: Int!,
  $resolved: Boolean!
) {
  update_core_message_mention_by_pk(
    pk_columns: { id: $mention_id },
    _set: { resolved: $resolved }
  ) {
    contact_id
    id
    resolved
  }
}`;

const READ_MESSAGE = gql`
mutation ReadMessage(
  $message_id: bigint!,
  $read: Boolean!
) {
  update_core_message_by_pk(
    pk_columns: { id: $message_id },
    _set: { is_msg_read: $read }
  ) {
    id
    is_msg_read
  }
}`;

const CHAT_PREVIEW_BY_MESSAGE = gql`
query GetChatPreviewByMessage($business_id: uuid!, $contact_id: Int!, $message_id_fulcrum: bigint!) {
  target_message: core_message(where: {business_id: {_eq: $business_id}, _and: {contact_id: {_eq: $contact_id}, id: {_eq: $message_id_fulcrum}}}) {
    ...message
  }
  above_messages: core_message(where: {business_id: {_eq: $business_id}, _and: {contact_id: {_eq: $contact_id}, id: {_lt: $message_id_fulcrum}}}, order_by: {id: desc}, limit: 6) {
    ...message
  }
  below_messages: core_message(where: {business_id: {_eq: $business_id}, _and: {contact_id: {_eq: $contact_id}, id: {_gt: $message_id_fulcrum}}}, order_by: {id: asc}, limit: 6) {
    ...message
  }
}
${message}
`;

const CHAT_PREVIEW_BY_CONTACT = gql`
query GetChatPreviewByContactOnly($business_id: uuid!, $contact_id: Int!) {
  messages: core_message(where: {business_id: {_eq: $business_id}, _and: {contact_id: {_eq: $contact_id}}}, order_by: {id: desc}, limit: 20) {
    ...message
  }
}
${message}
`;

// SDK Functions

const CHAT_LIST_BY_STATUS = gql`
query GetChatListByStatus(
  $business_id: uuid!,
  $status: status_conversation!,
  $pinned_chats: Boolean = false,
  $search: String = "",
  $sort_order: order_by = desc,
  $limit: Int = 15,
  $offset: Int = 0
) {
  chat: core_contact(
    where: {
      business_id: {_eq: $business_id},
      is_agent: {
        _eq: false
      },
      contact_meta: {
        is_chat_pinned: {_eq: $pinned_chats}
      },
      contact_profiles: {
        display_name: {_iregex: $search}
      },
      chat_status: {_eq: $status}
    }
    limit: $limit,
    offset: $offset,
    order_by: {created_at: $sort_order}
  ) {
    ...contact
  }
}
${contact}
`;

const CHAT_LIST_BY_AGENT = gql`
query GetChatListByAgent(
  $business_id: uuid!,
  $agent_id: bigint!,
  $pinned_chats: Boolean = false,
  $search: String = "",
  $sort_order: order_by = desc,
  $limit: Int = 15,
  $offset: Int = 0
) {
  response: core_contact_agent(
    where: {
      business_id: {_eq: $business_id},
      _and: {
        agent_id: {_eq: $agent_id}
        _and: {
          contact: {
            contact_meta: {
              is_chat_pinned: {_eq: $pinned_chats}
            },
            contact_profiles: {
              display_name: {_iregex: $search}
            }
          }
        }
      }
    }
    limit: $limit,
    offset: $offset,
    order_by: {last_assigned_timestamp: $sort_order}
  ) {
    chat_by_agent: contact {
      ...contact
    }
  }
}
${contact}
`;

const CHAT_LIST_ALL = gql`
query GetChatListQueue(
  $business_id: uuid!,
  $pinned_chats: Boolean = false,
  $search: String = "",
  $sort_order: order_by = desc,
  $limit: Int = 15,
  $offset: Int = 0
) {
  chat: core_contact(
    where: {
      business_id: {_eq: $business_id},
      is_agent: {_eq: false},
      contact_meta: {
        is_chat_pinned: {_eq: $pinned_chats}
      },
      contact_profiles: {
        display_name: {_iregex: $search}
      }
    },
    limit: $limit, offset: $offset, order_by: {created_at: $sort_order})
    {
      ...contact
    }
}
${contact}
`;

const CHAT_HISTORY = gql`
query GetChatHistoryByContact(
  $business_id: uuid!,
  $contact_id: Int!,
  $limit: Int = 25,
  $offset: Int = 0
) {
  core_message(
    where: {
      business_id : { _eq: $business_id },
      _and: {
        contact_id: { _eq: $contact_id }
      }
    },
    order_by: { id: desc },
    limit: $limit,
    offset: $offset
  ) {
    ...message
  }
}
${message}
`;

const GET_LIVE_24H_WINDOW = gql`
subscription GetLive24Window($contact_id: Int!) {
  response: core_contact_meta(where: {contact_id: {_eq: $contact_id}}) {    
    last_incoming_message_at
    last_outgoing_message_at
  }
}`;

export { 
  GET_CHATS, 
  GET_CONTACTS, 
  UPDATE_CONTACT_META, 
  GET_AGENTS_TEAMS, 
  GET_ASSIGNATION, 
  ASSIGN_CHAT, 
  UPDATE_CHAT_STATUS, 
  GET_CONTACT_PROFILE, 
  GET_PROFILE_CHAT_CLOSE_STATUS,
  GET_CUSTOM_FIELDS, 
  ATTACH_CUSTOM_FIELD, 
  SEND_PRIVATE_MESSAGE, 
  GET_QUICK_REPLIES, 
  GET_QUICK_REPLY_BY_SHORT_CODE, 
  GET_TEMPLATES, 
  GET_AGENTS, 
  GET_TEAMS, 
  NEW_CONVERSATION_LISTING, 
  RESOLVE_MENTION, 
  READ_MESSAGE, 
  CHAT_PREVIEW_BY_MESSAGE, 
  CHAT_PREVIEW_BY_CONTACT, 
  CHAT_LIST_BY_STATUS, 
  CHAT_LIST_BY_AGENT, 
  CHAT_LIST_ALL, 
  CHAT_HISTORY, 
  GET_LIVE_24H_WINDOW 
};