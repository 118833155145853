import React, { useState } from 'react';
import { Button, FormHelperText, IconButton, InputAdornment, Stack, TextField } from '@mui/material';

// icons
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//  utils
import { useTranslation } from 'react-i18next';

//  helpers
import { reset_password } from '../../helpers/auth';

//  validations
import * as yup from 'yup';
import { useForm,  Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Sentry from "@sentry/react";

const inputProps = {
  disableUnderline: true,
  sx: { borderRadius: 2.5, backgroundColor: 'transparent !important', border: '1px solid', borderColor: 'light.gray8' }
};

export const ResetPassword = ({ setTab }): JSX.Element => {
  const { t } = useTranslation();

  const [showPassword, setShowPassword] = React.useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = React.useState(false);
  const [error, setError] = useState('');

  const schema = yup.object().shape({
    password: yup.string().required(),
    confirm_password: yup.string().required().when('password', (password) => {
      return yup.string().required().equals(password, t('VALIDATIONS.CONFIRM_PASSWORD').toString());
    }),
  });

  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: { password: '', confirm_password: '' }
  });

  const submit = async (payload: any) => {
    try {
      await reset_password(payload.password, new URLSearchParams(window.location.search).get('reset_password') as string);
      setTab(1);
    } catch (error) {
      Sentry.captureException(error);
      setError(t('VALIDATIONS.TOKEN_EXPIRED_RESET_PASSWORD').toString());
    }
  }

  return (
    <Stack alignItems="center" gap={1}>
      <Controller name='password' control={control} render={({ field: { value, onChange, onBlur } }) => (
        <TextField label
        ={t('LOGIN.NEW_PASSWORD')} type={showPassword ? 'text' : 'password'} autoComplete="current-password" variant="filled" InputProps={{...inputProps, endAdornment: <InputAdornment position="end">
        <IconButton aria-label="toggle password visibility" onClick={()=> setShowPassword(show => !show)}>{showPassword ? <VisibilityOff /> : <Visibility />}</IconButton></InputAdornment>}} fullWidth value={value} onChange={onChange} onBlur={onBlur} error={Boolean(errors.password)} helperText={errors.password?.message} /> )} />
      <Controller name='confirm_password' control={control} render={({ field: { value, onChange, onBlur } }) => (
        <TextField label={t('LOGIN.CONFIRM_PASSWORD')} type={showConfirmPassword ? 'text' : 'password'} autoComplete="current-password" variant="filled" InputProps={{...inputProps, endAdornment: <InputAdornment position="end"><IconButton aria-label="toggle password visibility" onClick={()=> setShowConfirmPassword(show => !show)}>{showConfirmPassword ? <VisibilityOff /> : <Visibility />}</IconButton></InputAdornment>}} fullWidth value={value} onChange={onChange} onBlur={onBlur} error={Boolean(errors.confirm_password)} helperText={errors.confirm_password?.message} /> )} />
      <Button onClick={handleSubmit(submit)} disableElevation variant="contained" sx={{ width: '100%', mt: {md:3, xs:2}, mb: {md:2, xs:1}, borderRadius: 2.5, fontSize: {md:15, xs:13, sm:14}, fontWeight: 600 , alignItems:{md:"flex-start", xs:"center"}}}>{t(`COMMON.CONFIRM`)}</Button>
      { error && <FormHelperText data-testid="error-msg" sx={{ color: 'error.main', textAlign: 'center !important', width: '100%', boxSizing: 'border-box' }}>{error}</FormHelperText> }
    </Stack>
  );
}
