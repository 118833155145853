import React, { useEffect, useRef, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Box, Stack, Avatar, Tooltip, Button, ClickAwayListener, Popper, Fade, Typography, ButtonBase, Snackbar, Popover, IconButton, Skeleton } from '@mui/material';

//  icons
import PersonIcon from '@mui/icons-material/Person';
import LogoutRoundedIcon from '@mui/icons-material/LogoutRounded';
import SettingsIcon from '@mui/icons-material/Settings';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import SyncAltIcon from '@mui/icons-material/SyncAlt';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';

//  translations
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

//  gql
import { useMutation, useQuery } from '@apollo/client';
import { GET_PROFILE_IMAGE, UPDATE_AGENT_STATUS } from '../../../graphql/queries/settings';

//  helpers
import { stringAvatar } from '../../../helpers/constants';
import { logout, switch_business } from '../../../helpers/auth';
import { publish } from '../../../helpers/events';
import CryptoJS from "crypto-js";
// import { showToast } from '../../helpers/toast';

//  redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../redux/store';
import { setAuthenticated, setCurrentRoute } from '../../../redux/sdk';

import * as Sentry from "@sentry/react";

//responsive
import { useMediaQuery } from 'react-responsive';
import { StorageKeys, StorageService } from '../../../storage';
import { getUserImage } from '../../../helpers/contacts';
import './styles.css'
import environment from '../../../environment';

export const AgentStatus = (): JSX.Element => {
  //  hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ maxWidth: 786 });
  
  const mode = useSelector((state: RootState) => state.sdk.mode);

  
  //  local states
  const [open, setOpen] = useState(false);
  const [statusOpened, setStatusOpened] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [switchOrganizationOpened, setSwitchOrganizationOpened] = useState(false)
  const [businessId, setBusinessId] = useState("")
  const [businesses, setBusinesses] = useState([])
  const navigate = useNavigate();

  const buttonRef = useRef<HTMLButtonElement>(null);
  const statusButtonRef = useRef<HTMLButtonElement>(null);
  const switchOrganizationButtonRef = useRef<HTMLButtonElement>(null)

  const { data, loading } = useQuery(GET_PROFILE_IMAGE, { variables: { contact_id: window.contact_id } });

  const [EDIT_STATUS] = useMutation(UPDATE_AGENT_STATUS);

  useEffect(() => {
    const businesses = StorageService.getBusinesses() as any
    const businessId = StorageService.getBusinessId() as string

    setBusinessId(businessId)
    setBusinesses(businesses)
  }, [])

  const handleLogout = () => {
    setShowSnackbar(true);
    setOpen(false);
    setTimeout(logout, 1000);
  }

  const goToSettings = (path: string) => {
    dispatch(setCurrentRoute(path));
    setOpen(false);
  }

  const changeStatus = (status: string) => {
    EDIT_STATUS({
      variables: {
        contact_meta_id: data?.core_contact_meta?.[0]?.id,
        status: status.toLowerCase()
      }
    })
    setStatusOpened(false);
  }

  const handleClickAwayListener = () => {
    setOpen(false)
    setSwitchOrganizationOpened(false)
  }

  const handleSwitchBusiness = async (business_id: string) => {
    if(business_id === businessId){
      return
    }

    try {
      const data = await switch_business(business_id);
      const role: string = data?.user_details?.role;
      const cipherRole: string = CryptoJS.AES.encrypt(role, environment.REACT_APP_SECRET_ENCRYPTION_KEY).toString();

      StorageService.setAuthToken(data.headers.Authorization);
      StorageService.setAvcBillingSource(data.billing_source);
      StorageService.setBusinessId(data.headers['x-hasura-business-id']);
      StorageService.setUserDetailsAsJson(data.user_details)
      StorageService.removeItem(StorageKeys.CHAT_ID)
      StorageService.setCipherRole(cipherRole);

      Sentry.setTag("avc-business_id", data.headers['x-hasura-business-id']);
      Sentry.setTag("avc-token", data.headers.Authorization);

      window.sdk.authorize();
      publish('LOGIN', {});
      dispatch(setAuthenticated(true));

      const home = '/chat';
      if (mode === 'host') navigate(home);
      dispatch(setCurrentRoute(home));

      window.location.reload();
    } catch (error) {
      // showToast({
      //   type: 'error',
      //   text: t('SETTINGS.SOMETHING_WENT_WRONG')
      // })

    }
  }

  const isRtl = i18next.language === 'ar';
  const avatar_thumbnail = stringAvatar(data?.core_contact_profile[0]?.display_name);
  const status_color = `sdk_status.${data?.core_contact_meta?.[0]?.status}.solid`;

  const getUserImage = (imgSrc = '') => {
    let defaultImage = `${environment.REACT_APP_ASSETS_ENDPOINT}/metadata/app/default-user.png`;
    const imageSrc: string = JSON.parse(StorageService.getUserDetails() || '{}')?.image;
    if (imageSrc) {
      defaultImage = imageSrc;
    }
    return defaultImage;
  }

  let checkSOModalPosition = ''
  if(isMobile) {
    checkSOModalPosition = isRtl ? "-220px 210px" : "265px 210px"
  } else {
    checkSOModalPosition = isRtl ? "-15px 10px" : "15px 10px"
  }

  const toggleProfileDropdown = () => setOpen(!open)

  return (
    <>
    {isMobile ? 
      <Button ref={buttonRef} type="button" onClick={toggleProfileDropdown}>
        <Avatar src={getUserImage(data?.core_contact_profile?.[0]?.image)} className='mobile-profile-image' />
      </Button>
      : 
      <Tooltip title={t('NAVBAR_TOOLTIP.PROFILE')} placement="right" arrow>
        <Button ref={buttonRef} onClick={toggleProfileDropdown}>
          <Avatar src={getUserImage(data?.core_contact_profile[0]?.image)} className='desktop-profile-image' {...avatar_thumbnail} />
        </Button>
      </Tooltip>
    }

    <ClickAwayListener mouseEvent="onMouseDown" touchEvent="onTouchStart" onClickAway={handleClickAwayListener}>
      <Popper
        open={open}
        anchorEl={buttonRef.current}
        placement={ isRtl ? "auto-start" : "auto-start" }
        sx={{ zIndex: 999, borderRadius: 2 }}
        transition
      >
        {({ TransitionProps }) => ( 
          <Fade {...TransitionProps} timeout={350}>
            <Stack sx={{ borderRadius: 2, backgroundColor: 'light.main', boxShadow: 15 }}>
                {
                  loading ?
                     <Stack flexDirection="row" alignItems="center" gap={2} sx={{ p: 2 }}>
                      <Skeleton variant="circular" width={40} height={40} />
                      <Stack alignItems="flex-start" gap={1}>
                        <Skeleton variant="rounded" width={90} height={25} />
                        <Skeleton variant="rounded" width={95} height={25} />
                      </Stack>
                    </Stack>
                    :
                     <Stack flexDirection="row" alignItems="center" gap={2} sx={{ p: 2 }}>
                        <Avatar src={getUserImage()} style={{ backgroundColor:'transparent', width: 40, height: 40 }} {...avatar_thumbnail} />
                        <Stack alignItems="flex-start" gap={1}>
                          <Stack flexDirection="row" alignItems="center" gap={1}>
                              <Typography fontSize={16} fontWeight={600} lineHeight="24px">{data?.core_contact_profile[0]?.display_name || data?.core_contact_profile[0]?.name}</Typography>
                              <Box sx={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: status_color, marginInlineEnd: 4 }}></Box>
                            </Stack>
                            <Button ref={statusButtonRef} endIcon={<KeyboardArrowDownIcon color="secondary" />} onClick={() => setStatusOpened(true)} sx={{
                              py: 0.5,
                              fontWeight: 600,
                              color: status_color,
                              backgroundColor: `sdk_status.${data?.core_contact_meta?.[0]?.status}.alpha`,
                              '&:hover': { backgroundColor: `sdk_status.${data?.core_contact_meta?.[0]?.status}.alpha2` }
                            }}>
                              {t('USERS_SETTINGS.AVAILABLE_OPTIONS.' + data?.core_contact_meta?.[0]?.status.toUpperCase())}
                            </Button>
                        </Stack>
                      </Stack>
                }

              <Stack sx={{ borderBottom: '1px solid', borderTop: '1px solid', borderColor: 'light.gray3', p: 1 }}>
                <ButtonBase component={Link} to={mode === 'host' ? '/settings/account/profile' : ''} onClick={() => goToSettings('/settings/account/profile')} sx={{ alignItems: 'center', justifyContent: 'flex-start', gap: 2, px: 1, py: 0.5, height: 40, borderRadius: 2 }}>
                  <PersonIcon color="secondary" />
                  <Typography lineHeight="24px" fontSize={16} sx={{ color: 'text.main' }}>{t('SETTINGS.PROFILE')}</Typography>
                </ButtonBase>
                {!isMobile ? 
                <ButtonBase component={Link} to={mode === 'host' ? '/settings/workspace/account-profile' : ''} onClick={() => goToSettings('/settings/workspace/account-profile')} sx={{ alignItems: 'center', justifyContent: 'flex-start', gap: 2, px: 1, py: 0.5, height: 40, borderRadius: 2 }}>
                  <SettingsIcon color="secondary" />
                  <Typography lineHeight="24px" fontSize={16} sx={{ color: 'text.main' }}>{t('SETTINGS.SETTINGS')}</Typography>
                </ButtonBase> : <></>}
              </Stack>

              <Stack sx={{ borderBottom: '1px solid', borderTop: '1px solid', borderColor: 'light.gray3', p: 1 }}>
                <ButtonBase ref={switchOrganizationButtonRef} onClick={() => setSwitchOrganizationOpened(!switchOrganizationOpened)} sx={{ alignItems: 'center', justifyContent: 'flex-start', gap: 2, px: 1, py: 0.5, height: 40, borderRadius: 2 }}>
                  <SyncAltIcon color="secondary" />
                  <Typography lineHeight="24px" fontSize={16} sx={{ color: 'text.main' }}>{t('SETTINGS.SWITCH_ORGANIZATION')}</Typography>
                  { isRtl ? <ChevronLeftIcon color="action" /> : <ChevronRightIcon color="action" /> }
                </ButtonBase>
              </Stack>
              
              <ButtonBase onClick={handleLogout} data-testid="logout-button" sx={{ p: 1.75, alignItems:'center', justifyContent: 'center', gap: 1, color: 'error.main' }}>
                <LogoutRoundedIcon />
                <Typography variant='body1' fontWeight='500'>{t('SETTINGS.LOGOUT')}</Typography>
              </ButtonBase>
              <Popover
                open={statusOpened}
                anchorEl={statusButtonRef.current}
                onClose={() => setStatusOpened(false)}
                anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
                transformOrigin={{ vertical: 'top', horizontal: 'center' }}
                sx={{ '.MuiPaper-root' : { boxShadow: 4, borderRadius: 2 } }}
              >
                <Stack p={1}>
                { Object.entries(t('USERS_SETTINGS.AVAILABLE_OPTIONS', { returnObjects: true })).map(([key, value]: any) => (
                  <Button key={key} sx={{ justifyContent: 'flex-start', width: '100%', minWidth: 100, borderRadius: 2, px: 2.5, color: `sdk_status.${key.toLowerCase()}.solid` }} onClick={() => changeStatus(key)}>{value}</Button>
                ))}
                </Stack>
              </Popover>
            </Stack>
          </Fade>
        )}
      </Popper>
    </ClickAwayListener>

    <Popper
      open={switchOrganizationOpened && open}
      anchorEl={switchOrganizationButtonRef.current}
      placement={ isRtl ? "right-end" : "left-end" }
      sx={{ zIndex: 999, borderRadius: 2, translate: checkSOModalPosition }}
      transition
    >
      {({ TransitionProps }) => ( 
        <Fade {...TransitionProps} timeout={350}>
          <Stack sx={{ borderRadius: 2, backgroundColor: 'light.main', boxShadow: 15 }}>
            {businesses?.map((business:any) => (
              <Stack key={business.business_id} sx={{ p: 1.5 }}>
                <ButtonBase onClick={() => handleSwitchBusiness(business?.business_id)} sx={{ alignItems: 'center', justifyContent: 'flex-start', gap: 2, px: 1, py: 0.5, height: 40, borderRadius: 2 }}>
                  <Stack  flexDirection="row" alignItems="center" gap={2} sx={{ p: 0 }}>
                    <Avatar src={business?.image || getUserImage()} style={{ backgroundColor:'transparent', width: 40, height: 40 }} {...avatar_thumbnail} />
                    <Stack alignItems="flex-start" gap={1}>
                      <Stack flexDirection="row" alignItems="center" gap={1}>
                        <Typography fontSize={16} fontWeight={600} lineHeight="24px">{business?.org_name || business?.org_legal_name}</Typography>
                        <Typography fontSize={16} fontWeight={600} lineHeight="24px"> - {business?.phone_number}</Typography>
                        {business?.business_id === businessId && (
                          <Box sx={{ width: 8, height: 8, borderRadius: '50%', backgroundColor: status_color, marginInlineEnd: 4 }}></Box>
                        )}
                      </Stack>
                    </Stack>
                  </Stack>
                </ButtonBase>
              </Stack>
            ))}
          </Stack>
        </Fade>
      )}
    </Popper>

    <Snackbar open={showSnackbar} message={t('SETTINGS.LOGGING_OUT')} onClose={() => setShowSnackbar(false)} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} />
    </>
  );
}