import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

// Slice
const slice = createSlice({
  name: 'broadcast',
  initialState: {
    //  general
    createStage: 0,
    lastReachedStage: 0,
    editMode: false,
    //  Stage 0
    broadcastName: '',
    broadcastLanguage: '', // Select language as default language
    broadcastSelectedLanguage: '',
    broadcastType: 'instant',
    selectedTemplate: null as any,
    draftBroadcast: null as any,
    
    //  Stage 1
    showContactsPopup: false,
    isContactsPopupTarget: true,
    showTagsPopup: false,
    isTagsPopupTarget: true,
    showCustomFieldsPopup: false,
    showAgentsPopup: false,
    showTeamsPopup: false,
    showCountriesPopup: false,
    
    targetChats: '',
    targetContacts: {} as any,
    targetTags: {} as any,
    targetCustomFields: {} as any,
    targetAgents: {} as any,
    targetTeams: {} as any,
    targetCountries: {
      allCountries: false,
      selectedCountries: {} as any
    } as any,
    targetCustomerCreated: { from: null, to: null },
    targetLastActivity: { from: null, to: null },

    filterOptedOut: false,
    
    blacklistContacts: {} as any,
    blacklistTags: {} as any,
    
    liquidVariables: [] as string[],

    //  Stage 2
    schedule: null as any,
  },
  reducers: {
    //  general
    formReset: (state) => {
      state.createStage = 0;
      state.editMode = false;
      state.broadcastName = '';
      state.broadcastType = 'instant';
      state.selectedTemplate = null;
      state.draftBroadcast = null;
      slice.caseReducers.targetsReset(state, { payload: true, type: 'ALL' });
      state.lastReachedStage = 0;
      state.liquidVariables = [];

      state.schedule = null;
    },
    nextStage: (state) => {
      state.createStage = state.createStage + 1;
      state.lastReachedStage = state.createStage + 1;
    },
    backStage: (state) => {
      state.createStage = state.createStage - 1;
    },
    setStage: (state, action) => {
      state.createStage = action.payload;
    },
    //  Stage 0
    draftBroadcastChanged: (state, action) => {
      state.draftBroadcast = action.payload;
    },
    broadcastDetailsChanged: (state, action) => {
      if(action.payload === null) return;
      state.broadcastName = action.payload.name;
      state.broadcastLanguage = action.payload.language;
      state.broadcastSelectedLanguage = action.payload.selectedLanguage;
      state.broadcastType = action.payload.draft ? 'draft' : 'instant';
      if (state.selectedTemplate?.id !== action.payload?.template?.id) {
        state.liquidVariables = [];
      }
      state.selectedTemplate = action.payload.template;
      state.createStage = 1;
    },
    //  Stage 1
    targetsReset: (state, action) => {
      state.targetChats = '';
      state.targetContacts = {};
      state.targetTags = {};
      state.targetCustomFields = {};
      state.targetAgents = {};
      state.targetTeams = {};
      state.targetCountries = {
        allCountries: false,
        selectedCountries: {}
      };
      state.targetCustomerCreated = { from: null, to: null };
      state.targetLastActivity = { from: null, to: null };
      state.filterOptedOut = false;
      if (action.payload) {
        state.blacklistContacts = {};
        state.blacklistTags = {};
      }
    },
    targetCleared: (state, action) => {
      switch (action.payload) {
        case 'contacts':
          state.targetContacts = {};
          break;
        case 'tags':
          state.targetTags = {};
          break;
        case 'custom_fields':
          state.targetCustomFields = {};
          break;
        case 'agents':
          state.targetAgents = {};
          break;
        case 'teams':
          state.targetTeams = {};
          break;
        case 'countries':
          state.targetCountries = {
            allCountries: false,
            selectedCountries: {}
          };
          break;
        case 'customer_created':
          state.targetCustomerCreated = { from: null, to: null };
          break;
        case 'last_activity':
          state.targetLastActivity = { from: null, to: null };
          break;
        case 'blacklist_contacts':
          state.blacklistContacts = {};
          break;
        case 'blacklist_tags':
          state.blacklistTags = {};
          break;
        default:
          break;
      }
    },
    showContactsPopupChanged: (state, action) => {
      state.isContactsPopupTarget = action.payload.isTarget;
      state.showContactsPopup = action.payload.showContactsPopup;
    },
    showTagsPopupChanged: (state, action) => {
      state.isTagsPopupTarget = action.payload.isTarget;
      state.showTagsPopup = action.payload.showTagsPopup;
    },
    showCustomFieldsPopupChanged: (state, action) => {
      state.showCustomFieldsPopup = action.payload;
    },
    showAgentsPopupChanged: (state, action) => {
      state.showAgentsPopup = action.payload;
    },
    showTeamsPopupChanged: (state, action) => {
      state.showTeamsPopup = action.payload;
    },
    showCountriesPopupChanged: (state, action) => {
      state.showCountriesPopup = action.payload;
    },
    targetChatsChanged: (state, action) => {
      if (action.payload === 'ALL_CONTACTS') {
        slice.caseReducers.targetsReset(state, { payload: false, type: 'TARGETS_ONLY' });
      }
      state.targetChats = action.payload;
    },
    targetContactsChanged: (state, action) => {
      state.targetContacts = action.payload;
    },
    targetTagsChanged: (state, action) => {
      state.targetTags = action.payload;
    },
    targetCustomFieldsChanged: (state, action) => {
      state.targetCustomFields = action.payload;
    },
    targetAgentsChanged: (state, action) => {
      state.targetAgents = action.payload;
    },
    targetTeamsChanged: (state, action) => {
      state.targetTeams = action.payload;
    },
    targetCountriesChanged: (state, action) => {
      state.targetCountries = action.payload;
    },
    targetCustomerCreatedChanged: (state, action) => {
      state.targetCustomerCreated = action.payload;
    },
    targetLastActivityChanged: (state, action) => {
      state.targetLastActivity = action.payload;
    },
    filterOptedOutToggled: (state) => {
      state.filterOptedOut = !state.filterOptedOut;
    },
    blacklistContactsChanged: (state, action) => {
      state.blacklistContacts = action.payload;
    },
    blacklistTagsChanged: (state, action) => {
      state.blacklistTags = action.payload;
    },
    liquidVariableChanged: (state, { payload: { index, value }}) => {
      state.liquidVariables[index] = value;
    },
    
    //  Stage 2
    scheduleChanged: (state, action) => {
      state.schedule = action.payload;
    },
    ///////////////
    draftBroadcastContinued: (state, action) => {
      state.draftBroadcast = action.payload;
      state.createStage = 1;
      state.broadcastName = action.payload.name;
      state.broadcastLanguage = action.payload.template.language;
      state.broadcastSelectedLanguage = action.payload.template.selectedLanguage;
      state.broadcastType = action.payload.status;
      state.selectedTemplate = action.payload.template;
      state.editMode = true;
    },
  },
});

export default slice.reducer;

// Actions
const {
  // general
  formReset, setStage, nextStage, backStage,
  // broadcast details
  broadcastDetailsChanged, draftBroadcastChanged,
  // popups
  showContactsPopupChanged, showTagsPopupChanged, showCustomFieldsPopupChanged, showAgentsPopupChanged, showTeamsPopupChanged, showCountriesPopupChanged,
  // targets
  targetsReset, targetCleared,
  targetChatsChanged, targetContactsChanged, targetTagsChanged, targetCustomFieldsChanged, targetAgentsChanged, targetTeamsChanged, targetCountriesChanged, targetCustomerCreatedChanged, targetLastActivityChanged,
  // optd out
  filterOptedOutToggled,
  // blacklist
  blacklistContactsChanged, blacklistTagsChanged,
  // preview
  liquidVariableChanged,
  // schedule and create
  scheduleChanged,
  
  draftBroadcastContinued,
} = slice.actions;

// General
export const resetForm = (): any => (dispatch: Dispatch): void => {
  dispatch(formReset());
}

export const changeStage = (type: 'next' | 'back' | 'set', stage?: number): any => (dispatch: Dispatch): void => {
  if (type === 'next') {
    dispatch(nextStage());
  } else if (type === 'back') {
    dispatch(backStage());
  } else {
    dispatch(setStage(stage));
  }
}

////////////////
//  Stage 0  ///
////////////////

export const changeDraftBroadcast = (draftBroadcast: any): any => (dispatch: Dispatch): void => {
  dispatch(draftBroadcastChanged(draftBroadcast));
}

export const setBroadcastDetailsAndNext = (broadcastDetails: any): any => (dispatch: Dispatch): void => {
  dispatch(broadcastDetailsChanged(broadcastDetails));
}

////////////////
//  Stage 1  ///
////////////////

export const resetTargets = (): any => (dispatch: Dispatch): void => {
  dispatch(targetsReset(true));
}

export const clearTarget = (target: 'contacts' | 'tags' | 'custom_fields' | 'agents' | 'teams' | 'countries' | 'customer_created' | 'last_activity' | 'blacklist_contacts' | 'blacklist_tags'): any => (dispatch: Dispatch): void => {
  dispatch(targetCleared(target));
}

// Popups status
export const changeShowContactsPopup = (showContactsPopup: boolean, isTarget: boolean = true): any => (dispatch: Dispatch): void => {
  dispatch(showContactsPopupChanged({ showContactsPopup, isTarget }));
}

export const changeShowTagsPopup = (showTagsPopup: boolean, isTarget: boolean = true): any => (dispatch: Dispatch): void => {
  dispatch(showTagsPopupChanged({ showTagsPopup, isTarget }));
}

export const changeShowCustomFieldsPopup = (showCustomFieldsPopup: boolean): any => (dispatch: Dispatch): void => {
  dispatch(showCustomFieldsPopupChanged(showCustomFieldsPopup));
}

export const changeShowAgentsPopup = (showAgentsPopup: boolean): any => (dispatch: Dispatch): void => {
  dispatch(showAgentsPopupChanged(showAgentsPopup));
}

export const changeShowTeamsPopup = (showTeamsPopup: boolean): any => (dispatch: Dispatch): void => {
  dispatch(showTeamsPopupChanged(showTeamsPopup));
}

export const changeShowCountriesPopup = (showCountriesPopup: boolean): any => (dispatch: Dispatch): void => {
  dispatch(showCountriesPopupChanged(showCountriesPopup));
}

// Targets data
export const changeTargetChats = (targetChats: string): any => (dispatch: Dispatch): void => {
  dispatch(targetChatsChanged(targetChats));
}

export const changeTargetContacts = (targetContacts: any): any => (dispatch: Dispatch): void => {
  dispatch(targetContactsChanged(targetContacts));
}

export const changeTargetTags = (targetTags: any): any => (dispatch: Dispatch): void => {
  dispatch(targetTagsChanged(targetTags));
}

export const changeTargetCustomFields = (targetCustomFields: any): any => (dispatch: Dispatch): void => {
  dispatch(targetCustomFieldsChanged(targetCustomFields));
}

export const changeTargetAgents = (targetAgents: any): any => (dispatch: Dispatch): void => {
  dispatch(targetAgentsChanged(targetAgents));
}

export const changeTargetTeams = (targetTeams: any): any => (dispatch: Dispatch): void => {
  dispatch(targetTeamsChanged(targetTeams));
}

export const changeTargetCountries = (targetCountries: any): any => (dispatch: Dispatch): void => {
  dispatch(targetCountriesChanged(targetCountries));
}

export const changeTargetCustomerCreated = (targetCustomerCreated: any): any => (dispatch: Dispatch): void => {
  dispatch(targetCustomerCreatedChanged(targetCustomerCreated));
}

export const changeTargetLastActivity = (targetLastActivity: any): any => (dispatch: Dispatch): void => {
  dispatch(targetLastActivityChanged(targetLastActivity));
}

export const toggleFilterOptedOut = (): any => (dispatch: Dispatch): void => {
  dispatch(filterOptedOutToggled());
}

export const changeBlacklistContacts = (blacklistContacts: any): any => (dispatch: Dispatch): void => {
  dispatch(blacklistContactsChanged(blacklistContacts));
}

export const changeBlacklistTags = (blacklistTags: any): any => (dispatch: Dispatch): void => {
  dispatch(blacklistTagsChanged(blacklistTags));
}

export const changeLiquidVariable = (index: number, value: string): any => (dispatch: Dispatch): void => {
  dispatch(liquidVariableChanged({ index, value }));
}

export const changeSchedule = (schedule: any): any => (dispatch: Dispatch): void => {
  dispatch(scheduleChanged(schedule));
}

//////////////////////////
export const continueDraftBroadcast = (draftBroadcast: any): any => (dispatch: Dispatch): void => {
  dispatch(draftBroadcastContinued(draftBroadcast));
}
