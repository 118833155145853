
export const cell_style: any = {
  fontSize: { xs: 14, md: 14 },
  '&.name': { fontWeight: 500 },
}

export const head_cell_style: any = {
  fontWeight: 600,
  fontSize: { md:"14px", xs:"13px" },
}

export const overflowTextByLines = (lines: number): any => ({
  display: '-webkit-box',
  WebkitLineClamp: lines,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  overflowWrap: 'anywhere',
});
