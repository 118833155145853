import React, { useEffect, useState } from 'react';
import { Button, Stack, TextField } from '@mui/material';

//  utils
import { useTranslation } from 'react-i18next';

//  validations
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { forgot_password } from '../../helpers/auth';
import { useDispatch } from 'react-redux';
import { changeShowResetPasswordNotification } from '../../redux/auth';
import * as Sentry from "@sentry/react";
import { MAX_EMAIL_LENGTH } from '../../helpers/constants';

const inputProps = {
  disableUnderline: true,
  sx: { borderRadius: 2.5, backgroundColor: 'transparent !important', border: '1px solid', borderColor: 'light.gray8' }
};

export const ForgotPassword = ({tab, setTab}): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [btnDisabled, setDisabled] = useState<boolean>(false);

  const schema = yup.object().shape({
    email: yup.string().max(MAX_EMAIL_LENGTH,"VALIDATIONS.INVALID_MAX_EMAIL_LENGTH").email('VALIDATIONS.INVALID_EMAIL').required('LOGIN.REQUIRED_EMAIL'),
  });

  const { control, handleSubmit, setValue, clearErrors ,formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema)
  });

  const sendResetLink = async (payload: any) => {
    try {
      const response = await forgot_password(payload);
      const { status, data: { success } } = response
      if (status === 200 && success === true) {
        dispatch(changeShowResetPasswordNotification());
        setDisabled(true);
        setTimeout(() => {
          setTab(1);
          setDisabled(false);
          setValue("email", '');
        }, 3000)
      }
    } catch (error) {
      Sentry.captureException(error);
    }
  }

  useEffect(() => clearErrors(), [tab]);

  return (
    <Stack>

      <Controller name='email' control={control} render={({ field: { value, onChange, onBlur } }) => (
        <TextField label={t('LOGIN.EMAIL_ADDRESS')} type="email" name='email' variant="filled" InputProps={inputProps} sx={{ mt: 2 }} value={value || ''} onChange={onChange} onBlur={onBlur} error={Boolean(errors.email)} helperText={t(errors.email?.message || '')} />
      )} />
      <Button disabled={btnDisabled} onClick={handleSubmit(sendResetLink)} disableElevation variant="contained" sx={{ width: '100%', mt: 3, mb: 2, borderRadius: 2.5, fontSize: 15, fontWeight: 600 }}>{t('LOGIN.SEND_RESET_LINK')}</Button>

    </Stack>
  );
}
