// eslint-disable-next-line import/no-anonymous-default-export
export default {
  REACT_APP_VERSION: process.env?.REACT_APP_VERSION || "v1-dev",
  REACT_APP_AVOCADO_ENV: process.env?.REACT_APP_AVOCADO_ENV || "local-dev", // dev, stg, prd, local-dev -> avoid sentry and other prod integrations
  REACT_APP_TYPE: process.env?.REACT_APP_TYPE || "HOST",
  REACT_APP_HOST_ID: process.env?.REACT_APP_HOST_ID || "186f1b43-f7e5-4fce-a898-53afae8df838",
  REACT_APP_GM_API_KEY: process.env?.REACT_APP_GM_API_KEY || "AIzaSyAwyQZB_6ALaHelIX8tJv5NT5pilRlkt64",
  REACT_APP_LOGROCKET_APP_ID: process.env?.REACT_APP_LOGROCKET_APP_ID || "svbjdz/avocado",
  REACT_APP_360D_PARTNER_ID: process.env?.REACT_APP_360D_PARTNER_ID || "83fmVkPA",
  REACT_APP_ASSETS_ENDPOINT: process.env?.REACT_APP_ASSETS_ENDPOINT || "https://assets.avocad0.dev",
  REACT_APP_API_GATEWAY_ENDPOINT: process.env?.REACT_APP_API_GATEWAY_ENDPOINT || "https://test.api.avocad0.dev/v1",
  REACT_APP_HUB_API_ENDPOINT:
    process.env?.REACT_APP_HUB_API_ENDPOINT ||
    "https://test.hub.avocad0.dev/api/v1",
  REACT_APP_LANDBOT_API_ENDPOINT:
    process.env?.REACT_APP_LANDBOT_API_ENDPOINT ||
    "https://igt.avocad0.dev/api/v1",
  REACT_APP_HUB_HASURA_ENDPOINT:
    process.env?.REACT_APP_HUB_HASURA_ENDPOINT ||
    "https://test.hub.avocad0.dev/api/v1",
  REACT_APP_HASURA_ENDPOINT:
    process.env?.REACT_APP_HASURA_ENDPOINT ||
    "https://hasura.avocad0.dev/v1",
  REACT_APP_HUB_SALLA_APP_LINK:
    process.env?.REACT_APP_HUB_SALLA_APP_LINK ||
    "https://apps.salla.sa/en/preview/439826754",
  REACT_APP_HUB_SHOPIFY_APP_LINK:
    process.env?.REACT_APP_HUB_SHOPIFY_APP_LINK ||
    "https://admin.shopify.com/store/{STORE_SLUG}/settings/apps/app_installations/app/avocado-5",
  REACT_APP_HUB_ZID_APP_LINK:
    process.env?.REACT_APP_HUB_ZID_APP_LINK ||
    "https://web.zid.sa/market/app/2819",
  REACT_APP_CALENDLY_TOKEN: process.env?.REACT_APP_CALENDLY_TOKEN || null,
  REACT_APP_WEBSOCKET_URL:
    process.env?.REACT_APP_WEBSOCKET_URL ||
    "wss://realtime.avocad0.dev/heartbeat",
  PORT: process.env?.PORT || "3000",
  REACT_APP_HUB_API_PRIVATE_KEY:
    process.env?.REACT_APP_HUB_API_PRIVATE_KEY ||
    "2SI6IkpvaG4gRG9lIiwiaW18166275918",
  REACT_APP_SENTRY_DSN:
    process.env?.REACT_APP_SENTRY_DSN ||
    "https://8b6b72ed25740e8fdfa06fc0a57db459@o422913.ingest.us.sentry.io/4506937273876480",
  REACT_APP_ENABLE_SENTRY: process.env?.REACT_APP_ENABLE_SENTRY || "false",
  REACT_APP_FOODER_API_ENDPOINT:
    process.env?.REACT_APP_FOODER_API_ENDPOINT ||
    "https://dev-salver.fooder.in/api/v1",
  REACT_APP_FOODER_API_KEY:
    process.env?.REACT_APP_FOODER_API_KEY ||
    "YYPXN8qymQrICmV5AMp4Av4zcTjhjRYwxvG1YZb7KMEFeWxfxKDJQ7xjcVRgo9AK",
  REACT_APP_FOODER_APP_LINK:
    process.env?.REACT_APP_FOODER_APP_LINK || "https://dev-kobra.fooder.in",
  REACT_APP_PARAGON_PROJECT_ID:
    process.env?.REACT_APP_PARAGON_PROJECT_ID || "c965ebd1-7724-4daf-a442-0952d674cee8",
  REACT_APP_PARAGON_SIGNING_KEY: process.env?.REACT_APP_PARAGON_SIGNING_KEY ||"",
  REACT_APP_FCM_API_KEY: process.env?.REACT_APP_FCM_API_KEY || "AIzaSyCCLAQjHBxLS4wpf4ovJCRcjcqqLPYifUc",
  REACT_APP_FCM_AUTH_DOMAIN:
    process.env?.REACT_APP_FCM_AUTH_DOMAIN || "avocado-403608.firebaseapp.com",
  REACT_APP_FCM_PROJECT_ID:
    process.env?.REACT_APP_FCM_PROJECT_ID || "avocado-403608",
  REACT_APP_FCM_STORAGE_BUCKET:
    process.env?.REACT_APP_FCM_STORAGE_BUCKET || "avocado-403608.appspot.com",
  REACT_APP_FCM_MSG_SENDER_ID:
    process.env?.REACT_APP_FCM_MSG_SENDER_ID || "899600826777",
  REACT_APP_FCM_APP_ID:
    process.env?.REACT_APP_FCM_APP_ID ||
    "1:899600826777:android:94d3ae2b845d4fdfe21308",
  REACT_APP_FCM_VAPID_KEY:
    process.env?.REACT_APP_FCM_VAPID_KEY ||
    "BLSthbV73ZlnkISlUHpkx1ixhBnZY5OX0sskwX_m0Vit75qLopCCUFA_XY-nrT4XgJGt0ya0ARhR0QBaGDFiiDU",
  REACT_APP_ZOHO_DESK_APP_ID:
    process.env?.REACT_APP_ZOHO_DESK_APP_ID || "527933000050844021",
  REACT_APP_ZOHO_DESK_ORG_ID:
    process.env?.REACT_APP_ZOHO_DESK_ORG_ID || "723454380",
    REACT_APP_SECRET_ENCRYPTION_KEY: process.env?.REACT_APP_SECRET_ENCRYPTION_KEY || "avc-secret-195357",
};
