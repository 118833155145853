import React, { createContext, useState, useContext, useCallback, ReactNode } from 'react';
import { Snackbar } from '@mui/material';
import { useTranslation } from 'react-i18next';

interface SnackbarContextType {
  (): void;
}

const SnackbarContext = createContext<SnackbarContextType | undefined>(undefined);

export const useSnackbar = (): SnackbarContextType => {
  const context = useContext(SnackbarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
};

interface SnackbarProviderProps {
  children: ReactNode;
}

export const SnackbarProvider: React.FC<SnackbarProviderProps> = ({ children }) => {
  const { t } = useTranslation();
  const [snackbar, setSnackbar] = useState<{ open: boolean; message: string }>({
    open: false,
    message: '',
  });

  const showSnackbar = useCallback(() => {
    setSnackbar({ open: true, message: t('AUTH.ACCESS_DENIED') });

    setTimeout(() => {
      setSnackbar({ open: false, message: '' });
    }, 2000);
  }, [t]);

  const handleCloseSnackbar = useCallback(() => {
    setSnackbar({ open: false, message: '' });
  }, []);

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <Snackbar
        open={snackbar.open}
        message={snackbar.message}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        sx={{ width: 'fit-content !important' }}
      />
    </SnackbarContext.Provider>
  );
};
