import { createSlice } from "@reduxjs/toolkit";
import { Dispatch } from "redux";

export interface AbandonedCartsFilter {
  from_date: string;
  to_date: string;
  total: number;
  products_count: number;
}

const slice = createSlice({
  name: "stores",
  initialState: {
    storeID: "" as string,
    abandonedCartsGetRequest: {} as AbandonedCartsFilter,
    maxCost: 0 as number,
    currency: "SAR" as string,
    messageCostRate: 0.0 as number,
    estimatedRevenue: 0 as number,
    attachedTemplates: {} as any,
    orderStatus: "" as string,
    openInstallDialog: false as boolean,
    isRunCampaignStepsCompleted: false as boolean,
    toastContext: "" as string,
    toastTimer: 0 as number,
    toastType: "" as string,
    templateVariables: [] as string [],
  },
  reducers: {
    setStoreID: (state, action) => {
      state.storeID = action.payload;
    },
    setAbandonedCartsGetRequest: (state, action) => {
      state.abandonedCartsGetRequest = action.payload;
    },
    setEstimatedRevenue: (state, action) => {
      state.estimatedRevenue = action.payload;
    },
    setMaxCost: (state, action) => {
      state.maxCost = action.payload;
    },
    setCurrency: (state, action) => {
      state.currency = action.payload;
    },
    setMessageCostRate(state, action) {
      state.messageCostRate = action.payload;
    },
    setAttachedTemplate: (state, action) => {
      state.attachedTemplates = {
        ...action.payload
      };
    },
    setOrderStatus: (state, action) => {
      state.orderStatus = action.payload;
    },
    setOpenInstallDialog: (state, action) => {
      state.openInstallDialog = action.payload;
    },
    setIsRunCampaignStepsCompleted: (state, action) => {
      state.isRunCampaignStepsCompleted = action.payload;
    },
    setToastContent: (state, action) => {
      state.toastContext = action.payload;
    },
    setToastTimer: (state, action) => {
      state.toastTimer = action.payload;
    },
    setToastType: (state, action) => {
      state.toastType = action.payload;
    },
    setTemplateVariables: (state, action) => {
      state.templateVariables = action.payload;
    },
  },
});

export default slice.reducer;

const {
  setStoreID,
  setAbandonedCartsGetRequest,
  setIsRunCampaignStepsCompleted,
  setEstimatedRevenue,
  setMaxCost,
  setCurrency,
  setMessageCostRate,
  setOrderStatus,
  setToastContent,
  setToastTimer,
  setOpenInstallDialog,
  setAttachedTemplate,
  setToastType,
  setTemplateVariables} = slice.actions;

export const changeStoreID = (storeID: string): any => (dispatch: Dispatch): void => {
    dispatch(setStoreID(storeID));
}

export const updateAbandonedCartsBodyRequest = (abandonedCartsBodyRequest: AbandonedCartsFilter): any => (dispatch: Dispatch): void => {
  dispatch(setAbandonedCartsGetRequest(abandonedCartsBodyRequest));
}

export const updateEstimatedRevenue = (etimatedRevenue: number): any => (dispatch: Dispatch): void => {
  dispatch(setEstimatedRevenue(etimatedRevenue));
}

export const updateMaxCost = (maxCost: number): any => (dispatch: Dispatch): void => {
  dispatch(setMaxCost(maxCost));
}
export const updateCurrency = (currency: string): any => (dispatch: Dispatch): void => {
  dispatch(setCurrency(currency));
}
export const updateMessageCostRate = (rate: number): any => (dispatch: Dispatch): void => {
  dispatch(setMessageCostRate(rate));
}

export const updateAttachedTemplate = (template: {[k:string]: string}): any => (dispatch: Dispatch): void => {
  dispatch(setAttachedTemplate(template));
}

export const updateOrderStatus = (orderStatus: string): any => (dispatch: Dispatch): void => {
  dispatch(setOrderStatus(orderStatus));
}

export const updateOpenInstallDialog = (openInstallDialog: boolean): any => (dispatch: Dispatch): void => {
  dispatch(setOpenInstallDialog(openInstallDialog));
}

export const updateIsRunCampaignStepsCompleted = (isRunCampaignStepsCompleted: boolean): any => (dispatch: Dispatch): void => {
  dispatch(setIsRunCampaignStepsCompleted(isRunCampaignStepsCompleted));
}

export const updateToastContent = (content: string): any => (dispatch: Dispatch): void => {
  dispatch(setToastContent(content));
}

export const updateToastTimer = (seconds: number): any => (dispatch: Dispatch): void => {
  dispatch(setToastTimer(seconds));

  setTimeout(() => {
    dispatch(setToastTimer(0));
  }, seconds * 1000);
}

export const updateToastType = (type: string): any => (dispatch: Dispatch): void => {
  dispatch(setToastType(type));
}
export const updateTemplateVariables = (variables: string[]): any => (dispatch: Dispatch): void => {
  dispatch(setTemplateVariables(variables));
}