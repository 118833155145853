import { createTheme } from '@mui/material';
import { createSlice } from '@reduxjs/toolkit';
import { SendJsonMessage } from 'react-use-websocket/dist/lib/types';
import { Dispatch } from 'redux';
import { Mode } from '../models/sdk';
import SDK from '../sdk/sdk';
import { theme_factory } from '../theme/theme';
import { StorageService } from '../storage';

// Slice
const authenticatedInitialValue: boolean = (StorageService.getAuthToken() && StorageService.getAuthToken()?.startsWith('Bearer')) && StorageService.getUserDetails() && StorageService.getBusinessId() ? true : false;

const slice = createSlice({
  name: 'sdk',
  initialState: {
    currentRoute: { path: '/', params: {} as any },
    previousRoute: { path: '', params: {} as any },
    mode: '' as Mode,
    routesStack: [] as any[],
    sdk: null as SDK | null,
    authenticated: authenticatedInitialValue,
    theme: theme_factory(),
    websocketsEmitter: null as SendJsonMessage | null,
    // websocketRawEmitter: null as SendMessage | null, // #TODO: To be used in future
    // websocketInstance: null as WebSocketLike | null, // #TODO: To be used in future
  },
  reducers: {
    currentRouteChanged: (state, action) => {
      const newRoute = { path: action.payload.path, params: action.payload.params };
      state.currentRoute = newRoute;
      state.routesStack.push(newRoute);
      state.previousRoute = newRoute;   //  not changed by backNavigation
      if (action.payload.path === '/login') {
        state.authenticated = false;
      }
    },
    backNavigation: (state) => {
      state.routesStack.pop();
      if (!state.routesStack.length) return;
      
      state.currentRoute = state.routesStack[state.routesStack.length - 1];

    },
    modeChanged: (state, action) => {
      if (!state.mode) {
        state.mode = action.payload.mode;
      }
    },
    initialize: (state, action) => {
      if (!action.payload.sdk || state.sdk) return;
      state.sdk = action.payload.sdk;
    },
    paramsChanged: (state, action) => {
      const { key, value } = action.payload;
      state.currentRoute.params[key] = value;
      if (state.routesStack.length > 0) state.routesStack[state.routesStack.length - 1].params[key] = value;
    },
    authenticatedChanged: (state, action) => {
      state.authenticated = action.payload;
    },
    themeChanged: (state, action) => {
      state.theme = createTheme(action.payload) as any;
    },
    websocketsEmitterChanged: (state, action) => {
      state.websocketsEmitter = action.payload;
    },
    // websocketsRawEmitterChanged: (state, action) => {
    //   state.websocketRawEmitter = action.payload;
    // },
    // websocketLikeChanged: (state, action) => {
    //   state.websocketInstance = action.payload;
    // }
  },
});

export default slice.reducer;

// Actions
const { currentRouteChanged, backNavigation, modeChanged, initialize, paramsChanged, authenticatedChanged, themeChanged, websocketsEmitterChanged } = slice.actions;

export const setCurrentRoute = (path: string, params: any = {}): any => (dispatch: Dispatch): void => {
  dispatch(currentRouteChanged({ path, params }));
}

export const navigateBack = (): any => (dispatch: Dispatch): void => {
  dispatch(backNavigation());
}

export const setMode = (mode: string): any => (dispatch: Dispatch): void => {
  dispatch(modeChanged({ mode }));
}

export const init = (sdk: SDK): any => (dispatch: Dispatch): void => {
  dispatch(initialize({ sdk }));
}

export const setParams = (key: string, value: any): any => (dispatch: Dispatch): void => {
  dispatch(paramsChanged({ key, value }));
}

export const setAuthenticated = (value: boolean): any => (dispatch: Dispatch): void => {
  dispatch(authenticatedChanged(value));
}

export const setTheme = (new_theme: any): any => (dispatch: Dispatch): void => {
  dispatch(themeChanged(new_theme));
}

export const setWebsocketsEmitter = (emitter: SendJsonMessage): any => (dispatch: Dispatch): void => {
  dispatch(websocketsEmitterChanged(emitter));
}

// export const setWebsocketRawEmitter = (emitter: SendMessage): any => (dispatch: Dispatch): void => {
//   dispatch(websocketsRawEmitterChanged(emitter));
// }

// export const setWebsocketLike = (websocket: WebSocketLike | null): any => (dispatch: Dispatch): void => {
//   dispatch(websocketLikeChanged(websocket));
// }
