import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

// Slice
const slice = createSlice({
  name: 'templates',
  initialState: {
    filter: {
      status: '',
      language: '',
      category: '',
      search: '',
      page: 1,
    } as any,
    showNoChannelWarning: false as boolean,
  },
  reducers: {
    filterChanged: (state, action) => {
      state.filter = action.payload;
    },
    setShowNoChannelWarning: (state, action) => {
      state.showNoChannelWarning = action.payload;
    }
  },
});

export default slice.reducer;

// Actions
const { filterChanged, setShowNoChannelWarning } = slice.actions;

export const changeFilter = (filter: any): any => (dispatch: Dispatch): void => {
  dispatch(filterChanged(filter));
}

export const changeShowNoChannelWarning = (show: boolean): any => (dispatch: Dispatch): void => {
  dispatch(setShowNoChannelWarning(show));
}
