import { ApolloClient, ApolloLink, InMemoryCache, split } from "@apollo/client";
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition, Observable } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { BatchHttpLink } from "@apollo/client/link/batch-http";
import { logout } from "../helpers/auth";
import environment from "../environment";
import { StorageService } from "../storage";
import { onError } from '@apollo/client/link/error';
import * as Sentry from '@sentry/react';
import { showToast } from '../helpers/toast';

const graphqlURI = environment.REACT_APP_HASURA_ENDPOINT as string + '/graphql';

const getStackTrace = () => {
  const obj = {stack : ''};
	Error.captureStackTrace(obj, getStackTrace);
	return obj.stack;
};

// Define a custom link for response interception
const responseInterceptorLink = new ApolloLink((operation, forward) => {
  return new Observable(observer => {
		forward(operation).subscribe({
      next: response => {
				// Intercept and modify the response if needed
				if (response.errors?.[0].extensions.code === 'invalid-jwt') {
					logout('SESSION_TIMED_OUT');
				}
				// Pass the response along to the next link
				observer.next(response);
			},
      error: error => {
				// Enhanced error logging
				const errorDetails = {
					message: error.message,
					error_full: error,
					stack: error.stack || getStackTrace(),
					operationName: operation.operationName,
					variables: operation.variables,
					query: operation.query.loc?.source.body,
					component: operation.getContext().componentName, // Assumes you pass component info in context
				};

				// Log detailed error information
				console.error('GraphQL Error:', JSON.stringify(errorDetails, null, 2));

        if (error.message.startsWith('server returned results with length 1, expected length of ')) {
					logout('SESSION_TIMED_OUT');
				}

				observer.error(error);
			},
			complete: () => {
				observer.complete();
      }
		});
	});
});

const errorLink = onError(({ graphQLErrors }) => {

  const invalidErrorMsgs = ["Could not verify JWT: JWTExpired", "Malformed Authorization header"];
  const filteredErrors = graphQLErrors?.filter(err => !invalidErrorMsgs.includes(err.message));

	if ( filteredErrors && (environment.REACT_APP_AVOCADO_ENV != 'local-dev') ) {
		filteredErrors.forEach(({ message, locations, path }) => {
			Sentry.captureException('Apollo Error ocurred', {
				data: `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
			});

			showToast({
				text: message,
				type: 'error',
			});
		});
	}
});

const cache = new InMemoryCache();

export const client_factory = () => {
	const headers = {
		'x-hasura-role': 'admin',
		authorization: StorageService.getAuthToken() as string,
  }

	//  for subscriptions
  const wsLink = new GraphQLWsLink(createClient({
			url: graphqlURI.replace('https://', 'wss://'),
			connectionParams: {
      headers
			},
  }));

	// for batch queries
	const batchHttpLink = new BatchHttpLink({
		uri: graphqlURI,
		batchMax: 5,
		batchInterval: 30,
		batchDebounce: true,
		headers,
		credentials: 'include',
	});

	//  split based on operation type (subscription, query, mutation)
	const splitLink = split(
		({ query }) => {
			const definition = getMainDefinition(query);
			return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
			);
		},
		wsLink,
    batchHttpLink,
	);

  const finalLink = ApolloLink.from([errorLink, responseInterceptorLink, splitLink]);

	return new ApolloClient({
		link: finalLink,
    cache
	});
}
