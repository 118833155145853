import React, { useState, Suspense } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from "@sentry/react";

//  components
import { LoadingSpinner } from '../../components/Common/LoadingSpinner';
import Error from '../../pages/Error';

//  redux
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

//  models
import { LazyProps } from '../../models/Common';

//  utils
// import LogRocket from 'logrocket';

//  enums
const enum ErrorType {
  CHUNK_LOAD_FAILURE = 'CHUNK_LOAD_FAILURE',
  OTHER_ERROR = 'OTHER_ERROR'
}

export const Lazy = ({ component }: LazyProps): JSX.Element => {
  //  redux states
  const currentRoute = useSelector((state: RootState) => state.sdk.currentRoute);

  //  local states
  const [errorType, setErrorType] = useState(ErrorType.CHUNK_LOAD_FAILURE);

  return (
    <ErrorBoundary
      resetKeys={[currentRoute]}
      onReset={() => setErrorType(ErrorType.CHUNK_LOAD_FAILURE)}
      fallback={ errorType === ErrorType.CHUNK_LOAD_FAILURE ? <LoadingSpinner /> : <Error type={500} />}
      onError={(error) => {
        Sentry.captureException(error);
        if (/Loading chunk [\d]+ failed/.test(error.message)) {
          setErrorType(ErrorType.CHUNK_LOAD_FAILURE);
          window.location.reload();
        } else {
          setErrorType(ErrorType.OTHER_ERROR);
        }
        // ## WE WILL USE IT LATERM BUT LET'S DISABLE IT FOR NOW
        // LogRocket.captureException(error, {
        //   tags: { type: 'chunk load failure' },
        //   extra: { reason: 'Loading ' + (component as any)?._source?.fileName }
        // });
      }}
    >
      <Suspense fallback={<LoadingSpinner />}>
        { component }
      </Suspense>
    </ErrorBoundary>
  )
}