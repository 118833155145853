import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, ButtonBase, FormHelperText, Checkbox, Stack, Typography, TextField } from '@mui/material';

//  utils
import { useTranslation } from 'react-i18next';

//  helpers
import { signup } from '../../helpers/auth';
import { publish } from '../../helpers/events';

//  validations
import * as yup from 'yup';
import { useForm,  Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//  redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setAuthenticated, setCurrentRoute } from '../../redux/sdk';
import { StorageService } from '../../storage';
import * as Sentry from "@sentry/react";

export const Purpose = ({ setTab, detailsRef }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const mode = useSelector((state: RootState) => state.sdk.mode);

  const schema = yup.object().shape({
    purpose: yup.array().of(yup.string()).required().min(1),
    other_purpose: yup.string().when("purpose", {
      is: (value: string[]) => value.includes('other'),
      then: () => yup.string().required("Must enter other purpose")
    })
  });

  const { control, handleSubmit, formState: { errors } } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
    defaultValues: { purpose: [] }
  });

  const next = async ({ purpose, other_purpose }: any) => {
    const _purpose = [...purpose];
    const other_index = _purpose.indexOf('other');
    if (other_index !== -1) {
      _purpose[other_index] = other_purpose;
    }
    detailsRef.current.realm.purpose = _purpose.join(', ');
    submit();
  }
  
  const skip = async () => {
    detailsRef.current.realm.purpose = "N/A";
    submit();
  }

  const submit = async () => {
    const token = new URLSearchParams(window.location.search).get('verified') as string;
    try {
      const data = await signup(detailsRef.current, token);
      StorageService.setAuthToken(data.headers.Authorization);
      StorageService.setAvcBillingSource(data.billing_source);
      StorageService.setBusinessId(data.headers['x-hasura-business-id']);
      StorageService.setUserId(data.headers['x-hasura-user-id']);
      StorageService.setUserEmail(detailsRef.current.email);
      StorageService.setUserDetailsAsJson(data.user_details);

      Sentry.setUser({ email: detailsRef?.current?.email , id: data.headers['x-hasura-user-id'] });
      Sentry.setTag("avc-business_id", data.headers['x-hasura-business-id']);      
      Sentry.setTag("avc-token", data.headers.Authorization);


      window.sdk.authorize();
      publish('LOGIN', {});
      dispatch(setAuthenticated(true));
      
      // const home = window.screen.width <= 786 ? '/chat' : '/dashboard/overview';
      const home = '/chat';
      if (mode === 'host') navigate(home);
      dispatch(setCurrentRoute(home));
    } catch (error) {
      Sentry.captureException(error);
      console.error(error);
    }
  }
  
  const purposesOptions: any = t('LOGIN.PURPOSES', { returnObjects: true });

  const handleClick = (key: string, value: any[], onChange: any) => {
    if (!value.includes(key)) onChange([ ...value, key ]);
    else onChange(value.filter(purpose => key !== purpose));
  }

  return (
    <Stack alignItems="center">
      <Typography fontSize={{md:14, xs:12, sm:13}} fontWeight={500} textAlign="center" sx={{ color: 'text.secondary', mb: 2 }}>{t('LOGIN.PURPOSE_SUBTITLE')}</Typography>
      <Controller name='purpose' control={control} render={({ field: { value, onChange } }) => 
        <Stack sx={{ width: '100%', overflowY: 'auto', gap: {md:2, xs:1.5}, p: {md:3, sm:2, xs: 1}, boxSizing: 'border-box' }}>
        { Object.entries(purposesOptions).map(([key, text]: any) => (
          <ButtonBase key={key} onClick={() => handleClick(key, value, onChange)} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2, px: {md:3, xs:1.5}, py: {md:2, xs:1}, width: '100%', boxShadow: 7, borderRadius: 2 }}>
            <Typography>{text}</Typography>
            <Checkbox value={key} checked={value.includes(key)} onMouseDown={e => e.stopPropagation()} />
          </ButtonBase>
        ))}
          <ButtonBase onClick={() => handleClick('other', value, onChange)} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', gap: 2, px: {md:3, xs:1.5}, py: {md:2, xs:1}, width: '100%', boxShadow: 7, borderRadius: 2 }}>
            <Controller name='other_purpose' control={control} render={({ field: { value, onChange, onBlur } }) => (
              <TextField variant='standard' value={value} onChange={onChange} onBlur={onBlur} placeholder={t('LOGIN.SOMETHING_ELSE').toString()} InputProps={{ disableUnderline: true }} />
            )} />
            <Checkbox value={'other'} checked={value.includes('other')} onMouseDown={e => e.stopPropagation()} />
          </ButtonBase>
        </Stack>
      } />
      { errors.purpose && <FormHelperText data-testid="purpose-error" sx={{ color: 'error.main', width: '100%', boxSizing: 'border-box', paddingInlineStart: 4 }}>{ errors.purpose.message }</FormHelperText> }
      { errors.other_purpose && <FormHelperText data-testid="other_purpose-error" sx={{ color: 'error.main', width: '100%', boxSizing: 'border-box', paddingInlineStart: 4 }}>{ errors.other_purpose.message }</FormHelperText> }
      <Stack flexDirection="row" alignItems="center" gap={2} sx={{ mt: 2, px: 6, width: '100%', boxSizing: 'border-box' }}>
        <Button onClick={handleSubmit(next)} disableElevation variant="contained" sx={{ flex: 1, borderRadius: 2, fontSize: 15, fontWeight: 600 }}>{t('COMMON.CONTINUE')}</Button>
        <Button onClick={skip} variant="outlined" sx={{ flex: 1, borderRadius: 2, fontSize: 15, fontWeight: 600 }}>{t('COMMON.SKIP')}</Button>
      </Stack>
    </Stack>
  );
}