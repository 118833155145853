import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, ButtonBase, Typography } from '@mui/material';
//  models
import { SettingButtonProps } from '../../models/Settings';
//  redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setCurrentRoute } from '../../redux/sdk';
import { useSnackbar } from '../Common/NotAuthSnackbar';


export const SettingButton = ({ text, route, icon: Icon, isActive, style, onClick, changeRoute = true, hideTextInResponsive = false, disabled = false }: SettingButtonProps): JSX.Element => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const showSnackbar = useSnackbar();
	const textColor: string = disabled? 'light.gray5' : 'text.secondary';
	const boxColor: string = isActive? 'primary.main' : (disabled? 'light.gray5' : 'secondary.main');

    const mode = useSelector((state: RootState) => state.sdk.mode);

	const handleClick = () => {
		if(disabled) {
            showSnackbar();
        } else if (changeRoute) {
			if (mode === 'host') navigate(route);
			dispatch(setCurrentRoute(route));
		}
		onClick?.();
	};
	return (
		<ButtonBase data-testid='setting-button' onClick={handleClick} sx={{ p: 1.5, alignItems: 'center', justifyContent: 'flex-start', gap: 1, borderRadius: 2.5, backgroundColor: isActive ? 'light.gray13' : 'light.main', ...style }}>
			<Box sx={{ height: 24, color: boxColor }}>{Icon}</Box>
			<Typography color={textColor} fontSize='14px' fontWeight='500' sx={{ minWidth: 'max-content', display: hideTextInResponsive ? { xs: 'none', md: 'block' } : '' }}>
				{text}
			</Typography>
		</ButtonBase>
	);
};