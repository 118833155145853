export const decodeBase64 = (base64String: string) => {
  try {
    // Decode base64 to binary string
    const binaryString = atob(base64String);

    // Convert binary string to an array of 8-bit unsigned integers
    const byteArray = new Uint8Array(
      [...binaryString].map((char) => char.charCodeAt(0))
    );

    // Decode as UTF-8 text
    const utf8Decoder = new TextDecoder("utf-8");
    return utf8Decoder.decode(byteArray);
  } catch (error) {
    console.error("Error decoding base64 string:", error);
    throw new Error("Invalid base64 input");
  }
};
