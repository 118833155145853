import React, { useEffect } from 'react'
import { StorageService } from '../storage';
import { Login } from '../pages/Login';
import { useNavigate } from 'react-router-dom';
import { isTokenExpired } from '../helpers/auth';

export const LoginGuard = () => {
    const token: string | null = StorageService.getAuthToken();
    const navigate = useNavigate();

    useEffect(() => {
        if(token && !isTokenExpired(token.split(" ")[1])) navigate('/chat');
    }, [])
    if(!token) return <Login />
    // else navigate('/chat');
}
