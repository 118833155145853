import React, { ReactNode } from "react";
import { AvcModulesKeys,  } from "../helpers/constants";
import { useNavigate, } from "react-router-dom";
import { RootState } from "../redux/store";
import { useDispatch, useSelector } from "react-redux";
import { setCurrentRoute } from "../redux/sdk";
import useAppIntegration from "../helpers/hooks/useAppIntegration";
import { isPermitted } from "../helpers/auth";
import { NotAuthorized } from "../layouts/Login/NotAuthorized";

type Props = {
  children: ReactNode
  moduleKey: AvcModulesKeys,
  path: string,
  action?: string
}

const ProtectedRoute = ({ children, moduleKey, path, action = 'FULL' }: Props): JSX.Element => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const mode = useSelector((state: RootState) => state.sdk.mode);
  const { isModuleAllowed} =useAppIntegration();
  const isVisible = isModuleAllowed(moduleKey);
  const role: string | null = useSelector((state: RootState) => state.auth.userRole);

  if(!role) {
    if (!isVisible) {
      (mode === 'host') ? navigate(path): dispatch(setCurrentRoute(path));
     }
     return isVisible ? <>{ children }</> : <></> ;
  } else {
    const module = moduleKey.toUpperCase();
    
    const canUserAccess = isPermitted({ role, module, action, returnType: 'bool' });

    
    if(canUserAccess) return <>{ children }</>;
    else return <NotAuthorized />
  }
}

export default ProtectedRoute
