import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import SDK from './sdk/sdk';
import environment from "./environment";

// import { inject } from '@vercel/analytics';
import { swDev } from './swDev';
// inject();

import * as Sentry from "@sentry/react";
import { StorageService } from './storage';


// Do not run, if the env is dev
if (environment.REACT_APP_ENABLE_SENTRY != "false") {

  Sentry.init({
    dsn: environment.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
    environment: environment.REACT_APP_AVOCADO_ENV,
    release: environment.REACT_APP_VERSION,
    tracesSampleRate: 1.0,
    tracePropagationTargets: ["localhost", /^https:\/\/.*\.avocad0\.dev$/, /^https:\/\/.*\.mottasl\.ai$/],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
    beforeSend(event, hint) {
      Sentry.setUser({email: StorageService.getUserEmail() || "na@avocad0.dev", name: StorageService.getUserId() || "no-user"})
      if (event.exception && event.event_id && environment.REACT_APP_AVOCADO_ENV === "prd") {
        Sentry.showReportDialog({ eventId: event.event_id });
      }

      return event;
    },
  });

  // if (environment.REACT_APP_AVOCADO_ENV === "prd") {
  //   Sentry.addIntegration(Sentry.feedbackIntegration({
  //     colorScheme: "light",
  //     showEmail: false,
  //     showName: false,
  //     useSentryUser: {email: StorageService.getUserEmail() || "na@avocad0.dev", name: StorageService.getUserId() || "no-user"},
  //   }))
  // }
}


const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const sdk = new SDK();
sdk.authorize();

const initial_path =
  window.location.pathname !== '/' ? window.location.pathname :
  window.screen.width <= 786 ? '/chat' :
  '/chat';
// '/dashboard/overview';

root.render(
  // <React.StrictMode>
  <App currentScreen={initial_path} mode='host' sdk={sdk} currentScreenSearchParams={ window.location.search} />
  // </React.StrictMode>
);
swDev();

export { default as SDK } from './sdk/sdk';
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// serviceWorkerRegistration.register();
