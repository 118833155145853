import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { StorageService } from '../storage';

// Slice
const slice = createSlice({
    name: 'settings',
    initialState: {
        //  users
        editUser: null as any,
        showCreateUser: false,
        revokeUser: null as any,
        //  teams
        revokeTeam: null as any,
        showCreateTeam: false,
        editTeam: null as any,
        // channels
        connectWhatsappPopup: false,
        //  webhooks & APIs
        apiKeyPopup: false,
        webhookPopup: false,
        editWebhook: null as any,
        webhookStatus: null as any,
        webhookStatusPopup: false,
        apiRevokeSuccess: false,
        //  account profile
        showPasswordPopup: false,
        //  notifications
        insideChat: 'CONTEXT',
        outsideChat: 'ALL',
        soundNotifications: [true, StorageService.getPlaySoundNotification(), StorageService.getDesktopNotification()],

        groupNotification: false as boolean
    },
    reducers: {
        //  users
        editUserChanged: (state, action) => {
            state.editUser = action.payload;
        },
        createUserChanged: (state, action) => {
            state.showCreateUser = action.payload;
        },
        showRevokeUserModal: (state, action) => {
            state.revokeUser = action.payload.revokeUser;
        },
        showRevokeTeamModal: (state, action) => {
            state.revokeTeam = action.payload.revokeTeam;
        },
        //  teams
        teamPopupChanged: (state, action) => {
            state.showCreateTeam = action.payload.opened;
        },
        editTeamChanged: (state, action) => {
            state.editTeam = action.payload;
        },
        connectWhatsappPopupChanged: (state, action) => {
            state.connectWhatsappPopup = action.payload;
        },
        // api key
        apiKeyPopupChanged: (state, action) => {
            state.apiKeyPopup = action.payload.opened;
        },
        apiKeyDeletedChanged: (state, action) => {
            state.apiRevokeSuccess = action.payload;
        },
        webhookPopupChanged: (state, action) => {
            state.webhookPopup = action.payload.opened;
        },
        setEditWebhook: (state, action) => {
            state.editWebhook = action.payload;;            
        },
        setWebhookStatus: (state, action) => {
            state.webhookStatus = action.payload;;            
        },
        webhookStatusPopupChanged: (state, action) => {
            state.webhookStatusPopup = action.payload.opened;
        },
        //  account profile
        showPasswordPopupChanged: (state, action) => {
            state.showPasswordPopup = action.payload.opened;
        },
        //  notifications
        insideChatChanged: (state, action) => {
            state.insideChat = action.payload;
        },
        outsideChatChanged: (state, action) => {
            state.outsideChat = action.payload;
        },
        soundNotificationChanged: (state, action) => {
            const { index, value } = action.payload;
            state.soundNotifications[index] = value;
        },
        groupNotificationChaned: (state, action) => {
            state.groupNotification = action.payload;
        }
    }
},
);

export default slice.reducer;

export const createTemplate = slice.reducer;

// Actions
const {
    editUserChanged, showRevokeUserModal, showRevokeTeamModal, createUserChanged,
    teamPopupChanged, editTeamChanged,
    connectWhatsappPopupChanged, apiKeyDeletedChanged,
    apiKeyPopupChanged, webhookPopupChanged, setEditWebhook,
    showPasswordPopupChanged, groupNotificationChaned,
    insideChatChanged, outsideChatChanged, soundNotificationChanged,
    setWebhookStatus,webhookStatusPopupChanged
    
} = slice.actions;

export const setEditUser = (editUser: any): any => (dispatch: Dispatch): void => {
    dispatch(editUserChanged(editUser));
}

export const showCreateUser = (status: boolean): any => (dispatch: Dispatch): void => {
    dispatch(createUserChanged(status));
}

export const changeApiKeyPopup = (opened: boolean): any => (dispatch: Dispatch): void => {
    dispatch(apiKeyPopupChanged({ opened }));
}

export const changeApiKeyRevoked = (apiKeyDeleted: boolean): any => (dispatch: Dispatch): void => {
    dispatch(apiKeyDeletedChanged(apiKeyDeleted));
}

export const changeRevokeUserModalState = (revokeUser: any): any => (dispatch: Dispatch): void => {
    dispatch(showRevokeUserModal({ revokeUser }));
}


export const changeRevokeTeamModalState = (revokeTeam: any): any => (dispatch: Dispatch): void => {
    dispatch(showRevokeTeamModal({ revokeTeam }));
}


export const changeWebhookPopup = (opened: boolean): any => (dispatch: Dispatch): void => {
    dispatch(webhookPopupChanged({ opened }));
}

export const changeTeamPopup = (opened: boolean): any => (dispatch: Dispatch): void => {
    dispatch(teamPopupChanged({ opened }));
}

export const setEditTeam = (editTeam: any): any => (dispatch: Dispatch): void => {
    dispatch(editTeamChanged(editTeam));
}

export const setConnectWhatsappPopup = (status: boolean): any => (dispatch: Dispatch): void => {
    dispatch(connectWhatsappPopupChanged(status));
}

export const changeEditWebhook = (data: any): any => (dispatch: Dispatch): void => {
    dispatch(setEditWebhook(data));
}

export const changeWebhookStatus = (data: any): any => (dispatch: Dispatch): void => {
    dispatch(setWebhookStatus(data));
}

export const changeWebhookStatusPopup = (opened: boolean): any => (dispatch: Dispatch): void => {
    dispatch(webhookStatusPopupChanged({ opened }));
}

export const changePasswordPopup = (opened: boolean): any => (dispatch: Dispatch): void => {
    dispatch(showPasswordPopupChanged({ opened }));
}

export const changeInsideChat = (value: string): any => (dispatch: Dispatch): void => {
    dispatch(insideChatChanged(value));
}

export const changeOutsideChat = (value: string): any => (dispatch: Dispatch): void => {
    dispatch(outsideChatChanged(value));
}

export const changeSoundNotification = (value: boolean, index: number): any => (dispatch: Dispatch): void => {
    dispatch(soundNotificationChanged({ value, index }));
}

export const changeGroupNotification = (value: boolean): any => (dispatch: Dispatch): void => {
    dispatch(groupNotificationChaned(value));
}
