
import axios from "axios";
import environment from "../environment";
import { logout } from "./auth";
import { StorageService } from "../storage";
// const cached_queries: string[] = [];
import {captureException} from "@sentry/react";

export const fetchMessages = async ({ queryKey }) => {
  const [_, search, type, direction, msgType, { from, to }, chatStatus, assignedTo, tags, limit, offset, contact_id] = queryKey;
  if (contact_id !== undefined && !search && !msgType.length && !direction && !from) return { total: 0 };
  // if (cached_queries.includes(JSON.stringify(queryKey))) return;
  // cached_queries.push(JSON.stringify(queryKey));
  const options = {
    method: 'GET',
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/search`,
    params: {
      text: search,
      type: type === 0 ? 'message' : 'contact',
      agent_id: assignedTo || undefined,
      contact_id,
      direction: direction || undefined,
      from: from?.replace('Z', ''),
      to: to?.replace('Z', ''),
      message_type: msgType ? msgType.join(',').toLowerCase() : undefined,
      // since: '500d',
      // is_team: false,
      status: chatStatus || undefined,
      tags: tags.join(',') || undefined,
      start: offset,
      limit: limit
    },
    headers: {
      'x-hasura-business-id': window.business_id,
      Accept: 'application/json',
      Authorization: StorageService.getAuthToken()
    }
  };

  try {
    const { data } = await axios.request(options);
    return data;
  } catch (error) {
    console.error('fetch-search error: ', error);
    if (error.response.status === 401) {
      logout('SESSION_TIMED_OUT');
    } else {
      captureException(error);
    }
    return {};
  }
}

export const fetchAllContacts = async ({ queryKey }) => {
  const [_, search, type, direction, msgType, { from, to }, chatStatus, assignedTo, tags, limit, offset, contact_id] = queryKey;
  if (contact_id !== undefined && !search && !msgType.length && !direction && !from) return { total: 0 };
  // if (cached_queries.includes(JSON.stringify(queryKey))) return;
  // cached_queries.push(JSON.stringify(queryKey));
  const options = {
    method: 'GET',
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/search`,
    params: {
      text: search,
      type: type === 0 ? 'message' : 'contact',
      agent_id: assignedTo || undefined,
      contact_id,
      direction: direction || undefined,
      from: from?.replace('Z', ''),
      to: to?.replace('Z', ''),
      message_type: msgType ? msgType.join(',').toLowerCase() : undefined,
      // since: '500d',
      // is_team: false,
      status: chatStatus || undefined,
      tags: tags.join(',') || undefined,
      start: offset,
      limit: limit,
      search
    },
    headers: {
      'x-hasura-business-id': window.business_id,
      Accept: 'application/json',
      Authorization: StorageService.getAuthToken()
    }
  };

  try {
    const { data } = await axios.request(options);
    return data.results.map(item => item.platform_sender_id);
  } catch (error) {
    console.error('fetch-search error: ', error);
    if (error.response.status === 401) {
      logout('SESSION_TIMED_OUT');
    } else {
      captureException(error);
    }
    return {};
  }
}

export const getContacts = async ({ queryKey }) => {
  const [_, search] = queryKey;

  const options = {
    method: 'GET',
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/search`,
    params: {
      text: search,
      limit: 25,
      type:  'contact',
      search
    },
    headers: {
      'x-hasura-business-id': window.business_id,
      Accept: 'application/json',
      Authorization: StorageService.getAuthToken()
    }
  };

  try {
    const { data } = await axios.request(options);
    // return data.results;
    return data.results.map(item => ({
      contactId: item.contact_id,
      platformId: item.platform_sender_id,
      displayName: item.display_name
    }));
  } catch (error) {
    console.error('fetch-search error: ', error);
    if (error.response.status === 401) {
      logout('SESSION_TIMED_OUT');
    } else {
      captureException(error);
    }
    return {};
  }
}

export enum SEARCH_ACTIONS {
  GROUP = "assign_chat",
  TAG =  "add_tag",
  CHAT =  "mark_chat_read",
  UNREAD_CHAT = "mark_chat_unread",
  CANCEL = "close_chat",
}

export interface BulkActionProps {
  action_type: string,
  platform_ids: string[],
  agent_id?: string,
  is_team?: boolean,
  tag_id?: number
}