import i18n from "i18next";
import { LANG } from "./helpers/common";
import moment from "moment";
import { DATE_FORMAT } from "./helpers/analytics";

export const isRTL = ():boolean => i18n.language === 'ar';

export const langDirection = (): 'right' | 'left' => isRTL() ? 'right' : 'left';


export function localizedDateDisplay(
    datetime: string | null, 
    lang: string = LANG.EN, 
    format: DATE_FORMAT, 
    days: number = 0
  ) {
    const dateValue = datetime ? datetime : new Date();
    
    return moment(dateValue)
        .locale(lang)
        .add(days, 'days')
        .format(format);
  }