import React, { useEffect, useRef, useState } from 'react';
import { Snackbar, Button } from '@mui/material';

//  utils
import axios from 'axios';
import environment from '../../environment';

export const NewContentAvailable = (): JSX.Element => {
  const [updateAvailable, setUpdateAvailable] = useState(false);

  const versionRef = useRef<string | null>(null);

  useEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      axios.get('/asset-manifest.json').then((res) => {
        versionRef.current = JSON.stringify(res.data);
      }).catch((err) => {
        console.error(err);
      });

      setInterval(() => {
        axios.get('/asset-manifest.json').then((res) => {
          if (versionRef.current !== JSON.stringify(res.data)) {
            setUpdateAvailable(true);
          }
        }).catch((err) => {
          console.error(err);
        });
      }, 5 * 60 * 1000);
    }
  }, []);
  
  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      open={updateAvailable}
      message="New update available ✨"
      action={ <Button color="inherit" size="small" onClick={() => window.location.reload()}>Refresh</Button> }
    />
  );
}
