import React, { useEffect, useState } from 'react'
//import { handleGetToken } from '../firebase';
import axios from 'axios';
import { StorageService } from '../storage';

export const DeviceToken = () => {
    return (
        <h1>{StorageService.getFcmToken()}</h1>
    )
}
