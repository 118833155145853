import React, { Suspense } from 'react';
import { useMediaQuery } from 'react-responsive';
import { Stack } from '@mui/material';

//  components
import { LoadingSpinner } from '../../components/Common/LoadingSpinner';
import Error from '../../pages/Error';

//  utils
// import LogRocket from 'logrocket';
import { ErrorBoundary } from 'react-error-boundary';
import * as Sentry from "@sentry/react";

//  redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { changeStatus } from '../../redux/search';
import { createSearchParams, useLocation, useNavigate } from 'react-router-dom';

//  Lazy Pages
const SearchPage = React.lazy(() => import('../../pages/Search'));

const Container = ({ child }: { child: JSX.Element }): JSX.Element => {
  const isMobile = useMediaQuery({ maxWidth: 786 });
  return (
    <Stack minWidth={ isMobile ? 'auto' : '830px' } sx={{ overflowY: 'auto', boxSizing: 'border-box', m: '10px', borderRadius: 2.5, boxShadow: 5, backgroundColor: 'light.main', height: `calc(100% - 20px)`, width: isMobile ? 'calc(100% - 20px)' : '60%' }}>
      { child }
    </Stack>
  )
}

export const LazySearch = () => {
  //  hooks
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const searchOpened = useSelector((state: RootState) => state.search.opened);

  const closeSearch = () => {
    dispatch(changeStatus(false));
    let queryParams = {};
    let locationQuery = new URLSearchParams(location.search);
    for (const key of locationQuery.keys()) {
        queryParams[key] = locationQuery.get(key);
    }
    if (queryParams['search']) {
      delete queryParams['search'];
    }
    navigate({
        pathname: location.pathname,
        search: createSearchParams(queryParams).toString() 
    });
  }

  return (
    searchOpened ?
    <Stack sx={{ backgroundColor: 'light.gray29', position: 'absolute', width: '100%', height: '100%', top: 0, zIndex: 1201 }} onClick={closeSearch}>
      <ErrorBoundary
        fallback={
          <Container child={<Error type={'RELOAD'} />} />
        }
        onError={(error) => {
          Sentry.captureException(error);
          // ## WE WILL USE IT LATERM BUT LET'S DISABLE IT FOR NOW
          // LogRocket.captureException(error, {
          //   tags: { type: 'chunk load failure' },
          //   extra: { reason: 'Loading Search Screen' }
          // });
        }}
      >
        <Suspense fallback={
          <Container child={<LoadingSpinner />} />
        }>
          <SearchPage />
        </Suspense>
      </ErrorBoundary>
    </Stack> :
    <></>
  );
}
