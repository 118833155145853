import React, { useEffect, useState } from "react";
import { Link, createSearchParams, useLocation } from "react-router-dom";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import Button from "@mui/material/Button";

//  icons
import PermMediaRoundedIcon from "@mui/icons-material/PermMediaRounded";
import ChatIcon from "@mui/icons-material/Chat";
import SearchIcon from "@mui/icons-material/Search";
import AppsIcon from "@mui/icons-material/Apps";
import SettingsIcon from "@mui/icons-material/Settings";
import NotificationsNoneOutlinedIcon from "@mui/icons-material/NotificationsNoneOutlined";
import ContactPageIcon from "@mui/icons-material/ContactPage";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import DashboardIcon from "@mui/icons-material/Dashboard";

//  helpers
import {
  toggleButtonGroupStyle,
  inactiveIcon,
  iconLinkStyle,
  activeIcon,
  ToggleButton,
} from "../../helpers/chatViewLayoutStyles";
import { getAssetUrl } from "../../helpers/assets";

//  redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { changeStatus } from "../../redux/search";
import { setCurrentRoute } from "../../redux/sdk";

//  translations
import { useTranslation } from "react-i18next";
import i18next from "i18next";

//  components
// import { VoiceChatMenu } from './VoiceChatMenu';
import { AgentStatus } from "./agentStatus/AgentStatus";
import { useNavigate } from "react-router-dom";
import { StorageService } from "../../storage";
import environment from "../../environment";
import { Capacitor } from '@capacitor/core';
import { ACTIONS, AvcModulesKeys } from "../../helpers/constants";
import { isPermitted } from "../../helpers/auth";
import { SxProps } from "@mui/system";

let prevTab = "";

export const LeftSideNav = (): JSX.Element => {
  //  hooks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  //  local states
  const [currentTap, setCurrentTab] = useState<string | null>(null);

  //  redux states
  const searchOpened = useSelector((state: RootState) => state.search.opened);
  const path = useSelector((state: RootState) => state.sdk.currentRoute.path);
  const mode = useSelector((state: RootState) => state.sdk.mode);

  // permissions
  const module = AvcModulesKeys.SEARCH.toUpperCase();
  const role: string = useSelector((state: RootState) => state.auth.userRole);
  const canAccessSearch: SxProps = isPermitted({role, module, action: ACTIONS.FULL_ACCESS_TO_MODULE}) as SxProps;
  
  //  handlers
  const handleChange = (_: any, newTab: string) => {
    if (newTab === 'Search') {
      dispatch(changeStatus(!searchOpened));

      let queryParams = {};
      let locationQuery = new URLSearchParams(location.search);
      for (const key of locationQuery.keys()) {
        queryParams[key] = locationQuery.get(key);
      }
      queryParams['search'] = true;
      navigate({
        pathname: location.pathname,
        search: createSearchParams(queryParams).toString()
      });

    } else {
      if (searchOpened) {
        dispatch(changeStatus(false));
      }
      setCurrentTab(newTab);
      prevTab = newTab;
    }
  };
  const changeScreen = (newScreen: string, params: any = {}) => {
    dispatch(setCurrentRoute(newScreen, params));
  };

  useEffect(() => {
    let locationQuery = new URLSearchParams(location.search);
    let searchQuery = locationQuery.get('search');
    if (!searchQuery && searchOpened) {
      dispatch(changeStatus(false));
    } else if (searchQuery && !searchOpened) {
      dispatch(changeStatus(true));
      dispatch(setCurrentRoute(path, { search: true }));
    }
  }, [location]);

  useEffect(() => {
    if (searchOpened) {
      setCurrentTab("Search");
    } else {
      setCurrentTab(prevTab);
    }
  }, [searchOpened]);

  useEffect(() => {
    let ignore = false;
    const path = location.pathname.split("/");
    let tab = path[1].charAt(0).toUpperCase() + path[1].slice(1) || "Dashboard";
    if (!ignore) {
      if (
        tab === "Broadcast" ||
        tab === "Templates" ||
        tab === "Quick-replies" ||
        tab === "Rules" ||
        tab === "Greeting"
      )
        tab = "Apps";
      prevTab = tab;
      setCurrentTab(tab);
    }
    return () => {
      ignore = true;
    };
  }, []);

  useEffect(() => {
    if (!location.pathname) return;
    let currentTap = "";
    let locationQuery = new URLSearchParams(location.search);
    let searchQueryParam = locationQuery.get('search');

    if (location.pathname.startsWith("/dashboard")) currentTap = "Dashboard";
    else if (location.pathname.startsWith("/media-library")) currentTap = "Media-library";
    else if (location.pathname.startsWith("/chat")) currentTap = "Chat";
    else if (location.pathname.startsWith("/contacts")) currentTap = "Contacts";
    else if (location.pathname.startsWith("/search")) currentTap = "Search";
    else if (location.pathname.startsWith("/settings")) currentTap = "Settings";
    else if (location.pathname.startsWith("/apps")) currentTap = "Apps";
    else if (location.pathname.startsWith("/ecommerce-apps")) currentTap = "EcommerceApps";
    else if (location.pathname.startsWith("/billing-subscription")) currentTap = "Billing";

    if (searchQueryParam) {
      currentTap = "Search";
    }
    setCurrentTab(currentTap);
  }, [location]);

  const toggleLanguage = () => {
    const newLanguage = i18next.language === "en" ? "ar" : "en";
    i18next.changeLanguage(newLanguage);
    StorageService.setLanguage(newLanguage);
    document.dir = i18next.dir();
  };

  return (
    <Box
      sx={{
        width: 72,
        backgroundColor: "light.gray3",
        boxShadow: 24,
        zIndex: 999,
      }}
    >
      <Stack
        alignItems="center"
        justifyContent="space-between"
        sx={{ height: "100%", overflowY: "auto" }}
      >
        <Stack alignItems={"center"} spacing={2} sx={{ marginTop: 1.5 }}>
          <Tooltip
            placement={i18next.language === "ar" ? "left" : "right"}
            title={environment.REACT_APP_VERSION}
            arrow
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: 35,
                height: 35,
                borderRadius: 1.5,
                border: "1px solid",
                borderColor: "light.gray3",
                backgroundColor: "light.secondary",
                mt: 3,
              }}
              p={0.6}
            >
              <img
                alt="avocado icon"
                src={getAssetUrl("mottasl_blue.png")}
                style={{ maxWidth: "100%" }}
              />
            </Box>
          </Tooltip>
          <ToggleButtonGroup
            orientation="vertical"
            sx={{ gap: 1 }}
            value={currentTap}
            exclusive
          >
            {/* Hidden for now */}
            <ToggleButton onClick={handleChange} aria-label='Dashboard' style={toggleButtonGroupStyle} value='Dashboard'>
              <Tooltip placement={ i18next.language === 'ar' ? 'left' : 'right' } title={t('NAVBAR_TOOLTIP.REPORTS')} arrow>
                <Link to={mode === 'host' ? '/dashboard/overview' : ''} style={iconLinkStyle} onClick={() => changeScreen('/dashboard/overview')}>
                  <DashboardIcon sx={ currentTap === 'Dashboard' ? activeIcon : inactiveIcon } />
                </Link>
              </Tooltip>
            </ToggleButton>

            <ToggleButton
              onClick={handleChange}
              aria-label="Search"
              style={toggleButtonGroupStyle}
              value="Search"
              sx={{...canAccessSearch}}
            >
              <Tooltip
                placement={i18next.language === "ar" ? "left" : "right"}
                title={t("NAVBAR_TOOLTIP.SEARCH")}
                arrow
              >
                <Box style={iconLinkStyle}>
                  <SearchIcon
                    sx={currentTap === "Search" ? activeIcon : inactiveIcon}
                  />
                </Box>
              </Tooltip>
            </ToggleButton>

            <ToggleButton
              onClick={handleChange}
              aria-label="Chat"
              style={toggleButtonGroupStyle}
              value="Chat"
            >
              <Tooltip
                placement={i18next.language === "ar" ? "left" : "right"}
                title={t("NAVBAR_TOOLTIP.CHAT")}
                arrow
              >
                <Link
                  to={mode === "host" ? "/chat" : ""}
                  style={iconLinkStyle}
                  onClick={() => changeScreen("/chat")}
                >
                  <ChatIcon
                    sx={currentTap === "Chat" ? activeIcon : inactiveIcon}
                  />
                </Link>
              </Tooltip>
            </ToggleButton>

            <ToggleButton
              onClick={handleChange}
              aria-label="Media-library"
              style={toggleButtonGroupStyle}
              value="Media-library"
            >
              <Tooltip
                placement={i18next.language === "ar" ? "left" : "right"}
                title={t("NAVBAR_TOOLTIP.MEDIA_LIBRARY")}
                arrow
              >
                <Link
                  to={mode === "host" ? "/media-library" : ""}
                  style={iconLinkStyle}
                  onClick={() => changeScreen("/media-library")}
                >
                  <PermMediaRoundedIcon
                    sx={
                      currentTap === "Media-library" ? activeIcon : inactiveIcon
                    }
                  />
                </Link>
              </Tooltip>
            </ToggleButton>

            <ToggleButton
              onClick={handleChange}
              aria-label="Contacts"
              style={toggleButtonGroupStyle}
              value="Contacts"
            >
              <Tooltip
                placement={i18next.language === "ar" ? "left" : "right"}
                title={t("NAVBAR_TOOLTIP.CONTACTS")}
                arrow
              >
                <Link
                  to={mode === "host" ? "/contacts?tab=0" : ""}
                  style={iconLinkStyle}
                  onClick={() => changeScreen("/contacts", { tab: 0 })}
                >
                  <ContactPageIcon
                    sx={currentTap === "Contacts" ? activeIcon : inactiveIcon}
                  />
                </Link>
              </Tooltip>
            </ToggleButton>

            <ToggleButton
              onClick={handleChange}
              aria-label="Apps"
              style={toggleButtonGroupStyle}
              value="Apps"
            >
              <Tooltip
                placement={i18next.language === "ar" ? "left" : "right"}
                title={t("NAVBAR_TOOLTIP.APPS")}
                arrow
              >
                <Link
                  to={mode === "host" ? "/apps" : ""}
                  style={iconLinkStyle}
                  onClick={() => changeScreen("/apps")}
                >
                  <AppsIcon
                    sx={currentTap === "Apps" ? activeIcon : inactiveIcon}
                  />
                </Link>
              </Tooltip>
            </ToggleButton>

            <ToggleButton
              onClick={handleChange}
              aria-label="EcommerceApps"
              style={toggleButtonGroupStyle}
              value="EcommerceApps"
            >
              <Tooltip
                placement={i18next.language === "ar" ? "left" : "right"}
                title={t("NAVBAR_TOOLTIP.ECOMMERCE_APPS")}
                arrow
              >
                <Link
                  to={mode === "host" ? "/ecommerce-apps" : ""}
                  style={iconLinkStyle}
                  onClick={() => changeScreen("/ecommerce-apps")}
                >
                  <ShoppingCartIcon
                    sx={
                      currentTap === "EcommerceApps" ? activeIcon : inactiveIcon
                    }
                  />
                </Link>
              </Tooltip>
            </ToggleButton>

            <ToggleButton
              onClick={handleChange}
              aria-label="Settings"
              style={toggleButtonGroupStyle}
              value="Settings"
            >
              <Tooltip
                placement={i18next.language === "ar" ? "left" : "right"}
                title={t("NAVBAR_TOOLTIP.SETTINGS")}
                arrow
              >
                <Link
                  to={
                    mode === "host" ? "/settings/workspace/account-profile" : ""
                  }
                  style={iconLinkStyle}
                  onClick={() =>
                    changeScreen("/settings/workspace/account-profile")
                  }
                >
                  <SettingsIcon
                    sx={currentTap === "Settings" ? activeIcon : inactiveIcon}
                  />
                </Link>
              </Tooltip>
            </ToggleButton>
          </ToggleButtonGroup>

          {/* <IconButton id='open-copilot'>
            <Tooltip placement={ i18next.language === 'ar' ? 'left' : 'right' } title={t('NAVBAR_TOOLTIP.COPILOT')} arrow>
              <SmartToyIcon sx={inactiveIcon} />
            </Tooltip>
          </IconButton> */}
        </Stack>

        <Stack alignItems="center" gap={2.5} py={2.5}>
          {/* <VoiceChatMenu /> */}
          {Capacitor.getPlatform() !== 'ios' && (
              <ToggleButtonGroup
              orientation="vertical"
              sx={{ gap: 1 }}
              value={currentTap}
              exclusive
              >
                <ToggleButton
                  // onClick={handleChange}
                  aria-label="Billing"
                  style={toggleButtonGroupStyle}
                  value="Billing"
                  disableFocusRipple={true}
                  disableTouchRipple={true}
                >
                  <Tooltip
                    placement={i18next.language === "ar" ? "left" : "right"}
                    arrow
                  >
                    <Link
                      to={mode === "host" ? "/billing-subscription" : ""}
                      style={iconLinkStyle}
                      onClick={() => changeScreen("/billing-subscription")}
                    >
                      <CreditCardIcon
                        color="primary"
                        sx={currentTap === "Billing" ? activeIcon : inactiveIcon}
                      />
                    </Link>
                  </Tooltip>
                </ToggleButton>
              </ToggleButtonGroup>
            )
          }

          <Tooltip
            title={
              i18next.language == "ar" ? "اللغة الإنجليزية" : "English Language"
            }
            placement="right"
            arrow
          >
            <Button onClick={toggleLanguage}>
              <Avatar
                alt="Language"
                src={
                  i18next.language === "ar"
                    ? getAssetUrl("saudi-flag.png")
                    : getAssetUrl("english.webp")
                }
                sx={{ width: "24px", height: "24px" }}
              />
            </Button>
          </Tooltip>

          <Tooltip
            title={t("NAVBAR_TOOLTIP.NOTIFICATIONS")}
            placement="right"
            arrow
          >
            <Button
              component={Link}
              to={mode === "host" ? "/settings/account/notifications" : ""}
              onClick={() => changeScreen("/settings/account/notifications")}
            >
              <NotificationsNoneOutlinedIcon color="secondary" />
            </Button>
          </Tooltip>

          <AgentStatus />
        </Stack>
      </Stack>
    </Box>
  );
};