import { avcAPI } from "../helpers/api";

import { useQuery } from "@tanstack/react-query";
import { StorageService } from "../storage";
import { AvcModulesKeys } from "./constants";
import environment from "../environment";

export const enum DATE_FORMAT {
  DATE_TIME = "YYYY-MM-DD hh:mm:ss",
  DATE = "YYYY-MM-DD",
  TIME = "hh:mm:ss"
}

const fetchAnalyticsData = (url: string) => {
  return avcAPI
    .get(url, {
      headers: {
        Authorization: `${StorageService.getAuthToken()}`,
      },
    })
    .then((res) => res.data);
};

export const useGetAnalyticsData = (
  module: "conversation" | "agent" | "team",
  startdate: string,
  enddate: string,
  IDs: string = ''
) => {
  let url: string = `/analytics/${module}?startdate=${startdate}&enddate=${enddate}`;
  if(IDs) url = url + `&${module}_ids=${IDs}`;  
  return useQuery(["analytics"], () => fetchAnalyticsData(url));
};

export const useGetAnalyticsReport = (
  module: "conversation" | "agent" | "team",
  startdate: string,
  enddate: string,
  IDs: string = '',
  enabled: boolean
) => {
  let url: string = `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/reports/${module}.download?startdate=${startdate}&enddate=${enddate}`;
  if (IDs && module !== "conversation") url = url + `&${module}_ids=${IDs}`;

  url = url + `&timestamp=${new Date().getTime()}`;

  return useQuery(
    ["analyticsReport", module, startdate, enddate, IDs],
    async () => {
      const response = await fetch(url, {
        headers: {
          Authorization: `${StorageService.getAuthToken()}`,
        }
      });
      if (!response.ok) {
        throw new Error(`Failed to fetch report: ${response.status}`);
      }
      return await response.blob();
    },
    { enabled }
  );
};

export const useGetAgentsDataForAnalytics = (start: number =0, limit: number = 5) => {
  let url: string = `/analytics/conversation/agent?start=${start}&limit=${limit}`;
  return useQuery(["AgentsAnalytics", {start, limit}], () => fetchAnalyticsData(url));
};

export const useGetHeatMap = () => {
  let url: string = `/analytics/overview/heatmap`;
  return useQuery(["AnalyticsHeatMap"], () => fetchAnalyticsData(url));
};

export const statisticsTimeFormat = (totalSeconds: number): {min: number, sec: number} => {
  const minutes: number = totalSeconds / 60;
  const minutesFloored: number = Math.floor(minutes);
  const seconds: number = Math.floor((minutes - minutesFloored)*60);
  return {
    min: minutesFloored,
    sec: seconds,
  };
};

const conversationKeys: { [k:string]: string } = {
  total_conversations: 'chats',
  avg_resolution_time: 'avg_resolution_time',
  avg_first_response_time: 'avg_first_response_time',
  total_out_count: 'out_conversations',
  total_in_count: 'in_conversations',
  avg_response_time: 'avg_response_time',
  total_resolution_count: 'resolution_count',
}

export const getDataKey = (module: string, activeCard: string): string => {
  if (module === AvcModulesKeys.ANALYTICS_CONV) {
    return conversationKeys[activeCard];
  }
    return activeCard?.slice(6);
}

export const downloadReport = (data): void => {
  const url = URL.createObjectURL(data);
  const a = document.createElement('a');
  a.href = url;
  a.download = "report.csv"; // adjust file extension if needed
  a.click();
  URL.revokeObjectURL(url); // cleanup
}