import { CSSProperties } from "react";
import MuiToggleButton from "@mui/material/ToggleButton";
import { styled, SxProps } from "@mui/material/styles";

export const inactiveTab: CSSProperties = {
  border: '0px',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  gap: '8px',
  borderRadius: '10px !important',
  textTransform: 'capitalize',
  padding: '8px',
  fontSize: '14px',
  fontWeight: 500,
  display:'flex',
  flexDirection:'row',
};

export const activeTab: CSSProperties = {
  backgroundColor: 'light.gray13'
}
export const toggleButtonGroupStyle: CSSProperties = {
  border: '0px',
  width: '100%',
  borderRadius: 15,
  padding: 0
};

export const iconLinkStyle: CSSProperties = {
  display: 'flex',
  padding: '12px'
}

export const inactiveIcon: CSSProperties = {
  color: 'secondary.main',
}

export const activeIcon: CSSProperties = {
  color: 'primary.main',
}


export const ToggleButton = styled(MuiToggleButton)(({ theme }) => ({
  "&.Mui-selected, &.Mui-selected:hover": {
    backgroundColor: (theme.palette as any).light.main,
    boxShadow: theme.shadows[15],
  }
}));

export const toggleButtonGroupSX: SxProps = {
  gap: 1,
  '.MuiToggleButton-root': {
    ...inactiveTab,
    color: 'text.secondary',
    '&.Mui-selected': {
      ...activeTab,
      color: 'text.secondary',
      '.MuiSvgIcon-root': activeIcon
    },
    '.MuiSvgIcon-root': inactiveIcon
  }
}