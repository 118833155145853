import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';

const slice = createSlice({
  name: 'appIntegration',
  initialState: {
    appIntegrationKey: "none",
  },
  reducers: {
    setAppIntegrationKey: (state, action) => {
      state.appIntegrationKey = action.payload;
    },
  },
});

export default slice.reducer;

// Actions
const { setAppIntegrationKey } = slice.actions;

export const changeAppIntegrationKey = (appkey: string): any => (dispatch: Dispatch): void => {
  dispatch(setAppIntegrationKey(appkey));
}
