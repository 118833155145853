import { SxProps } from "@mui/material";

export const PINNED: string = 'pinned';
export const NOT_PINNED: string = 'notPinned';

export const DISPLAY_NONE: string = 'none';
export const DISPLAY_BLOCK: string = 'block';

export const MEDIA_NONE: string = 'none';
export const MEDIA_IMG: string = 'Image';
export const MEDIA_VIDEO: string = 'Video';
export const MEDIA_DOCS: string = 'Docs';

export const IMAGE_TAG: string = 'IMAGE';
export const VIDEO_TAG: string = 'VIDEO';
export const DOC_TAG: string = 'DOCUMENT';

export const NONE_VALUE: string = 'none';
export const IMG_VALUE: string = 'image';
export const VIDEO_VALUE: string = 'video';
export const DOC_VALUE: string = 'document';
export const TXT_VALUE: string = 'text';
export const UNKNOWN_VALUE: string = 'unknown';

export const AUTH: string = 'AUTHENTICATION';
export const UTILITY: string = 'UTILITY';
export const MARKETING: string = 'MARKETING';

export const BUTTON_NONE: string = 'none';
export const CTA_BUTTON: string = 'CTA';
export const QUICK_REPLY_BUTTON: string = 'QUICK_REPLY';
export const URL: string = 'URL';
export const PHONE_NUMBER: string = 'PHONE_NUMBER';


export const SUBMITTED: string = 'submitted';
export const APPROVED: string = 'approved';
export const REJECTED: string = 'rejected';
export const DELETED: string = 'deleted';

export const AVAILABLE: string = 'AVAILABLE';
export const UNAVAILABLE: string = 'UNAVAILABLE';

export const OWNER: string = 'Owner';
export const MANAGER: string = 'Manager';
export const AGENT: string = 'Agent';
export const TEAM: string = 'team';

export const COPY_CODE: string = 'COPY_CODE';
export const AUTOFILL: string = 'ONE_TAP';

export const PDF_MIME_TYPE: string = 'application/pdf';
export const JPG_MIME_TYPE: string = 'image/jpeg';
export const PNG_MIME_TYPE: string = 'image/png';
export const MP4_MIME_TYPE: string = 'video/mp4';

export const WRONG_CURR_PASSWORD_RESPONSE_MESSAGE: string = 'invalid current password, cryptographic tokens dont match';

export const channelsMap: any = {
  'wa': 'WhatsApp',
}

export const FEEDBACK_SATISFIED: string = 'Satisfied';
export const FEEDBACK_EXCELLENT: string = 'Excellent';
export const FEEDBACK_AWESOME: string = 'Awesome';

export const TOTAL: string = 'Total';
export const AVERAGE: string = 'Avg';

export const SHOW_IN_CHAT: string = 'showInChat';
export const DELETE_TEMPLATE: string = 'deleteTemplate';

function stringToColor(string: string) {
  let hash = 0;
  let i: number;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string?.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export function stringAvatar(name: string) {
  const splitedName: string[] = name ? name.toUpperCase().split(' ').filter(n => n) : ['U', 'U'];
  return {
    sx: {
      bgcolor: stringToColor(name),
    },
    children: splitedName.length > 1 ?
    `${splitedName[0][0]}${splitedName[1][0]}` :
    `${splitedName[0][0]}`
    ,
  };
}

export function getCreateMeetingBody(name: string, id: number): any {
  return {
    "meeting_title": `Meet with ${name}`,
    "guest_agent_id": id
  }
}

export const progresSteps: any[] = [
  {
    color: 'primary.main',
    barColor: 'primary.main',
    barBgColor: 'transperent',
    bgColor: 'primary.alpha1',
    label: 'Created',
    date: ' Dec 20, 2022',
    time: ' 03:02 AM'
  },
  {
    color: 'primary.main',
    barColor: 'warning.solid',
    barBgColor: 'warning.alpha3',
    bgColor: 'transperent',
    label: 'Submitted',
    date: ' Dec 20, 2022',
    time: ' 03:02 AM'
  },
  {
    color: 'warning.solid',
    barColor: 'success.solid3',
    barBgColor: 'success.alpha3',
    bgColor: 'warning.alpha3',
    label: 'Revoked',
    date: 'N/A',
    time: ''
  },
  {
    color: 'success.solid3',
    bgColor: 'success.alpha3',
    label: 'Live',
    date: ' Dec 20, 2022',
    time: ' 03:02 AM'
  }
];

export const conversation: any[] = [
  {
    color: '#FF4369',
    label: 'All Conversation'
  },
  {
    color: '#19AEC3',
    label: 'Business Initiated'
  },
  {
    color: '#1595F0',
    label: 'User Initiated'
  },
  {
    color: '#19AEC3',
    label: 'Marketing'
  },
  {
    color: '#FFC759',
    label: 'Service'
  },
  {
    color: '#BED6E8',
    label: 'Authentication'
  },
  {
    color: '#FF8FA6',
    label: 'Utility'
  }
];

export const chartData = [
  {
    date: "Feb 2023",
    all: 6,
    business: 11,
    user: 12,
    marketing: 10,
    service: 9,
    authentication: 7,
    utility: 8
  },
  {
    date: "April 2023",
    all: 0,
    business: 1,
    user: 2,
    marketing: 4,
    service: 3,
    authentication: 5,
    utility: 6
  },
  {
    date: "June 2023",
    all: 12,
    business: 1,
    user: 10,
    marketing: 5,
    service: 9,
    authentication: 2,
    utility: 8
  }

];
//  define enum data type
export enum UPDATE_QUERY_POLICY {
  MERGE = 0,
  REPLACE = 1,
}

export const tableRowSX: SxProps = {
  display: "table-row",
  transition: "all 0.3s ease",
  ":hover": {
    backgroundColor: "light.gray1",
    cursor: "pointer",
  },
  '&.disabled': {
    pointerEvents: 'none',
    ':hover': {
      backgroundColor: 'transparent',
    }
  }
};

export const emptyError = { title: '', subtitle: '' };

export const MAX_EMAIL_LENGTH = 60;
export const MAX_PASSWORD_LENGTH = 30;


/**
 * Exporting the AvcModulesKeys, will allow us to use them in the app
 */
export enum AvcModulesKeys {
  CHAT = "chat",
  SEARCH = "SEARCH",
  CONTACTS = 'CONTACTS',
  BROADCAST = "broadcast",
  ANALYTICS = "analytics",
  ANALYTICS_CONV = "conversations",
  TEMPLATES = "templates",
  QUICKREPLIES = "quick_replies",
  RULES = "rules",
  GREETINGS = "greetings",
  CUSTOM_ATTRIBUTES = "custom_attributes",
  TAGS = "tags",
  MEDIA_LIBRARY = "media_library",
  BOT_BUILDER = "bot-builder",
  WHATSAPP_CATALOG = "whatsapp-catalog",
  WHATSAPP_FLOW = "whatsapp-flow",
  KNOWLEDGE_BASE = "knowledge-base",
  MOTTASL_BOT = "mottasl-bot",
  DIALOGFLOW = "dialogflow",
  CHATGPT = "chatgpt",
  CHANNELS = "channels",
  HUBSPOT = "hubspot",
  ZAPIER = "zapier",
  CSML = "csml",
  GOOGLE_SHEETS = "googlesheets",
  GOOGLE_TRANSLATE = "google-translate",
  ACTIVECAMPAIGN = "activecampaign",
  PIPEDRIVE = "pipedrive",
  ODOO = "custom.odoo",
  ECOMMERCE_APPS= "ecommerce",
  ZID_APP = "zid-app",
  SALLA_APP = "salla-app",
  SHOPIFY_APP = "shopify-app",
  WOOCOMMERCE_APP = "woocommerce-app",
  FOODER_APP = "fooder-app",
  WEBHOOKS_AND_APIS = "webhooks_apis",
  MESSAGE_LOGS = "message_logs",
  USERS="users",
  TEAMS="teams",
  WEBHOOKS_APIS="WEBHOOKS_APIS",
  WORKSPACE_PROFILE="WORKSPACE_PROFILE",
  BILLING="BILLING",
  WHATSAPP_WIDGET="whatsapp-widget",
  BILLING_SOURCE_ZOHO="zoho_billing",
  BILLING_SOURCE_JUGAAR="jugaar_billing"
}


export const moduleVisibilityCalibration  = {
  fooder: [
            AvcModulesKeys.RULES,
            AvcModulesKeys.BOT_BUILDER,
            AvcModulesKeys.WHATSAPP_CATALOG,
            AvcModulesKeys.WHATSAPP_FLOW,
            AvcModulesKeys.KNOWLEDGE_BASE,
            AvcModulesKeys.DIALOGFLOW,
            AvcModulesKeys.CHATGPT,
            AvcModulesKeys.HUBSPOT,
            AvcModulesKeys.ZAPIER,
            AvcModulesKeys.CSML,
            AvcModulesKeys.GOOGLE_SHEETS,
            AvcModulesKeys.GOOGLE_TRANSLATE,
            AvcModulesKeys.ACTIVECAMPAIGN,
            AvcModulesKeys.PIPEDRIVE,
            AvcModulesKeys.ODOO,
            AvcModulesKeys.ZID_APP,
            AvcModulesKeys.SALLA_APP,
            AvcModulesKeys.SHOPIFY_APP,
            AvcModulesKeys.WOOCOMMERCE_APP,
            AvcModulesKeys.WEBHOOKS_AND_APIS,
            AvcModulesKeys.MESSAGE_LOGS,
  ],
  none: [
     AvcModulesKeys.BOT_BUILDER,
     AvcModulesKeys.WHATSAPP_CATALOG,
     AvcModulesKeys.WHATSAPP_FLOW,
     AvcModulesKeys.KNOWLEDGE_BASE,
     AvcModulesKeys.DIALOGFLOW,
     AvcModulesKeys.CHATGPT,
     AvcModulesKeys.HUBSPOT,
     AvcModulesKeys.ZAPIER,
     AvcModulesKeys.CSML,
     AvcModulesKeys.GOOGLE_TRANSLATE,
  ]
}

export const createContactsEnum = {
  SYNC: 'sync',
  PARAM_ACTION: 'separate'
}

export const permissions = {
  admin: {
    FULL_ACCESS: true,
  },
  superadmin: {
    FULL_ACCESS: true,
  },
  view : {
    MEDIA_LIBRARY : {
      UPLOAD_MEDIA : false
    },
    CONTACTS: {
      CREATE: false,
      IMPORT : false,
      EXPORT : false,
      ARCHIVE: false,
      DELETE: false,
      EDIT: false,
      SEND_MESSAGE: false,
      ACTIVITIES: false,
    },
    ANALYTICS: {
      FULL: false
    },
    SEARCH: {
      ASSIGN_CHAT: false,
      BULK_ACTIONS: false
    },
    CHAT: {
      VIEW_AS: false,
      INBOX: false,
      ASSIGNED_TO_ME: false,
      UNANSWERED: false,
      UNASSIGNED: false,
      EXPORT_CHAT: false,
      NEW_CHAT: false,
      COPY_MESSAGE: false,
      ADD_QUICK_REPLY: false,
      PIN_UNPINT_CHAT: false,
      MARK_AS_READ: false,
      CLOSE_CHAT: false,
      SEND_MESSAGE: false,
      EDIT_CONTACT: false,
      ADD_TAGS: false,
      ADD_CUSTOM_ATTRIBUTES: false,
      CHANGE_ASSIGNMENT: false,
      REMOVE_TAGS: false,
      DELETE_CUSTOM_ATTRIBUTES: false,
      VIEW_EMAIL: false,
      VIEW_PHONENUMBER: false,
      VIEW_AS_MYSELF: false,
    },
    BROADCAST: {
      NEW_BROADCAST: false,
      EDIT_BROADCAST: false
    },
    TEMPLATES: {
      ADD_LANGUAGE: false,
      SUBMIT_TEMPLATE: false,
      DUPLICATE_TEMPLATE: false,
      DELETE_TEMPLATE: false,
    },
    QUICK_REPLIES: {
      CREATE_QUICK_REPLY: false,
      EDIT_QUICK_REPLY: false,
      DELETE_QUICK_REPLY: false
    },
    RULES: {
      NEW_RULE: false,
      EDIT_RULE: false,
      CHANGE_STATUS: false,
      DELETE: false
    },
    GREETINGS: {
      EDIT_WORKING_HOURS: false,
      EDIT_MESSAGE: false
    },
    CUSTOM_ATTRIBUTES: {
      ADD_CUSTOM_ATTRIBUTE: false,
      DELETE: false,
      EDIT: false,
    },
    TAGS: {
      ADD: false,
      EDIT: false,
      DELETE: false
    },
    KNOWLEDGE_BASE: {
      CREATE_KNOWLEDGE_BASE: false,
      TRAIN: false,
      DELETE: false,
      EDIT: false
    },
    EXTERNAL_APPS: {
      EDIT: false,
      VIEW: false
    },
    WORKSPACE_PROFILE: {
      FULL: false,
    },
    USERS: {
      ADD_NEW_AGENT: false,
      EDIT: false,
      REVOKE: false
    },
    TEAMS: {
      ADD_TEAM: false,
      EDIT: false,
      DELETE: false
    },
    CHANNELS: {
      FULL: false
    },
    WEBHOOKS_APIS: {
      FULL: false
    },
    MESSAGE_LOGS: {
      FULL: false,
      EXPORT_LOGS: false
    },
    BILLING: {
      FULL: false
    }
  },
  agent : {
    MEDIA_LIBRARY : {
      FULL : true
    },
    CONTACTS: {
      CREATE: true,
      IMPORT : false,
      EXPORT : false,
      ARCHIVE: false,
      DELETE: false,
      EDIT: true,
      ACTIVITIES: false,
      SEND_MESSAGE: false,
      EDIT_CONTACT: false,
    },
    ANALYTICS: {
      FULL: false
    },
    SEARCH: {
      ASSIGN_CHAT: false,
      BULK_ACTIONS: false
    },
    CHAT: {
      VIEW_AS: false,
      INBOX: false,
      ASSIGNED_TO_ME: false,
      UNASSIGNED: false,
      EXPORT_CHAT: false,
      VIEW_EMAIL: false,
      CHANGE_ASSIGNMENT: false,
      REMOVE_TAGS: false,
      DELETE_CUSTOM_ATTRIBUTES: false,
      VIEW_PHONENUMBER: false,
      EDIT_CONTACT: false
    },
    BROADCAST: {
      NEW_BROADCAST: false,
      EDIT_BROADCAST: false
    },
    TEMPLATES: {
      SUBMIT_TEMPLATE: false,
      DUPLICATE_TEMPLATE: false,
      DELETE_TEMPLATE: false,
      ADD_LANGUAGE: false,
    },
    QUICK_REPLIES: {
      EDIT_QUICK_REPLY: false,
      DELETE_QUICK_REPLY: false
    },
    RULES: {
      NEW_RULE: false,
      EDIT_RULE: false,
      CHANGE_STATUS: false,
      DELETE: false
    },
    GREETINGS: {
      EDIT_WORKING_HOURS: false,
      EDIT_MESSAGE: false
    },
    CUSTOM_ATTRIBUTES: {
      ADD_CUSTOM_ATTRIBUTE: false,
      DELETE: false,
      EDIT: false,
    },
    TAGS: {
      FULL: true
    },
    KNOWLEDGE_BASE: {
      CREATE_KNOWLEDGE_BASE: false,
      TRAIN: false,
      DELETE: false,
      EDIT: false
    },
    EXTERNAL_APPS: {
      EDIT: false,
      VIEW: false
    },
    WORKSPACE_PROFILE: {
      UPDATE: false
    },
    USERS: {
      ADD_NEW_AGENT: false,
      EDIT: false,
      REVOKE: false
    },
    TEAMS: {
      ADD_TEAM: false,
      EDIT: false,
      DELETE: false
    },
    CHANNELS: {
      FULL: false
    },
    WEBHOOKS_APIS: {
      FULL: false
    },
    MESSAGE_LOGS: {
      EXPORT_LOGS: false
    },
    BILLING: {
      FULL: false
    }
  },
  superagent: {
    CONTACTS: {
      IMPORT : false,
      EXPORT : false,
      DELETE: false,
      ARCHIVE: false,
      ACTIVITIES: true,
    },
    ANALYTICS: {
      DOWNLOAD_REPORT: false,
    },
    SEARCH: {
      FULL: true,
    },
    CHAT: {
      EXPORT_CHAT: false,
    },
    BROADCAST: {
      FULL: true,
    },
    MEDIA_LIBRARY : {
      FULL : true
    },
    TEMPLATES: {
      FULL: true,
    },
    QUICK_REPLIES: {
      FULL: true,
    },
    RULES: {
      FULL: true,
    },
    GREETINGS: {
      FULL: true,
    },
    CUSTOM_ATTRIBUTES: {
      FULL: true,
    },
    TAGS: {
      FULL: true,
    },
    USERS: {
      REVOKE: false,
    },
    TEAMS: {
      FULL: true,
    },
    CHANNELS: {
      FULL: true,
    },
    WEBHOOKS_APIS: {
      CREATE_WEBHOOK: false,
      CREATE_API: false,
      EDIT: false,
      DELETE: false
    },
    WORKSPACE_PROFILE: {
      UPDATE: false
    },
    MESSAGE_LOGS: {
      FULL: true,
    },
    BILLING: {
      FULL: false
    }
  },
  developer: {
    MEDIA_LIBRARY : {
      FULL : false
    },
    ANALYTICS: {
      FULL: false
    },
    SEARCH: {
      FULL : false
    },
    CHAT: {
      FULL : false
    },
    CONTACTS: {
      CREATE: false,
      IMPORT : false,
      EXPORT : false,
      ARCHIVE: true,
      DELETE: false,
      EDIT: false,
      SEND_MESSAGE: false,
      ACTIVITIES: false,
    },
    BROADCAST: {
      FULL: false,
    },
    TEMPLATES: {
      FULL: true,
    },
    QUICK_REPLIES: {
      FULL: false,
    },
    RULES: {
      FULL: true,
    },
    GREETINGS: {
      FULL: false,
    },
    CUSTOM_ATTRIBUTES: {
      FULL: false,
    },
    TAGS: {
      FULL: false,
    },
    KNOWLEDGE_BASE: {
      FULL: true,
    },
    EXTERNAL_APPS: {
      FULL: true
    },
    USERS: {
      FULL: false,
    },
    TEAMS: {
      FULL: false,
    },
    CHANNELS: {
      FULL: true,
    },
    WEBHOOKS_APIS: {
      FULL: true
    },
    WORKSPACE_PROFILE: {
      FULL: false
    },
    MESSAGE_LOGS: {
      FULL: true
    },
    BILLING: {
      FULL: false
    }
  },
  finance: {
    MEDIA_LIBRARY : {
      FULL : false
    },
    ANALYTICS: {
      FULL: false
    },
    SEARCH: {
      FULL : false
    },
    CHAT: {
      FULL : false
    },
    CONTACTS: {
      FULL: false,
    },
    BROADCAST: {
      FULL: false,
    },
    TEMPLATES: {
      FULL: false,
    },
    QUICK_REPLIES: {
      FULL: false,
    },
    RULES: {
      FULL: false,
    },
    GREETINGS: {
      FULL: false,
    },
    CUSTOM_ATTRIBUTES: {
      FULL: false,
    },
    TAGS: {
      FULL: false,
    },
    KNOWLEDGE_BASE: {
      FULL: false,
    },
    EXTERNAL_APPS: {
      FULL: false
    },
    USERS: {
      FULL: false,
    },
    TEAMS: {
      FULL: false,
    },
    CHANNELS: {
      FULL: false,
    },
    WEBHOOKS_APIS: {
      FULL: false
    },
    WORKSPACE_PROFILE: {
      FULL: false
    },
    MESSAGE_LOGS: {
      FULL: false
    },
    BILLING: {
      FULL: true
    }
  },
}

export const ACTIONS = {
  FULL_ACCESS: "FULL_ACCESS",
  FULL_ACCESS_TO_MODULE: 'FULL',
  "MEDIA_LIBRARY": {
      "UPLOAD_MEDIA": "UPLOAD_MEDIA"
  },
  "CONTACTS": {
      "CREATE": "CREATE",
      "IMPORT": "IMPORT",
      "EXPORT": "EXPORT",
      "ARCHIVE": "ARCHIVE",
      "DELETE": "DELETE",
      "EDIT": "EDIT",
      "SEND_MESSAGE": "SEND_MESSAGE",
      "ACTIVITIES": "ACTIVITIES"
  },
  "ANALYTICS": {
      "FULL": "FULL",
      "DOWNLOAD_REPORT": "DOWNLOAD_REPORT"
  },
  "SEARCH": {
      "ASSIGN_CHAT": "ASSIGN_CHAT",
      "BULK_ACTIONS": "BULK_ACTIONS"
  },
  "CHAT": {
      "VIEW_AS": "VIEW_AS",
      "INBOX": "INBOX",
      "ASSIGNED_TO_ME": "ASSIGNED_TO_ME",
      "UNANSWERED": "UNANSWERED",
      "UNASSIGNED": "UNASSIGNED",
      "EXPORT_CHAT": "EXPORT_CHAT",
      "NEW_CHAT": "NEW_CHAT",
      "COPY_MESSAGE": "COPY_MESSAGE",
      "ADD_QUICK_REPLY": "ADD_QUICK_REPLY",
      "PIN_UNPINT_CHAT": "PIN_UNPINT_CHAT",
      "MARK_AS_READ": "MARK_AS_READ",
      "CLOSE_CHAT": "CLOSE_CHAT",
      "SEND_MESSAGE": "SEND_MESSAGE",
      "EDIT_CONTACT": "EDIT_CONTACT",
      "ADD_TAGS": "ADD_TAGS",
      "ADD_CUSTOM_ATTRIBUTES": "ADD_CUSTOM_ATTRIBUTES",
      "CHANGE_ASSIGNMENT": "CHANGE_ASSIGNMENT",
      "REMOVE_TAGS": "REMOVE_TAGS",
      "DELETE_CUSTOM_ATTRIBUTES": "DELETE_CUSTOM_ATTRIBUTES",
      "VIEW_EMAIL": "VIEW_EMAIL",
      "VIEW_PHONENUMBER": "VIEW_PHONENUMBER",
      VIEW_AS_MYSELF: "VIEW_AS_MYSELF",
  },
  "BROADCAST": {
      "NEW_BROADCAST": "NEW_BROADCAST",
      "EDIT_BROADCAST": "EDIT_BROADCAST"
  },
  "TEMPLATES": {
      "ADD_LANGUAGE": "ADD_LANGUAGE",
      "SUBMIT_TEMPLATE": "SUBMIT_TEMPLATE",
      "DUPLICATE_TEMPLATE": "DUPLICATE_TEMPLATE",
      "DELETE_TEMPLATE": "DELETE_TEMPLATE"
  },
  "QUICK_REPLIES": {
      "CREATE_QUICK_REPLY": "CREATE_QUICK_REPLY",
      "EDIT_QUICK_REPLY": "EDIT_QUICK_REPLY",
      "DELETE_QUICK_REPLY": "DELETE_QUICK_REPLY"
  },
  "RULES": {
      "NEW_RULE": "NEW_RULE",
      "EDIT_RULE": "EDIT_RULE",
      "CHANGE_STATUS": "CHANGE_STATUS",
      "DELETE": "DELETE"
  },
  "GREETINGS": {
      "EDIT_WORKING_HOURS": "EDIT_WORKING_HOURS",
      "EDIT_MESSAGE": "EDIT_MESSAGE"
  },
  "CUSTOM_ATTRIBUTES": {
      "ADD_CUSTOM_ATTRIBUTE": "ADD_CUSTOM_ATTRIBUTE",
      "DELETE": "DELETE",
      "EDIT": "EDIT"
  },
  "TAGS": {
      "ADD": "ADD",
      "EDIT": "EDIT",
      "DELETE": "DELETE"
  },
  "KNOWLEDGE_BASE": {
      "CREATE_KNOWLEDGE_BASE": "CREATE_KNOWLEDGE_BASE",
      "TRAIN": "TRAIN",
      "DELETE": "DELETE",
      "EDIT": "EDIT"
  },
  "EXTERNAL_APPS": {
      "EDIT": "EDIT",
      "VIEW": "VIEW"
  },
  "WORKSPACE_PROFILE": {
      "FULL": "FULL",
      UPDATE: "UPDATE"
  },
  "USERS": {
      "ADD_NEW_AGENT": "ADD_NEW_AGENT",
      "EDIT": "EDIT",
      "REVOKE": "REVOKE"
  },
  "TEAMS": {
      "ADD_TEAM": "ADD_TEAM",
      "EDIT": "EDIT",
      "DELETE": "DELETE"
  },
  "CHANNELS": {
      "FULL": "FULL"
  },
  "WEBHOOKS_APIS": {
      "FULL": "FULL",
      CREATE_WEBHOOK: 'CREATE_WEBHOOK',
      CREATE_API: 'CREATE_API',
      EDIT: 'EDIT',
      DELETE: 'DELETE'
  },
  "MESSAGE_LOGS": {
      "EXPORT_LOGS": "EXPORT_LOGS"
  },
  "BILLING": {
      "FULL": "FULL"
  }
}

export enum ACTIONS_TYPE  {
  BOOLEAN =  "bool",
  STYLE = "style"
}
