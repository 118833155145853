import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import environment from "../environment";
import toast from "react-hot-toast";
import CryptoJS from "crypto-js";
import { StorageService } from "../storage";
import { captureException } from "@sentry/react";
import i18n from 'i18next';


function convertSearchParamsToString(searchParams: object) {

  let newSearchParamsString = ""

  const newParametersEnteries = Object.entries(searchParams).filter(([key, value]) => key && value);

  if (newParametersEnteries.length) {
     newSearchParamsString =  "?" + newParametersEnteries.map(([key, value]) => `${key}=${value}`).join("&");
  }

  return newSearchParamsString;
}

class ApiClient {
  private instance: AxiosInstance;
  public headers: { [k: string]: string } = {};


  constructor(baseURL: string) {
    this.instance = axios.create({
      baseURL,
      headers: {
        "ngrok-skip-browser-warning": "1",
        "bypass-tunnel-reminder": "1",
        "Content-Type": "application/json",
        Accept: 'application/json',
        ...this.headers,
      },
    });

    // Add a response interceptor
    this.instance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {

        const errorMessage = error?.response?.data?.message || error?.message || i18n.t('ERROR.ERROR_OCCURRED') ;
        if (!errorMessage.includes('Failed to fetch the wallet details')) {
          toast.error(errorMessage);
        }

        console.error('APIClient Error: ', error.message);
        captureException(error);
        throw error;
      }
    );
  }

  // Methods to match the Axios API
  get<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    const parameters = config?.params;
    const searchParamsString = parameters ? convertSearchParamsToString(parameters) : '';
    const fullUrl = this.instance.defaults.baseURL + url + searchParamsString;
    this._updateHeaders(fullUrl);
    return this.instance.get<T>(url, config);
  }

  post<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    const fullUrl = this.instance.defaults.baseURL + url;
    this._updateHeaders(fullUrl, data);
    return  this.instance.post<T>(url, data, config);
  }

  put<T = any>(
    url: string,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    const fullUrl = this.instance.defaults.baseURL + url;
    this._updateHeaders(fullUrl, data);
    return this.instance.put<T>(url, data, config);
  }

  delete<T = any>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    const parameters = config?.params;
    const searchParamsString = parameters ? convertSearchParamsToString(parameters) : '';
    const fullUrl = this.instance.defaults.baseURL + url + searchParamsString;
    this._updateHeaders(fullUrl);
    return this.instance.delete<T>(url, config);
  }

  _updateHeaders(fullUrl: string, data: object | null = null): void {
    const dataToSign = fullUrl + (data ? JSON.stringify(data) : "");
    const hmacSignature = CryptoJS.HmacSHA256(
      dataToSign,
      environment.REACT_APP_HUB_API_PRIVATE_KEY
    ).toString(CryptoJS.enc.Base64);

    const businessID: string | null = StorageService.getBusinessId();
    const authToken: string | null = StorageService.getAuthToken();
    this.instance.defaults.headers.common["X-API-SIGNATURE"] = hmacSignature;
    this.instance.defaults.headers.common["X-BUSINESS-ID"] = businessID;
    this.instance.defaults.headers.common["Avc-User-Id"] = StorageService.getUserId();

    // required for avc core api
    this.instance.defaults.headers.common["x-hasura-business-id"] = businessID;
    this.instance.defaults.headers.common["Authorization"] = authToken;
  }

}

export default ApiClient;

export const hubApi = new ApiClient(environment.REACT_APP_HUB_API_ENDPOINT);

export const landbotApi = new ApiClient(environment.REACT_APP_LANDBOT_API_ENDPOINT);

export const avcAPI = new ApiClient(environment.REACT_APP_API_GATEWAY_ENDPOINT);

export const installApp = (
  platform: string,
  installCode: string,
  accountId: string,
  params: any
) => {
  const queryParams = new URLSearchParams(params);
  const queryParamsString = queryParams.toString();

  return hubApi.post(
    `/integration/callback/${platform}/account?${queryParamsString}`
  );
};

export const UpdateInstallStoreData = (platform: string, storeId: string) =>
  hubApi.post( `/integration/callback/${platform}/add-business`, { "store_id": storeId });

export const saveTemplatesSettings = (
  storeId: string,
  newTemplatesValue: Record<string, any>
) =>
  hubApi.put(`/store/${storeId}/settings`, {
    templates: newTemplatesValue,
  });
