const EN_TRANSLATIONS = {
  AUTH : {
    ACCESS_DENIED: "Access Denied!",
    NOT_ALLOWED: "You are not authorized to view this page.",
  },
  NAVBAR_TOOLTIP: {
    REPORTS: "Reports",
    MEDIA_LIBRARY: "Media Library",
    SEARCH: "Search",
    CHAT: "Chat",
    CONTACTS: "Contacts",
    APPS: "Apps",
    SETTINGS: "Settings",
    NOTIFICATIONS: "Notifications",
    PROFILE: "Profile",
    COPILOT: "CoPilot",
    ECOMMERCE_APPS: "E-commerce Apps",
  },
  HOME: {},
  CHAT: {
    PAYLOAD: "Payload",
    TITLE: "Title",
    UPLOAD: "Upload ",
    LISTS_ADD_BODY: "Max 1024 characters",
    LISTS_ADD_FOOTER: "Max 60 characters",
    LISTS_ADD_BTN_TITLE: "Max 20 characters",
    LISTS_ADD_BTN_PAYLOAD: "Max 256 characters",
    LISTS_MENU_TITLE: "Max 20 characters",
    LISTS_SECTION_TITLE: "Max 24 characters",
    MAX_200_CHAR: "Max 200 character",
    ADD_SECTION: "Add Section",
    SECTION: "Setion",
    MENU_TITLE: "Menu Title",
    MENU_LIST_ITEMS: "Menu List Items",
    HEADER_TEXT: "Header Text",
    FOOTER: "Footer",
    NO_PREVIEW_AVAILABLE: "No preview available",
    SHARE_IMAGE: "Share image",
    SHARE_VIDEO: "Share video",
    SHARE_AUDIO: "Share audio",
    SHARE_FILE: "Share file",
    MESSAGE: "Message",
    CALL: "Call",
    COPY: "Copy",
    FAST_REPLY: "Fast reply",
    SEND: "Send",
    ADD_TO_CART: "Add to cart",
    SEND_TO_CHAT: "Send to chat",
    GALLERY: "Gallery",
    SAR: "SAR",
    ATTACHEMENTS: "Attachements",
    TAGS: "Tags",
    NOTE: "Note",
    ID: "ID",
    VIEW_AS: "View As",
    INBOX: "Inbox",
    UNANSWERED: "Unanswered",
    CHATBOT: "Chatbot",
    ASSIGNED_TO_ME: "Assigned to me",
    VIEW_MYSELF: "View as Myself",
    MENTIONS: "Mentions",
    ASSIGNED:"Assigned",
    CLOSED:"Closed",
    UNASSIGNED: "Unassigned",
    ARCHIVED: "Archived",
    ASSIGN_TO_ME: "Assign to me",
    SALES_TEAM: "Sales Team",
    MARKETING_TEAM: "Marketing Team",
    NEW_CHAT: "+ New Chat",
    NEW_CHAT_TITLE: "New Chat",
    SOMETHING_WENT_WRONG: "Something went wrong",
    PINNED_CHATS: "Pinned Chats",
    ALL_CHATS: "All Chats",
    START_NEW_CONVERSATION: "Start new conversation",
    CREATE_CONTACT: "Create Contact",
    TO: "To",
    SEARCH_FOR_NAME_NUMBER: "Search for a name or number",
    VIA_WHATSAPP: "via WhatsApp",
    SELECT_CHANNEL: "Select a channel",
    PIN_CHAT: "Pin Chat",
    UNPIN_CHAT: "Unpin Chat",
    MARK_AS_UNREAD: "Mark as Unread",
    MARK_AS_READ: "Mark as Read",
    EXPORT_CHAT: "Export Chat",
    CLOSE_CHAT: "Close Chat",
    OPEN_CHAT: "Open Chat",
    PREVIEW_CHAT: "Preview Chat",
    ASSIGN_CHAT: "Assign Chat",
    READ_CHAT: "Read Chat",
    UNREAD_CHAT: "Unread Chat",
    START_CONVERSATION: "Start a conversation",
    MESSAGE_INFO: "Message Info:",
    SENT: "Sent",
    DELIVERED: "Delivered",
    READ: "Read",
    FAILED: "Failed",
    ATTACHMENTS: "Attachments",
    PRIVATE_NOTE: "Private Note",
    SELECT_AGENT_OR_TEAM_TO_VIEW_CHATS: "Select an agent or team to view chats",
    NO_MSG_SELECTED: "No contacts selected",
    NO_MSG_SELECTED_SUBTITLE:
      "Please select one contact from the chats sidebar.",
    NO_PINNED_MESSAGES: "No pinned messages",
    NO_PINNED_MESSAGES_SUBTITLE: "All your pinned messages will be shown here",
    NO_MESSAGES_AVAILABLE: "No messages available",
    NO_MESSAGES_AVAILABLE_SUBTITLE: "All your messages will be shown here",
    PLEASE_START_CONVERSATION: "Please start a conversation to see messages here",
    WHATSAPP_NAME: "WhatsApp Name",
    PROFILE_NAME: "Profile Name",
    PHONE_NUMBER: "Phone Number",
    EMAIL_ADDRESS: "Email Address",
    CREATE_SEARCH_PLACEHOLDER: "Search",
    IMAGES: "Images",
    VIDEOS: "Videos",
    DOCUMENTS: "Documents",
    CHAT_CLOSED: "Chat Closed",
    CHAT_REOPENED: "Chat Reopened",
    CHAT_CLOSED_BODY:
      "The chat was closed by <0>'{{email}}'</0>. Kindly restart the chat to resume the conversation.",
    RESTART: "Restart",
    MESSAGE_BOX_PLACEHOLDER: 'Enter message or type "/" to use saved reply',
    QUICK_REPLIES_PLACEHOLDER: "Search for Quick Replies...",
    TEMPLATES_PLACEHOLDER: "Search for templates...",
    ENTER_TEMPLATE_VARIABLES: "Enter Template Variables",
    PREVIEW: "Preview: ",
    CLICK_TO_SEND_TEMPLATE: "Click to Send Template",
    NOT_ALLOWED_SEND_MESSAGE: "You can respond to a WhatsApp message within 24 hours of receiving it. But you don't have the permission to send messages.",
    _24_HOURS_WINDOW_TITLE: "Customer chat window closed",
    _24_HOURS_WINDOW_BODY:
      "You can respond to a WhatsApp message within 24 hours of receiving it. Post 24 hours, only pre-approved templates can be used to initiate a chat. <0>Learn more</0>",
    TIME_AGO_FROM_WHATSAPP: "{{time}} from WhatsApp",
    SENT_BY_AGO: "Sent by {{platform_id}} {{time}}",
    PRIVATE_NOTE_BY:
      "Private note added By {{platform_id}} · {{time}} · Only Visible for agents",
    TEAMS: "Teams",
    SYSTEM: "System",
    SEARCH_CHATS: "Search chats",
    UNAVAILABLE_MESSAGE: "Unavailable Message",
    TEXT_MESSAGE: "Text Message",
    IMAGE_MESSAGE: "Image Message",
    VIDEO_MESSAGE: "Video Message",
    AUDIO_MESSAGE: "Audio Message",
    VOICE_MESSAGE: "Voice Message",
    STICKER_MESSAGE: "Sticker Message",
    DOCUMENT_MESSAGE: "Document Message",
    LOCATION_MESSAGE: "Location Message",
    INTERACTIVE_MESSAGE: "Interactive Message",
    TEMPLATE_MESSAGE: "Template Message",
    CONTACTS_MESSAGE: "Contacts Message",
    MENTIONS_MESSAGE: "Mentions Message",
    SYSTEM_MESSAGE: "System Message",
    UNKNOWN_MESSAGE: "Unknown Message",
    ASSIGNED_TO_AGENT: "Chat assigned to {{email}}",
    CHAT_PINNED: "Chat Pinned",
    CHAT_UNPINNED: "Chat Unpinned",
    QUICK_REPLIES: "Quick Replies",
    TEMPLATES: "Templates",
    CHAT_EXPORTED:
      "Chat export request submitted. You will receive a mail shortly with download link.",
    CHAT_QUEUE: "Chat Queue",
    PHOTO: "Photo",
    VIDEO: "Video",
    INTERACTIVE: "Interactive",
    TEMPLATE: "Template",
    AUDIO: "Audio",
    VOICE: "Voice",
    YOU: "You",
    CONTACT: "Contact: {{name}}",
    MARK_AS_RESOLVED: "Mark as Resolved",
    MARK_AS_UNRESOLVED: "Mark as Unresolved",
    LAST_SEEN: "Last Seen: {{time}}",
    LONG_TIME_AGO: "Long time ago",
    SEARCH_CONTACTS: "Search Contacts",
    REPLY: "Reply",
    MESSAGE_INFO_BUTTON: "Message Info",
    ADD_QUICK_REPLY: "Add Quick Reply",
    COPY_MESSAGE: "Copy Message",
    LOCATION: "Location: {{location}}",
    CHAT_MARKED_AS_READ: "Chat is marked as read",
    CHAT_MARKED_AS_UNREAD: "Chat is marked as unread",
    SEARCH_AGENTS_TEAMS: "Search agents or teams",
    NO_CHANNELS_AVAILABLE: "No channels available for this business",
    NO_CHANNELS_CONNECTED:
      "No channel is currently connected. Please enable one in order to send/receive messages",
    MESSAGE_STATUS_DESCRIPTION: {
      ERROR: "Error: The message could not sent.",
      RETRY1: "Sending failed: Retrying attempt 1",
      RETRY2: "Sending failed: Retrying attempt 2",
    },
    MICROPHONE_DENIED_MESSAGE: "Please allow browser access to use microphones in recording voice messages",
    BROWSER_NOT_SUPPORT_MEDIA_RESOURCES: "GetUserMedia is not supported in this browser"
  },
  TEMPLATE: {
    CREATE_TEMPLATE: "Create template",
    SUBMIT_TEMPLATE: "Submit Template",
    SYNC_TEMPLATE: "Sync Template",
    TEMPLATE_ID_ERR:
      "This template is already submitted with the same language",
    TEMPLATE_SUB_TITLE: "Manage your WhatsApp templates here.",
    TEMPLATES: "Templates",
    NO_CHANNELS_CONNECTED:
      "Please connect to WhatsApp channel in order to send message, submit a template or to run a broadcast.",
    ZERO_TEMPLATES: "You have no templates yet",
    ZERO_DELETED_TEMPLATES: "You have no deleted templates to display",
    ZERO_TEMPLATES_MATCH: "You have no templates match the data you entered",
    OPT_PLACEHOLDER: "One Time Password (OTP)",
    ONLY_PDF: "Only pdf files are allowed",
    ONLY_SUPPORTED_IMG: "Only JPG and PNG images are allowed",
    ONLY_SUPPORTED_VIDEOS: "Only MP4 videos are allowed",
    VIEW_TEMPLATE: "View Template",
    BUTTON_TITLE: "Button Title",
    COPY_CODE_DESCRIBTION:
      "A copy code button copies the one-time password or code to the user's clipboard. The user can then manually switch to your app and paste the password or code into your app's interface.",
    AUTOFILL_DESCRIBTION: `This is only supported on Android, and requires changes to your app's code in order to perform a "handshake", and your app's signing key hash.`,
    COPY_CODE: "COPY CODE",
    COPY_CODE_BUTTON_TITLE: "Copy Code",
    AUTOFILL: "AUTOFILL",
    PHONE_NUMBER_ERR_MSG: "Please Enter a valid phone number!",
    AUTOFILL_BUTTON_TITLE: "Autofill",
    PACKAGE_NAME: "com.example.app",
    SIGNATURE_HASH: "Ex: h83m0^#83",
    ADD_LANGUAGE_TO_TEMPLATE: "Add a New Language",
    CREATE_TEMPLATE_DESCRIPTION:
      "Submit your message templates for approval to WhatsApp here.",
    VIEW_TEMPLATE_DESCRIPTION: "View your WhatsApp templates here.",
    TEMPLATE_ID: "Template ID",
    CATEGORY: "Category",
    SELECT_CATEGORY: "Select Category",
    DESCRIPTION: "Description",
    DESCRIPTION_PLACEHOLDER: "Description (Optional)",
    LANGUAGE: "Language",
    SELECT_LANGUAGE: "Select a Language",
    MEDIA: "Media",
    NONE: "None",
    IMAGE: "Image",
    VIDEO: "Video",
    DOCS: "Docs",
    BODY_TEXT: "Body Text",
    INSERT_VAR: "INSERT VARIABLE {{#}}",
    INSERT_OPT_OUT_LINK: "INSERT OPT OUT LINK",
    FOOTER: "Footer (optional)",
    FORMAT_TEXT_OPTION: "Want to Format Text?",
    FORMATTING_WHATSAPP_MESSAGES: "See Formatting WhatsApp Messages ",
    HERE: "here",
    BUTTONS: "Buttons",
    CTA_BUTTONS: "CTA Buttons",
    QUICK_REPLIES: "Quick replies",
    NEXT: "Next",
    SUBMIT: "Submit",
    SAVING: "Saving...",
    SAVED: "Saved",
    ERROR: "Error",
    WHATSAPP_ALERT_MESSAGE:
      "WhatsApp Template Messages must be submitted and approved before they can be sent to your contacts.",
    CREATE_MOBILE_MESSAGE:
      "Submittion of templates is complex from a mobile screen. Please visit this page from a larger screen",
    SUBMITTED: "SUBMITTED",
    SELECT_STATUS: "Select a Status",
    CLEAR_FILTER: "Clear Filter",
    SEARH_TEMPLATES: "Search Templates",
    STATUS: "Status",
    SHOW_CHAT: "Show in Chat",
    ACTION: "Action",
    ADD_BUTTON: "+ Add Button",
    BUTTON: "Button",
    TYPE_OF_ACTION: "Type of action",
    PHONE_NUMBER: "Phone number",
    STATIC_LINK: "Static Link",
    ENTER_URL_HERE: "Enter URL Here",
    ENTER_PHONE_HERE: "Enter phone number",
    BUTTON_DISPLAY_TEXT: "Button display text",
    APPROVED: "APPROVED",
    REJECTED: "REJECTED",
    DELETED: "DELETED",
    ALLOW_CATEGORY_CHANGE: "Allow category change",
    SECURITY_DISCLAIMER: "Security disclaimer",
    EXPIRATION_WARNING: "Expiration warning",
    DELETE_DIALOG_HEADER: "Delete Template",
    DELETE_DIALOG_SUB_HEADER:
      "Are you sure you want to delete this template? If you delete the template, You won`t be able to use it again.",
    CONFIRM_DELETE: "Confirm delete",
    ALL_TEMPLATES: "All Templates",
    DELETED_TEMPLATES: "Deleted Templates",
    FILTERS: {
      STATUS: {
        PLACEHOLDER: "Select a Status",
        OPTIONS: {
          APPROVED: "Approved",
          SUBMITTED: "Submitted",
          REJECTED: "Rejected",
          DELETED: "Deleted",
          PAUSED: "Paused",
        },
      },
      LANGUAGE: {
        PLACEHOLDER: "Select a Language",
        OPTIONS: {
          AR: "AR",
          EN: "EN",
        },
      },
      CATEGORY: {
        PLACEHOLDER: "Select a Category",
        OPTIONS: {
          MARKETING: "Marketing",
          OTP: "One Time Password",
          TRANSACTIONAL: "Transactional",
        },
      },
    },
    ACTIONS_BTN: {
      VIEW: "View",
      PREVIEW: "Preview",
      ADD_LANGUAGE: "Add Language",
      DUPLICATE: "Duplicate template",
      DELETE: "Delete template",
    },
    SELECT_CATEGORY_OPTIONS: {
      AUTHENTICATION: "Authentication",
      MARKETING: "Marketing",
      UTILITY: "Utility",
    },
    VARIABLES_PREVIEW: {
      UPLOAD_SAMPLE_IMAGE: "Upload sample image",
      UPLOAD_SAMPLE_VIDEO: "Upload sample video",
      UPLOAD_SAMPLE_DOCUMENT: "Upload sample documnet",
      UPLOAD_IMAGE: "Upload image",
      UPLOAD_VIDEO: "Upload video",
      UPLOAD_DOCUMENT: "Upload document",
      ENTER_VARIABLE: "Enter the variable",
      ADVANCED_OPTIONS: "Advanced Options (Buttons)",
      BACK: "Back",
      SUBMIT: "Submit for review",
    },
    LANGUAGE_WARNING:
      "The selected language does not match the content you have entered. Kindly check and proceed.",
  },
  SETTINGS: {
    SETTINGS: "Settings",
    SUBTITLE: "Manage your Profile and Workspace settings here",
    WORKSPACE_SETTINGS: "Workspace Settings",
    CUSTOM_ATTRIBUTES: "Custom Attributes",
    WORKSPACE_PROFILE: "Workspace Profile",
    USERS: "Agents",
    TEAMS: "Teams",
    CONTACTS: "Contacts",
    DEVTOOLS: "Dev Tools",
    CHANNELS: "Channels",
    WEBHOOKS_APIS: "Webhooks & APIs",
    MESSAGE_LOGS: "Message Logs",
    ACCOUNT_SETTINGS: "Account Settings",
    PROFILE: "Profile",
    NOTIFICATIONS: "Notifications",
    LOGOUT: "Logout",
    LOGGING_OUT: "Logging out...",
    COPILOT_INITIAL_MSG:
      "Hi, I'm CoPilot, your personal assistant. I can help you with anything you need. Just ask me!",
    SWITCH_ORGANIZATION: "Switch Organization",
    SOMETHING_WENT_WRONG: "Something went wrong",
    DEV_TOOLS: {
      CREATE_API_KEY: "Create API Key",
      FOR_RULES: "For Rules",
      API_NAME: "API Name",
      ENTER_API_NAME: "Enter API name",
      ACCESS_MODE: "Access Mode",
      RESET: "Reset",
      SELECT_MODE: "Select Mode",
      READ: "Read",
      READ_WRITE: "Read/Write",
      CANCEL: "Cancel",
      SAVE: "Save",
      SAVING: "Saving...",
      SAVED: "Saved",
      ERROR: "Error",
      VIEW_DETAILS: "View Details",
      CONNECTED: "Connected",
      CONNECTING: "Connecting...",
      AVAILABLE_CHANNELS: "Available Channels",
      CONNECT: "Connect",
      CONNECT_VIA: "Connect via",
      CONTINUE_WITH_META: "🟡 Continue with Meta",
      CONNECTED_VIA: "Connected via",
      CONNECT_VIA_360: "Connect via 360Dialog",
      CONNECT_VIA_META: "Connect via Meta",
      CONNECT_WHATSAPP_TITLE: "Connect WhatsApp via Meta",
      CONNECT_WHATSAPP_SUBTITLE: "Connect WhatsApp to your inbox",
      CONNECT_WHATSAPP_CLOUD: "Connecting WhatsApp Cloud API",
      PHONE_NUMBER_ID: "WhatsApp Phone Number ID*",
      ACCESS_TOKEN: "Access Token*",
      WHATSAPP_BUSINESS_ID: "WhatsApp Business Account ID",
      COPY_FIELDS_TO_WHATSAPP: "Copy the following fields to WhatsApp",
      CALLBACK_URL: "Callback URL",
      VERIFY_TOKEN: "Verification Token",
      CONNECTED_VIA_META: "Connected via Meta",
      META_VIEW_DETAILS: "Due to the channel being connected directly via Meta, any changes to Business profile or details should be made on Meta\'s WhatsApp Manager.",
      VISIT_BUSINESS_MANAGER: "Visit your Business Manager",
      WAITING_FOR_VERIFICATION: "Waiting for verification",
      VERIFICATION_SUCCESSFUL: "Verification Successful",
      SUCCESSFULLY_CONNECTED_WHATSAPP:
        "You have successfully connected WhatsApp Cloud API",
      NOW_ACTIVE: "Now you will start receiving messages from your contacts",
      ADD_WHATSAPP_CHANNEL: "Add a new WhatsApp channel",
      CONNECT_WHATSAPP_INBOX: "Connect WhatsApp to your inbox",
      ADD_WHATSAPP_ACCOUNT: "Add a new WhatsApp account",
      ADD_WHATSAPP_360:
        "Add a new WhatsApp account (with 360Dialog as a provider)",
      SIGNUP_NEW_ACCOUNT: "Signup for a new account",
      DIALOG_INFO_360:
        "You will be prompted to signup for an account through 360dialog in a popup.",
      DATE: "Date",
      FROM: "From (Sender)",
      TO: "To (Receiver)",
      SRC: "SRC",
      MESSAGE_SOURCE: {
        CHANNEL_CONTACT: "Channel Contact",
        AGENT: "Agent",
        BOT: "Bot",
        EXTERNAL: "External",
        BUSINESS: "Business",
        HOST: "Host",
      },
      CREATE_WEBHOOK: "Create Webhook",
      EDIT_WEBHOOK: "Edit Webhook",
      NAME: "Name",
      ENTER_WEBHOOK_NAME: "Enter webhook name",
      DESCRIPTION: "Description",
      DESCRIPTION_WEBHOOK: "Description for your webhook",
      EVENT: "Event",
      ACTIONS_TRIGGER_INFO_WEBHOOK:
        "These are the real time events that will trigger the call to your webhook URL",
      SELECT_EVENT: "Select Event",
      INCOMING_MESSAGE: "Incoming Message",
      OUTGOING_MESSAGE: "Outgoing Message",
      CHAT_ASSIGNED: "Chat Assigned",
      CHAT_CLOSED: "Chat Closed",
      CHAT_MENTIONED: "Chat Mentioned",
      CONTACT_CREATED: "Contact Created",
      TEMPLATE_APPROVED: "Template Approved",
      BROADCAST_COMPLETED: "Broadcast Completed",
      BILLING_ALERT: "Billing Alert",
      CONTACT: "Contact",
      POLICY: "Policy",
      WEBHOOK_POLICY_INFO: "Policy to use while triggering the webhook",
      SIMPLE: "Simple",
      SELECT_POLICY: "Select Policy",
      STREAMING_MODE: "Streaming Mode",
      SELF_HEALING: "Self Healing",
      SAMPLING_30: "Sampling - 30%",
      BATCH_MODE: "Batch Mode",
      BATCH_HIGH_FREQUENCY: "Batch - High Frequency",
      ENDPOINT: "Endpoint",
      ENDPOINT_INFO:
        "An HTTP POST request will be sent to this URL for the event you set up",
      SECURITY_TOKEN: "Security Token",
      SECURITY_TOKEN_INFO:
        "A security challenge token to identify your webhook. Will sent as 'token' query param",
      CREATED_BY: "Created By",
      CREATED_DATE: "Created Date",
      URL: "URL",
      API_KEY: "API Key",
      WEBHOOKS: "Webhooks",
      WEBHOOK_INFO:
        "Listen for events on your account so integrations can automatically trigger reactions",
      API_INFO: "Create API keys to access your account programmatically",
      CREATE_API: "Create API",
      EDIT: "Edit",
      DELETE: "Delete",
      STATUS_LOG: "Status Log",
      TOTAL: "Total",
      SUCCESS: "Success",
      FAILED: "Failed",
      REQUEST_ID: "Request ID",
      RETRY_COUNT: "Retry Count",
      STATUS_CODE: "Status Code",
      REQUEST_BODY: "Response Body",
      TIME_STAMP: "Time Stamp",
      NO_STATUS: "No status data to display",
      REVOKE: "Revoke",
      CLICK_TO_COPY: "Click to copy",
      COPIED: "Copied ✅",
      CONNECT_WORKSPACE: "Connect your Workspace with third-party channels.",
      PHONE: "Phone",
      PHONE_DESCRIPTION: "Connect with users through Phone",
      WHATSAPP: "WhatsApp",
      WHATSAPP_DESCRIPTION: "Connect with users through WhatsApp Business API",
      FB: "Facebook",
      FB_DESCRIPTION:
        "Connect with users through Facebook channel via the platform",
      IG: "Instagram",
      IG_DESCRIPTION:
        "Integrate your Instagram store without switching to mobile app",
      SMS: "SMS",
      SMS_DESCRIPION: "Integrate SMS capabilities to your platform",
      TELEGRAM: "Telegram",
      TELEGRAM_DESCRIPION:
        "Connect Telegram as a channel and respond to users freely",
      RCS: "RCS",
      RCS_DESCRIPTION:
        "Integrate RCS as a channel and target customers on SMS band",
      CHANNEL_UNAVAILABLE: "Channel Unavailable",
      CHANNEL_UNAVAILABLE_INFO:
        "This channel is currently being developed. Stay tuned for its availability in the coming weeks.",
      MESSAGES_LOGS: "Message Logs",
      MESSAGES_LOGS_SERVICE: "View and Download customer message logs",
      ALL_CHANNELS: "All Channels",
      EXPORT_RESULTS: "Export Results",
      MESSAGE_TYPE: "Message Type",
      MESSAGE_TYPE_OPTIONS: {
        in: "Incoming",
        out: "Outgoing",
      },
      CHANNEL_OPTIONS: {
        facebook: "Facebook",
        whatsapp: "WhatsApp",
        instagram: "Instagram",
      },
      STATUS_OPTIONS: {
        ACCEPTED: "Accepted",
        DELETED: "Deleted",
        DELIVERED: "Delivered",
        FAILED: "Failed",
        RECEIVED: "Received",
        SEEN: "Seen",
        SENT: "Sent",
      },
      CHANNEL: "Channel",
      DATE_OPTIONS: {
        LAST_7_DAYS: "Last 7 Days",
        LAST_30_DAYS: "Last 30 Days",
        LAST_3_MONTHS: "Last 3 Months",
        LAST_6_MONTHS: "Last 6 Months",
        LAST_YEAR: "Last Year",
        CUSTOM_DATE_RANGE: "Custom Date Range",
      },
      SELECT_BY_DATE: "Select by Date",
      WEBHOOKS_API: "Webhooks & API",
      MANAGE_WEBHOOKS_API: "Manage your Workspace Webhooks & API Settings",
      NO_WEBHOOKS: "You have no webhooks yet",
      ACCESS_KEYS: "Access keys",
      CREATE_FIRST_WEBHOOK: "+ Create your first webhook",
      NO_API_KEYS: "You have no API keys yet",
      CREATE_FIRST_API: "+ Create your first key",
      WEBHOOKS_DELETE_TITLE: "Are you sure you wish to revoke <0>{{name}}</0>? ",
      WEBHOOKS_DELETE_BODY: "⚠️ Any existing API integration currently using <0>{{name}}</0> key will lose the ability to used further.",
      REVOKED_SUCCESSFULLY: "API key has been revoked successfully",
      CREATE_WEBHOOK_FORM: {
        ERRORS: {
          NAME: "Name is required",
          NAME_LENGTH: "Name must be at most 50 characters",
          DESCRIPTION: "Description is required",
          DESCRIPTION_LENGTH: "Description must be at most 1024 characters",
          EVENT: "Event is required",
          POLICY: "Policy is required",
          ENDPOINT: "Endpoint is required",
          ENDPOINT_VALID: "Endpoint must be a valid URL",
          SECURE_TOKEN: "Secure token is required",
          SECURE_TOKEN_VALID:
            "Secure token must match the following: '/^[^a-zA-Z0-9 -_]+$/g'",
        },
      },
      CREATE_API_KEY_FORM: {
        ERRORS: {
          NAME: "API name is required",
          NAME_LENGTH: "API name must be at most 50 characters",
          ACCESS_MODE: "Access mode is required",
        },
      },
    },
    ACCOUNT: {
      NOTIFICATIONS: "Notifications",
      NOTIFICATIONS_PREFRENCES: "Manage notifications preferences",
      NEW_MESSAGE_NOTIFICATION: "New Message Notifications",
      INSIDE_CHAT_SCREEN: "Inside Chat Screen",
      INSIDE_CHAT_SCREEN_SUBTITLE: "Receive New Message Notifications by",
      INSIDE_CHAT_SCREEN_FILTER: {
        CONTEXT: "Context",
        AGENT: "Agent",
        // TEAM: "Team",
        ALL: "All",
      },
      OUTSIDE_CHAT_SCREEN: "Outside Chat Screen",
      OUTSIDE_CHAT_SCREEN_SUBTITLE: "Receive New Message Notifications by ",
      OUTSIDE_CHAT_SCREEN_FILTER: {
        ALL: "All",
        AGENT: "Agent",
        // TEAM: "Team",
      },
      SOUND_NOTIFICATION: "Sound Notifications",
      PLAY_SOUND_NOTIFICATION:
        "Play sound notifications for contacts assigned to me or unassigned contacts",
      DESKTOP_NOTIFICATIONS: "Desktop notifications",
      UNBLOCK_DESKTOP_NOTIFICATION_HELP:
        "👆 Desktop notifications have been blocked. Please enable them by clicking Icon on the left side of the address bar > Notifications > Allow",
      ENABLE_DESKTOP_NOTIFICATION:
        "Desktop notifications is blocked by your browser. <0>Click here</0> to enable desktop notifications!",
      SOUND_NOTIFICATION_1:
        "Play sound notifications for contacts assigned to me or unassigned contacts",
      SOUND_NOTIFICATION_2: "Play sound notifications for new messages",
      EMAIL_NOTIFICATION: "Email Notifications",
      GROUP_NOTIFICATION: "Group Notifications",
      ENABLE_EMAIL_NOTIFICATION:
        "Send me notification for the contacts assigned to me and mentions",
      GROUP_EMAIL_NOTIFICATION:
        "Send group notification to the team members assigned to the team on mentions",
      IMPORTANT_NOTIFICATIONS:
        "Important notifications about your account security.",
      USER_PROFILE: "Agent Profile",
      USER_PROFILE_DESCRIPTION: "Manage your profile and sign-in details.",
      PROFILE_PHOTO: "Profile Photo",
      UPLOAD_IMAGE: "Upload image",
      CONTACT_NAME: "Contact Name",
      PASSWORD: "Password",
      UPDATE: "Update",
      UPDATING: "Updating...",
      UPDATED: "Updated",
      ERROR: "Error",
      CONTACT_EMAIL: "Contact Email",
      SELECT_LANGUAGE: "Select Language",
      COUNTRY: "Country",
      PHONE: "Phone Number",
      DOB: "Date Of Birth",
      ADDRESS: "Address",
      EN: "English",
      AR: "Arabic",
      HI: "Hindi",
      CHANGE_PASSWORD: "Change Password",
      CURRENT_PASSWORD: "Current Password",
      NEW_PASSWORD: "New Password",
      NEW_PASSWORD_ERROR: "New password cannot be the same as current password",
      NEW_PASSWORD_VALIDATION: "Password cannot be less than 8 characters and should contain at least 1 upper case letter, 1 lower case letter and 1 numeric digit",
      CURRENT_PASSWORD_ERROR_MESSAGE: "Failed changing password, current password entered was incorrect",
      DELETE_ACCOUNT: {
        DELETE_BUTTON: "Delete my Account",
        DELETE_ACC_TITLE: "Delete account",
        DELETE_ACC_DESC: "Are you sure you want to delete your account? All of your chats will be permanently moved to the selected agent. This action cannot be undone.",
        DELETE_ASSIGN_USER: "Assign contacts to another user",
        DELETE_ACC_BUTTON: "Delete Account",
        DELETING: "Deleting...",
        DELETED: "Deleted",
      }
    },
  },
  GREETINGS: {
    TITLE: "Greeting and Away Messages",
    DESCRIPTION:
      "Set your greeting/away messages to be sent to customers inside/outside working hours.",
    SET_WORKING_HOURS: "Set your Working Hours",
    SET_BUSINESS_HOURS: "Setup your business working hours",
    GREETING_MSG: "Greeting message",
    GREETINGS_MSG_ALERT: "Greetings message will be only activated for chats that are in unassigned.",
    GREETING_MESSAGE_DESCRIPTION:
      "Welcome new customers automatically when they message you during business hours.",
    TIME_INTERVAL: "Time Interval",
    AWAY_MSG: "Away Message",
    AWAY_MSG_DESCRIPTION:
      "Let customers know that they have reached you outside business hours.",
    GREEING_AWAY: "Greeting and Away Messages",
    SET_GREETING_AWAY:
      "Set your greeting/away messages to be sent to customers inside/outside working hours.",
    SELECT_TIME_ZONE: "Select Time Zone",
    UPDATE_ADR_ERROR: "Error updating ADR",
    ERROR_NO_BUSINESS_HOURS: "Business hours are required to update greeting/away message settings",
    TIME_ERROR: "Start time can't be after close time",
    INTERVAL_MIN_ERROR: "Interval must be greater than or equal to 1",
    INTERVAL_MAX_ERROR: "Interval can't be greater than ",
    INTERVAL_VALIDATION_ERROR: "Interval must be Number",
    AWAY_TYPE_ERROR:
      "Away interval must be a `number` type, but the final value was: `NaN` (cast from the value `''`).",
    GREETING_TYPE_ERROR:
      "Greeting interval must be a `number` type, but the final value was: `NaN` (cast from the value `''`).",
    DELAY_DESCRIBTION: "Delay Message (in Sec):",
    MSG_PLACE_HOLDER: "Type your message...",
    SAVE: "Save",
    SAVING: "Saving...",
    SAVED: "Saved",
    ERROR: "Error",
    SUNDAY: "Sunday",
    MONDAY: "Monday",
    TUESDAY: "Tuesday",
    WEDNESDAY: "Wednesday",
    THURSDAY: "Thursday",
    FRIDAY: "Friday",
    SATURDAY: "Saturday",
    TIMEZONE_SET: "You need to set your timezone to use this feature",
  },
  CONTACTS: {
    CONTACT: "Contact",
    HEADER: "Contacts",
    SUBTITLE: "Manage your contacts",
    EMPTY_CONTACTS:
      "Contacts list is empty. Add contacts to start engaging with your customers.",
      FALSE_SEARCH_RESULTS: "No contacts found for the search term",
    IMPORT_EXPORT_CONTACTS_BTN: "Bulk Actions",
    IMPORT_CONTACTS_BTN: "Import contacts",
    EXPORT_CONTACTS_BTN: "Export contacts",
    IMPORTS: "Imports",
    EXPORTS: "Exports",
    CREATE_CONTACT_BTN: "Create Contact",
    NO_CONTACTS: "You have no contacts yet",
    CREATE_FIRST_CONTACT_BTN: "Create my first contact",
    SHOW_LIST: "Show List",
    EXPAND_TO_VIEW: "Expand to view: ",
    IMPORT_POPUP: {
      TITLE: "Import contacts",
      SUBTITLE: "Prepare your file:",
      P1: "Optimize your import by preparing your file. Make sure the first column is the name, the second the phone number and the third column the email address.",
      P2: "Contacts will not be duplicated. If a contact already exists, it will be updated with the new information.",
      DOWNLOAD_SAMPLE_BTN: "Download XLSX Sample",
      UPLOAD_FILE_BTN: "Upload .xlsx File",
    },
    EXPORT_POPUP: {
      TITLE: "Export contacts",
      SUBTITLE_1: "Download your contacts:",
      SUBTITLE_2: "You can download contacts as CSV or Excel format",
      DOWNLOAD_CSV: "Download as CSV",
      DOWNLOAD_EXCEL: "Download as Excel",
    },
    TABLE: {
      NORMAL_TITLE: "Contacts",
      NORMAL_SUBTITLE: "Create and Manage your contacts",
      IMPORT_TITLE: "Import Contacts",
      ACTIVITIES: "Activities",
      MOST_USED_TAGS: "Most Used Tags",
      ALL_TAGS: "All Tags",
      SEARCH_TAGS: "Search Tags",
      HEADERS: {
        NAME: "Name",
        DISPLAY_NAME: "Display Name",
        EMAIL: "Email",
        PLATFORM_ID: "Platform ID",
        COUNTRY: "Country",
        LANGUAGE: "Language",
        ASSIGNED_TO: "Assigned to",
        USER: "Users",
        TAGS: "Tags",
        CHANNEL: "Channel",
        CONVERSATION: "Conversation",
        ASSIGNEE: "Assignee",
        LAST_MESSAGE: "Last Message",
        ACTION: "Action",
        FILE_NAME: "File Name",
        NO_CONTACTS: "No. of Contact's",
        CREATED_BY: "Created by",
        CREATED_AT: "Created at",
        STATUS: "Status",
        REPORT: "Report",
        URL: "URL",
        DOWNLOAD: "Download",
        REASON: "Reason",
      },
      FOOTER: "Import {{total}} contacts",
      INVALID_TAG_NAME: "Invalid tag name",
      INVALID_AGENT_EMAIL: "Invalid agent email",
    },
    NOTIFICATIONS: {
      SUCCESS: "Success",
      IMPORT_SUCCESS: "Successfully imported all records",
    },
    CREATE_POPUP: {
      TITLE: "Create new contact",
      UPDATE_TITLE: "Update contact",
      TITLES: {
        PROFILE_PHOTO: "Profile Photo",
        CONTACT_NAME: "Contact Name",
        CONTACT_EMAIL: "Contact Email",
        CONTACT_PHONE: "Contact Phone",
        CHANNEL: "Channel",
        TAGS: "Tags",
      },
      BUTTONS: {
        UPLOAD_IMAGE: "Upload image",
        CANCEL: "Cancel",
        SAVE: "Save",
        SAVING: "Saving...",
        SAVED: "Saved",
        EDIT: "Edit",
        UPDATE: "Update",
        UPDATING: "Updating...",
        UPDATED: "Updated",
        ERROR: "Error",
        ARCHIVE: "Archive Contact",
        DELETE: "Delete",
        MORE: "More",
      },
      TAGS_OPTIONS: {
        TAG_1: "Tag 1",
        TAG_2: "Tag 2",
        TAG_3: "Tag 3",
      },
      ERROR_MSG: {
        UNIQUENESS_VIOLATION :"Uniqueness violation",
        CONTACT_DUPLICATED : "This contact already exists." ,
      }
    },
    ACTIVITIES_IMPORT_POPUP: {
      TOTAL_CONTACTS: "Total Contacts",
      FAILED_CONTACTS: "Failed Contacts",
      WARNING_CONTACTS: "Warning Contacts",
      PROCESSED_CONTACTS: "Processed Contacts",
      NO_CONTACTS: "No failed contacts data to display",
    },
  },
  SEARCH: {
    HEADER: "Search",
    DIRECTION: "Direction",
    SEARCH_CONTACTS_PLACEHOLDER: "Search for contacts",
    SEARCH_MESSAGES_PLACEHOLDER: "Search for messages",
    NO_MSGS_FOUND: "No message found",
    TRY_AGAIN_WITH_OTHER_WORDS: "Tinker with a word or two and try again.",
    CATEGORIES: {
      ALL: "All",
      MESSAGES: "Messages",
      CONTACTS: "Contacts",
    },
    FILTERS: {
      MESSAGE_TYPE: {
        PLACEHOLDER: "Message type",
        OPTIONS: {
          TEXT: "Text",
          IMAGE: "Image",
          VIDEO: "Video",
          DOCUMENT: "Document",
        },
      },
      READ_STATUS: {
        PLACE_HOLDER: "Read status",
        OPTIONS: {
          READ: "Read",
          UNREAD: "Unread",
        },
      },
      CHAT_STATUS: {
        PLACE_HOLDER: "Chat status",
        OPTIONS: {
          // unanswered: "Unanswered",
          assigned: "Assigned",
          unassigned: "Unassigned",
          closed: "Closed",
          // snoozed: "Snoozed",
          archived: "Archived",
        },
      },
      ASSIGNED_TO: {
        PLACEHOLDER: "Assigned to",
      },
      CHANNEL: {
        PLACEHOLDER: "Channel",
        OPTIONS: {
          WHATSAPP: "WhatsApp",
          FACEBOOK: "Facebook",
          INSTAGRAM: "Instagram",
        },
      },
      LAST_ACTIVITY: {
        PLACEHOLDER: "Last activity",
        OPTIONS: {
          LAST_7_DAYS: "Last 7 Days",
          LAST_30_DAYS: "Last 30 Days",
          LAST_6_MONTHS: "Last 6 Months",
          LAST_3_MONTHS: "Last 3 Months",
          LAST_YEAR: "Last Year",
          CUSTOM_DATE_RANGE: "Custom Date Range",
        },
      },
      DATE_ADDED: "Date added",
      TAGS: {
        PLACEHOLDER: "Tags",
        OPTIONS: {
          TAG_1: "Tag 1",
          TAG_2: "Tag 2",
          TAG_3: "Tag 3",
        },
      },
      MESSAGE_DIRECTION: {
        in: "Incoming",
        out: "Outgoing",
      },
    },
    TOOLTIPS: {
      ASSIGN_CHAT: "Assign Chat",
      ADD_TAG: "Add Tag",
      MARK_CHAT_READ: "Mark as read",
      MARK_CHAT_UNREAD: "Mark as unread",
      CLOSE_CHAT: "Close chat",
    },
    START_SEARCHING: "Start searching or use filter for results",
    SELECT_ALL: "Select all",
    GO_TO_CHAT: "Go to Chat",
    LIMIT_MSG: {
      SELECTED: "Selected {{count}} {{type}}",
      SELECTED_TO_LIMIT: "Caution ⚠️ You have selected the first {{count}} {{type}} for bulk action",
      SELECTED_TO_MAXLIMIT: "Select more than 256 contacts (Max upto 1000 {{type}}). The action applied once cannot be undone",
    },
    BULK_ACTION_CONFIRMATION_TITLE: "Bulk Action Confirmation",
    BULK_ACTION_CONFIRMATION_DESCRIPTION: "Are you sure you want to",
    BULK_ACTION_FOR: " for these ",
    PROCESSING  : "Processing...",
  },
  APPS: {
    HEADER: "Apps",
    EXTERNAL_INTEGRATIONS: "External Integrations",
    SUBTITLE:
      "Unlock the full potential of your business with powerful apps and integrations",
    BROADCAST: {
      TITLE: "Broadcast",
      DESCRIPTION:
        "Use WhatsApp broadcasts to message multiple contacts together – for transactional or marketing messages.",
    },
    TEMPLATES: {
      TITLE: "Templates",
      DESCRIPTION:
        "Templates enable users to initiate conversations by sending WhatsApp-approved messages & for the purpose of utility, authentication & marketing.",
    },
    QUICK_REPLIES: {
      TITLE: "Quick replies",
      DESCRIPTION:
        "Shortcuts for frequently sent messages and media like images or videos. Respond to customers faster using Quick Replies.",
    },
    RULES: {
      TITLE: "Rules",
      DESCRIPTION:
        "Establish rules for both incoming and outgoing messages. Let our rules engine simplify your life, automating workflows so you can dedicate time to what truly matters.",
    },
    GREETINGS_AWAY: {
      TITLE: "Greetings & Away",
      DESCRIPTION:
        "Designed to enhance your customer interactions. Greeting messages welcome customers, while away messages inform them when you're unavailable.",
    },
    TAGS: {
      TITLE: "Tags",
      DESCRIPTION:
        "Classify & Associate customers based on their attributes using ‘tags’. Segregate & organize different categories of your customer base effectively.",
    },
    CHATGPT: {
      TITLE: "ChatGPT",
      DESCRIPTION:
        "Revolutionize your customer engagement by providing accurate, relevant, and quick contextual solutions with ChatGPT",
    },
    DIALOG_FLOW: {
      TITLE: "Dialogflow",
      DESCRIPTION:
        "Create smart chatbots that efficiently handle queries, improving the user experience with Dialogflow integration",
    },
    GOOGLE_TRANSLATE: {
      TITLE: "Google Translate",
      DESCRIPTION:
        "Provide real-time translations for improved satisfaction, without manual interpretation",
    },
    HUBSPOT: {
      TITLE: "Hubspot",
      DESCRIPTION:
        "Optimize your business operations with streamlined customer relationship management",
    },
    ZAPIER: {
      TITLE: "Zapier",
      DESCRIPTION:
        "Maximize your productivity with Zapier. Automate workflow and seamlessly transfer data between applications",
    },
    CSML: {
      TITLE: "CSML",
      DESCRIPTION:
        "Integrate CSML chatbots into your platform to automate your customer support and sales processes",
    },
    GOOGLE_SHEETS: {
      TITLE: "Google Sheets",
      DESCRIPTION:
        "Automatically sync your data with Google Sheets to keep your team updated with the latest information",
    },
    BOT_BUILDER: {
      TITLE: "Bot Builder",
      DESCRIPTION:
      "Create and manage your chatbot with ease using the Bot Builder tool. Customize your bot to suit your business needs and improve customer interactions.",
    },
    WHATSAPP_CATALOG: {
      TITLE: "WhatsApp Catalog",
      DESCRIPTION:
        "Create and manage your WhatsApp catalog to showcase your products and services to customers",
    },
    KNOWLEDGE_BASE: {
      TITLE: "Knowledge Base",
      DESCRIPTION:
        "An information hub with articles, guides & FAQs. It enables users to find solutions & insights, encouraging self-help & reducing the need for direct support.",
    },
    MEDIA_LIBRARY: {
      TITLE: "Media Library",
      DESCRIPTION:
        "A unified resource for handling diverse media like images, videos, audio, documents, etc. Promoting easy discovery, sharing, and usage in projects and communication.",
    },
    WHATSAPP_FLOW: {
      TITLE: "WhatsApp Flow",
      DESCRIPTION:
        "Enabling businesses to craft comprehensive customer experiences on WhatsApp, enriching interactions.",
    },
    MOTTASL_BOT: {
      TITLE: "Mottasl Bot",
      DESCRIPTION:"Enhance your chat experience by activating the exclusive Chatbot through the Mottasl Bot.",
      CONFIGURATION: "Mottasl Bot Configuration",
      CONFIGURE: "Configure your Mottasl bot here",
      ADD_CONFIGURATION_INFO: "Enter details for Configuration",
      CHANNEL_ID: "Channel ID",
      CHANNEL_TOKEN: "Channel Auth Token",
      BOT_ID: "Bot ID",
      DEFAULT_AGENT: "Default Agent",
      DEFAULT_TEAM: "Default Team",
      DEFAULT_ASSIGNED_ACCOUNT_TYPE: "Default assigned account type",
      ERROR_MESSAGES: {
        CHANNEL_ID_IS_REQUIRED: "Channel id is required",
        CHANNEL_TOKEN_IS_REQUIRED: "Channel auth token is required",
        BOT_ID_IS_REQUIRED: "Bot id is required",
        DEFAULT_AGENT_IS_REQUIRED: "Select an agent is required",
        DEFAULT_TEAM_IS_REQUIRED: "Select a team is required",
        DEFAULT_ASSIGNED_ACCOUNT_TYPE_IS_REQUIRED: "Select a assigned account type is required",
      },
       CONNECTED_SUCCESSFULLY:  "Bot Connected & Activated Successfully",
       ACTIVATED_AND_RUNNING:  "Your Bot is active and running",
      START_RECEIVING_MESSAGES: "Now you will start receiving messages from your contacts",

       ACTIVE_STATUS_TITLE :"Your Bot is active now",
       ACTIVE_STATUS_DESCRIPTION :"Your Bot is active and running",
       INACTIVE_STATUS_TITLE :"Your Bot is inactive now",
       INACTIVE_STATUS_DESCRIPTION: "Your Bot is inactive and halted",

       DEACTIVE_STATUS_TITLE: "Deactivate Bot",
       DEACTIVE_QUESTION: "Are you sure you want to deactivate your Bot?",
       DEACTIVE_STATUS_DESCRIPTION :"All of your bot based activity will be halted.",

    },
     ACTIVECAMPAIGN: {
      TITLE: "ActiveCampaign",
      DESCRIPTION:
        "ActiveCampaign account to manage your contacts, deals, and campaigns in ActiveCampaign.",
    },
    PIPEDRIVE: {
      TITLE: "Pipedrive",
      DESCRIPTION:
        "Pipedrive account used to sync records from your Pipedrive CRM. ",
    },
     "CUSTOM.ODOO" : {
      TITLE : "Odoo",
      DESCRIPTION: "Odoo account to sync records from your Odoo CRM."
    },
    GOOGLESHEETS: {
      TITLE:"Google Sheets",
      DESCRIPTION:
        "Provide real-time translations for improved satisfaction, without manual interpretation",
    },
    BADGES: {
      DEV: "Dev",
      UPCOMING: "Upcoming",
      BETA: "Beta",
    },
    UNDER_DEV: {
      COMMING_SOON: "Coming Soon..",
      BODY1: "We're currently working on this feature. Stay tuned for its release",
      BODY2: "coming weeks and get ready to streamline your communication!"
    },
    WHATSAPP_WIDGET: {
      TITLE: "WhatsApp Widget",
      DESCRIPTION:
        "Give customers a way to reach you on WhatsApp from your website.",
    },
  },
  ECOMMERCE_APPS: {
    HEADER: "E-commerce Apps",
    SUBTITLE: "Integrate your business with leading e-commerce platforms to streamline your operations",
    MANAGE_YOUR_STORE: "Manage your store",
    MANAGE: "Manage",
    ZID: {
      TITLE: "Zid",
      DESCRIPTION:
        "Elevate Your Store with Zid: Automate alerts, manage orders effortlessly, reclaim abandoned carts, and boost revenue with targeted campaigns. Transform your operations with Zid.",
    },
    SALLA: {
      TITLE: "Salla",
      DESCRIPTION:
        "Automate communication, oversee orders with precision, recover lost sales, and drive revenue growth through strategic marketing. Elevate your store with Salla.",
    },
    FOODICS: {
      TITLE: "Foodics",
      DESCRIPTION:
        "Install Foodics: Streamline Your Store's Operations Automate notifications, manage order statuses, recover abandoned carts, and boost revenue with targeted campaigns.",
    },
    SHOPIFY: {
      TITLE: "Shopify",
      DESCRIPTION:
        "Enhance your store with automated notifications, streamlined order management, abandoned cart recovery, and revenue-boosting marketing campaigns.",
    },
    WOOCOMMERCE: {
      TITLE: "Woocommerce",
      DESCRIPTION:
        "Enhance your store with automated notifications, streamlined order management, abandoned cart recovery, and revenue-boosting marketing campaigns.",
    },
    FOODER: {
      TITLE: "Fooder",
      DESCRIPTION:
        "Receive orders via Fooder WhatsApp within minutes after creating your menu and make your customers' experience the best by owning the largest database.",
      INSTALL_TITLE: "Please contact your account manager to install Fooder account",
      INSTALL_DESCRIPTION: "Click the button below to be redirected to gofooder.com",
    },
    INSTALL_MODAL: {
      TITLE: "{{platform}} Integration",
      STORE_URL_LABEL: "Store URL",
      STORE_URL_PLACEHOLDER: "https://my-store.com",
      CONSUMER_KEY_LABEL: "Consumer Key",
      CONSUMER_KEY_PLACEHOLDER: "xx-xx-xx-xx-xx-xx-xx",
      CONSUMER_SECRET_LABEL: "Consumer Secret",
      CONSUMER_SECRET_PLACEHOLDER: "xx-xx-xx-xx-xx-xx-xx",
      CONFIRM_MOTTASL_PLUGIN_INSTALL_LABEL: "Confirm that mottasl extention must be installed in your store",
      VALIDATION_URL_MESSAGE: "Please enter valid https url",
      VALIDATION_CONSUMER_KEY_MESSAGE: "Please enter a valid consumer key",
      VALIDATION_CONSUMER_SECRET_MESSAGE: "Please enter a valid consumer secret",
      VALIDATION_INSTALL_MOTTASL_PLUGIN_MESSAGE: "Please install mottasl plugin and check confirm.",
      MAIN_NOTE:
        "You will be redirected to {{platform}} Store for Installation",
      STEPS_HEADER: "To Install the App, Follow These Simple Steps:",
      CLICK_INSTALL: "Click on the 'Install' Button Below:",
      REDIRECT_YOU: "This will redirect you to the {{platform}} Store.",
      LOG_IN: "Log In:",
      USE_CREDENTIALS:
        "Use your store login credentials to access your account.",
      INSTALL_APP: "Install the App:",
      FIND_APP: "Find the app and click 'Install'.",
      RETURN_TO_AVC: "Return to Avocado:",
      INSTALL_COMPLETE:
        "Once the installation is complete, come back to the Avocado platform.",
      ACTION: "Install {{platform}}",
      SUCCEESS_INSTALL_MESSAGE: "Installed successfully.",
      SALLA: {
        COPY_BUSINESS: "Copy Business ID",
        USE_BUSINESS_TO_CONFIG: 'Click the "Copy Business ID" button. This ID will be used to configure the app',
        INSTALL_SALLA: "Install Salla:",
        INSTALL_IN_MARKETPLACE: 'Click the "Install Salla" button to be directed to the app in the Salla marketplace.',
        ACCEPT_AUTHORIZATION: "Accept Authorization:",
        CLICK_TO_ACCEPT_AUTHORIZATION: 'Click the "Accept Authorization" button in the pop-up window.',
        ACCESS_APP_SETTINGS: "Access App Settings:",
        CLICK_TO_ACCESS_APP_SETTINGS: "Click the mini red icon on the app card. A pop-up will appear once the red icon is clicked.",
        ADD_BUSINESS_ID: "Add Business ID:",
        PAST_BUSINESS_ID: "Paste the copied Business ID into the provided field.",
        CONFIRM_BUSINESS_ID: "Confirm Business ID:",
        CLICK_TO_ADD_BUSINESS_ID: 'Click the "Add Business ID" button.',
        FOLLOW_STEPS_TO_INSTALL_SALLA:
          "By following these steps, the Salla app will be automatically activated on your platform. ✅",
      },
      "INSTALL_STORE_SUCCESS": "App store installed successfully",
      INSTALL_STORE_ERROR: {
        DEFAULT: "Something went wrong.",
        IS_ALREADY_INSTALLED: "Ooops, Store already installed with another business"
      }
    },
    SETTINGS: {
      TOTAL_INSTALLED_STORES: "Total Installed Stores",
      NO_DATA_AVAILABLE: "No data available",
      CLICK_ON_SHOW_ALL: 'Click On "Show All" button',
      FILTER_ORDER_STATUS: {
        ORDER_CREATED: "New orders",
        ORDER_READY: "Order ready",
        ORDER_PREPARING: "Order Preparing",
        ORDER_DELIVERING: "Order delivering",
        ORDER_COMPLETED: "Order Completed",
        ORDER_CANCELLED: "Order Canceled",
        COD_CONFIRMATION: "COD Confirmation",
        PAYMENT_PENDING: "Payment Pending",
      },
      ACTIVE: "Active",
      NO_ARGS_REQUIRED: "No arguments quired, you can attach your template!",
      ORDER_ANALYTICS: "Order Analytics",
      ORDERS: "Orders",
      ABANDONED_CART_ANALYTICS: "Abandoned Cart Analytics",
      CHARTS_OPTIONS: {
        ABANDONED_CART_FIRST_SHOT: "First Shot",
        ABANDONED_CART_SECOND_SHOT: "Second Shot",
        INSTANT_CAMPAIGN: "Instant Campaign",
      },
      TOTAL_ABANDONED_CARTS: "Total abandoned carts",
      ABANDONED_FIRST_CARTS: "Abandoned first carts",
      ABANDONED_SECOND_CARTS: "Abandoned second carts",
      FAILED_ABANDONED_CARTS: "Failed abandoned carts",
      TOTAL_REVENUE_GENERATED: "Total revenue generated",
      ABANDONED_INSTANT_NOTIFICATIONS: "Instant campaign",
      ABANDONED_CARTS_EMPTY_WARNING: "No Abandoned Carts Available for this campaign based on your selected filters.",
      IN_SAR: "In SAR",
      SAR: "SAR",
      IN_AVG: "In AVG",
      IN_TOTAL: "In Total",
      HALALA: "HALALA",

      TOTAL_TRIGGERED_NOTIFICATIONS: "Triggered notifications",
      TOTAL_COMPLETED_NOTIFICATIONS: "Completed notifications",
      TOTAL_INCOMPLETED_NOTIFICATIONS: "uncompleted notifications",
      INSTANCES: "Instances",
      ORDER_NOTIFICATION: "Order Notification",
      EVENT_TYPE_PLACEHOLDER: "Select event type",
      STORE_INFO: "Store Info",
      CONTROL_STATUSES: "Control Statuses",
      ATTACH_TEMPLATES: "Attach Templates",
      CART_RECOVERY: "Cart Recovery",
      VIEW_ORDERS: "View & manage all your orders here",
      ENABLE_CART_RECOVERY: "Enable Cart Recovery",
      CAMPAIGN: "Campaign",
      ATTACH: "Attach",
      ANALYTICS: "Analytics",
      STORE: "Store",
      VIEW_STORE_INFO: "View all your store info here",
      CHANNEL_NAME: "Channel Name",
      CHANNEL_PLATFORM: "Channel Platform",
      STOREFRONT_PLATFORM: "Storefront Platform",
      EMAIL: "Email Address",
      STORE_URL: "Web Store URL",
      STORE_ID: "Store Id",
      STORE_SUBSCRIPTION_TITLE: "Store subscription in",
      PACKAGE_TYPE: "Package Type",
      SUBSCRIPTION_STATUS: "Subscription Status",
      START_DATE: "Start Date",
      END_DATE: "End Date",
      UNINSTALLED: "UNINSTALLED",
      VIEW_ORDER_STATUS: "View & manage all your orders here",
      ATTACH_TEMPLATE: "Attach Template",
      ATTACHED_TEMPLATE: "Attached Template",
      AVAILABLE_TEMPLATES: "Available Templates",
      NO_AVAILABLE_TEMPLATES: "No available templates",
      TEMPLATE_ATTACHED: "Template Attached",
      ENTER_VARIABLE: "Enter the variable",
      SELECT_ARGUMENTS: "Select the arguments",
      ADD_COUPON: "Add Coupon",
      COUPON_ADDED: "Coupon added",
      ADD_COUPON_HERE: "Add your coupon code here",
      SOME_MISSING_ARGS: "Some arguments are missing.",
      RUN_INSTANT_CAMPAIGN: "Run Instant Campaign",
      ORDER_NOTIFICATION_TITLE: "Order Notification",
      ORDER_NOTIFICATION_SUBTITLE: "View your store analytics here",
      ABANDONED_CART_NOTIFICATION_SUBTITLE:
        "Abandoned cart analytics based on your store notifications",
      SELECT_ABANDONED_CART: "Select Abandoned Cart",
      ANALYTICS_TITLE: "Analytics",
      ANALYTICS_SUBTITLE: "Elevate decision-making with our analytics page. Visualize data for actionable insights and growth.",
      ORDER_NOTIFICATION_SENT: "Order Notification Sent",
      ABANDONED_CART: "Abandoned Cart",
      SELECT_TEMPLATE: "Select Template",
      RUN_INSTANT_CART: "Run Instant Cart",
      FROM_DATE: "From Date",
      TO_DATE: "To Date",
      MIN_CART_TOTAL: "Min Cart Total",
      ENTER_AMOUNT: "Enter Amount",
      MIN_PRODUCT_TOTAL: "Min Product Total",
      ENTER_QUANTITY: "Enter Quantity",
      SEARCH_ABANDONED_CART: "Search Abandoned Cart",
      FINANCIAL_METRICS: "Financial Metrics",
      ESTIMATED_REVENUE: "Estimated Revenue",
      ESTIMATED_MAX_COST: "Estimated Max Cost",
      NEXT: "Next",
      FINANCIAL_METRICS_NOTE:
        "*This is the financial metrics for the selected Abandoned cart Recovery campaign",
      RUN_CAMPAIGN: "Run Campaign",
      ABANDONED_CART_TABLE: {
        ID: "ID",
        CUSTOMER: "Customer",
        CHECKOUT_URL: "Checkout URL",
        TOTAL: "Total (in SAR)",
        AGE: "Age (in Min)",
        CREATED_ON: "Created at",
        CUSTOMER_PHONE: "Customer phone",
        FIRST_SHOT: "First Shot",
        SECOND_SHOT: "Second Shot",
        PRODUCTS_COUNT: "Products count",
      },
      ORDER_STATUSES: {
        TITLE: "Order Statuses",
        ORDER_CREATED: "Order Created",
        ORDER_PREPARING: "Order Preparing",
        COD_CONFIRMATION: "COD Confirmation",
        ORDER_CANCELLED: "Order Cancelled",
        ORDER_COMPLETED: "Order Completed",
        ORDER_READY: "Order Ready",
        ORDER_DELIVERING: "Order Delivering",
        CUSTOMER_CREATED: "Customer Created",
        ADMIN_INVENTORY_NOTIFICATION: "Admin Inventory Notification",
        ORDER_RESTORED: "Order Restored",
        ORDER_RESTORING: "Order Restoring",
        ORDER_SHIPPED: "Order Shipped",
        PAYMENT_PENDING: "Payment Pending",
        RESERVATION_CREATED: "Reservation Created",
        ORDER_FAILED: "Order Failed",
        ORDER_REFUNDED: "Order Refunded",
        ORDER_REVERSED: "Order Reversed",
        ORDER_CHARGEBACK: "Order Chargeback",
        ORDER_EXPIRED: "Order Expired",
        ORDER_PROCESSED: "Order Processed",
        ORDER_VOIDED: "Order Voided",
        ORDER_DENIED: "Order Denied",
        ORDER_PROCESSING_REVERSED: "Order Processing Reversed",
        ORDER_CANCELED_REVERSAL: "Order Canceled Reversal",
        CART_RECOVERY_FIRST: "Abandoned Cart First Shot (After ~3 Hours)",
        CART_RECOVERY_SECOND: "Abandoned Cart Second Shot (After ~24 Hours)",
        ABANDONED_CART_FIRST_SHOT: "Abandoned Cart First Shot (After ~3 Hours)",
        ABANDONED_CART_SECOND_SHOT:
          "Abandoned Cart Second Shot (After ~24 Hours)",
        INSTANT_CAMPAIGN: "Instant campaign",
      },
      TEMPLATES_VARIABLES: {
        MINIMUM_QUANTITY: "Minimum Quantity",
        PRODUCT_NAME: "Product Name",
        CURRENT_QUANTITY: "Current Quantity",
        CUSTOMER_NAME: "Customer Name",
        CUSTOMER_PHONE_NO: "Customer Phone No",
        CUSTOMER_EMAIL: "Customer Email",
        ORDER_ID: "Order Id",
        ORDER_STATUS_AR: "Order Status Arabic",
        ORDER_STATUS_EN: "Order Status English",
        ORDER_URL: "Order Url",
        RATING_LINK: "Rating Link",
        INVOICE_PDF: "Invoice Pdf",
        ORDER_TOTAL: "Order Total",
        STORE_NAME: "Store Name",
        STORE_URL: "Store Url",
        SHIPPING_METHOD_NAME: "Shipping Method Name",
        COUPON_CODE: "Coupon Code",
        SHIPPING_TRACKING_LINK: "Shipping Tracking Link",
        PAYMENT_METHOD_NAME: "Payment Method Name",
        BANNER_URL: "Banner Url",
        CART_ID: "Cart Id",
        PHASE: "Phase",
        CUSTOMER_ID: "Customer Id",
        PRODUCTS_COUNT: "Products Count",
        CART_URL_FULL: "Cart Url Full",
        CART_URL_SEMI: "Cart Url Semi",
        FIRST_SHOT_CODE: "First Shot Code",
        SECOND_SHOT_CODE: "Second Shot Code",
        PENDING_PAYMENT_LINK: "Pending Payment Link",
      },
      SEND_CAMPAIGN: {
        START: "Run Campaign",
        LOADING: "Sending...",
        SUCCESS: "Sent",
        FAIL: "Error"
      },
    },
  },
  BROADCAST: {
    HEADER: "Broadcast",
    SUBTITLE:
      "Send a WhatsApp template message to all or part of your contact list at once.",
    CREATE_BROADCAST_BTN: "New Broadcast",
    ZERO_BROADCASTS: "You have no broadcast yet",
    CONNECT_CHANNEL: "You need to attach your channel to start using broadcasts.",
    ADD_CHANNEL: "Add Channel",
    STATUS_BOX: {
      MESSAGING_TIER: {
        KEY: "Messaging Tier:",
        VALUE: "{{tier}}",
        DESCRIPTION: "(MAX {{count}} customers in 24 hours)",
        UNLIMITED: "Unlimited number of customers"
      },
      ACCOUNT_HEALTH: {
        KEY: "Account Health:",
        VALUE: {
          GREEN: "Green",
          RED: "Red",
          YELLOW: "Yellow",
        },
      },
      ACCOUNT_STATUS: {
        KEY: "Account Status:",
        VALUE: {
          CONNECTED: "CONNECTED",
          LIVE: "LIVE",
          INACTIVE: "INACTIVE"
        },
      },
      ACCOUNT_TIER: {
        "SANDBOX": "Sandbox",
        "TIER_1": "First Tier",
        "TIER_2": "Second Tier",
        "TIER_3": "Third Tier",
        "UNLIMITED": "Unlimited",
      }
    },
    FILTERS: {
      STATUS: {
        PLACEHOLDER: "Select a Status",
        OPTIONS: {
          DRAFT: "Draft",
          SCHEDULED: "Scheduled",
          SUBMITTED: "Submitted",
          IN_REVIEW: "In Review",
          PROCESSING: "Processing",
          COMPLETED: "Completed",
          FAILED: "Failed",
          HALTED: "Halted",
          INVALIDATED: "Invalidated",
        },
      },
      DATE: "Select by Date",
      SEARCH: "Search",
    },
    BROADCASTS_TABLE: {
      HEADERS: {
        BROADCAST_NAME: "Broadcast Name",
        CHANNEL: "Channel",
        RECIPIENTS: "Recipients",
        STATUS: "Status",
        SENT: "Sent",
        MESSAGING_SUMMARY: "Messaging Metrics",
        ACTION: "Action",
      },
      N_DAYS_AGO: "{{days}} days ago",
      RECIPIENTS: "Recipients: {{count}}",
      TEMPLATE: "Template: {{template_id}}",
    },
    DETAILS: {
      SENT_ON: "Sent on {{date}}",
      BROADCAST_STATUS: "Broadcast Status",
      TARGET_SUMMARY: "Target Summary",
      LAST_UPDATED: "Last updated {{date}}",
      VIEW_DETAILS: "View Details",
      MESSAGING_METRICS: 'Messaging Metrics',
      MESSAGING_SUMMARY: {
        TOTAL_BROADCASTS: 'Total Broadcasts',
        DELIVERED_BROADCASTS: 'Delivered Rate',
        DELIVERED_PERCENTAGE: 'Delivered Rate',
        SUB_DELIVERED_PERCENTAGE: 'Messages Delivered',
        SUB_READ_PERCENTAGE: 'Messages Read',
        SUB_RESPONSE_PERCENTAGE: 'Messages Responded',
        READ_BROADCASTS: 'Read Broadcasts',
        READ_PERCENTAGE: 'Read Rate',
        ENGAGING_RESPONSES: 'Engaging Responses',
        RESPONSE_PERCENTAGE: 'Response Rate',
        DELIVERED_PERCENTAGE_DESC:"Delivered",
        READ_PERCENTAGE_DESC:"Read",
        RESPONSE_PERCENTAGE_DESC:"Health",
      },
      FILTERS: {
        STAGE: {
          PLACEHOLDER: "API Stage",
          OPTIONS: {
            ACCEPTED: "Accepted (Sent)",
            READ: "Read",
            FAILED: "Failed",
            DELIVERED: "Delivered",
          },
        },
        RESULTS: {
          PLACEHOLDER: "Results",
          OPTIONS: {
            RESPONDED: "Responded",
            CLICKED: "Clicked",
            CONVERTED: "Converted",
            OPT_OUT: "Opt Out",
            BLANKS: "Blanks",
          },
        },
        DOWNLOAD_CONTACTS: "Download Contacts",
      },
      TABLE_HEADERS: {
        USERS: "Agents",
        STATUS: "Status",
        RESULTS: "Results",
        DATE: "Date",
      },
      STATUS: {
        FAILED: "FAILED",
        SENT: "SENT",
        DELIVERED: "DELIVERED",
        READ: "READ",
      },
      RESULTS: {
        RESPONDED: "RESPONDED",
        CLICKED: "CLICKED",
        CONVERTED: "CONVERTED",
      },
      MOBILE_MESSAGE: {
        HEADER: "This feature is optimized for desktop site.",
        DESCRIPTION:
          "Viewing and downloading the broadcast messages are much easier on a computer. Follow these instruction and we'll get you what you need:",
        VISIT: {
          1: "Visit ",
          2: "Mottasl",
          3: " on your desktop",
        },
        SELECT: "Select",
        NAVIGATE: "Navigate",
        DONE: "Done",
      },
      STATUS_DESCRIPTION: {
        draft: "",
        scheduled: "The broadcast is scheduled successfully",
        submitted: "The broadcast is submitted successfully",
        in_review: "The broadcast is reviewed",
        processing: "The broadcast is processed successfully",
        completed: "The broadcast is sent successfully",
        failed: "The broadcast review failed",
        halted: "",
        invalidated: "",
      },
      SCHEDULED_IN: "This Broadcast is scheduled in",
      SEND_DELETE:
        "You can either Send the broadcast now or delete it from here",
      CANCEL_BROADCAST: "Cancel Broadcast",
      SEND_BROADCAST: "Send Broadcast Now",
      SCHEDULED_FOR: "Scheduled for {{date}}",
      BROADCAST: "Broadcast",
      HOURS_AGO: "{{hours}} hours ago",
      GO_TO_CHAT: "GO TO CHAT",
    },
    CREATE_POPUP: {
      TITLE: "Create Broadcast",
      SUBTITLE: "Create new broadcast to start using it",
      EDIT_TITLE: "Edit Draft Broadcast",
      EDIT_SUBTITLE: "Edit your draft broadcast to start using it",
      BROADCAST_DETAILS: "Broadcast Details",
      BROADCAST_DETAILS_SUBTITLE:
        "Name your broadcast and select the language and template.",
      RECIPIENT_SELECTION: "Recipient Selection",
      RECIPIENT_SELECTION_SUBTITLE: "Select your broadcast targets here",
      SCHEDULE_OR_SEND_BROADCAST: "Schedule or Send Broadcast",
      SCHEDULE_OR_SEND_BROADCAST_SUBTITLE:
        "Send your broadcast or schedule it here",
      TEMPLATE_VARIABLES: "Template Variables",
      NO_TEMPLATES_FOR_LANGUAGE:
        "No templates are available for {{language}} language",
      TITLES: {
        BROADCAST_NAME: "Broadcast Name",
        LANGUAGE: "Language",
        SELECT_TEMPLATE: "Select Template",
        PREPARE_DRAFT: "Prepare broadcast as draft?",
      },
      SEARCH_TEMPLATES: "Search by template id",
      SELECT_TARGET: "Select Target",
      RESET_TARGETS: "Reset Targets",
      CLEAR_WARNING:
        "Clearing the selected target type will remove all the selected targets from it. Are you sure you want to continue?",
      ALL_CONTACTS: "All Contacts",
      ARCHIVED_CONTACTS_NOT_INCLUDED: "Archived contacts are not included",
      CONTACTS: "Contacts",
      LAST_ASSIGNED_TO_AGENT: "Last Assigned to Agent",
      CUSTOMER_CREATED: "Customer Created",
      ALL_CLOSED_CHATS: "All Closed Chats",
      TAGS: "Tags",
      LAST_ASSIGNED_TO_TEAMS: "Last Assigned to Teams",
      COUNTRY: "Country",
      ALL_UNREAD_CHATS: "All Unread Chats",
      CUSTOM_FIELDS: "Custom Fields",
      LAST_ACTIVITY: "Last Activity",
      WHATSAPP: "WhatsApp",
      FILTER_OPTED_OUT: "Filter Opted Out",
      FILTER_OPTED_OUT_TOOLTIP:
        "Whether to filter out the opted-out customers from the broadcast",
      INFO_TEMPLATE_VARIABLES:
        "You can use the following variables in your template",
      SELECT_DO_NOT_TARGET: "Select Do Not Target",

      SELECT_CONTACTS: "Select Contacts",
      SELECT_TAGS: "Select Tags",
      SELECT_CUSTOM_FIELD: "Select Custom Field",
      SELECT_AGENT: "Select Agent",
      SELECT_TEAMS: "Select Teams",
      SELECT_COUNTRIES: "Select Countries",

      SEARCH: "Search",
      YES: "Yes",
      NO: "No",
      IF: "If",
      IS: "is",
      ARE: "are",
      TEXT: "Text",
      URL: "URL",
      CHECKED: "Checked",
      UNCHECKED: "Unchecked",
      TEAM_MEMBERS: "Team Members",
      SELECT_TIME: "Select Time",
      SELECT_DATE: "Select Date",
      ON: "on",
      AT: "at",
      BETWEEN_DATES: "Between {{startDate}} and {{endDate}}",
      COUNTRIES: "Countries",
      ALL_COUNTRIES: "All Countries",
      SELECTED_COUNTRIES: "Selected Countries",
      SEARCH_COUNTRIES: "Search Countries",

      SEND_TO_TARGET: "Send to Target",
      NOT_SEND_TO_TARGET: "Do Not Send to Target",
      SCHEDULE_OR_SEND: "Schedule or Send",
      SCHEDULE_TO_SENT_AT: "Schedule to send at {{time}}",
      BROADCAST_SUMMARY: "Broadcast Summary",
      TAGED_CONTACTS: "{{tagged}} Contacts tagged",
      TOTAL_N_CONTACTS: "Total {{count}} contacts",
      N_CONTACTS: "{{count}} contacts",

      UPLOAD_IMAGE: "Upload image",
      UPLOAD_VIDEO: "Upload video",
      UPLOAD_DOCUMENT: "Upload document",
      CHANGE_IMAGE: "Change image",
      CHANGE_VIDEO: "Change video",
      CHANGE_DOCUMENT: "Change document",

      CONFIRM_TITLE: "Confirm Send Broadcast",
      CONFIRM_BODY:
        "Are you certain that you want to send the template <0>{{template_name}}</0> to <0>{{count}} customers</0>?",

      DELETED_TEMPLATE: "Template not found",
      DELETED_TEMPLATE_BODY:
        "The template you have selected earlier is either removed or rejected. Kindly select a new template from <0>“Broadcast Details”</0> to proceed further.",

      ALERT_MOBILE:
        "Creating broadcast is complex from a mobile screen. Please visit this page from a larger screen.",

      SELECT_FUTURE_DATE: "You have to select a time in the future",

      SUMMARY: {
        BROADCAST_NAME: "Broadcast Name: ",
        LANGUAGE: "Language: ",
        BROADCAST_SCHEDULE: "Scheduled to sent at ",
        TEMPLATE_NAME: "Template Name: ",
      },
      BUTTONS: {
        VIEW: "View",
        CONTINUE_DRAFT: "Continue Draft",
        CANCEL: "Cancel",
        BACK: "Back",
        NEXT: "Next",
        SEND_NOW: "Send Now",
        SENDING: "Sending...",
        SENT: "Sent",
        CREATE: "Create",
        CREATING: "Creating...",
        CREATED: "Created",
        SAVING: "Saving...",
        SAVED: "Saved",
        ERROR: "Error",
        SCHEDULE: "Schedule",
        RESCHEDULE: "Reschedule",
        CONFIRM_SCHEDULE: "Confirm & Schedule",
        UNSCHEDULE: "Unschedule",
        ADD_TO_FILTERS: "Add to Filters",
        SELECT_TEMPLATE: "Select Template",
      },
    },
    ESTIMATE_POPUP:{
      TITLE:'Estimation',
      SUB_TITLE:'View the estimated cost of the Broadcast',
      ESTIMATED_COST:'Estimated Cost',
      WALLET_BALANCE:'Wallet Balance',
      COUNTRY:'Country',
      RECIPIENTS:'Recipients',
      CHARGES:'Charges',
      SEND_NOW:'Send Now',
      INSUFFICIENT_BALANCE:'Insufficient Credit Balance!',
      ADD_CREDIT:'Add Credits'
    },
    NO_CHANNEL:'No active channel for this account'
  },
  QUICK_REPLIES: {
    HEADER: "Quick Replies",
    SUBTITLE: "Respond to customers faster by setting pre-composed messages.",
    CREATE_BTN: "Create Quick Reply",
    NO_TEMPLATES: "You have no Quick Reply yet",
    TYPE_PLACEHOLDER: "All types of Quick Replies",
    NO_QR_MATCH: "There's no quick replies match the data you entered",
    TYPE_OPTIONS: {
      TEXT: "Text",
      VIDEO: "Video",
      IMAGE: "Image",
      // AUDIO: "Audio",
      // VOICE: "Voice",
      // STICKER: "Sticker",
      // LOCATION: "Location",
      // INTERACTIVE: "Interactive",
      // TEMPLATE: "Template",
      // CONTACTS: "Contacts",
      // MENTIONS: "Mentions",
      // SYSTEM: "System",
      DOCUMENT: "Document",
    },
    SEARCH_PLACEHOLDER: "Search",
    TABLE_HEADERS: {
      SHORT_CODE: "Short Code",
      MESSAGE: "Message",
      KEYWORDS: "Keywords",
      AVAILABLE_TO: "Available To",
      ACTION: "Action",
    },
    AVAILABLE_TO: {
      ALL: "All",
      TEAMS: "Teams",
      CREATOR: "Only Creator",
      AVAILABLE_TO_ALL: "Availabe to : All",
      AVAILABLE_TO_CREATOR: "Available to : Creator",
      AVAILABLE_TO_TEAMS: "Available to : Teams",
    },
    CREATE_POPUP: {
      MOBILE_TITLE: "Create Quick Reply",
      TITLE: "Create New Quick Reply",
      EDIT_TITLE: "Edit Quick Reply",
      LABELS: {
        SHORT_CODE: "Short Code",
        KEYWORDS: "Keywords",
        BODY_TEXT: "Body Text",
        AVAILABLE_TO: "Available to",
      },
      SELECT_TEAMS: "Select Teams",
      ERRORS: {
        SHORT_CODE: "Short code is required",
        SHORT_CODE_LENGTH: "Short code must be at most 50 characters",
        BODY_TEXT: "Body text is required",
        BODY_TEXT_LENGTH: "Body text must be at most 1024 characters",
        KEYWORDS: "Keywords is required",
        MAX_KEYWORDS: "You can't add more than 10 keywords",
      },
      BUTTONS: {
        CANCEL: "Cancel",
        SAVE: "Save",
        SAVING: "Saving...",
        SAVED: "Saved",
        UPDATE: "Update",
        UPDATING: "Updating...",
        UPDATED: "Updated",
        ERROR: "Error",
      },
    },
  },
  TAGS: {
    HEADER: "Tags",
    SUBTITLE: "Create and Manage Tags here",
    ZERO_TAGS: "You have no Tags yet",
    CREATE_BTN: "Add New Tag",
    SEARCH_PLACEHOLDER: "Search Tags",
    CANT_DELETE_ITEM: "This item cannot be removed. It could be already in use with one of broadcast target or attached to a customer.",
    TABLE_HEADERS: {
      NAME: "Name",
      DESCRIPTION: "Description",
      CREATED_BY: "Created By",
      NO_OF_CONTACTS: "No. of Contacts:",
      CREATED_ON: "Created On",
      ACTION: "Action",
    },
    CREATE_POPUP: {
      TITLE: "Add Tag",
      EDIT_TITLE: "Update Tag",
      PICK_COLOR: "Pick a color",
      LABELS: {
        TAG_NAME: "Tag Name",
        DESCRIPTION: "Description",
        COLOR: "Color",
      },
      PLACEHOLDERS: {
        TAG_NAME: "Enter Tag Name here",
        DESCRIPTION: "Enter Tag description here",
        COLOR: "Tag1",
      },
      ERRORS: {
        TAG_NAME: "Tag name is required",
        DESCRIPTION: "Tag description is required",
        COLOR: "Color is required",
      },
      BUTTONS: {
        CANCEL: "Cancel",
        ADD_TAG: "Add Tag",
        ADDING: "Adding...",
        ADDED: "Added",
        UPDATE_TAG: "Update Tag",
        UPDATING: "Updating...",
        UPDATED: "Updated",
        ERROR: "Error",
        VIEW_CONTACT: "View Contacts",
      },
    },
  },
  RULES: {
    HEADER: "Rules",
    SUBTITLE: "Automatically take actions on events in your workspace",
    CREATE_BTN: "New Rule",
    ZERO_RULES: "You have no Rules yet",
    ZERO_DISABLED_RULES: "You have no disabled rules to show",
    ACTIVE: "Active",
    DISABLED: "Disabled",
    SEARCH: "Search",
    CLEAR_SEARCH: "Clear Search",
    RULE_NAME: "Rule Name",
    DESCRIPTION: "Description",
    STATUS: "Status",
    ACTION: "Action",
    RULE: "Rule",
    CREATE_POPUP: {
      CREATE_TITLE: "Create New Rule",
      EDIT_TITLE: "Update Rule",
      VIEW_TITLE: "View Rule",
      SUBTITLE:
        "Manage team by renaming it, change its description and adding or removing of team members.",
        START:"Start",
        END:"End",
      WA_NAME_TOOLTIP: "This name should be as same as customer name on whatsapp",
      OUTGOING_MESSAGES_TOOLTIP: "Please be aware that no outgoing message will be sent from any triggered broadcast",
      LABELS: {
        RULE_NAME: "Rule Name",
        RULE_DESCRIPTION: "Rule Description",
        CHANNEL: "Channel",
        TRIGGERS: "Triggers",
        WHEN: "When",
        IF: "If",
        THEN: "Then",
      },
      PLACEHOLDERS: {
        RULE_NAME: "Add rule name",
        RULE_DESCRIPTION: "Add rule description",
        SELECT: "Select",
        ENTER_TEXT: "Enter text here...",
        SELECT_AGENT: "Select Agent",
        ENTER_URL: "Select a Webhook",
        ENTER_REASON: "Enter Reason",
        SELECT_PLATFORMS: "Select Platforms",
        SELECT_TYPES: "Select Types",
      },
      ERR_MSGS: {
        EMPTY_NAME: "Invalid empty rule name",
        MIN_NAME: "Rule name must be at least 3 characters",
        MAX_NAME: "Rule name must be at most 50 characters",
        EMPTY_DESCRIPTION: "Invalid empty rule description",
        MIN_DESCRIPTION: "Rule description must be at least 3 characters",
        MAX_DESCRIPTION: "Rule description must be at most 50 characters",
        EMPTY_CHANNEL: "Invalid empty channel",
        SELECT_VALID_OPTION: "Please select valid options",
        SELECT_VALID_DATES: "Please select valid end date"
      },
      CHANNELS: {
        WHATSAPP: "WhatsApp",
        INSTAGRAM: "Instagram",
        FACEBOOK: "Facebook",
      },
      MESSAGE_TYPE: {
        INCOMING: "Incoming Message",
        OUTGOING: "Outgoing Message",
      },
      CHAT_TYPE: {
        OPENED: "Opened Chat",
        ASSIGNED: "Assigned Chat",
        CLOSED: "Closed Chat",
        SNOOZED: "Snoozed Chat",
      },
      TIME_ZONE_MESSAGES: {
        TIMEZONE_AVAILABLE:
          "The timing is subjected to current business timezone",
        TIMEZONE_NOT_AVAILABLE:
          "Default business timezone not selected, please update in the workspace settings",
      },
      CONDITION_NAME_OPTIONS: {
        TEXT_IN_MESSAGE: "Text in Message",
        MESSAGE_TYPE: "Message Type",
        MESSAGE_TIME: "Message Time",
        CUSTOMER_CHANNEL_ID: "Customer Channel ID",
        CUSTOMER_PLATFORM: "Customer Platform",
        CUSTOMER_DISPLAY_NAME: "Customer Display Name",
        DATE_TIME: "Message DateTime",
      },
      PLATFORM_OPTIONS: {
        WHATSAPP: "WhatsApp",
        INSTAGRAM: "Instagram",
        SMS: "SMS",
        TELEGRAM: "Telegram",
        RCS: "RCS",
        FACEBOOK: "Facebook",
        PHONE: "Phone",
        AVOCADO: "Mottasl",
      },
      TYPE_OPTIONS: {
        TEXT: "Text",
        IMAGE: "Image",
        AUDIO: "Audio",
        VIDEO: "Video",
        STICKER: "Sticker",
        DOCUMENT: "Document",
        LOCATION: "Location",
        INTERACTIVE: "Interactive",
        TEMPLATE: "Template",
        CONTACTS: "Contacts",
      },
      CONDITION_OPERATOR_OPTIONS: {
        EQ: "Equal to",
        "!EQ": "Not equal to",
        GT: "Greater than",
        LT: "Less than",
        GTE: "Greater than or equal",
        LTE: "Less than or equal",
        SS: "Contains",
        "!SS": "Not Contains",
        SW: "Starts with",
        "!SW": "Not starts with",
        RG: "In range",
        RGR: "In range",
        "!RG": "Not in range",
        "!RGR": "Not in range",
      },
      ACTION_OPTIONS: {
        ASSIGN_CHAT: "Assign Chat",
        ADD_TAG: "Add Tag",
        REMOVE_TAG: "Remove Tag",
        MENTION: "Mention",
        WEBHOOK: "Webhook",
        SNOOZE_CONVERSATION: "Snooze Conversation",
        CLOSE_CONVERSATION: "Close Conversation",
        ASSIGN_LAST_AGENT_TEAM: "Assign to Last Agent/Team",
      },
      SNOOZE_CONVERSATION_OPTIONS: {
        NEXT_15_MINUTES: "Next 15 Minutes",
        NEXT_HOUR: "Next Hour",
        NEXT_3_HOURS: "Next 3 Hours",
        NEXT_6_HOURS: "Next 6 Hours",
        NEXT_DAY: "Next Day",
      },
      IN: "In",
      AND: "AND",
      OR: "OR",
      BUTTONS: {
        ADD_TRIGGER: "+ Add Trigger Events",
        ADD_CONDITION: "+ Add Condition",
        ADD_ACTION: "+ Add Action",
        CANCEL: "Cancel",
        CREATE: "Create New Rule",
        CREATING: "Creating...",
        VALIDATING: "Validating",
        NOT_VALID: "Not Valid",
        CREATED: "Created",
        ERROR: "Error",
      },
      INVALID_URL: "Invalid URL",
      VALIDATION_FAILED: "Validation failed",
      MISSING_OR_INCORRECT_FIELDS: "Missing or incorrect fields found during the validation",
      EXISTING_WEBHOOK: "Webhook already exists",
    },
  },
  CSAT: {
    HEADER: "CSAT Reports",
    DURATION: "Duration",
    DURATION_OPTIONS: {
      LAST_7_DAYS: "Last 7 Days",
      LAST_30_DAYS: "Last 30 Days",
      LAST_6_MONTHS: "Last 6 Months",
      LAST_3_MONTHS: "Last 3 Months",
      LAST_YEAR: "Last Year",
      CUSTOM_DATE_RANGE: "Custom Date Range",
    },
    SELECT_BY_DATE: "Select by Date",
    SELECT_AGENTS: "Select Agents",
    AGENTS: {
      AGENT_1: "Agent 1",
      AGENT_2: "Agent 2",
      AGENT_3: "Agent 3",
      AGENT_4: "Agent 4",
      AGENT_5: "Agent 5",
    },
    DOWNLOAD_REPORTS: "Download Reports",
    TOTAL_RESPONSES: "Total Responses",
    SATISFACTION_SCORE: "Satisfaction Score",
    RESPONSE_RATE: "Response Rate",
    FEEDBACK: "Feedback",
    VERY_SAD: "Very Sad",
    SAD: "Sad",
    NEUTRAL: "Neutral",
    GOOD: "Good",
    HAPPY: "Happy",
    CONVERSATION_TRAFFIC: "Conversation Traffic",
    CONTACT: "Contact",
    ASSIGNED_AGENT: "Assigned Agent",
    RATING: "Rating",
    LAST_MESSAGE: "Last Message",
    SATISFIED: "Satisfied",
    AWESOME: "Awesome",
    EXCELLENT: "Excellent",
  },
  AGENTS_TEAMS: {
    AGENTS_HEADER: "Agents Overview",
    TEAMS_HEADER: "Teams Overview",
    SELECT_TEAM: "Select Team",
    COMING_SOON: "(COMING SOON)",
    TIME_PLACEHOLDER: "4m 20s",
    TEAMS: {
      TEAM_1: "Team 1",
      TEAM_2: "Team 2",
      TEAM_3: "Team 3",
      TEAM_4: "Team 4",
      TEAM_5: "Team 5",
    },
    CONVERSATIONS: "Conversations",
    TOTAL_CHATS: "Total Conversations",
    TOTAL_IN_CONVERSATIONS: "Incoming Messages",
    TOTAL_OUT_CONVERSATIONS: "Outgoing Messages",
    FIRST_RESPONSE_TIME: "First Response Time",
    AVG_FIRST_RESPONSE_TIME: "Average First Response Time",
    RESOLUTION_TIME: "Resolution Time",
    AVG_RESOLUTION_TIME: "Average Resolution Time",
    RESOLUTION_COUNT: "Resolution Count",
    TOTAL_RESOLUTION_COUNT: "Total Resolution Count",
    TOTAL_CONVERSATIONS: "Total Conversations",
    TOTAL_OUT_COUNT: "Outgoing Messages",
    TOTAL_IN_COUNT: "Incoming Messages",
    AVG_RESPONSE_TIME: "Average Response Time",
    STATISTICS_TIME: '{{min}}m {{sec}}s',
    TOTAL: "In Total",
    AVG: "In Avg",
    CHART_SUBTITLE: "Based on the selected period",
    JAN: "Jan",
    FEB: "Feb",
    MAR: "Mar",
    APR: "Apr",
    MAY: "May",
    JUN: "Jun",
    JUL: "Jul",
    AUG: "Aug",
    SEP: "Sep",
    OCT: "Oct",
    NOV: "Nov",
    DEC: "Dec",
    COUNT: "Count:",
  },
  WORKSPACE_SETTINGS: {
    WORKSPACE_PROFILE: "Workspace Profile",
    SUBTITLE: "Manage your Workspace settings",
    PROFILE_PHOTO: "Profile Photo",
    UPLOAD_IMAGE: "Upload Image",
    ORGANIZE_NAME: "Organization Name",
    LEGAL_NAME: "Legal Name",
    UPDATE: "Update",
    BUSINESS_TIMEZONE: "Business Timezone",
    TIMEZONE_TOOLTIP:
      "Timezone selected here will be used by the rules, greetings away messages and other modules",
  },
  USERS_SETTINGS: {
    USERS: "Agents",
    SUBTITLE: "Edit team members from your Workspace.",
    ADD_AGENT: "Add New Agent",
    ROLES: "Roles",
    TEAMS: "Teams",
    AGENTS:  "Agents",
    AVAILABLE: "Available",
    ROLES_OPTIONS: {
      VIEW: {
        VALUE: "View",
        SENIORITY_LEVEL: 6
      },
      AGENT: {
        VALUE: "Agent",
        SENIORITY_LEVEL: 5
      },
      SUPERAGENT: {
        VALUE: "Super Agent",
        SENIORITY_LEVEL: 4
      },
      FINANCE: {
        VALUE: "Finance",
        SENIORITY_LEVEL: 3
      },
      DEVELOPER: {
        VALUE: "Developer",
        SENIORITY_LEVEL: 2
      },
      ADMIN: {
        VALUE: "Admin",
        SENIORITY_LEVEL: 1
      },
    },
    TEAMS_OPTIONS: {
      SALES: "Sales Team",
      MARKETING: "Marketing Team",
      SUPPORT: "Support",
    },
    AVAILABLE_OPTIONS: {
      ACTIVE: "Active",
      INACTIVE: "Inactive",
      OFFLINE: "Offline",
      DELETED: 'Deleted',
    },
    EMPTY_NAME: "Invalid empty name",
    MIN_NAME: "Name must be at least 5 characters",
    MAX_NAME: "Name must be less than 30 characters",
    EMPTY_EMAIL: "Invalid empty email",
    INVALID_EMAIL: "Invalid Email",
    INVALID_ACCES_LEVEL: "Invalid empty access level",
    EMPTY_STATUS: "Invalid empty status",
    SEARCH_AGENTS: "Search Agents",
    ROLE: "Role",
    LAST_SEEN_ON: "Last Seen On",
    LAST_SEEN: "{{date}}",
    STATUS: "Status",
    ACTION: "Action",
    EDIT: "Edit",
    REVOKE: "Revoke",
    ADD_USER: "Add Agent",
    ADDING: "Adding...",
    ADDED: "Added",
    EDIT_USER: "Edit Agent",
    ADD_USER_SUBTITLE: "Manage and invite team members to your Workspace.",
    EDIT_USER_SUBTITLE: "Edit team members from your Workspace.",
    USER_NAME: "Name",
    USER_EMAIL: "Email Adress",
    ACCESS_LEVEL: "Access Level",
    OWNER_NOTE:
      "Owner can access all modules within the workspace and they have full control of the settings with no restrictions.",
    MANAGER_NOTE:
      "Managers have access to all the different modules within the workspace including Settings. However, they are not able to invite/delete other managers or delete channels.",
    AGENT_NOTE:
      "Managers have access to all the different modules within the workspace including Settings. However, they are not able to invite/delete other managers or delete channels.",
    ADVANCED_RESTRUCTION: "Advanced Restruction",
    RESTRICT_CONTACT_VISIBILITY: {
      TITLE: "Restrict Contact Visibility",
      SUBTITLE: "This user can only view contacts assigned to",
      OPTIONS: {
        ANYONE_IN_THE_USER_TEAM: "Anyone in the user's team",
        USER: "User",
      },
    },
    RESTRICT_SHORTCUT_BUTTON: {
      TITLE: "Restrict Shortcut Button",
      SUBTITLE:
        "The Shortcuts button in the messaging module will be disabled for this user",
    },
    RESTRICT_DATA_EXPORT: {
      TITLE: "Restrict Data Export",
      SUBTITLE: "This user will not be able to export data from the workspace.",
    },
    RESTRICT_CONTACT_DELETION: {
      TITLE: "Restrict Contact Deletion",
      SUBTITLE:
        "This user will not be able to delete any contacts from the workspace.",
    },
    RESTRICT_WORKSPACE_SETTINGS_ACCESS: {
      TITLE: "Restrict Access to Workspace Settings",
      SUBTITLE: "This user will not be able to access the workspace settings.",
    },
    RESTRICT_INTEGRATION_SETTINGS_ACCESS: {
      TITLE: "Restrict Access to Integration Settings",
      SUBTITLE:
        "This user will not be able to access the Integration settings.",
    },
    REVOKE_POPUP: {
      TITLE: "Revoke Access",
      SUBTITLE: "Are you sure you want to revoke this agent's access?",
      ASSIGN_TO_USER: "Assign contacts to another agent",
      NOTE: "If you revoke this agent, all contacts assigned to this agent will be marked unassigned.",
      CONFIRM: "Revoke",
      REVOKING: "Revoking",
      REVOKED: "Revoked",
    },
    CANCEL: "Cancel",
    UPDATE: "Update",
    UPDATING: "Updating...",
    UPDATED: "Updated",
    ERROR: "Error",
    DELETE: "Delete",
    ADD_CREDIT: "Add Credit",
    ADDING_AGENT: "Adding Agent",
    ADD_AGENT_FAILED: "Adding Agent Failed",
    ADD_AGENT_FAILED_DESC:
      "Adding an extra agent will cost ${{cost}}, You don’t have sufficient wallet to proceed.",
    ADD_AGENT_DESC:
      "Adding an extra agent will cost ${{cost}}. Do you want to proceed?",
    AGENT_ACTIVATED: "Agent Activated Successfully",
    AGENT_ACTIVATED_DESC:
      "Please find the user from Agent section in Workspace Settings.",
    ADD_TEAM: "Adding Team",
    ADD_TEAM_FAILED: "Adding Team Failed",
    ADD_TEAM_FAILED_DESC:
      "Adding an extra Team will cost ${{cost}}, You don’t have sufficient wallet to proceed.",
      ADD_TEAM_DESC:
      "Adding an extra team will cost ${{cost}}. Do you want to proceed?.",
      TEAM_ACTIVATED: "Team Activated Successfully",
      TEAM_ACTIVATED_DESC:
      "Please find the Team from Teams section in Workspace Settings.",
      CONFIRM: "Confirm",
      RENEW:'Renew',
      SUBSCRIPTION_EXPIRED:'Subscription was expired on {{endDate}}, renew the subscription',
  },
  TEAMS_SETTINGS: {
    TEAMS: "Teams",
    SUBTITLE: "Create and manage teams to group the agents in your Workspace.",
    ADD_TEAM: "Add Team",
    ADDING: "Adding...",
    ADDED: "Added",
    ERROR: "Error",
    SEARCH_TEAMS: "Search Teams",
    TEAM_NAME: "Team Name",
    TEAM_EMAIL: "Team Email",
    DESCRIPTION: "Description",
    MEMBERS: "Members",
    ACTION: "Action",
    ERROR_MESSAGES: {
      EMPTY_NAME: "Invalid empty Team name",
      MIN_NAME: "Team can't be less than 5 characters",
      EMPTY_EMAIL: "Email is required",
      EMPTY_DESCRIPTION: "Invalid empty Team Discription",
      MIN_DESCRIPTION: "Discription can't be less than 20 characters",
      EMPTY_MEMBERS: "Invalid Members",
      MIN_MEMBERS: "Team must have at least one member"
    },
    ADD_TEAM_SUBTITLE:
      "Add team by naming it, change its description and adding team members.",
    TEAM_NAME_PLACEHOLDER: "Sales team",
    TEAM_DESCRIPTION: "Team Description",
    TEAM_DESCRIPTION_PLACEHOLDER: "Add team description",
    TEAM_MEMBERS: "Team Members",
    TEAM_MEMBERS_PLACEHOLDER: "Add team members",
    MANAGE_TEAM: "Manage Team",
    MANAGE_TEAM_SUBTITLE:
      "Manage team by renaming it, change its description and adding or removing of team members.",
    CANCEL: "Cancel",
    SAVE: "Save",
    SAVING: "Saving...",
    SAVED: "Saved",
    REVOKE_POPUP: {
      SUBTITLE: "Are you sure you want to revoke this team's access?",
      ASSIGN_TO_TEAM: "Assign contacts to another team",
      NOTE: "If you revoke this team, all contacts assigned to this team will be marked unassigned.",
    },
    ZERO_TEAMS: "You have no Teams yet",
    CREATE_TEAM_BTN: "Add New Team",
  },
  CONTACTS_SETTINGS: {
    CONTACTS: "Contacts",
    AGENT: "Agent",
    TEAM: "Team",
    SUBTITLE:
      "Create and manage contact fields to save additional information about your contacts.",
    ADD_CONTACT: "Add Contact",
    ALL_CONTACTS: "All Contacts",
    ARCHIVED: "Archived",
    SEARCH_CONTACTS: "Search Contacts",
    CHANNEL: {
      PLACEHOLDER: "Channel",
      OPTIONS: {
        WHATSAPP: "Whatsapp",
        FACEBOOK: "Facebook",
        INSTAGRAM: "Instagram",
      },
    },
    TAGS: {
      PLACEHOLDER: "Tags",
      OPTIONS: {
        PREMIUM: "Premium",
        ASSIGNED: "Assigned",
        UNASSIGNED: "Unassigned",
        TAG_3: "Tag 3",
        TAG_4: "Tag 4",
        TAG_5: "Tag 5",
      },
    },
    DATE: {
      PLACEHOLDER: "Date Added",
      OPTIONS: {
        LAST_7_DAYS: "Last 7 Days",
        LAST_30_DAYS: "Last 30 Days",
        LAST_6_MONTHS: "Last 6 Months",
        LAST_3_MONTHS: "Last 3 Months",
        LAST_YEAR: "Last Year",
        CUSTOM_DATE_RANGE: "Custom Date Range",
      },
    },
    SELECT_BY_DATE: "Select by Date",
    UESRS: "Agents",
    EMAIL: "Email",
    ACTION: "Action",
    UNARCHIVE: "Unarchive",
    DELETE: "Delete",
  },
  CUSTOM_ATTRIBUTES: {
    HEADER: "Custom Attributes",
    SUBTITLE:
      " Establish and oversee unique descriptors to enhance the information associated with assets, extending their meaning beyond stand.",
    CONCERSATION: "Conversation",
    ZERO_CUSTOMS: "You have no custom attributes yet",
    CONTACT: "Contact",
    TYPE: "Type",
    KEY: "Key",
    ADD_CUSTOM_ATTR: "Add Custom Attribute",
    SEARCH_PLACEHOLDER: "Search Attributes",
    CANT_DELETE_ITEM: "This item cannot be removed. It could be already in use with one of broadcast target.",
    CREATE_POPUP: {
      TITLE: "Add Custom Attribute",
      UPDATE_TITLE: "Edit Custom Attribute",
      APPLY_TO: "Applies to",
      DISPLAY_NAME: "Display name",
      PLACEHOLDER_KEY: "Enter Custom Attribute key",
      PLACEHOLDER_DNAME: "Enter name here",
      PLACEHOLDER_D_DESCRIPTION: "Enter description here",
      PLACEHOLDER_TYPE: "Select Custom Attribute type",
      PLACEHOLDER_APPLIES_TO: "Select Custom Attribute applies to",
      DISCRIPTION_PH: "Enter Custom Attribute description",
      TEXT: "Text",
      URL: "Url",
      SWITCH: "Switch",
      CHECKBOX: "Checkbox",
      CHIPS: "Chips",
      DATE: "Date",
      TIME: "Time",
      REQUIRED_NAME: "Custom attribute Name is required",
      TOOLTIP: {
        KEY: "Key can not be update",
        TYPE: "Type can not be update",
      },
      SUBMIT: {
        ADD: "Add Custom Attribute",
        UPDATE: "Update",
        ADDING: "Adding Custom Attribute...",
        UPDATING: "Updating...",
        ADDED: "Custom Attribute added",
        UPDATED: "Updated",
        ERROR: "Error",
      },
      ERROR_MSG: {
        UNIQUENESS_VIOLATION :"Uniqueness violation",
        CUSTOM_DUPLICATED : "This custom attribute by this key already exists" ,
        LIMIT_VIOLATION :"Limit violation",
        CUSTOM_LIMIT_REACH : "Custom fields limit reached" ,
      }
    },
    TABLE: {
      URL: "Url",
      TEXT: "Text",
      SWITCH: "Switch",
      CHECKBOX: "Checkbox",
      CHIPS: "Chips",
      DATE: "Date",
      TIME: "Time",
    },
    DELETE_CUSTOM_ATTRIBUTES_ERROR: "Error ocurrs during delete custom attribute from contacts.",
  },
  CONVERSATIONS: {
    CONVERSATIONS: "Conversations",
    DURATION: "Duration",
    DURATION_OPTIONS: {
      LAST_7_DAYS: "Last 7 Days",
      LAST_30_DAYS: "Last 30 Days",
      LAST_6_MONTHS: "Last 6 Months",
      LAST_3_MONTHS: "Last 3 Months",
      LAST_YEAR: "Last Year",
      CUSTOM_DATE_RANGE: "Custom Date Range",
    },
    EXPORT: "Export",
    SELECT_BY_DATE: "Select by Date",
    GROUP_BY: "",
    GROUP_BY_OPTIONS: {
      DAY: "Day",
      WEEK: "Week",
      MONTH: "Month",
    },
    DOWNLOAD_REPORTS: "Download Reports",
  },
  OVERVIEW: {
    OVERVIEW: "Overview",
    CONVERSATIONS_STATS: "Conversations Stats",
    AGENTS_STATUS: "Agents Status",
    LIVE: "Live",
    DOWNLOAD_REPORTS: "Download Reports",
    OPEN: "Open",
    UNATTENDED: "Unattended",
    UNASSIGNED: "Unassigned",
    ONLINE: "Online",
    CONVERSATION_TRAFFIC: "Conversation Traffic",
    CONVERSATIONS_BY_AGENTS: "Conversations by Agents",
    USERS: "Agents",
    ROLE: "Role",
    TEAM: "Team",
    ROLES: {
      VIEW:"View",
      AGENT: "Agent",
      SUPERAGENT: "Super Agent",
      FINANCE: "Finance",
      DEVELOPER: "Developer",
      ADMIN: "Admin",
    },
    TEAMS: {
      SALES: "Sales Team",
      SUPPORT: "Support Team",
      MARKETING: "Marketing Team",
    },
  },
  DASHBOARD: {
    ANALYTICS: "Analytics",
    SUBTITLE: "View your platform reports here",
    OVERVIEW: "Overview",
    CSAT_REPORTS: "CSAT Reports",
    AGENTS: "Agents",
    TEAMS: "Teams",
    CONVERSATIONS: "Conversations",
  },
  MEDIA_LIBRARY: {
    HEADER: "Media Library",
    ASSET: "Asset",
    SUBTITLE: "Upload and view all your media files at a single destination",
    UPLOAD_1: "Click to upload ",
    UPLOAD_2: "or drag and drop a file here",
    UPLOAD_2_MOBILE: "a file here",
    UPLOAD_SUBTITLE:
      "Image, Audio, Video, Document file should be less than 10MB",
    SORT_BY: "Sort",
    OLDEST: "Oldest",
    LATEST: "Latest",
    MEDIA_TYPES: {
      ALL: "All",
      IMAGES: "Images",
      AUDIO: "Audio",
      VIDEO: "Video",
      DOCUMENTS: "Documents",
    },
    INFO: "Info",
    VIEW: "View",
    COPY_URL: "Copy URL",
    DELETE: "Delete",
    B: "{{size}} B",
    KB: "{{size}} KB",
    MB: "{{size}} MB",
    GB: "{{size}} GB",
    CREATED_AT: "Created at",
    CREATED_BY: "Created by",
    UPLOAD_FILE: "Upload File",
    MEDIA_TITLE: "Media Title",
    CAPTION: "Caption",
    REQUIRED_CAPTION: "Caption is required",
    UPLOADING: " • Uploading...",
    TITLE: "Title",
    SIZE: "Size",
    MODIFIED_AT: "Modified at",
    ACTIONS: "Actions",
    CANT_UPLOAD: "Can't upload this file",
    UPLOAD_WARNING: "The size of this file is {{size}}MB, exceeds the limit (10MB)",
    UPLOAD_FORMATE_WARNING: "This file format is not supported",
    ZERO_MEDIA: "You have no media yet",
  },
  WHATSAPP_FLOW: {
    HEADER: "WhatsApp Flow",
    SUBTITLE: "Create and Manage WhatsApp Flow here",
    CREATE_FLOW: "Create Flow",
    SEARCH_WA_FLOWS: "Search whatsapp Flows",
    FILTERS: {
      STATUS: {
        OPTIONS: {
          DRAFT: "Draft",
          PUBLISHED: "Published",
        },
      },
    },
    TABLE: {
      FLOW_NAME: "Flow Name",
      FLOW_ID: "Flow ID",
      STATUS: "Status",
      LAST_UPDATE: "Last Updated",
    },
    CREATE_WHATSAPP_FLOW: "Create WhatsApp Flow",
    CREATE_WHATSAPP_FLOW_SUBTITLE: "Create WhatsApp Flow here",
    NO_FLOW_YET: "You have no flow yet",
    NO_FLOW_MATCH: "You have no flows matches the data you entered",
    SAMPLE_FLOW: "Sample Flow",
    DRAFT: "DRAFT",
    SELECT_CATEGORIES: "Select Categories",
    SELECT_TEMPLATE: "Select Template (Optional)",
    CANCEL: "Cancel",
    CREATE: "Create",
    EDITOR: "Editor",
    RUN: "Run",
    SAVE: "Save",
    DONE: "Done",
    CONTINUE: "Continue",
    PREVIEW: "Preview",
    FIRSTNAME: "First Name",
    LASTNAME: "Last Name",
    PHONE_NUMBER: "Phone Number",
    SIGNUP_TITLE: "Join the waitlist Now!",
    SIGNUP_SUBTITLE: "Please enter the details below",
    SIGNUP_TITLE_1: "Before you go",
    SIGNUP_SUBTITLE_1: "How did you hear about us?",
    TEMPLATES: {
      NONE: "None",
      BOOK_TABLE: "Book Table",
      COMPLETE_OUR_QUIZ: "Complete our Quiz",
      COMPLETE_SIGNUP: "Complete Signup",
      GET_FEEDBACK: "Get Feedback",
      GET_QUOTE: "Get Quote",
      GET_SUPPORT: "Get Support",
      REGISTER_ACCOUNT: "Register for an account",
      REGISTER_EVENT: "Register for Event",
      SIGNIN: "Sign In",
      UPDATE_PREFERENCES: "Update Preference",
    },
    CHANGES_SAVED: "Changes Saved",
  },
  COMMON: {
    ACTIONS_BTN: {
      VIEW: "View",
      EDIT: "Edit",
      NEXT: "Next",
      DONE: "Done",
      DELETE: "Delete",
      YES: "Delete",
      NO: "Cancel",
      SHOW_ALL: "Show all",
      DEACTIVATE: "Deactivate",
      COPIED: "Copied"
    },
    DELETE_CONFIRMATION_DIALOGUE: {
      TITLE: 'Delete <0>"{{name}}"</0>',
      BODY: 'Are you sure you want to delete the <1>{{field}}</1> <0>"{{name}}"</0> ? It will be deleted permanently.',
    },
    DELETE__DIALOG_2: {
      TITLE: "Are you sure?",
      BODY: " You are about to delete this {{item}}. This action cannot be undone.",
      CANCEL: "Cancel",
      DELETE: "Delete",
    },
    PAGINATION: {
      TITLE: "Showing {{start}} to {{end}} out of {{total}} entries",
      ZERO_ENTERIES: "Showing 0 out of 0 entries",
      TITLE_MOBILE: "{{start}}-{{end}} of {{total}} entries",
      ZERO_ENTERIES_MOBILE: "0 out of 0 entries",
    },
    WARNING: {
      TITLE: "Warning",
      CANCEL: "Cancel",
      CONTINUE: "Continue",
    },
    MSB: {
      SEARCH: "Search...",
      SELECT: "Select",
    },
    STATUS: {
      COMPLETED: "Completed",
      FAILED: "Failed",
      IN_PROGRESS: "In Progress",
    },
    FILTER: "Search",
    CLOSE: "Close",
    SEARCH: "Search",
    SECONDS: "Seconds",
    MINUTES: "Minutes",
    HOURS: "Hours",
    RE_INSTALL: "Re-install",
    SECONDS_PLACEHOLDER: "Seconds=60",
    MINUTES_PLACEHOLDER: "Minutes=60",
    HOURS_PLACEHOLDER: "Hours=12",
    SUBMIT: "Submit",
    RESET: "Reset",
    SHARE: "Share",
    CANCEL: "Cancel",
    UPLOAD: "Upload",
    SUBMITTING: "Submitting...",
    SUBMITTED: "Submitted",
    ERROR: "Error",
    CLEAR: "Clear",
    CLOSE_WARNING:
      "You are about to close the current window, all changes will be lost. Are you sure you want to continue?",
    CONTINUE: "Continue",
    SKIP: "Skip",
    CONFIRM: "Confirm",
    CREATED_AT_DATE: "Created at {{date}}",
    CHANGE: "Change",
    UNAVAILABLE: "Unavailable",
    CREATED_AT: "Created at {{date}}",
    EMAIL: "Email",
    NAME: "Name",
    SEND: "Send",
    BACK: "Back",
    ADD: "Add",
    SAVE: "Save",
    ALL: "All",
    AGENTS: "Agents",
    TEAMS: "Teams",
    INSTALLED: "Installed",
    INSTALL: "Install",
    UNINSTALL: "Uninstall",
    ACTIVE: "Active",
    INACTIVE: "Inactive",
    TODAY: "Today",
    YESTERDAY: "Yesterday",
    NO_RESULTS_FOUND: "No Search Results Found",
    KINDLY_TRY_AGAIN: "Kindly try searching with different keywords",
    CANT_DELETE_ITEM: "This item can't be deleted.",
  },
  VALIDATIONS: {
    CONFIRM_PASSWORD: "Your password and confirmation password do not match.",
    INVALID_EMAIL_PASSWORD: "Invalid email or password",
    EMAIL_EXISTS: "Email already exist",
    TOKEN_EXPIRED_RESET_PASSWORD:
      "Token expired/invalid. Please send reset password again",
    NOT_REGISTERED:
      "No accounts match this email. Please register a new account",
    INVALID_EMAIL: "Email must be a valid email",
    INVALID_MAX_EMAIL_LENGTH: "Email is too long. Maximum length is 60 characters",
    INVALID_PASSWORD: "Password must be at least 8 characters",
    INVALID_MAX_PASSWORD_LENGTH: "Password is too long. Maximum length is 30 characters",
    EMPTY_FIELD: "This field can't be empty",
    CONTACTS: {
      MIN_NAME: "Name must be at least 5 characters",
      INVALID_PHONE_NUM: "Invalid phone number",
    },
    TEMPLATES: {
      EMPTY_MEDIA_TYPE: "Please select a media type.",
      EMPTY_TOGGLE_BTNS: "Please select from the available options.",
      EMPTY_BTN_TYPE: "Please choose a button type.",
      INVALID_TEMPLATE_ID: "Template id must match the following: '[a-z0-9_]'",
      TEMP_ID_LESS_THAN_5: "Template ID must be at least 5 characters",
      SIGNATURE_HASH_LENGHT: "Signature hash must be 11 characters",
    },
    ACCOUNT_DELETED: 'This account has been deleted',
  },
  CHATGPT: {
    CREATE_POPUP: {
      TITLE: "ChatGPT",
      SUBTITLE:
        "ChatGPT allows you to suggest quick responses based on the last 20 messages of the conversations.",
      SUBTITLE1:
        "It is also trained on the Knowledge Base to give more contextual responses learning from the organization data.",
      OPEN_API_KEY: "Open API",
      PLACEHOLDER_OPEN_API_KEY: "Bearer yikk3k-4sdfhh-34fwef-XXXXX-XXX",
      CHATGPT_CANT_BE_ENABLED: "* ChatGPT cannot be enabled yet",
      OPTION_AGENT: {
        TITLE: "Agent",
        PLACEHOLDER_AVAILABLE: " Available to",
        AGENT: "Agents",
        SUPERAGENT: "Super Agents",
        ADMIN: "Admin",
      },
      BUTTONS: {
        SAVE: "Save",
        CANCEL: "Cancel",
      },
    },
  },
  DIALOGFLOW: {
    CREATE_POPUP: {
      TITLE: "Dialogflow",
      SUBTITLE:
        "Dialogflow allows you to integrate with your custom chatbot. Acts as an automated agent to reply and engage with your customers.",
      CONNECT: "Custom chatBot",
      DIALOGFLOW_CANT_BE_ENABLED: "* Dialogflow cannot be enabled yet",
      OPTION_CONNECT: {
        PLACEHOLDER_CONNECT: "All chatbot",
        UNANSWERED: "Unanswered Chats",
        ASSIGNED_CHAT: "Assigned Chats",
        UNASSIGNED_CONTACTS: "Unassigned Contacts",
        CLOSED_CHATS: "Closed Chats",
        SNOOZED_CHATS: "Snoozed Chats",
        ARCHIVED_CONTACTS: "Archived Contacts",
      },
      DRAG_DROP: {
        HEADER: "",
        TITLE: "Drop your Service Account Config JSON here",
        BUTTONS: {
          SELECTED_FILES: "Select files",
        },
      },
      BUTTONS: {
        SAVE: "Save",
        CANCEL: "Cancel",
      },
    },
  },
  GOOGLE_TRANSLATE: {
    CREATE_POPUP: {
      TITLE: "Google Translate",
      SUBTITLE:
        "Translate your customers messages on the fly with the help of Google translate.",
      GOOGLE_API_KEY: "Google API Key",
      PLACEHOLDER_GOOGLE_API_KEY: "ayjkk3k-4sdfhh-34fwef-XXXXX-XXX",
      TRANSLATE_CANT_BE_ENABLED: "* Translate cannot be enabled yet",
      BUTTONS: {
        SAVE: "Save",
        CANCEL: "Cancel",
      },
    },
  },
  CSML: {
    HEADER: "CSML",
    SUBTITLE:
      "Integrate CSML chatbots into your platform to automate your customer support and sales processes",
    CSML_CANOT_BE_ENABLED: "* CSML cannot be enabled yet",
    CSML_TABLE: {
      TITLE: "Bot Name",
      BOT_DESCRIPTION: "Bot Description",
    },
    BUTTONS: {
      SAVE: "Validate and Save",
    },
  },
  WHATSAPP_INTEGRATION: {
    HEADER: "WhatsApp Integration",
    SUBTITLE: "Manage your WhatsApp Messaging API",
    WHATSAPP_INFO: {
      HEADER: "WhatsApp Info",
      SUBTITLE: "View and Configure your WhatsApp Business account",
      ACCOUNT_CONFIG: {
        HEADER: "Account Configuration",
        LEGAL_BUSINESS_NAME: "Legal Business Name:",
        BUSINESS_MANAGER_ID: "Business Manager ID:",
      },
      WHATSAPP_BUSINESS_PROFILE: {
        HEADER: "WhatsApp Business Profile",
        USER_PROFILE: {
          HEADER: "User Profile",
          PROFILE_PHOTO: "Profile Photo",
          ABOUT_TEXT: "About Text",
        },
        BUSINESS_PROFILE: {
          HEADER: "Business Profile",
          ADDRESS: "Address",
          DESCRIPTION: "Description",
          EMAIL: "Email",
          WEBSITE_PRIMARY: "Website (primary)",
          WEBSITE_SECONDARY: "Website (secondary)",
          BUSINESS_VERTICAL: "Business Vertical",
          FILTER_BUSINESS_VERTICAL: {
            UNDEFINED: "UNDEFINED",
            OTHER: "OTHER",
            AUTO: "AUTO",
            BEAUTY: "BEAUTY",
            APPAREL: "APPAREL",
            EDU: "EDU",
            ENTERTAIN: "ENTERTAIN",
            EVENT_PLAN: "EVENT_PLAN",
            FINANCE: "FINANCE",
            GROCERY: "GROCERY",
            GOVT: "GOVT",
            HOTEL: "HOTEL",
            HEALTH: "HEALTH",
            NONPROFIT: "NONPROFIT",
            PROF_SERVICES: "PROF_SERVICES",
            RETAIL: "RETAIL",
            TRAVEL: "TRAVEL",
            RESTAURANT: "RESTAURANT",
            NOT_A_BIZ: "NOT_A_BIZ",
          },
        },
        PROFILE_REVIEW: {
          HEADER: "Profile",
          NAME: "Name",
          WARN: "This is not your username or pin. This name will be visible to your WhatsApp contacts.",
          ABOUT: "About",
          PHONE: "Phone",
        },
        REVIEW_BUTTON: "Submit to review",
      },
      CHANNEL_ACCOUNT_EVENTS: {
        HEADER: "Channel Account Events",
        EVENT_TYPE: "Event Type",
        EVENT_ID: "Event ID",
        DATE: "Date",
        DETAILS: "Details",
        EVENT_LOG: "Event Log",
        VIEW_JSON_BTN: "View JSON",
        VIEW_JOSN_POPUP: {
          HEADER: "View JSON",
          CANCEL: "Cancel",
          COPY_JSON: "Copy JSON",
          COPIED: "Copied",
        },
      },
    },
    ACCOUNT_EVENTS: {
      HEADER: "Account Events",
      SUBTITLE:
        "Realtime events at WhatsApp Business API level for your account",
      REGISTRATION_PROGRSS: {
        HEADER: "Registration Progress",
        CREATED: "Created",
        SUBMITTED: "Submitted",
        REVOKED: "Revoked",
        LIVE: "Live",
      },
      CHART: {
        ALL_CONVERSATION: "All Conversations",
        BUSINESS_INITIATED: "Business Initiated",
        USER_INITIATED: "User Initiated",
        MARKETING: "Marketing",
        SERVICE: "Service",
        AUTHENTICATION: "Authentication",
        UTILITY: "Utility",
      },
    },
    CUSTOMIZE: "Customize",
  },
  HUBSPOT: {
    HEADER: "HubSpot",
    SUBTITLE:
      "Send contacts and conversations to HubSpot and use HubSpot to send messages",
    HUBSPOT_INTEGRATION_TITLE: "How does it work?",
    HUBSPOT_INTEGRATION_SUBTITLE:
      "The Hubspot integration will allow you to link Mottasl conversations to your HubSpot contacts. It will sync the attributes on either side. You can send messages from HubSpot directly.",
    READ_MORE: "Read more",
    HUBSPOT_CONNECTION_GUIDE_TITLE:
      "Here's a step guide on how to integrate your HubSpot workspace with Avocoda",
    HUBSPOT_CONNECTION_GUIDE_BUTTON: "HubSpot connection guide",
    HUBSPOT_WORKSPACE: "Connect your HubSpot workSpace",
    HUBSPOT_WORKSPACE_SUBTITLE: "Click on the button to connect your workspace",
    CONNECT_BUTTON: "Connect HubSpot",
  },
  LOGIN: {
    AVOCADO: "Mottasl",
    TWERLO_PRODUCT: "A Twerlo Product",
    SIGNUP_TITLE: "Create An Account",
    LOGIN_TITLE: "Sign in to your account",
    CHECK_EMAIL_TITLE: "Please check your email",
    FORGOT_PASSWORD_TITLE: "Forgot password",
    WORKSPACE_NAME_TITLE: "Name your workspace",
    WORKSPACE_NAME_SUBTITLE: "Give a name to your  workspace to get started",
    PHONE_NUMBER_TITLE: "Get expert answers to your questions",
    PHONE_NUMBER_SUBTITLE:
      "We will connect you back on WhatsApp.<0></0>(You can opt-out anytime)",
    ROLE_TITLE: "What is your role?",
    ROLE_SUBTITLE: "Let us know what's your role in your company",
    TEAM_SIZE_TITLE: "What's the size of your team?",
    TEAM_SIZE_SUBTITLE: "Let us know what's your role in your company",
    PURPOSE_TITLE: "What do you need Mottasl for?",
    PURPOSE_SUBTITLE: "Select one or more options",
    NEW_PASSWORD_TITLE: "Create New Password",
    NEW_PASSWORD: "New Password",
    CONFIRM_PASSWORD: "Confirm Password",
    NAME: "Name",
    EMAIL_ADDRESS: "Email address",
    PASSWORD: "Password",
    SELECT_WORKSPACE: "Select Workspace",
    ENTER_NAME: "Enter the name here",
    ROLE_PLACEHOLDER: "Select Role",
    REMEMBER_ME: "Remember me",
    CREATE_ACCOUNT: "Create Account",
    LOGIN: "Login",
    REQUIRED_NAME: "Name is required",
    REQUIRED_EMAIL: "Email is required",
    VALID_EMAIL: "Email must be a valid email",
    REQUIRED_PASSWORD: "Password is required",
    REQUIRED_WORKSPACE: "WorkSpace is required",
    SEND_RESET_LINK: "Send Reset Link",
    FORGOT_PASSWORD: "Forgot Password?",
    FOOTER_SIGNUP: "Already have an account? <0>Login</0>",
    FOOTER_LOGIN: "Don't have an account? <0>Register</0>",
    FOOTER_CHECK_EMAIL: "Already have an account? <0>Login</0>",
    FOOTER_FORGOT_PASSWORD: "Go back to <0>Login</0>",
    CONFIRMATION_SENT: "We have sent a confirmation link to your email address",
    SOMETHING_ELSE: "Something Else",
    RESET_PASSWORD_NOTIFICATION:
      "Password reset link sent! Check your inbox (including spam)",
    UNDEFINED_ORG: "This organization is undefined",
    ROLES: {
      FOUNDER_OWNER: "Founder / Owner",
      MARKETING_MANAGER: "Marketing Manager",
      SALES_BUSINESS_DEVELOPMENT: "Sales or Business Development",
      CUSTOMER_SUPPORT: "Customer Support",
    },
    SIZES: {
      FROM_1_5: "1-5 Employees",
      FROM_6_15: "6-15 Employees",
      FROM_15_50: "15-50 Employees",
      FROM_50: ">50 Employees",
    },
    PURPOSES: {
      SALES: "Sales",
      SUPPORT: "Support",
      MARKETING: "Marketing",
    },
    UNAUTHORIZED_MESSAGE: "401 Unauthorized, redirecting to login..",
    SESSION_TIMED_OUT: "Session timed out, redirecting to login..",
    USER_REQUESTED_ACCOUNT_DELETION: "Account successfully deleted. Logging out..",
  },
  BILLING_SUBSCRIPTION: {
    TITLE: "Subscription Management",
    FEAT_UNA:
      "Subscription & Billing are currently not enabled for your account. Please contact your account manager for more information.",
    SUBTITLE: "Manage and review your subscription and billing details.",
    ACCOUNT_BLOCKED:
      "Account Blocked: Please get in touch with our team immediately to solve the issue. (Please note blocked accounts will be deleted after 21 days)",
    ACCOUNT_RESTRICTED:
      "Account Restricted: Your account balance is running low. Please recharge for uninterrupted service.",
    BILLING_DASHBOARD: "Billing Dashboard",
    USAGE_REPORT: "Usage Report",
    INVOICE: "Invoice",
    BILLING_SETUP: "Billing Setup",
    SUBSCRIPTION: "Subscription Details",
    SUBSCRIPTION_DETAILS: "Subscription Details",
    CURRENT_PLAN: "Current Plan:",
    CURRENT_BILLING_DATE: "Current Billing Date:",
    NEXT_BILLING_DATE: "Next Billing Date:",
    PREMIUM_PLUS: "Premium Plus",
    AVAILABLE_WA_CREDIT: "Available WA Credit",
    AVAILABLE_CONVERSATION_TARIFF: "Available Conversation Tariff",
    NOT_AVAILABLE: "Not Available",
    ADD_FUNDS: "Add Funds",
    CREDIT_BALANCE: "Credit Balance:",
    ADD_FUNDS_TITLE: "Add funds",
    ADD_FUNDS_SUBTITLE: "Select the amount and add funds to your wallet.",
    CHOOSE_PAYMENT_METHOD: "Choose payment method",
    ADD_FUNDS_TO: "Add Funds to",
    BANK_TRANSFER: "Bank Transfer",
    BANK_TRANSFER_DETAILS:
      "Streamline your payments with our easy online bank transfers. This secure method ensures your transactions are processed swiftly and safely. All bank transfers are accepted.",
    ALL_ACCEPTED: "(All Bank Transfers Accepted)",
    UPLOAD_TRANSACTION: "Upload Your Transaction Details",
    UPLOAD_TRANSACTION_DETAILS:
      "Upload your transaction receipt or screenshot here to confirm your payment.",
    UPLOAD_BTN: "Upload jpg/pdf",
    THANKS_TITLE: "Thank You for your payment",
    THANKS_BODY:
      "We will confirm your payment and add funds to your account shortly.<0></0>Kindly revisit your billing page in sometime.",
    USAGE_REPORTS: "Usage Reports",
    STARTING_BALANCE: "Starting Balance",
    TOP_UPS: "Top-up",
    KNOW_TOP_UP_REPORT: "Know your complete Top-up report here",
    TOP_UP_LOGS: "Top-Up Logs",
    PAID: "Paid",
    USAGE: "Usage Credits",
    ENDING_BALANCE: "Ending Balance",
    USAGE_DETAILS: "Usage Details",
    USAGE_DETAILS_SUBTITLE: "Know your complete usage report here",
    CONVERSATION_CHARGES: "Conversation Charges",
    WHATSAPP_CHARGES: "WhatsApp Channel Conversation Charges",
    PLATFORM_CHARGES: "Platform  Conversation Charges",
    MONTHLY_FAIR_LIMIT: "Monthly fair use limit",
    ADD_ON: "Add On",
    TOTAL_CONSUMPTION: "Total Consumption",
    BALANCE: "Balance",
    ADD_ON_LOGS: "Addon Logs",
    VIEW_ADD_ON_LOGS: "View the Addon-on logs here",
    RATE_FOR_ADDITIONAL: "Rate for Additional",
    TOTAL_AMOUNT: "Total Amount",
    CONVERSATION: "Conversation",
    TOTAL_QTY: "Total QTY",
    CHARGABLE_QTY: "Chargable QTY",
    CHANNEL_RATE: "Channel Rate",
    CHARGES: "Charges",
    SEARCH_INVOICES: "Search invoices",
    NEWEST_FIRST: "Newest",
    OLDEST_FIRST: "Oldest",
    STATUS: "Payment Status",
    ALL: "All",
    DATE: "Date",
    TYPE: "Transaction Type",
    AMOUNT: "Amount",
    FUNDS_ADDED: "Funds Added",
    DOWNLOAD: "Download",
    PENDING: "Pending",
    CANCELLED: "Cancelled",
    OPEN: "Open",
    NOT_SETUP: "Getting Started!",
    SUBSCRIBE_PLAN:
      "No active subscription. Select a plan to start using services.",
    SETUP_BILLING: "Setup Billing",
    SELECT_PLAN_ADD_FUNDS: "Select a Plan & Add Fund",
    CHOOSE_SUBSCRIPTION:
      "Choose a subscription for you based on your needs and add funds.",
    SELECT_PLAN: "Choose a Plan",
    MONTHLY: "Monthly",
    QUARTERLY: "Quarterly",
    HALF_YEARLY: "Half Yearly",
    YEARLY: "Yearly",
    STARTER: "Starter",
    PER_MONTH: "${{price}} /month",
    PER_MONTHS: "${{price}} /{{number}} months",
    PER_CONVERSATION: "+ ${{price}}/Conversation",
    WA_CHARGES: "+ WhatsApp Charges",
    STARTER_DESCRIPTION:
      "For growing teams starting to scale to new heights with Mottasl",
    CHOOSE_PLAN: "Choose Plan",
    TEAM: "Team",
    TEAM_DESCRIPTION:
      "Kick it up a notch with this pro plan and its advanced access",
    PRO: "Pro",
    PRO_DESCRIPTION:
      "Kick it up a notch with this pro plan and its advanced access",
    BUSINESS: "Business",
    BUSINESS_DESCRIPTION:
      "When you require streamlined and centralized controls",
    STILL_UNDECIDED: "Still undecided? <0>Compare our plans in detail</0>",
    SELECTED: "Selected",
    SELECT_AMOUNT: "Select amount",
    MINIMUM: "Minimum",
    AUTO_TOP_UP: "Auto Top-up",
    AUTO_TOP_UP_DESCRIPTION:
      "Allow you to automatically top-up your wallet balance when you run low.",
    WHEN_BALANCE_BELOW: "When the balance is below :",
    AUTOMATICALLY_RECHARGE: "Automatically Recharge:",
    DIRECT_PAYMENT: "Direct Payment",
    DIRECT_PAYMENT_DESCRIPTION:
      "Experience seamless, secure, and prompt transactions with our Direct Payment option in our platform. Simplify your process, save time, and focus on what truly matters.",
    ENTER_CARD_DETAILS: "Enter Card Details",
    CARD_NUMBER: "Card Number",
    EXPIRY_DATE: "Expiry Date",
    CVV: "CVV",
    BILLING_DETAILS: "Billing Details",
    COMPANY_NAME: "Company Name",
    ADDRESS_LINE: "Address Line {{line}}",
    COUNTRY_REGION: "Country/Region",
    STATE_PROVINCE: "State/Province",
    ZIP_CODE: "ZIP Code",
    CITY: "City",
    TAX_ID: "Tax ID",
    EMAIL: "Email",
    AVAILABLE_CREDIT: "Credit Status",
    ADD_CREDIT: "Add Credit",
    YOUR_CURRENT_PLAN: "Current Subscription Plan",
    SUBSCRIPTION_PLAN: "Subscription Plan:",
    MAX_PER_MONTH: "Max {{number}} / month",
    MAX_PER_MONTHS: "Max {{number}} / {{months}} months",
    MAX_PER_YEAR: "Max {{number}} / year",
    RENEW_ON: "Renewal Date:",
    CHANGE_PLAN: "Change plan",
    INVOICES: "Invoices",
    ADD_CREDIT_TITLE: "Add credit",
    ADD_CREDIT_SUBTITLE: "Select the payment method and add credit to your wallet.",
    TWERLO_SUBSCRIPTION_CHARGES: "Twerlo Subscription Charges",
    PLAN: "Plan",
    TIME_PERIOD: "Time Period",
    DAYS: "Days",
    RATE: "Rate",
    CREDIT_USED: "Credit Used",
    FAIR_USE_AGENTS: "Fair Use Charges - Agents",
    INCLUDED: "Included",
    ADDITIONAL: "Additional",
    FAIR_USE_TEAMS: "Fair Use Charges - Teams",
    FAIR_USE_ROUTING: "Fair Use Charges - Routing Rules",
    FAIR_USE_API: "Fair Use Charges - API Calls",
    TWERLO_RATE: "Twerlo Rate",
    FREE_1000_CONVERSATION: "First 1000 Conversations",
    MARKETING: "Marketing Conversations",
    UTILITY: "Utility Conversations",
    AUTHENTICATION: "Authentication Conversations",
    SERVICE: "Service Conversations",
    CONVERSATION_OTHER_CHANNELS: "Conversation Charges (other channels)",
    CREDIT_ADDED: "Credit Added",
    PAYMENT_MODE: "Payment Mode",
    DRAFT: "Draft",
    NO_SETUP_ACCOUNT: "You haven’t setup your account",
    WALLET_NOT_ACTIVE: "InActive",
    WALLET_NOT_ACTIVE_DESC: "Activate your wallet to view available credit.",
    SUBSCRIBE_A_PLAN: "No active subscription. Select a plan to start using services.",
    BASIC: "Basic",
    BASIC_DESCRIPTION:
      "For small teams that want to test the shared inbox waters",
    GROWTH: "Growth",
    GROWTH_DESCRIPTION: "When you require streamlined and centralized controls",
    BILLING_NOTIFICATION: "Notification Settings",
    BILLING_NOTIFICATION_DESC: "Set up alerts and manage notification preferences for billing",
    NOTIFICATION_ALERT_BALANCE: "Alert me when balance below",
    EMAIL_NOTIFICATION: "Send notifications mail to",
    SAVE_SETTINGS: "Save Settings",
    SAVING: "Saving",
    SAVED: "Saved",
    SAVED_ERROR: "Error",
    ONLINE: "Online",
    OFFLINE: "Offline",
    BLOCK_MESSAGE:
      "Account Blocked: Please get in touch with our team immediately to solve the issue.",
    API_CALLS: "API Calls",
    IN_PACKAGE: "In Package",
    CONSUMED: "Consumed",
    CONVERSATION_ADDON: "Conversation Addon",
    FAIR_BEYOND_API_CALLS: "Fair Beyond API Calls",
    FAIR_USE_CONVERSATION: "Fair Use Conversation Charges",
    PLATFORM_CONVERSATION: "Platform Conversation Charges",
    FAIR_BEYOND_PLATFORM: "Fair Beyond Platform Conversation",
    VIEW_INVOICE:'View Invoice',
    NO_USAGE:'No usage reports available for the selected period',
    EMAIL_RQUIRED:'Email is required',
    EMAIL_INVALID:'Email is invalid',
    LIMIT_REQUIRED:'Limit is required',
    SENT:'Sent',
    OVERDUE:'Overdue',
    PARTIALLY_PAID:'Partially paid',
    VOID:'Expired',
    INVOICE_ID:'Invoice ID',
    DUE_DATE:'Due Date',
    WLT_TOPUP:'Top-up',
    UPGRADE_REFUND:'Upgrade Refund',
    DOWNGRADE_REFUND:'Downgrade Refund'
  },
  ERROR: {
    "404_TITLE": "404: The page you are looking for isn't here",
    "404_DESCRIPTION":
      "You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.",
    "500_TITLE": "500: Internal Server Error",
    "500_DESCRIPTION":
      "You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.",
    "401_TITLE": "401: Authorization required",
    "401_DESCRIPTION":
      "You either tried some shady route or you came here by mistake. Whichever it is, try using the navigation.",
    RELOAD_TITLE:
      "Oops! The page you're looking for isn't available right now.",
    RELOAD_DESCRIPTION: "Don't worry, just reload the page and it'll be back.",
    BACK_BTN: "Take Me Back",
    RELOAD_BTN: "Reload Page",
    ERROR_OCCURRED:'An error occurred'
  },
  KNOWLEDGE_BASE: {
    HEADER: "Knowledge Base",
    SUBTITLE: "Create and Manage Knowledge Base here",
    BUTTON: {
      TRAIN: "TRAIN",
      CREATE_KNOWLEDGE_BASE: "Create Knowledge Base",
    },
    TABLE_HEADERS: {
      NAME: "Name",
      DESCRIPTION: "Description",
      CREATED_BY: "Created By",
      CREATED_ON: "Created On",
      STATUS: "Status",
      ACTION: "Action",
    },
    CREATE_POPUP: {
      HEADER_TITLE: "Create New Document",
      DOCUMENT_NAME: "Document Name",
      DESCRIPTION: "Description",
      KNOWLEDGE_TYPE: "Knowledge Type",
      UPLOAD: "Upload",
      WEBSITE: "Website",
      CONTENT_BODY: "Content Body",
      SELECT_FILE_HERE: "Select a file or drag and drop here",
      SELECT_FILE_PLACEHOLDER: "Document or PDF, file size no more than 10MB",
      SELECT_BUTTON: {
        BUTTON: "SELECT FILE",
      },
      DOCUMENT_PLACEHOLDER: "Document URL",
      CONTENT_BODY_TEXT: "Content Body",
      CONTENT_BODY_TEXT_PLACEHOLDER: "Enter body text here",
      BUTTON: {
        CANCEL: "Cancel",
        CREATE: "Create",
      },
    },
  },
  ONBOARDING: {
    AVOCADO: "Avocado",
    BOOK_SLOT: "Book Your Slots",
    DESCRIPTION: "Schedule a meeting with us, so we can connect back to you.",
    SCHEADULE_MEETING: "Let's schedule a meeting",
    THANKS_SCHEDULE: "Thank You for your Schedule",
    MAIL_INVITATION:
      "A calendar invitation has been sent to your email address.",
    DATE_TIME: "Date & Time:",
    USE_GOOGLE_MEET: "Use this meeting link to join the call",
    KNOW_PRODUCT: "Would you like to know more about the product?",
    TAKE_TOUR: "Take a tour",
  },
  WHATSAPP_WIDGET: {
    HEADER: "Click-to-Chat Button",
    SUBTITLE:
      "Give customers a way to reach you on WhatsApp, Facebook or Instagram.",
    CHAT_BUBBLE: "Chat Bubble",
    SELECT_BUTTON_TYPE: "Select the type of button you want",
    DEFAULT: "Default",
    AVATAR: "Human Avatar",
    BUBBLE_BACKGROUND_COLOR: "Bubble Background Color",
    CHAT_HEADER: "Chat Header",
    CHANGE: "Change",
    NAME: "Name",
    CAPTION: "Caption",
    WELCOME_MESSAGE: "Welcome Message",
    TEXT: "Text",
    BUTTON_TEXT: "Button Text",
    BUTTUN_COLOR: "Button Background Color",
    PRE_FILLED_MESSAGE: "Pre filled message",
    PRE_FILLED_MESSAGE_DESC:
    "Make it easy for visitors to start a chat by prefilling a greeting message for them to send you.",
    ENTER_GREETING_MESSAGE: "Enter your greeting text",
    POSITION_AND_VISABILITY: "Position & Visibility",
    POSITION_AND_VISABILITY_DESC:
    "You can select the default position of the chat button using the settings below.",
    WEB: "Web",
    ALIGNMENT: "Alignment",
    MARGIN_BOTTOM: "Margin bottom (px)",
    MARGIN_LEFT: "Margin left (px)",
    MARGIN_RIGHT: "Margin right (px)",
    MOBILE: "Mobile",
    SAVE_AND_VIEW: "Save & View Embed code",
    CHAT_POWERED_BY: "Chat Powered by",
    BOTTOM_RIGHT: "Bottom right",
    BOTTOM_LEFT: "Bottom left",
    ICON: "Icon",
    EMBEDDED_CODE: "Click-to-Chat Button Embed Code",
    COPY_EMBEDDED: "Copy Embed code",
    COPIED: "Copied",
    COPIED_FAILED: "Coping failed",
    ERROR_ON_SAVE: "Error on save data",
    CUSTOM: "Upload your own",
    UPLOAD:'Upload',
    NO_FILE_CHOSEN:'No file chosen',
    FILE_NOT_SUPPORTED:'File not supported',
    SAVING:'Saving',
    SAVED:'Saved',
    ERROR:'Error'
  },
};

export default EN_TRANSLATIONS;
