import { toast, ToastOptions } from 'react-toastify';
import i18next from 'i18next';

interface ToastDto {
	type: 'info' | 'success' | 'error';
	text: string;
}

const _getDefaultToastOptions = (): Partial<ToastOptions> => {
	return {
		position: i18next.dir() == 'ltr' ? 'top-right' : 'top-left',
		hideProgressBar: true,
	};
};

export const showToast = (input: ToastDto) => {
	switch (input.type) {
		case 'info':
			toast.info(input.text, _getDefaultToastOptions());
			break;

		case 'error':
			toast.error(input.text, _getDefaultToastOptions());

			break;

		case 'success':
			toast.success(input.text, _getDefaultToastOptions());
			break;
	}
};
