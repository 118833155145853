import React from 'react';
import {
  defaultIconPack,
  DyteClock,
  DyteDialogManager,
  DyteIcon,
  DyteLeaveButton,
  DyteMicToggle,
  DyteParticipantCount,
  DyteParticipantsAudio,
  DyteRecordingIndicator,
  DyteTooltip,
} from '@dytesdk/react-ui-kit';
import { useDyteMeeting, useDyteSelector } from '@dytesdk/react-web-core';
import { useEffect } from 'react';
import Stage from './Stage';
import { updateCalleState, updateMeetingToken } from '../../redux/meeting';
import { useDispatch } from 'react-redux';
import { Divider, Typography } from '@mui/material';

export default function Room() {
  const { meeting } = useDyteMeeting();
  const audioEnabled = useDyteSelector((meeting) => meeting.self.audioEnabled);
  const dispatch = useDispatch();


  useEffect(() => {
    function onKeyPress(e: KeyboardEvent) {
      if (e.ctrlKey && e.key.toLowerCase() === 'd') {
        if (meeting.self.audioEnabled) {
          meeting.self.disableAudio();
        } else {
          meeting.self.enableAudio();
        }
      }
    }

    window.addEventListener('keypress', onKeyPress);

    return () => {
      window.removeEventListener('keypress', onKeyPress);
      dispatch(updateMeetingToken(''));
      dispatch(updateCalleState(false));
    };
  }, []);


  return (
    <div className="h-full w-full flex flex-col roombg">
      <header className="flex items-center justify-between px-6 h-16">
        <h1 className="text-xl flex items-center gap-2">
          <Typography sx={{ color: 'dark.main' }}>{meeting.meta.meetingTitle}</Typography>
          <div style={{ display: 'flex', alignItems: 'center', backgroundColor: 'primary.alpha5', padding: 3, borderRadius: '8px' }}>
            <DyteClock meeting={meeting} />
          </div>

          {/* <DyteIcon icon={defaultIconPack.mic_on} className="h-6" /> */}
        </h1>

        <div>
          <DyteParticipantCount meeting={meeting} />
          <DyteRecordingIndicator meeting={meeting} />
        </div>
      </header>

      <Divider />
      <Stage />

      <footer className="flex items-center px-6 py-4 justify-center gap-1">
        <DyteLeaveButton variant="horizontal">
          <DyteIcon icon={defaultIconPack.call_end} className="h-7 w-7" />
        </DyteLeaveButton>


        <DyteTooltip label="Ctrl+D to toggle mic">
          <DyteMicToggle meeting={meeting}>
            <DyteIcon
              icon={
                audioEnabled ? defaultIconPack.mic_on : defaultIconPack.mic_off
              }
              className="h-7 w-7"
            />
          </DyteMicToggle>
        </DyteTooltip>
      </footer>

      <DyteDialogManager meeting={meeting} />
      <DyteParticipantsAudio meeting={meeting} />
    </div>
  );
}
