import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { DATE_FORMAT } from '../helpers/analytics';
import { LANG } from '../helpers/common';
import { localizedDateDisplay } from '../utils';

// Slice
const slice = createSlice({
    name: 'analytics',
    initialState: {
        duration: '',
        dateDuration: {
            startdate: localizedDateDisplay(null, LANG.EN, DATE_FORMAT.DATE, -30),
            enddate: localizedDateDisplay(null, LANG.EN, DATE_FORMAT.DATE),
        },
        AgentsTeamsIDs: "",
        enableDownloadReport: false,
        canDownloadReport: false,
    },
    reducers: {
        setDuration: (state, action) => {
            state.duration = action.payload.duration;
        },
        setDurationDate: (state, action) => {
            const {from, to} = action.payload
            if(from) state.dateDuration.startdate = from;
            if(!to && from) state.dateDuration.enddate = from;
            else if(to) state.dateDuration.enddate = to;
        },
        setAgentsTeamsIDs: (state, action) => {
            state.AgentsTeamsIDs = action.payload;
        },
        setDownloadReport: (state, action) => {
            state.enableDownloadReport = action.payload;
        },
        setCanDownloadReport: (state, action) => {
            state.canDownloadReport = action.payload;
        }
    },
});

export default slice.reducer;

const {setDuration, setDurationDate, setAgentsTeamsIDs, setDownloadReport, setCanDownloadReport} = slice.actions

export const changeDuration = (duration:string):any => (dispatch:Dispatch):void => {
    dispatch(setDuration({duration}))
}

export const changeDurationFilter = (duration:{from: string, to: string}):any => (dispatch:Dispatch):void => {
    dispatch(setDurationDate(duration))
}

export const changeAgentTeamsIDs = (IDs: string):any => (dispatch:Dispatch):void => {
    dispatch(setAgentsTeamsIDs(IDs))
}

export const changeEnableDownloadReport = (enable: boolean):any => (dispatch:Dispatch):void => {
    dispatch(setDownloadReport(enable))
}

export const changeCanDownloadReport = (enable: boolean):any => (dispatch:Dispatch):void => {
    dispatch(setCanDownloadReport(enable))
}