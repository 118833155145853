import React, { useEffect, useState } from "react";
import {
  Routes,
  Route,
  Navigate,
  useNavigate,
  useLocation,
} from "react-router-dom";
import { GlobalStyles, ThemeProvider } from "@mui/material";

//  components
import { Lazy } from "./components/Common/Lazy";
import { SdkRouter } from "./components/Common/SdkRouter";

// redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "./redux/store";
import sdk, { setCurrentRoute, init, setMode, navigateBack, setWebsocketsEmitter } from "./redux/sdk";

//  models
import { SdkProps } from "./models/sdk";

//  Pages
import Settings from "./pages/Settings/Settings";
import Analytics from "./pages/Analytics/Analytics";
import { Login } from "./pages/Login";
// import Onboarding from "./pages/Onboarding/Onboarding";

//  utils
import moment from "moment";
import i18next from "i18next";
import { useTranslation } from "react-i18next";
import "moment/locale/ar";
import { App as CapacitorApp } from '@capacitor/app';
import CryptoJS from "crypto-js";

//  helpers
import { isTokenExpired } from "./helpers/auth";
import { NotAuthNotification } from "./components/Login/NotAuthNotification";
import { MainRoutesWrapper } from "./components/Common/MainRoutesWrapper";
import { DeviceToken } from "./pages/DeviceToken";
import environment from "./environment";

//  websockets
import useWebSocket from 'react-use-websocket';
import { subscribe } from "./helpers/events";

import { StorageKeys, StorageService } from "./storage";
import { LoginGuard } from "./Guards/LoginGuard";
import { changeChatId } from "./redux/chatsPage";
import { ACTIONS, AvcModulesKeys } from "./helpers/constants";
import ProtectedRoute from "./Guards/ProtectedRoute";
import { changeAppIntegrationKey } from "./redux/appIntegration";
import { AppLanguage, setAppLanguage } from "./helpers/Languages";

import { updateUserRole } from "./redux/auth";
import { decodeBase64 } from "./helpers/decodeBase64String";

//  gql
// import { client_factory } from './graphql/client';
// import { MEETING_LISTENER } from './graphql/queries/Meeting';
// import { updateCallerData, updateMeetingToken } from './redux/meeting';

//  Lazy Pages
const ChatsPage = React.lazy(() => import("./pages/Chats"));
const ContactsPage = React.lazy(() => import("./pages/Contacts"));
const BroadcastPage = React.lazy(() => import("./pages/Broadcast"));
const CreateBroadcast = React.lazy(() => import("./pages/CreateBroadcast"));
const AppsPage = React.lazy(() => import("./pages/Apps"));
const Templates = React.lazy(() => import("./pages/Templates"));
const CreateTemplate = React.lazy(() => import("./pages/CreateTemplates"));
const GreetingAndAwayMessages = React.lazy(
  () => import("./pages/GreetingAndAway")
);
const QuickReply = React.lazy(() => import("./pages/QuickReply"));
const Rules = React.lazy(() => import("./pages/Rules"));
const Tags = React.lazy(() => import("./pages/Tags"));
const CustomAttributes = React.lazy(() => import("./pages/CustomAttributes"));
const WhatsappIntegration = React.lazy(
  () => import("./pages/WhatsappIntegration")
);
const Csml = React.lazy(() => import("./pages/Csml"));
const HubSpot = React.lazy(() => import("./pages/HubSpot"));
const Knowledge = React.lazy(() => import("./pages/Knowledge"));
const EcommerceApps = React.lazy(
  () => import("./pages/EcommerceApps/EcommerceApps")
);
const EcommerceAppSettings = React.lazy(
  () => import("./pages/EcommerceApps/EcommerceAppSettings")
);
const Onboarding = React.lazy(() => import("./pages/Onboarding/Onboarding"));
const MediaLibrary = React.lazy(() => import("./pages/MediaLibrary"));
const WhatsappFlow = React.lazy(() => import("./pages/WhatsappFlow"));
const WhatsappWidget = React.lazy(() => import("./pages/whatsappWidget"));
const WhatsAppFlowEditor = React.lazy(
  () => import("./pages/WhatsAppFlowEditor")
);
const BillingSubscription = React.lazy(
  () => import("./pages/BillingScreen")
);
const Error = React.lazy(() => import("./pages/Error"));

function Avocado({ currentScreen, mode = "sdk", sdk, currentScreenSearchParams }: SdkProps) {
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const navigate = useNavigate();
  moment.locale('en');
  const currentScreenParams = Object.fromEntries(new URLSearchParams(currentScreenSearchParams));

  const authenticated = useSelector(
    (state: RootState) => state.sdk.authenticated
  );
  const theme = useSelector((state: RootState) => state.sdk.theme);
  const chatId = useSelector((state: RootState) => state.chatsPage.chatId);
  const currentRoute = useSelector((state: RootState) => state.sdk.currentRoute);
  const userRole = useSelector((state: RootState) => state.auth.userRole);

  const [_token, _setToken] = useState(StorageService.getAuthToken() as string);

  const [_business_id, _setBusinessId] = useState(StorageService.getBusinessId() as string);

  const [_wssUrl, _setWssUrl] = useState(null as string | null);

  const [_wssQueryParams, _setWssQueryParams] = useState(null as any | null);

  const { sendJsonMessage: sendWebsocketMessage, lastMessage: lastWebsocketMessage, readyState, sendMessage: sendRawWebsocketMessage, getWebSocket } = useWebSocket(_wssUrl, {
    queryParams: _wssQueryParams,
    onOpen(event) {
      dispatch(setWebsocketsEmitter(sendWebsocketMessage));
      // dispatch(setWebsocketRawEmitter(sendRawWebsocketMessage)); #TODO: Uncomment when using in future
      // dispatch(setWebsocketLike(getWebSocket())); #TODO: Uncomment when using in future
    },
    share: true,
    heartbeat: {
      message: JSON.stringify({ event: "heartbeat", metadata: {agent_id: Number(StorageService.getUserId())}}),
      timeout: 60000, // 1 minute, if no response is received, the connection will be closed
      interval: 25000, //every 25s
    },
    shouldReconnect: (closeEvent) => {
      return authenticated;
    },
    reconnectAttempts: 10,
    reconnectInterval: (retryAttempt) => {
      return Math.min(Math.pow(2, retryAttempt) * 1000, 10000)
    },
    retryOnError: true,
    onError: (event) => {
    },
    onClose: (event) => {
    },
  });


  // #TODO: Once we have the meeting integration ready "FULLY", we can uncomment this code
  // const apolloClient = client_factory();
  // apolloClient.subscribe({
  //   query: MEETING_LISTENER,
  //   variables: {
  //     business_id: window.business_id,
  //     agent_id: StorageService.getUserId(),
  //   },
  // }).subscribe({
  //   next: (result: any) => {
  //     if (result?.data?.meetings?.length > 0) {
  //       const meetingToken: string = result?.data?.meetings[0].auth_token;
  //       const callerName: string = result?.data?.meetings[0].agent_host?.contact_profiles[0]?.display_name;
  //       const callerImg: string = result?.data?.meetings[0].agent_host?.contact_profiles[0]?.image;
  //       dispatch(updateCallerData({ callerName: callerName, callerImg: callerImg }));
  //       dispatch(updateMeetingToken(meetingToken));
  //     }
  //   },
  //   error: (error) => {
  //     console.log(error);
  //   },
  // });

  useEffect(() => {
    let initialScreen = currentScreen;
    const params = window.location.search;
    const params_obj = {};
    for (const entry of new URLSearchParams(params).entries()) {
      params_obj[entry[0]] = entry[1];
    }
    const token = StorageService.getAuthToken() as string;
    if (!token || isTokenExpired(token.split(" ")[1])) {
      initialScreen = "/login";
      if (currentScreen === "/onboarding") initialScreen = "/onboarding";
      else if (currentScreen === "/ecommerce-apps") initialScreen = "/ecommerce-apps";
    } else if (currentScreen === "/login") {
      if (!Object.entries(params_obj).length) initialScreen = "/chat";

      const { session, platform_id: platformId, platform_type: platformType, app, redirect_page: redirectPage , language: languageCode } = currentScreenParams;
      // check case fooder chat redirect
      if (session && app) {
        StorageService.setIntegrationApp(app);
        dispatch(changeAppIntegrationKey(app));
        setAppLanguage((languageCode as AppLanguage) || AppLanguage.ENGLISH);

        const data = JSON.parse(decodeBase64(session));
        const role: string = data?.user_details?.role;

        const cipherRole: string = CryptoJS.AES.encrypt(role, environment.REACT_APP_SECRET_ENCRYPTION_KEY).toString();

        window.business_id = data.headers["x-hasura-business-id"];
        StorageService.setAuthToken(data.headers.Authorization);
        StorageService.setAvcBillingSource(data.billing_source);
        StorageService.setBusinessId(data.headers["x-hasura-business-id"]);
        StorageService.setUserId(data.user_details.id);
        StorageService.setUserEmail(data.user_details.email);
        StorageService.setUserDetailsAsJson(data.user_details);
        StorageService.setChannelsAsJson(data.channels);
        StorageService.setCipherRole(cipherRole);

        dispatch(updateUserRole(role));

        if (platformId && platformType) {
          StorageService.setPlatformId(platformId);
          StorageService.setPlatformType(platformType);
        }

        if (redirectPage) initialScreen = `/${redirectPage}`;
      }

    } else if (!initialScreen) {
      initialScreen = "/chat";
      // initialScreen = window.screen.width <= 786 ? '/chat' : '/dashboard/overview';
    }
    if (mode === "host") navigate(initialScreen + params);
    dispatch(setCurrentRoute(initialScreen, params_obj));
    handleWssLogin();
  }, [currentScreen]);

  useEffect(() => {
    CapacitorApp.addListener('backButton', ({ canGoBack }) => {
      if (chatId || StorageService.getChatId()) {
        dispatch(changeChatId(null));
        StorageService.removeItem(StorageKeys.CHAT_ID);
      } else if (!canGoBack || (currentRoute.path === '/chat' && !chatId)) {
        CapacitorApp.exitApp();
      } else {
        window.history.back();
      }
    });
  }, []);

  useEffect(() => dispatch(setMode(mode)), [mode]);

  useEffect(() => dispatch(init(sdk)), [sdk]);

  function handleWssLogin() {

    let token = StorageService.getAuthToken() as string;
    let business_id = StorageService.getBusinessId() as string;

    if (token === null || business_id === null) {
      return;
    }
    _setToken(token);
    _setBusinessId(business_id);
    _setWssQueryParams({
      app_type: mode,
      host_id: environment.REACT_APP_HOST_ID,
      business_id: business_id,
      token: token,
    });

    _setWssUrl(environment.REACT_APP_WEBSOCKET_URL);
    sendWebsocketMessage({
      "event": "login",
      "metadata": {
        "token": token
      }
    });
  }

  useEffect(() => {
    i18next.changeLanguage(StorageService.getLanguage());
    document.dir = i18next.dir();
    if (mode === "host") {
      window.addEventListener("popstate", (e) => {
        dispatch(navigateBack());
      });
    }

    const avcIntegrationApp = StorageService.getIntegrationApp();
    if(avcIntegrationApp) dispatch(changeAppIntegrationKey(avcIntegrationApp));

    // In the effect that listens for storage changes
    const handleStorageChange = (event: StorageEvent) => {
      if (event.key === StorageKeys.AUTH_TOKEN && event.newValue !== _token) {
        window.location.reload();
        }
        if (event.key === StorageKeys.AUTH_TOKEN && event.newValue === null) {
          dispatch(setCurrentRoute("/login"));
        window.location.reload();
      }
    };

    window.addEventListener("storage", handleStorageChange);

    // console.log(process.env);
    if (process.env.REACT_APP_AVOCADO_ENV === "prd") {
      console.log = () => {};
    }

    subscribe('LOGIN', () => {
      handleWssLogin();
    });

    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  if (!authenticated) {
    if (pathname === "/onboarding") {
      return <Onboarding />;
    }
    return <Login />;
  }

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles
        styles={(theme: any) => ({
          "p, h1, h2, h3, h4, h5, h6": { color: theme.palette.text.main },
          ".MuiTableCell-root, .MuiTableRow-root, .MuiTableHead-root": {
            borderColor: `${theme.palette.light.gray3} !important`,
          },
        })}
      />
      {mode === "host" ? (
        // HOST VIEW
        <Routes>
          <Route path='/login' element={<LoginGuard />} />
          {/* <Route path='/onboarding' element={<Onboarding />} /> */}
          <Route
            path='/onboarding'
            element={<Lazy component={<Onboarding />} />}
          />
          <Route
            path='/*'
            element={
              <MainRoutesWrapper
                routes={
                  <Routes>
                    <Route
                      path='/'
                      element={<Navigate to='/dashboard' replace />}
                    />
                    <Route
                      path='/chat'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.CHAT } path="/chat">
                          <Lazy component={<Lazy component={<ChatsPage />} />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='/contacts'
                      element={<Lazy component={<ContactsPage />} />}
                    />
                    <Route
                      path='/apps'
                      element={<Lazy component={<AppsPage />} />}
                    />
                    <Route
                      path='/apps/broadcast/*'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.BROADCAST } path="/apps">
                          <Lazy component={<BroadcastPage />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='/apps/broadcast/create'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.BROADCAST} action={ACTIONS.BROADCAST.NEW_BROADCAST} path="/apps">
                          <Lazy component={<CreateBroadcast />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='/apps/templates'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.TEMPLATES } path="/apps">
                          <Lazy component={<Templates />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='/apps/templates/create/*'
                      element={
                        <ProtectedRoute action={ACTIONS.TEMPLATES.SUBMIT_TEMPLATE} moduleKey={AvcModulesKeys.TEMPLATES } path="/apps">
                          <Lazy component={<CreateTemplate />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='/apps/greeting'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.GREETINGS } path="/apps">
                          <Lazy component={<GreetingAndAwayMessages />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route path='/settings/*' element={<Settings />} />
                    <Route
                      path='/dashboard/*'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.ANALYTICS } path="/dashboard/*">
                          <Lazy component={<Analytics />} />
                        </ProtectedRoute>
                      }
                    />
                    {/* <Route path='/dashboard/*' element={<Analytics />} /> */}
                    <Route
                      path='/apps/quick-reply'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.QUICKREPLIES } path="/apps">
                          <Lazy component={<QuickReply />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='/apps/rules'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.RULES } path="/apps">
                          <Lazy component={<Rules />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='/apps/tags'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.TAGS } path="/apps">
                          <Lazy component={<Tags />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='/apps/custom-attributes'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.CUSTOM_ATTRIBUTES } path="/apps">
                        <Lazy component={<CustomAttributes />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='/whatsapp-integration'
                      element={<Lazy component={<WhatsappIntegration />} />}
                    />
                    <Route
                      path='/apps/csml'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.CSML } path="/apps">
                        <Lazy component={<Csml />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='/apps/hubspot'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.HUBSPOT } path="/apps">
                        <Lazy component={<HubSpot />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='/apps/knowledge'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.KNOWLEDGE_BASE } path="/apps">
                          <Lazy component={<Knowledge />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='/ecommerce-apps'
                      element={<Lazy component={<EcommerceApps />} />}
                    />
                    <Route
                      path='/ecommerce-apps/:platform/:store_id/*'
                      element={<Lazy component={<EcommerceAppSettings />} />}
                    />
                    <Route
                      path='/media-library'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.MEDIA_LIBRARY } path="/apps">
                          <Lazy component={<MediaLibrary />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='/apps/whatsapp-flow'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.WHATSAPP_FLOW } path="/apps">
                          <Lazy component={<WhatsappFlow />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='/apps/whatsapp-widget'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.WHATSAPP_WIDGET } path="/apps">
                          <Lazy component={<WhatsappWidget />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='/apps/whatsapp-flow/editor'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.WHATSAPP_FLOW } path="/apps">
                          <Lazy component={<WhatsAppFlowEditor />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='/billing-subscription'
                      element={
                        <ProtectedRoute moduleKey={AvcModulesKeys.BILLING } path="/billing-subscription">
                          <Lazy component={<BillingSubscription />} />
                        </ProtectedRoute>
                      }
                    />
                    <Route
                      path='/device-token'
                      element={<Lazy component={<DeviceToken />} />}
                    />
                    <Route
                      path='*'
                      element={<Lazy component={<Error type={404} />} />}
                    />
                  </Routes>
                }
              />
            }
          />
        </Routes>
      ) : // SDK VIEW
      authenticated ? (
        <MainRoutesWrapper routes={<SdkRouter />} />
      ) : (
        <Login />
      )}
      <NotAuthNotification />
    </ThemeProvider>
  );
}

export default Avocado;
