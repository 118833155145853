import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { AvcModulesKeys, moduleVisibilityCalibration } from "../constants";

const useAppIntegration = () => {
  const appIntegrationKey = useSelector((state: RootState) => state.appIntegration.appIntegrationKey);

  const isModuleAllowed = (moduleKey : AvcModulesKeys) => {
    return  !moduleVisibilityCalibration[appIntegrationKey]?.includes(moduleKey);
   }

  return {
    appIntegrationKey,
    isModuleAllowed,
  }
}

export default useAppIntegration
