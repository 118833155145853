import axios from "axios";
import i18next from "i18next";
import environment from "../environment";
import { setCurrentRoute } from "../redux/sdk";
import store from "../redux/store";
import { publish } from "./events";
import { StorageService } from "../storage";
import * as Sentry from "@sentry/react";
import { ACTIONS, ACTIONS_TYPE, permissions } from "./constants";
import { UPDATE_CONTACT_META } from "../graphql/queries/chats";
import { client_factory } from "../graphql/client";

const headers = {
  "Content-Type": "application/json",
  Accept: "application/json",
};

export const isTokenExpired = (token: string): boolean => {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  const { exp } = JSON.parse(jsonPayload);
  return Date.now() >= exp * 1000;
};

export const login = async (payload: any) => {
  const options = {
    method: "POST",
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/auth/login`,
    headers,
    data: {
      business_id: payload.workspace,
      platform_type: "avocado",
      platform_id: payload.email,
      host_id: environment.REACT_APP_HOST_ID,
      password: payload.password,
    },
  };

  const { data } = await axios.request(options);
  return data;
};

export const register = async (payload: any) => {
  const options = {
    method: "POST",
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/auth/register`,
    headers,
    data: {
      name: payload.name,
      platform_type: "avocado",
      platform_id: payload.email,
      host_id: environment.REACT_APP_HOST_ID,
      password: payload.password,
      language: i18next.language,
    },
  };

  const { data } = await axios.request(options);
  return data;
};

export const signup = async (payload: any, token: string) => {
  const options = {
    method: "POST",
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/auth/signup`,
    headers,
    params: {
      token,
    },
    data: payload,
  };

  const { data } = await axios.request(options);
  return data;
};

export const forgot_password = async (payload: any) => {
  const options = {
    method: "POST",
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/auth/forgot.password`,
    params: {
      action: "send_email",
    },
    headers,
    data: {
      platform_type: "avocado",
      platform_id: payload.email,
    },
  };

  const data = await axios.request(options);
  return data;
};

export const reset_password = async (password: string, token: string) => {
  const options = {
    method: "POST",
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/auth/forgot.password`,
    params: {
      action: "verify",
      token,
    },
    headers: {
      ...headers,
      "x-avc-password": password,
    },
  };

  const { data } = await axios.request(options);
  return data;
};

export const change_password = async (payload: any) => {
  const options = {
    method: "POST",
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/auth/change.password`,
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
      Authorization: StorageService.getAuthToken(),
    },
    data: payload,
  };

  const { data } = await axios.request(options);
  return data;
};

export const get_businesses = async (email: string) => {
  const options = {
    method: "GET",
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/auth/get.businesses`,
    headers: {
      "x-avc-platform-id": email,
      "x-avc-platform-type": "avocado",
      "x-avc-host-id": environment.REACT_APP_HOST_ID,
      Accept: "application/json",
    },
  };

  const { data } = await axios.request(options);
  return data;
};

export const switch_business = async (business_id: string) => {
  const options = {
    method: "GET",
    url: `${environment.REACT_APP_API_GATEWAY_ENDPOINT}/auth/switch.business`,
    headers: {
      Accept: "application/json",
      Authorization: StorageService.getAuthToken(),
    },
    params: { business_id }
  }

  const { data } = await axios.request(options)
  return data
}

const clearFirebaseDatabases = () => {
  const databases = [
    'firebase-heartbeat-database',
    'firebase-installations-database',
    'firebase-messaging-database'
  ];

  databases.forEach(dbName => {
    const request = indexedDB.deleteDatabase(dbName);
    request.onblocked = function () {
      console.log("Couldn't delete database due to the operation being blocked", dbName);
    };
    request.onsuccess = () => {
    };
    request.onerror = (event) => {
      console.error(`Failed to delete ${dbName}:`, event);
    };
  });
};


export const logout = async (reason?: string) => {
  const client = client_factory()
  if (reason !== "USER_REQUESTED_ACCOUNT_DELETION") {
    store.getState().sdk.websocketsEmitter?.({
      "event": "logout", 
      "metadata": {
        "agent_id": Number(StorageService.getUserId()),
      }
    });
  }
  
  //store.getState().sdk.websocketInstance?.close(1000, "User logged out")

  // Clear Firebase-related IndexedDB databases
  clearFirebaseDatabases();
  // Cleaning fcm_token through server-side
  try {
    await client.mutate({
      mutation: UPDATE_CONTACT_META,
      variables: {
        contact_id: Number(StorageService.getUserId()),
        meta_data: { fcm_token: '' }
      }
    });
  } catch (error) {
    console.error('Error updating contact metadata:', error);
  }

  StorageService.clearUserDetails();

  Sentry.setUser(null)
  Sentry.setTag("avc-business_id", null);
  Sentry.setTag("avc-token", null);

  if (reason === "SESSION_TIMED_OUT") {
    const currentScreen = store.getState().sdk.currentRoute.path;
    if (currentScreen === "/login") return;
    publish("SESSION_TIMED_OUT", {});
    await new Promise((resolve) => setTimeout(resolve, 4000));
  }

  if (reason === "USER_REQUESTED_ACCOUNT_DELETION") {
    const currentScreen = store.getState().sdk.currentRoute.path;
    if (currentScreen === "/login") return;
    publish("USER_REQUESTED_ACCOUNT_DELETION", {});
    await new Promise((resolve) => setTimeout(resolve, 4000));
  }

  window.sdk.logoutCallback?.();

  store.dispatch(setCurrentRoute("/login?tab=1"));
  if (store.getState().sdk.mode === "host") window.location.replace("/login?tab=1");
};

export const isPermitted = (
  { role, module, action, returnType = ACTIONS_TYPE.STYLE }: 
  { role: string, module: string, action: string, returnType?: 'style' | 'bool' }
): { display?: string } | boolean => {
  
  const hideStyle = { display: 'none !important' };
  
  // Handle 'style' return type
  if (returnType === ACTIONS_TYPE.STYLE) {
    if (ACTIONS.FULL_ACCESS in permissions[role]) {
      return {}; // Full access, no need to hide
    }
    
    if (!(action in permissions[role][module])) {
      return {}; // Action not found, no need to hide
    }
    
    if (!permissions[role][module][action]) {
      return hideStyle; // Action is not permitted, hide element
    }
    
    return {}; // Action is permitted, no need to hide
  }
  
  // Handle 'bool' return type
  if (returnType === ACTIONS_TYPE.BOOLEAN) {
    if (ACTIONS.FULL_ACCESS in permissions[role]) {
      return true; // Full access, permit action
    }
    
    if (ACTIONS.FULL_ACCESS_TO_MODULE in permissions[role][module]) {
      return permissions[role][module][ACTIONS.FULL_ACCESS_TO_MODULE]; // It could be true or false, but if It doesn't exist, it's true (Next condition)
    }
    
    if (!(action in permissions[role][module])) {
      return true; // Action not found, so permit the action
    }
    
    return permissions[role][module][action]; // Return specific permission for action
  }
  
  return false; // Default to false if none of the conditions are met
}