import React from 'react';
import { Stack } from '@mui/system'
import { Icon } from '@iconify/react';
import { Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

export const NotAuthorized = (): JSX.Element => {

  const { t } = useTranslation();

  return (
    <Stack width={'100%'} alignItems="center" justifyContent="center" direction="column" sx={{height: '100%'}}>
        <Icon icon="iwwa:alert" width="50px" height="50px" style={{color: 'light.main'}} />
        <Typography variant="h4" color='light.gray21' sx={{mt: 1}}>{t('AUTH.NOT_ALLOWED')}</Typography>
    </Stack>
  )
}
