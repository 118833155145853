import React, { PropsWithChildren, useEffect, useRef, useState } from "react";
import {
  Link,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Button, Snackbar, Stack, Typography } from "@mui/material";
import _SwipeableViews from "react-swipeable-views";
import * as Sentry from "@sentry/react";

//  utils
import { Trans, useTranslation } from "react-i18next";
import i18next from "i18next";
import { StorageService } from "../storage";
import CryptoJS from "crypto-js";

//  helpers
import { getAssetUrl } from "../helpers/assets";

//  components
import { TabPanel } from "../components/Common/TabPanel";
import { Form } from "../layouts/Login/Form";
import { ForgotPassword } from "../layouts/Login/ForgotPassword";
import { NameWorkspace } from "../layouts/Login/NameWorkspace";
import { PhoneNumber } from "../components/Login/PhoneNumber";
import { SelectRole } from "../components/Login/SelectRole";
import { TeamSize } from "../components/Login/TeamSize";
import { Purpose } from "../components/Login/Purpose";
import { LanguageButton } from "../components/Login/LanguageButton";
import { ResetPassword } from "../components/Login/ResetPassword";
import environment from "../environment";

// redux
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";
import { changeShowResetPasswordNotification, updateUserRole } from "../redux/auth";
import { hubApi } from "../helpers/api";
import { AxiosError } from "axios";
import {
  updateToastContent,
  updateToastTimer,
  updateToastType,
} from "../redux/stores";
import { setAuthenticated, setCurrentRoute } from "../redux/sdk";
import { publish } from "../helpers/events";
import { changeAppIntegrationKey } from "../redux/appIntegration";
import { AppLanguage, setAppLanguage } from "../helpers/Languages";
import { decodeBase64 } from "../helpers/decodeBase64String";

const SwipeableViews = _SwipeableViews as unknown as React.FC<
  PropsWithChildren<{ style: any; axis: string; index: number }>
>;

const tabs = [
  "signup",
  "login",
  "check_email",
  "forgot_password",
  "workspace_name",
  "phone_number",
  "role",
  "team_size",
  "purpose",
  "new_password",
];

export const Login = (): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const isMobile = useMediaQuery({ maxWidth: 786 });
  const mode = useSelector((state: RootState) => state.sdk.mode);


  const { showResetPasswordNotification } = useSelector(
    (state: RootState) => state.auth
  );

  const [tab, setTab] = useState(
    searchParams.get("verified")
      ? 4
      : searchParams.get("reset_password")
      ? 9
      : 1
  );

  const signupDetailsRef = useRef<any>({
    host_id: environment.REACT_APP_HOST_ID,
    realm: {},
  });

  useEffect(() => {
    const code = searchParams.get("code");
    const storeName = searchParams.get("install");

    if (code && storeName) {
      const params = window.location.search;

      hubApi
        .get(`/integration/callback/${storeName}/registeration${params}`)
        .then((response) => {
          // console.log("response => ", response);
          const {
            "store-id": storeId,
            "business-id": businessId,
            status,
          } = response.data;

          if (
            status === "PENDING" &&
            (businessId === "00000000-0000-0000-0000-000000000000" ||
              businessId === null)
          ) {
            navigate(`/onboarding?store-id=${storeId}`);
            return;
          } else {
            StorageService.setInstallAppInfo({ storeId, businessId, status })
          }
        })
        .catch((err: AxiosError) => {
          let errorMessage =
            err.message ||
            err.response.message ||
            "An error occurred. Please try again";

          dispatch(updateToastContent(errorMessage));
          dispatch(updateToastType("error"));
          dispatch(updateToastTimer(5));
        });
    }
  }, []);


  useEffect(() => {

    const params = Object.fromEntries(searchParams);
    const { session, platform_id: platformId, platform_type:  platformType, app, redirect_page: redirectPage, language: languageCode  } = params;

    if (!session || !app ) return;

    const data = JSON.parse(decodeBase64(session));
    StorageService.setIntegrationApp(app);
    dispatch(changeAppIntegrationKey(app));
    setAppLanguage((languageCode as AppLanguage) || AppLanguage.ENGLISH);
    const role: string = data?.user_details?.role;

    const cipherRole: string = CryptoJS.AES.encrypt(role, environment.REACT_APP_SECRET_ENCRYPTION_KEY).toString();

    window.business_id = data.headers["x-hasura-business-id"];
    StorageService.setAuthToken(data.headers.Authorization);
    StorageService.setAvcBillingSource(data.billing_source);
    StorageService.setBusinessId(data.headers["x-hasura-business-id"]);
    StorageService.setUserId(data.user_details.id);
    StorageService.setUserEmail(data.user_details.email);
    StorageService.setUserDetailsAsJson(data.user_details);
    StorageService.setChannelsAsJson(data.channels);
    StorageService.setCipherRole(cipherRole);

    dispatch(updateUserRole(role));

    if (platformId && platformType) {
      StorageService.setPlatformId(platformId);
      StorageService.setPlatformType(platformType);
    }

    Sentry.setUser({ email: data.user_details.email , id: data.user_details.id });
    Sentry.setTag("avc-business_id", data.headers["x-hasura-business-id"]);
    Sentry.setTag("avc-token", data.headers.Authorization);

    window.setTimeout(() => {
      publish('LOGIN', {});
      window.sdk.authorize();
      dispatch(setAuthenticated(true));

      if (redirectPage) {
        if (mode === 'host') navigate(`${redirectPage}`);
        dispatch(setCurrentRoute(redirectPage));
      }
    },100)

  }
  ,[])

  useEffect(() => {
    if(searchParams.get("verified")) {
      setTab(4);
    }
    else if(searchParams.get("reset_password")) {
      setTab(9);
    } else {
      const tab: string = searchParams.get('tab') || '1';
      setTab(parseInt(tab));
    }
  }, [location]);

  const handleCloseNotification = () =>
    dispatch(changeShowResetPasswordNotification());
  const currentTab = tabs[tab];

  return (
    <>
      <Snackbar
        sx={{ mt: isMobile ? 7 : 3 }}
        anchorOrigin={{
          vertical: "top",
          horizontal: i18next.language === "en" ? "right" : "left",
        }}
        open={showResetPasswordNotification}
        onClose={handleCloseNotification}
        autoHideDuration={3000}
        message={t("LOGIN.RESET_PASSWORD_NOTIFICATION")}
        action={
          <Button
            color='inherit'
            size='small'
            onClick={handleCloseNotification}
          >
            {t("COMMON.CLOSE")}
          </Button>
        }
      />
      <Stack
        alignItems={{ xs: "center", md: "flex-end" }}
        width='100%'
        height='100vh'
        sx={{
          maxHeight: "100%",
          backgroundImage: `url(${getAssetUrl("login-background.png")})`,
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        }}
      >
        <Stack
          alignItems='center'
          justifyContent='center'
          gap={{ md: 4, xs: 2, sm: 3 }}
          height='100%'
          width={{ xs: "100%", sm: "50%" }}
          sx={{
            // minWidth: i18next.language === "en"? "max-content": 0,
            backgroundColor: { md: "light.main" },
            p: 3,
            boxSizing: "border-box",
          }}
        >
          <Stack
            alignItems='center'
            sx={{
              width: { xs: "100%", sm: 444 },
              boxSizing: "border-box",
              boxShadow: 26,
              px: { md: 3, xs: 1, sm: 2 },
              pt: { md: 6, xs: 6, sm: 5 },
              pb: { md: 4, xs: 7 },
              borderRadius: 2.5,
              backgroundColor: "light.main",
            }}
          >
            <Stack
              flexDirection='row'
              alignItems='center'
              gap={{ md: 2, xs: 1 }}
            >
              <img
                src={getAssetUrl("mottasl_blue.png")}
                alt='avocado'
                width={isMobile ? 30 : 50}
                height={isMobile ? 30 : 50}
              />
              <Stack alignItems='end'>
                <Typography
                  fontSize={{ md: "20px", xs: "21px", sm: "22px" }}
                  fontWeight={500}
                  sx={{ color: "dark.main" }}
                >
                  {t("LOGIN.AVOCADO")}
                </Typography>
                <Typography fontSize={10}>
                  {t("LOGIN.TWERLO_PRODUCT")}
                </Typography>
              </Stack>
            </Stack>
            <Typography
              fontSize={{ md: 18, xs: 15, sm: 16 }}
              fontWeight={400}
              mt={{ md: 3, xs: 1 }}
              mb={2}
            >
              {t(`LOGIN.${currentTab?.toUpperCase()}_TITLE`)}
            </Typography>
            <SwipeableViews
              disabled={isMobile ? true : false}
              style={{ width: "100%" }}
              axis={i18next.language === "ar" ? "x-reverse" : "x"}
              index={tab}
            >
              <TabPanel
                value={0}
                currentView={tab}
                dir={i18next.dir()}
                child={<Form currentTab={currentTab} setTab={setTab} />}
              />
              <TabPanel
                value={1}
                currentView={tab}
                dir={i18next.dir()}
                child={<Form currentTab={currentTab} setTab={setTab} />}
              />
              <TabPanel
                value={2}
                currentView={tab}
                dir={i18next.dir()}
                child={
                  <Typography
                    fontSize={{ md: 14, xs: 13 }}
                    fontWeight={500}
                    textAlign='center'
                    sx={{
                      color: "text.secondary",
                      maxWidth: { xs: "220px", md: "460px" },
                      mx: "auto",
                    }}
                  >
                    {t("LOGIN.CONFIRMATION_SENT")}
                  </Typography>
                }
              />
              <TabPanel
                value={3}
                currentView={tab}
                dir={i18next.dir()}
                child={<ForgotPassword
                  tab={tab}
                  setTab={setTab} />}
              />
              <TabPanel
                value={4}
                currentView={tab}
                dir={i18next.dir()}
                child={
                  <NameWorkspace
                    setTab={setTab}
                    detailsRef={signupDetailsRef}
                  />
                }
              />
              <TabPanel
                value={5}
                currentView={tab}
                dir={i18next.dir()}
                child={
                  <PhoneNumber setTab={setTab} detailsRef={signupDetailsRef} />
                }
              />
              <TabPanel
                value={6}
                currentView={tab}
                dir={i18next.dir()}
                child={
                  <SelectRole setTab={setTab} detailsRef={signupDetailsRef} />
                }
              />
              <TabPanel
                value={7}
                currentView={tab}
                dir={i18next.dir()}
                child={
                  <TeamSize setTab={setTab} detailsRef={signupDetailsRef} />
                }
              />
              <TabPanel
                value={8}
                currentView={tab}
                dir={i18next.dir()}
                child={
                  <Purpose setTab={setTab} detailsRef={signupDetailsRef} />
                }
              />
              <TabPanel
                value={9}
                currentView={tab}
                dir={i18next.dir()}
                child={<ResetPassword setTab={setTab} />}
              />
            </SwipeableViews>
          </Stack>

          {tab <= 3 && (
            <Stack
              flexDirection='row'
              alignItems='center'
              gap={1}
              sx={{
                p: 3,
                border: "1px solid",
                borderColor: "light.gray3",
                borderRadius: 2.5,
                width: "80%",
                maxWidth: "444px",
                boxSizing: "content-box",
                justifyContent: "center",
                minWidth: i18next.language === "en"? "max-content" : 0,
              }}
            >
              <Trans
                i18nKey={`LOGIN.FOOTER_${currentTab?.toUpperCase()}`}
                style={{ fontSize: "14px" }}
                components={[
                  <Typography
                    component={Link}
                    to={`/login?tab=${(tab === 3 || tab === 0) ? 1 : 0}`}
                    color='primary'
                    sx={{
                      textDecoration: "none",
                      fontSize: { md: "16px", xs: "13px", sm: "14px" },
                    }}
                    onClick={() => setTab((tab === 3 || tab === 0) ? 1 : 0)}
                  />,
                ]}
              />
            </Stack>
          )}
          {tab <= 3 && <LanguageButton />}
        </Stack>
      </Stack>
    </>
  );
};
