import { useMutation } from '@apollo/client';
import { Capacitor } from '@capacitor/core';
import { ActionPerformed, PushNotificationSchema, PushNotifications, Token } from '@capacitor/push-notifications';
import { Avatar, Button, ButtonBase, Stack, Typography } from '@mui/material';
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  onMessage
} from "firebase/messaging";
import React, { useEffect, useState } from 'react';
import toast, { ToastBar, Toaster } from 'react-hot-toast';
import environment from '../../environment';
import { UPDATE_CONTACT_META } from '../../graphql/queries/chats';
import { getAssetUrl } from '../../helpers/assets';
import { overflowTextByLines } from '../../helpers/styles';
import { StorageService } from "../../storage";
import { useSelector } from 'react-redux';
import { RootState } from '../../redux/store';

const firebaseConfig = {
  apiKey: `${environment.REACT_APP_FCM_API_KEY}`,
  authDomain: `${environment.REACT_APP_FCM_AUTH_DOMAIN}`,
  projectId: `${environment.REACT_APP_FCM_PROJECT_ID}`,
  storageBucket: `${environment.REACT_APP_FCM_STORAGE_BUCKET}`,
  messagingSenderId: `${environment.REACT_APP_FCM_MSG_SENDER_ID}`,
  appId: `${environment.REACT_APP_FCM_APP_ID}`,
};

const fapp = initializeApp(firebaseConfig);

let messaging;
try {
  messaging = getMessaging(fapp);
} catch (err) {
  console.error('Failed to initialize Firebase Messaging', {err});
}

export { messaging };

const playSound = () => {
  const url = getAssetUrl('notification-sound.mp3');
  const audio = new Audio(url);
  // Ensure that the sound is played after user interaction
  audio.play().catch(err => {
    console.error('Failed to auto-play sound:', err);
    // Fallback - if auto-play fails, wait for user interaction (click, scroll, etc.)
    const userInteractionHandler = () => {
      audio.play().catch(err => console.error('Failed to play sound after interaction:', err));
      // Remove the event listener after interaction
      document.removeEventListener('click', userInteractionHandler);
    };
    document.addEventListener('click', userInteractionHandler, { once: true });
  });
}

const ToastNotification = ({ T, new_message }): JSX.Element => {
  const closeToast = () => {
    toast.dismiss(T.id);
  };

  const getToastBody = (): ()=> JSX.Element => 
      (): JSX.Element => {
      return (
        <Stack flexDirection="row" alignItems="center" sx={{
          backgroundColor: 'light.main',
          boxShadow: '36px',
          borderRadius: '0.5rem',
          width: '450px !important',
          opacity: 1,
          transition: "opacity 100ms ease-in-out"}}>
          <ButtonBase sx={{ display: 'flex', justifyContent: 'flex-start', alignItems: "center", gap: 1.5, flex: 1, p: 2, borderEndStartRadius: '0.5rem', borderStartStartRadius: '0.5rem' }} onClick={() => { }}>
            <Avatar src={getAssetUrl('broken-image.png')} alt={new_message?.title} />
            <Stack gap={0.5}>
              <Typography sx={{ fontSize: '0.875rem', fontWeight: 500, lineHeight: '1.25rem', color: 'rgb(17 24 39)', textAlign: 'start' }}>{new_message?.title}</Typography>
              <Typography sx={{ fontSize: '0.875rem', lineHeight: '1.25rem', color: 'rgb(107 114 128)', textAlign: 'start', ...overflowTextByLines(1) }}>{new_message?.body}</Typography>
            </Stack>
          </ButtonBase>
          <Button variant='text' sx={{ p: 2, height: '100%', borderInlineStart: '1px solid rgb(229 231 235)', borderStartEndRadius: '0.5rem', borderEndEndRadius: '0.5rem', fontSize: '0.875rem', fontWeight: 500, lineHeight: '1.25rem' }} onClick={closeToast}>{'CLOSE'}</Button>
        </Stack>)
      }
  return (
    <ToastBar toast={T}>
      {getToastBody()}
    </ToastBar>
  )
}

export const PushNotification = (): JSX.Element => {
  const isMobileApp: boolean = Capacitor.getPlatform() !== 'web';
  const nullEntry: any[] = []
  const [notifications, setnotifications] = useState(nullEntry);

  const soundNotifications = useSelector((state: RootState) => state.settings.soundNotifications);
  const isIOSDevice = Capacitor.getPlatform() === 'ios'
  const handleNewMessage = (payload: any, inside: boolean, isGlobal: boolean) => {
    // This will play sound based on sound notification settings
    if(soundNotifications[1]) {
      playSound();

      const event = new MouseEvent('click', {
        view: window,
        bubbles: true,
        cancelable: true
      });
      document.dispatchEvent(event);
    }

    if(!isIOSDevice){
      new Notification(payload?.notification?.title || 'New Message', {
          body: payload?.notification?.body || 'You have a new message',
      });
    }
    // This will show desktop notification based on desktop notification settings
    if(soundNotifications[2]) {
      toast.custom((T) => <ToastNotification T={T} new_message={payload?.notification} />, {
        id: payload?.messageId
      });
    }
  }

  useEffect(() => {
    if (messaging) {
      onMessage(messaging, (payload) => {
        handleNewMessage(payload, true, false);
      });
    } 

    if(soundNotifications[1]){
      navigator.serviceWorker.addEventListener('message', (event) => {
        if (event.data && event.data.type === 'NEW_MESSAGE') {
          handleNewMessage(event.data.payload, true, false);
        }
      });
    }
  }, [messaging, soundNotifications[1], soundNotifications[2]]);

  const [EDIT_CONTACT_META] = useMutation(UPDATE_CONTACT_META, {
    onCompleted(data) {
    },
  });

  useEffect(() => {
    if (!isMobileApp && messaging) {
      const registerAndRetrieveToken = async () => {
        try {
          const messaging = getMessaging();
          // Register the service worker and get the token
          const currentToken = await getToken(messaging, { vapidKey: `${environment.REACT_APP_FCM_VAPID_KEY}` }) || StorageService.getFcmToken();

          if (currentToken) {
            StorageService.setFcmToken(currentToken);
            // Update fcm token to contact_meta
            EDIT_CONTACT_META({
              variables: {
                contact_id: Number(StorageService.getUserId()),
                meta_data: { fcm_token: currentToken }
              }
            });
          } else {
            console.log('No registration token available. Request permission to generate one.');
          }
        } catch (err) {
          console.log('An error occurred while retrieving token. ', {err});
        }
      };
  
      // Call the async function
      registerAndRetrieveToken();
    }
  }, [isMobileApp, messaging]);

  useEffect(() => {
    if (isMobileApp) {
      console.log('Client device isMobileApp = ' + isMobileApp);
      PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== 'granted') {
          PushNotifications.requestPermissions().then((res) => {
            if (res.receive === 'denied') {
              showToast('Push Notification permission denied');
            }
            else {
              showToast('Push Notification permission granted');
              register();
            }
          });
        }
        else {
          register();
        }
      });
    }
  }, []);

  const register = () => {
    console.log('Initializing HomePage');

    // // Register with Apple / Google to receive push via APNS/FCM
    PushNotifications.register();

    // On success, we should be able to receive notifications
    PushNotifications.addListener('registration',
      (token: Token) => {
        showToast('Push registration success sdf' + token?.value);
        console.log('Push registration success sdf' + token?.value);
        StorageService.setFcmToken(token?.value);
        // Update fcm token to contact_meta
        EDIT_CONTACT_META({
          variables: {
            contact_id: Number(StorageService.getUserId()),
            meta_data: { fcm_token: token?.value }
          }
        });
      }
    );

    // Some issue with our setup and push will not work
    PushNotifications.addListener('registrationError',
      (error: any) => {
        console.log('Error on registration: ' + JSON.stringify(error));
      }
    );

    // Show us the notification payload if the app is open on our device
    PushNotifications.addListener('pushNotificationReceived',
      (notification: PushNotificationSchema) => {
        setnotifications(notifications => [...notifications, { id: notification.id, title: notification.title, body: notification.body, type: 'foreground' }])
      }
    );

    // Method called when tapping on a notification
    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        setnotifications(notifications => [...notifications, { id: notification.notification.data.id, title: notification.notification.data.title, body: notification.notification.data.body, type: 'action' }])
      }
    );
  }

  const showToast = async (msg: string) => {
    console.log(msg);
  }

  return (
    <Toaster
      position="top-right"
      reverseOrder={false}
      toastOptions={{
        duration: 1000 * 5
      }}
    />
  );
}