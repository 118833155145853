import { createSlice } from "@reduxjs/toolkit";
import { Dispatch } from "redux";
import { StorageService } from "../storage";

const role: string = StorageService.getUserRole() || 'admin';


const slice = createSlice({
  name: "auth",
  initialState: {
    showResetPasswordNotification: false as boolean,
    userRole: role as string,
  },
  reducers: {
    setShowResetPasswordNotification: (state) => {
      state.showResetPasswordNotification =
        !state.showResetPasswordNotification;
    },
    setUserRole: (state, action) => {
      state.userRole = action.payload
    }
  },
});

export default slice.reducer;

// actions
const { setShowResetPasswordNotification, setUserRole } = slice.actions;

export const changeShowResetPasswordNotification =
  (): any =>
  (dispatch: Dispatch): void => {
    dispatch(setShowResetPasswordNotification());
  };

// this will be only used after login directly
export const updateUserRole =
  (role: string): any =>
  (dispatch: Dispatch): void => {
    dispatch(setUserRole(role))
  };
