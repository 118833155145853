const AR_TRANSLATIONS = {
  AUTH : {
    ACCESS_DENIED: "تم منع وصولك لهذة الصفحة!",
    NOT_ALLOWED: "ليس لديك إذن للوصول إلى هذه الصفحة.",
  },
  NAVBAR_TOOLTIP: {
    REPORTS: "التقارير",
    MEDIA_LIBRARY: "مكتبة الوسائط",
    SEARCH: "بحث",
    CHAT: "محادثة",
    CONTACTS: "جهات الاتصال",
    APPS: "تطبيقات",
    ECOMMERCE_APPS: "تطبيقات التجارة الإلكترونية",
    SETTINGS: "إعدادات",
    NOTIFICATIONS: "إشعارات",
    PROFILE: "حساب تعريفي",
    COPILOT: "مساعد الطيار",
  },
  CHAT: {
    PAYLOAD: "الحمولة",
    TITLE: "عنوان",
    UPLOAD: "رفع ",
    LISTS_ADD_BODY: "1024 حرف كحد أقصى",
    LISTS_ADD_FOOTER: "60 حرف كحد أقصى",
    LISTS_ADD_BTN_TITLE: "20 حرف كحد أقصى",
    LISTS_ADD_BTN_PAYLOAD: "256 حرف كحد أقصى",
    LISTS_MENU_TITLE: "20 حرف كحد أقصى",
    LISTS_SECTION_TITLE: "24 حرف كحد أقصى",
    MAX_200_CHAR: "200 حرق كحد أقصى",
    ADD_SECTION: "إضافة قسم",
    SECTION: "سيشن",
    MENU_TITLE: "عنوان القائمة",
    MENU_LIST_ITEMS: "عناصر قائمة القائمة",
    HEADER_TEXT: "نص الرأس",
    FOOTER: "نص التذييل",
    NO_PREVIEW_AVAILABLE: "لا تتوفر معاينة",
    SHARE_IMAGE: "مشاركة الصورة",
    SHARE_VIDEO: "شارك الفيديو",
    SHARE_AUDIO: "مشاركة الصوت",
    SHARE_FILE: "مشاركة الملفات",
    MESSAGE: "رسالة",
    CALL: "يتصل",
    COPY: "نسخ",
    FAST_REPLY: "رد سريع",
    SEND: "إرسال",
    ADD_TO_CART: "أضف إلى السلة",
    SEND_TO_CHAT: "أرسل للمحادثة",
    GALLERY: "صالة عرض",
    SAR: "ريال سعودي",
    ATTACHEMENTS: "المرفقات",
    TAGS: "الوسوم",
    ASSIGNED:"تم التعيين",
    CLOSED:"مغلقة",
    NOTE: "ملحوظة",
    ID: "بطاقة تعريف",
    VIEW_AS: "",
    INBOX: "صندوق الوارد",
    UNANSWERED: "غير مقروءة",
    CHATBOT: "روبوت المحادثة",
    ASSIGNED_TO_ME: "المحادثات المُعينة لي",
    VIEW_MYSELF: "العرض كـ نفسي",
    MENTIONS: "الإشارات",
    UNASSIGNED: "غير معينة",
    ARCHIVED: "مؤرشف",
    ASSIGN_TO_ME: "تعيين لى",
    SALES_TEAM: "فريق المبيعات",
    MARKETING_TEAM: "فريق التسويق",
    NEW_CHAT: "محادثة جديدة",
    NEW_CHAT_TITLE: "محادثة جديدة",
    SOMETHING_WENT_WRONG: "هناك خطأ ما",
    PINNED_CHATS: "المحادثات المثبتة",
    ALL_CHATS: "جميع المحادثات",
    START_NEW_CONVERSATION: "ابدأ محادثة جديدة",
    CREATE_CONTACT: "إنشاء جهة اتصال",
    TO: "لـ",
    SEARCH_FOR_NAME_NUMBER: "ابحث عن اسم أو رقم",
    VIA_WHATSAPP: "عبر الواتساب",
    SELECT_CHANNEL: "اختر قناة",
    PIN_CHAT: "تثبيت المحادثة",
    UNPIN_CHAT: "ازالة التثبيت",
    MARK_AS_UNREAD: "وضع علامة كغير مقروءة",
    MARK_AS_READ: "وضع علامة كمقروءة",
    EXPORT_CHAT: "تصدير المحادثة",
    CLOSE_CHAT: "إغلاق المحادثة",
    OPEN_CHAT: "فتح المحادثة",
    PREVIEW_CHAT: "معاينة المحادثة",
    ASSIGN_CHAT: "تعيين موظف",
    READ_CHAT: "تمييز المحادثة كمقروء",
    UNREAD_CHAT: "تمييز المحادثة كغير مقروء",
    START_CONVERSATION: "بدء محادثة",
    MESSAGE_INFO: "معلومات الرسالة:",
    SENT: "مرسل",
    DELIVERED: "تم التوصيل",
    READ: "يقرأ",
    FAILED: "فشل",
    ATTACHMENTS: "المرفقات",
    PRIVATE_NOTE: "ملاحظة خاصة",
    SELECT_AGENT_OR_TEAM_TO_VIEW_CHATS: "اختر وكيلًا أو فريقًا لعرض المحادثات",
    NO_MSG_SELECTED: "لم تحدد محادثة بعد",
    NO_MSG_SELECTED_SUBTITLE: "اختر محادثة من قائمة المحادثات لعرضها",
    NO_PINNED_MESSAGES: "لا توجد رسائل مثبتة",
    NO_PINNED_MESSAGES_SUBTITLE: "سيتم عرض جميع رسائلك المثبتة هنا",
    NO_MESSAGES_AVAILABLE: "لا توجد رسائل متاحة",
    NO_MESSAGES_AVAILABLE_SUBTITLE: "سيتم عرض جميع رسائلك هنا",
    PLEASE_START_CONVERSATION: "يرجى بدأ المحادثة لترى رسائل هنا",
    WHATSAPP_NAME: "اسم الواتساب",
    PROFILE_NAME: "اسم الملف الشخصي",
    PHONE_NUMBER: "رقم الهاتف",
    EMAIL_ADDRESS: "عنوان الايميل",
    CREATE_SEARCH_PLACEHOLDER: "اصنع او ابحث",
    IMAGES: "صور",
    VIDEOS: "فيديوهات",
    DOCUMENTS: "ملفات",
    CHAT_CLOSED: "تم غلق المحادثة",
    CHAT_REOPENED: "تم إعادة فتح المحادثة",
    CHAT_CLOSED_BODY: "تم إغلاق المحادثة بواسطة <0>'{{email}}'</0>. ",
    RESTART: "إعادة الفتح",
    MESSAGE_BOX_PLACEHOLDER: 'أدخل الرسالة أو اكتب "/" لاستخدام الرد المحفوظ',
    QUICK_REPLIES_PLACEHOLDER: "ابحث عن الردود السريعة...",
    TEMPLATES_PLACEHOLDER: "ابحث عن القوالب...",
    ENTER_TEMPLATE_VARIABLES: "أدخل متغيرات القالب",
    PREVIEW: "مراجعة: ",
    CLICK_TO_SEND_TEMPLATE: "نافذة محادثة العميل مغلقة",
    NOT_ALLOWED_SEND_MESSAGE: "يمكنك الرد على رسالة واتساب خلال 24 ساعة من استلامها، لكن ليس لديك صلاحية إرسال رسائل.",
    _24_HOURS_WINDOW_TITLE: "انتهت صلاحية نافذة خدمة العملاء",
    _24_HOURS_WINDOW_BODY:
      "يمكنك الرد على رسالة واتساب خلال 24 ساعة من استلامها. بعد مرور 24 ساعة، يمكن استخدام القوالب المسبقة الاعتماد فقط لبدء المحادثة.  <0>اطلع على المزيد</0>",
    TIME_AGO_FROM_WHATSAPP: "{{time}} عن طريق واتساب",
    SENT_BY_AGO: "تم الإرسال بواسطة {{platform_id}} {{time}}",
    PRIVATE_NOTE_BY:
      "تمت إضافة ملاحظة خاصة بواسطة {{platform_id}} · {{time}} · مرئي للوكلاء فقط",
    TEAMS: "فريق العمل",
    SYSTEM: "النظام",
    SEARCH_CHATS: "بحث المحادثات",
    UNAVAILABLE_MESSAGE: "الرسالة غير متاحة",
    TEXT_MESSAGE: "رسالة نصية",
    IMAGE_MESSAGE: "رسالة الصورة",
    VIDEO_MESSAGE: "رسالة فيديو",
    AUDIO_MESSAGE: "رسالة صوتية",
    VOICE_MESSAGE: "رسالة صوتية",
    STICKER_MESSAGE: "رسالة الملصق",
    DOCUMENT_MESSAGE: "رسالة الوثيقة",
    LOCATION_MESSAGE: "رسالة الموقع",
    INTERACTIVE_MESSAGE: "رسالة تفاعلية",
    TEMPLATE_MESSAGE: "رسالة القالب",
    CONTACTS_MESSAGE: "رسالة جهات الاتصال",
    MENTIONS_MESSAGE: "يذكر رسالة",
    SYSTEM_MESSAGE: "رسالة النظام",
    UNKNOWN_MESSAGE: "رسالة غير معروفة",
    ASSIGNED_TO_AGENT: "تم تعيين المحادثة لـ {{email}}",
    CHAT_PINNED: "تم تثبيت المحادثة",
    CHAT_UNPINNED: "تم الغاء تثبيت المحادثة",
    QUICK_REPLIES: "ردود سريعة",
    TEMPLATES: "قوالب",
    CHAT_EXPORTED:
      "تم تقديم طلب تصدير المحادثة. سوف تستقبل ايميل قريبا يحتوى لينك التحميل.",
    CHAT_QUEUE: "المحادثات",
    PHOTO: "صورة",
    VIDEO: "فيديو",
    INTERACTIVE: "تفاعلى",
    TEMPLATE: "قالب",
    AUDIO: "مقطع صوتى",
    VOICE: "رسالة صوتية",
    YOU: "أنت",
    CONTACT: "جهة اتصال: {{name}}",
    MARK_AS_RESOLVED: "تمييز ك تم حله",
    MARK_AS_UNRESOLVED: "تمييز ك لم يتم حله",
    LAST_SEEN: "اخر نشاط: {{time}}",
    LONG_TIME_AGO: "منذ وقت طويل",
    SEARCH_CONTACTS: "البحث في جهات الاتصال",
    REPLY: "رد",
    MESSAGE_INFO_BUTTON: "معلومات الرسالة",
    ADD_QUICK_REPLY: "إضافة رد سريع",
    COPY_MESSAGE: "نسخ الرسالة",
    LOCATION: "عنوان: {{location}}",
    CHAT_MARKED_AS_READ: "تم تمييز الشات كمقروء",
    CHAT_MARKED_AS_UNREAD: "تم تمييز الشات كغير مقروء",
    NO_CHANNELS_AVAILABLE: "لا يوجد قنوات مُتاحة",
    NO_CHANNELS_CONNECTED:
      "لا يوجد قنوات متصلة, يرجى إتاحة قناة على الأقل لإرسال/إستقبال الرسائل",
    SEARCH_AGENTS_TEAMS: "البحث عن فرق أو عملاء",
    MESSAGE_STATUS_DESCRIPTION: {
      ERROR: "حدث خطأ: الرسالة لم يتم ارسالها.",
      RETRY1: "فشل الإرسال: إعادة المحاولة 1",
      RETRY2: "فشل الإرسال: إعادة المحاولة 2",
    },
    MICROPHONE_DENIED_MESSAGE: "برجاء السماح للمتصفح من استخدام الميكروفون لتسجيل الرسائل الصوتية.",
    BROWSER_NOT_SUPPORT_MEDIA_RESOURCES: "هذا اللمتصفح لايدعم استخدام الوسائط الصوتية والمرئية."
  },
  TEMPLATE: {
    CREATE_TEMPLATE: "إنشاء قالب",
    SUBMIT_TEMPLATE: "تقديم قالب",
    SYNC_TEMPLATE: "مزامنة القالب",
    TEMPLATE_ID_ERR: "تم تقديم هذا القالب بالفعل بنفس اللغة",
    TEMPLATE_SUB_TITLE: "قم بإدارة قوالب واتساب من هنا",
    TEMPLATES: "القوالب",
    OPT_PLACEHOLDER: "كلمة المرور لمرة واحدة (OTP)",
    ZERO_TEMPLATES: "لا يوجد أي نماذج",
    ZERO_DELETED_TEMPLATES: "لا يوجد نماذج محذوفة لعرضها",
    ZERO_TEMPLATES_MATCH: "لا يوجد نماذج تطابق المعلومات التي أدخلتها",
    NO_CHANNELS_CONNECTED: "يرجى الاتصال بقناة WhatsApp لإرسال رسالة، تقديم نموذج، أو لتنفيذ بث.",
    NO_CHANNELS_AVAILABLE: "لا يوجد قنوات متاحة",
    ONLY_PDF: "يسمح فقط بملفات pdf",
    ONLY_SUPPORTED_IMG: "يُسمح فقط بالصور JPG وPNG",
    ONLY_SUPPORTED_VIDEOS: "يُسمح فقط بمقاطع فيديو MP4",
    VIEW_TEMPLATE: "عرض القالب",
    BUTTON_TITLE: "عنوان الزر",
    COPY_CODE: "نسخ الكود",
    COPY_CODE_BUTTON_TITLE: "نسخ الكود",
    AUTOFILL: "الملء التلقائي",
    PHONE_NUMBER_ERR_MSG: "يرجى إدخال رقم هاتف صالح!",
    COPY_CODE_DESCRIBTION:
      "يقوم زر نسخ الرمز بنسخ كلمة المرور أو الرمز لمرة واحدة. يمكن للمستخدم بعد ذلك التبديل يدويًا إلى تطبيقك ولصق كلمة المرور أو الرمز في واجهة تطبيقك.",
    AUTOFILL_DESCRIBTION: `هذا مدعوم فقط على نظام التشغيل Android، ويتطلب إجراء تغييرات على رمز تطبيقك من أجل إجراء "المصافحة" وتجزئة مفتاح توقيع تطبيقك.`,
    AUTOFILL_BUTTON_TITLE: "الملء التلقائي",
    PACKAGE_NAME: "com.example.app",
    SIGNATURE_HASH: "على سبيل المثال: h83m0^",
    ADD_LANGUAGE_TO_TEMPLATE: "إضافة لغة جديدة",
    CREATE_TEMPLATE_DESCRIPTION:
      "قم بإرسال نماذج الرسائل الخاصة بك للموافقة عليها إلى WhatsApp هنا.",
    VIEW_TEMPLATE_DESCRIPTION: "عرض قوالب WhatsApp الخاصة بك هنا.",
    TEMPLATE_ID: "معرف القالب",
    CATEGORY: "فئة",
    SELECT_CATEGORY: "اختر الفئة",
    DESCRIPTION: "وصف",
    DESCRIPTION_PLACEHOLDER: "وصف (اختياري)",
    LANGUAGE: "اللغة",
    SELECT_LANGUAGE: "اختر لغة",
    MEDIA: "وسائط",
    NONE: "بدون",
    IMAGE: "صورة",
    VIDEO: "فيديو",
    DOCS: "المستندات",
    BODY_TEXT: "نص الرسالة",
    INSERT_VAR: "إدراج متغير {{#}}",
    INSERT_OPT_OUT_LINK: "أدخل رابط إلغاء الاشتراك",
    FOOTER: "تذييل (اختياري)",
    FORMAT_TEXT_OPTION: "هل تريد تنسيق النص؟",
    FORMATTING_WHATSAPP_MESSAGES: "انظر تنسيق رسائل واتساب ",
    HERE: "هنا",
    BUTTONS: "أزرار",
    CTA_BUTTONS: "أزرار CTA",
    QUICK_REPLIES: "ردود سريعة",
    NEXT: "التالي",
    SUBMIT: "يُقدِّم",
    SAVING: "إنقاذ...",
    SAVED: "أنقذ",
    ERROR: "خطأ",
    WHATSAPP_ALERT_MESSAGE:
      "يجب إرسال رسائل قالب WhatsApp والموافقة عليها قبل إرسالها إلى جهات الاتصال الخاصة بك.",
    CREATE_MOBILE_MESSAGE:
      "يعد تقديم النماذج أمرًا معقدًا من شاشة الهاتف المحمول. ",
    SUBMITTED: "مُقَدَّم",
    SELECT_STATUS: "حدد الحالة",
    CLEAR_FILTER: "مسح الترشيحات",
    SEARH_TEMPLATES: "بحث",
    STATUS: "حالة",
    SHOW_CHAT: "عرض في المحادثة",
    ACTION: "إجراءات",
    ADD_BUTTON: "إضافة زر",
    BUTTON: "زر",
    TYPE_OF_ACTION: "نوع العمل",
    PHONE_NUMBER: "رقم التليفون",
    STATIC_LINK: "رابط ثابت",
    ENTER_URL_HERE: "أدخل عنوان URL هنا",
    ENTER_PHONE_HERE: "أدخل رقم الهاتف",
    BUTTON_DISPLAY_TEXT: "نص عرض الزر",
    APPROVED: "موافقة",
    REJECTED: "مرفوض",
    DELETED: "تم الحذف",
    ALLOW_CATEGORY_CHANGE: "السماح بتغيير الفئة",
    SECURITY_DISCLAIMER: "إخلاء المسؤولية الأمنية",
    EXPIRATION_WARNING: "تحذير انتهاء الصلاحية",
    DELETE_DIALOG_HEADER: "حذف القالب",
    DELETE_DIALOG_SUB_HEADER: "هل أنت متأكد أنك تريد حذف هذا القالب؟ ",
    CONFIRM_DELETE: "تأكيد الحذف",
    ALL_TEMPLATES: "جميع القوالب",
    DELETED_TEMPLATES: "القوالب المحذوفة",
    FILTERS: {
      STATUS: {
        PLACEHOLDER: "حدد الحالة",
        OPTIONS: {
          APPROVED: "تمت الموافقة عليه",
          SUBMITTED: "تم تقديمه",
          REJECTED: "مرفوض",
          DELETED: "تم الحذف",
          PAUSED: "متوقف مؤقتًا",
        },
      },
      LANGUAGE: {
        PLACEHOLDER: "اختر لغة",
        OPTIONS: {
          AR: "AR",
          EN: "إن",
        },
      },
      CATEGORY: {
        PLACEHOLDER: "اختر تصنيف",
        OPTIONS: {
          MARKETING: "تسويق",
          OTP: "كلمة السر لمرة واحدة",
          TRANSACTIONAL: "المعاملات",
        },
      },
    },
    ACTIONS_BTN: {
      VIEW: "عرض",
      PREVIEW: "معاينة",
      ADD_LANGUAGE: "إضافة لغة",
      DUPLICATE: "نسخ النموذج",
      DELETE: "حذف القالب",
    },
    SELECT_CATEGORY_OPTIONS: {
      AUTHENTICATION: "مصادقة",
      MARKETING: "تسويق",
      UTILITY: "الدعم",
    },
    VARIABLES_PREVIEW: {
      UPLOAD_SAMPLE_IMAGE: "تحميل صورة عينة",
      UPLOAD_SAMPLE_VIDEO: "تحميل عينة الفيديو",
      UPLOAD_SAMPLE_DOCUMENT: "تحميل نموذج documnet",
      UPLOAD_IMAGE: "تحميل الصور",
      UPLOAD_VIDEO: "رفع فيديو",
      UPLOAD_DOCUMENT: "تحميل الوثيقة",
      ENTER_VARIABLE: "أدخل المتغير",
      ADVANCED_OPTIONS: "الخيارات المتقدمة (الأزرار)",
      BACK: "خلف",
      SUBMIT: "تقديم للمراجعة",
    },
    LANGUAGE_WARNING: "اللغة المحددة لا تتطابق مع المحتوى الذي أدخلته. ",
  },
  SETTINGS: {
    SETTINGS: "الإعدادات",
    SUBTITLE: "قم بإدارة إعدادات ملفك الشخصي ومساحة العمل هنا",
    WORKSPACE_SETTINGS: "إعدادات مساحة العمل",
    CUSTOM_ATTRIBUTES: "الوسوم المخصصه",
    WORKSPACE_PROFILE: "بيانات مساحة العمل",
    USERS: "الموظفين",
    TEAMS: "اقسام الموظفين",
    CONTACTS: "جهات الاتصال",
    DEVTOOLS: "أدوات التطوير",
    CHANNELS: "القنوات",
    WEBHOOKS_APIS: "Webhooks",
    MESSAGE_LOGS: "سجلات الرسائل",
    ACCOUNT_SETTINGS: "إعدادت الحساب",
    PROFILE: "الملف الشخصي",
    NOTIFICATIONS: "الإشعارات",
    LOGOUT: "تسجيل خروج",
    LOGGING_OUT: "تسجيل الخروج...",
    ORDER_NOTIFICATION_SENT: "تم إرسال إشعار الطلب",
    ABANDONED_CART: "السلات المتروكة",
    IN_TOTAL: "في المجموع",
    ANALYTICS_TITLE: "التحليلات",
    ANALYTICS_SUBTITLE: "قم بإدارة إعدادات ملفك الشخصي ومساحة العمل هنا",
    COPILOT_INITIAL_MSG:
      "مرحبًا، أنا كوبيلوت، مساعدك الشخصي. يمكنني مساعدتك في أي شيء تحتاجه. فقط اسألني!",
    SWITCH_ORGANIZATION: "تغيير المنظمة",
    SOMETHING_WENT_WRONG: "هناك خطأ ما",
    DEV_TOOLS: {
      CREATE_API_KEY: "إنشاء مفتاح API",
      API_NAME: "اسم واجهة برمجة التطبيقات",
      ENTER_API_NAME: "أدخل اسم واجهة برمجة التطبيقات",
      ACCESS_MODE: "وضع وصول",
      RESET: "إعادة تعيين",
      SELECT_MODE: "حدد الوضع",
      READ: "يقرأ",
      READ_WRITE: "قراءة و كتابة",
      CANCEL: "إلغاء",
      SAVE: "حفظ",
      SAVING: "إنقاذ...",
      SAVED: "أنقذ",
      ERROR: "خطأ",
      VIEW_DETAILS: "عرض التفاصيل",
      CONNECTED: "متصل",
      CONNECTING: "جاري الاتصال...",
      AVAILABLE_CHANNELS: "القنوات المتاحة",
      CONNECT: "يتصل",
      CONNECT_VIA: "توصيل عن طريق",
      CONNECTED_VIA: "متصل عن طريق",
      CONTINUE_WITH_META: "المتابعة مع ميتا",
      CONNECT_VIA_360: "توصيل عن طريق 360",
      CONNECT_VIA_META: "توصيل عن طريق ميتا",
      CONNECT_WHATSAPP_TITLE: "اتصل بالواتساب عن طريق ميتا",
      CONNECT_WHATSAPP_SUBTITLE: "وصل الواتساب بصندوق الرسائل الخاص بك",
      CONNECT_WHATSAPP_CLOUD: "اتصل بالواتساب السحابى",
      ACCESS_TOKEN: "توكن الوصول*",
      WHATSAPP_BUSINESS_ID: "رقم حساب الواتساب بزنس",
      COPY_FIELDS_TO_WHATSAPP: "انسخ هذه المدخلات إلى الواتساب",
      CALLBACK_URL: "رابط نداء العودة",
      VERIFY_TOKEN: "تأكيد التوكن",
      WAITING_FOR_VERIFICATION: "بانتظار التاكيد",
      VERIFICATION_SUCCESSFUL: "تم التأكيد بنجاح",
      CONNECTED_VIA_META: "متصل عبر ميتا",
      META_VIEW_DETAILS: "نظرًا لكون القناة متصلة مباشرةً عبر ميتا، يجب إجراء أي تغييرات على ملف العمل أو التفاصيل من خلال مدير واتساب ميتا.",
      VISIT_BUSINESS_MANAGER: "قم بزيارة مدير الأعمال الخاص بك",
      SUCCESSFULLY_CONNECTED_WHATSAPP:
        "لقد قمت بالاتصال بالواتساب السحابى بنجاح",
      NOW_ACTIVE: "الان سوف تبدء استقبال رسائل من جهات الاتصال الخاصة بك",
      ADD_WHATSAPP_CHANNEL: "اضافة قناة واتساب جديدة",
      CONNECT_WHATSAPP_INBOX: "ربط الواتساب بصندوق الوارد الخاص بك",
      ADD_WHATSAPP_ACCOUNT: "إضافة حساب واتساب جديد",
      ADD_WHATSAPP_360: "إضافة حساب WhatsApp جديد (مع 360Dialog كموفر)",
      SIGNUP_NEW_ACCOUNT: "سجل لحساب جديد",
      DIALOG_INFO_360:
        "سيُطلب منك التسجيل للحصول على حساب من خلال 360dialog في نافذة منبثقة.",
      DATE: "تاريخ",
      FROM: "من (المرسل)",
      TO: "إلى (المتلقي)",
      SRC: "المصدر",
      MESSAGE_SOURCE: {
        CHANNEL_CONTACT: "الاتصال بالقناة",
        AGENT: "وكيل",
        BOT: "بوت",
        EXTERNAL: "خارجي",
        BUSINESS: "عمل",
        HOST: "يستضيف",
      },
      CREATE_WEBHOOK: "إنشاء خطاف ويب",
      EDIT_WEBHOOK: "تحرير Webhooks",
      FOR_RULES: "للقواعد",
      NAME: "اسم",
      ENTER_WEBHOOK_NAME: "أدخل اسم Webhooks",
      DESCRIPTION: "وصف",
      DESCRIPTION_WEBHOOK: "وصف لWebhooks الخاص بك",
      EVENT: "حدث",
      ACTIONS_TRIGGER_INFO_WEBHOOK:
        "هذه هي الأحداث في الوقت الفعلي التي ستؤدي إلى الاتصال بعنوان URL للخطاف على الويب الخاص بك",
      SELECT_EVENT: "حدد الحدث",
      INCOMING_MESSAGE: "رسالة واردة",
      OUTGOING_MESSAGE: "رسالة صادرة",
      CHAT_ASSIGNED: "تم تعيين المحادثة",
      CHAT_CLOSED: "المحادثة مغلقة",
      CHAT_MENTIONED: "المحادثة المذكورة",
      CONTACT_CREATED: "تم إنشاء جهة الاتصال",
      TEMPLATE_APPROVED: "تمت الموافقة على القالب",
      BROADCAST_COMPLETED: "اكتمل البث",
      BILLING_ALERT: "تنبيه الفواتير",
      CONTACT: "اتصال",
      POLICY: "سياسة",
      WEBHOOK_POLICY_INFO: "السياسة المستخدمة أثناء تشغيل Webhooks",
      SIMPLE: "بسيط",
      SELECT_POLICY: "حدد السياسة",
      STREAMING_MODE: "وضع الدفق",
      SELF_HEALING: "الشفاء الذاتي",
      SAMPLING_30: "أخذ العينات - 30%",
      BATCH_MODE: "دفعة واسطة",
      BATCH_HIGH_FREQUENCY: "دفعة - عالية التردد",
      ENDPOINT: "نقطة النهاية",
      ENDPOINT_INFO:
        "سيتم إرسال طلب HTTP POST إلى عنوان URL هذا للحدث الذي قمت بإعداده",
      SECURITY_TOKEN: "رمز الأمان",
      SECURITY_TOKEN_INFO:
        "رمز التحدي الأمني ​​للتعرف على Webhooks الخاص بك. ",
      CREATED_BY: "منشأ من قبل",
      CREATED_DATE: "تاريخ الإنشاء",
      URL: "الرابط",
      API_KEY: "مفتاح API",
      WEBHOOKS: "Webhooks",
      WEBHOOK_INFO:
        "استمع للأحداث على حسابك حتى تتمكن عمليات التكامل من إثارة التفاعلات تلقائيًا",
      API_INFO: "قم بإنشاء مفاتيح API للوصول إلى حسابك برمجياً",
      CREATE_API: "إنشاء واجهة برمجة التطبيقات",
      EDIT: "تحرير",
      DELETE: "حذف",
      STATUS_LOG: "سجل الحالة",
      TOTAL: "المجموع",
      SUCCESS: "نجاح",
      FAILED: "فشل",
      REQUEST_ID: "طلب معرف",
      RETRY_COUNT: "إعادة المحاولة",
      STATUS_CODE: "رمز الحالة",
      REQUEST_BODY: "هيئة الاستجابة",
      TIME_STAMP: "الطابع الزمني",
      NO_STATUS: "لا توجد بيانات الحالة لعرضها",
      REVOKE: "إلغاء",
      CLICK_TO_COPY: "انقر للنسخ",
      COPIED: "منقول ✅",
      CONNECT_WORKSPACE: "قم بتوصيل مساحة العمل الخاصة بك بقنوات الطرف الثالث.",
      PHONE: "هاتف",
      PHONE_DESCRIPTION: "التواصل مع المستخدمين من خلال الهاتف",
      WHATSAPP: "واتساب",
      WHATSAPP_DESCRIPTION: "تواصل مع المستخدمين من خلال WhatsApp Business API",
      FB: "فيسبوك",
      FB_DESCRIPTION: "التواصل مع المستخدمين من خلال قناة الفيسبوك عبر المنصة",
      IG: "انستجرام",
      IG_DESCRIPTION:
        "قم بدمج متجر Instagram الخاص بك دون التبديل إلى تطبيق الهاتف المحمول",
      SMS: "رسالة قصيرة",
      SMS_DESCRIPION: "دمج إمكانيات الرسائل القصيرة في النظام الأساسي الخاص بك",
      TELEGRAM: "تيليجرام",
      TELEGRAM_DESCRIPION:
        "قم بتوصيل Telegram كقناة والرد على المستخدمين بحرية",
      RCS: "RCS",
      RCS_DESCRIPTION:
        "دمج RCS كقناة واستهداف العملاء على نطاق الرسائل القصيرة",
      CHANNEL_UNAVAILABLE: "هذه القناه قيد التطوير الآن",
      CHANNEL_UNAVAILABLE_INFO: ".ترقب تفعيل هذة القناه خلال الأسابيع القادمة",
      MESSAGES_LOGS: "سجلات الرسائل",
      MESSAGES_LOGS_SERVICE: "عرض وتنزيل سجلات رسائل العملاء",
      ALL_CHANNELS: "جميع القنوات",
      EXPORT_RESULTS: "نتائج التصدير",
      MESSAGE_TYPE: "نوع الرسالة",
      MESSAGE_TYPE_OPTIONS: {
        in: "واردة",
        out: "صادرة",
      },
      CHANNEL_OPTIONS: {
        facebook: "فيسبوك",
        whatsapp: "واتساب",
        instagram: "انستجرام",
      },
      STATUS_OPTIONS: {
        ACCEPTED: "قبلت",
        DELETED: "تم الحذف",
        DELIVERED: "تم التوصيل",
        FAILED: "فشل",
        RECEIVED: "تلقى",
        SEEN: "مرئي",
        SENT: "مرسل",
      },
      CHANNEL: "قناة",
      DATE_OPTIONS: {
        LAST_7_DAYS: "اخر 7 ايام",
        LAST_30_DAYS: "آخر 30 يومًا",
        LAST_3_MONTHS: "آخر 3 أشهر",
        LAST_6_MONTHS: "آخر 6 أشهر",
        LAST_YEAR: "العام الماضي",
        CUSTOM_DATE_RANGE: " تخصيص تاريخ محدد",
      },
      SELECT_BY_DATE: "اختر حسب التاريخ",
      WEBHOOKS_API: "Webhooks",
      ACCESS_KEYS: "مفتاح الربط",
      MANAGE_WEBHOOKS_API: "إدارة Webhooks لمساحة العمل الخاصة بك",
      NO_WEBHOOKS: "ليس لديك أي خطافات على الويب حتى الآن",
      CREATE_FIRST_WEBHOOK: "أنشئ Webhooks الأول الخاص بك",
      NO_API_KEYS: "ليس لديك مفاتيح API حتى الآن",
      CREATE_FIRST_API: "قم بإنشاء الرابط الأول",
      WEBHOOKS_DELETE_TITLE: "هل أنت متأكد من تعطيل <0>{{name}}</0>؟ ",
      WEBHOOKS_DELETE_BODY: "⚠️ يرجى العلم بأن أي API integration يستخدم <0>{{name}}</0> سيفقد قدرته على الإستخدام",
      REVOKED_SUCCESSFULLY: "تم تعطيل الرابط بنجاح",
      CREATE_WEBHOOK_FORM: {
        ERRORS: {
          NAME: "مطلوب اسم",
          NAME_LENGTH: "يجب أن لا يقل الاسم عن 50 حرفًا",
          DESCRIPTION: "الوصف مطلوب",
          DESCRIPTION_LENGTH: "يجب أن يكون الوصف 1024 حرفًا على الأكثر",
          EVENT: "الحدث مطلوب",
          POLICY: "السياسة مطلوبة",
          ENDPOINT: "نقطة النهاية مطلوبة",
          ENDPOINT_VALID: "يجب أن تكون نقطة النهاية عنوان URL صالحًا",
          SECURE_TOKEN: "مطلوب رمز آمن",
          SECURE_TOKEN_VALID:
            "يجب أن يتطابق الرمز المميز الآمن مع ما يلي: '/^[^a-zA-Z0-9 -_] $/g'",
        },
      },
      CREATE_API_KEY_FORM: {
        ERRORS: {
          NAME: "اسم API مطلوب",
          NAME_LENGTH:
            "يجب أن يكون اسم واجهة برمجة التطبيقات (API) 50 حرفًا على الأكثر",
          ACCESS_MODE: "وضع الوصول مطلوب",
        },
      },
    },
    ACCOUNT: {
      NOTIFICATIONS: "الإشعارات",
      NOTIFICATIONS_PREFRENCES: "ادارة تفضيلات الإشعارات",
      NEW_MESSAGE_NOTIFICATION: "إشعارات الرسائل الجديدة",
      INSIDE_CHAT_SCREEN: "داخل صفحة الشات",
      INSIDE_CHAT_SCREEN_SUBTITLE: "استقبال اشعارات الرسائل الجديدة",
      INSIDE_CHAT_SCREEN_FILTER: {
        CONTEXT: "سياق",
        AGENT: "عميل",
        TEAM: "فريق",
        ALL: "الكل",
      },
      OUTSIDE_CHAT_SCREEN: "خارج صفحة الشات",
      OUTSIDE_CHAT_SCREEN_SUBTITLE: "استقبال اشعارات الرسائل الجديدة",
      OUTSIDE_CHAT_SCREEN_FILTER: {
        ALL: "الكل",
        AGENT: "عميل",
        TEAM: "فريق",
      },
      SOUND_NOTIFICATION: "إشعار الصوت",
      PLAY_SOUND_NOTIFICATION:
        "تشغيل الإشعارات الصوتية لجهات الاتصال المعينة لي أو جهات الاتصال غير المعينة",
      DESKTOP_NOTIFICATIONS: "إعلام سطح المكتب",
      UNBLOCK_DESKTOP_NOTIFICATION_HELP:
        "👆 تم حظر إشعارات سطح المكتب. يرجى تمكينها من خلال النقر على الرمز الموجود على الجانب الأيسر من شريط العناوين > الإشعارات > السماح",
      ENABLE_DESKTOP_NOTIFICATION:
        "تم حظر إشعارات سطح المكتب بواسطة متصفحك. <0>أضغط هنا</0> للسماح باشعارات سطح المكتب",
      SOUND_NOTIFICATION_1:
        "تشغيل الإشعارات الصوتية للمستخدمين المخصصين لى او المستخدمين الغير مخصصين",
      SOUND_NOTIFICATION_2: "تشغيل اﻹشعارات الصوتية للرسائل الجديدة",
      EMAIL_NOTIFICATION: "اشعارات البريد الالكتروني",
      GROUP_NOTIFICATION: "اشعارات الفريق",
      ENABLE_EMAIL_NOTIFICATION:
        "أرسل لي إشعارًا بجهات الاتصال المخصصة لي والإشارات",
      IMPORTANT_NOTIFICATIONS: "إشعارات مهمة حول أمان حسابك.",
      USER_PROFILE: "ملف تعريفي للمستخدم",
      USER_PROFILE_DESCRIPTION:
        "إدارة ملف التعريف الخاص بك وتفاصيل تسجيل الدخول.",
      PROFILE_PHOTO: "صورة الملف الشخصي",
      UPLOAD_IMAGE: "رفع الصورة",
      CONTACT_NAME: "اسم جهة الاتصال",
      PASSWORD: "كلمة المرور",
      UPDATE: "تحديث",
      UPDATING: "جارٍ التحديث...",
      UPDATED: "محدث",
      ERROR: "خطأ",
      CONTACT_EMAIL: "البريد الاكتروني",
      SELECT_LANGUAGE: "اختار اللغة",
      COUNTRY: "دولة",
      PHONE: "رقم التليفون",
      DOB: "تاريخ الميلاد",
      ADDRESS: "عنوان",
      EN: "إنجليزي",
      AR: "عربي",
      HI: "الهندية",
      CHANGE_PASSWORD: "تغيير كلمة المرور",
      CURRENT_PASSWORD: "كلمة السر الحالية",
      NEW_PASSWORD: "كلمة المرور الجديدة",
      NEW_PASSWORD_ERROR: "كلمة المرور الجديدة يجب أن تختلف عن كلمة المرور القديمة",
      NEW_PASSWORD_VALIDATION: "يجب أن تحتوي كلمة المرور الجديدة على أكثر من 8 أحرف، وعلى الأقل 1 حرف كبير، 1 حرف صغير ورقم",
      CURRENT_PASSWORD_ERROR_MESSAGE: "كلمة المرور الحالية غير صحيحة",
      DELETE_ACCOUNT: {
        DELETE_BUTTON: "احذف حسابي",
        DELETE_ACC_TITLE: "حذف الحساب",
        DELETE_ACC_DESC: "هل أنت متأكد من أنك تريد حذف حسابك؟ سيتم نقل جميع محادثاتك بشكل دائم إلى الوكيل المحدد. لا يمكن التراجع عن هذا الإجراء",
        DELETE_ASSIGN_USER: "تعيين جهات الاتصال لمستخدم آخر",
        DELETE_ACC_BUTTON: "حذف الحساب",
        DELETING: "...حذف",
        DELETED: "تم الحذف",
      }
    },
  },
  GREETINGS: {
    TITLE: "رسائل الترحيب وخارج مواعيد العمل",
    DESCRIPTION:
      "قم بتعيين رسائل الترحيب ورسائل خارج الدوام لإرسالها إلى العملاء داخل/خارج ساعات العمل.",
    SET_WORKING_HOURS: "ضبط ساعات العمل الخاصة بك",
    SET_BUSINESS_HOURS: "قم بإعداد ساعات العمل الخاصة بشركتك",
    GREETING_MSG: "رسالة تحية",
    GREETINGS_MSG_ALERT: "سيتم تفعيل رسالة الترحيب فقط للمحادثات التي تكون في حالة غير مخصصة أو لم يتم الرد عليها",
    GREETING_MESSAGE_DESCRIPTION:
      "قم بالترحيب بالعملاء الجدد تلقائيًا عندما يقومون بمراسلتك خلال ساعات العمل.",
    TIME_INTERVAL: "الفاصل الزمني",
    AWAY_MSG: "رسالة خارج مواعيد العمل",
    AWAY_MSG_DESCRIPTION: "أخبر العملاء أنهم وصلوا إليك خارج ساعات العمل.",
    GREEING_AWAY: "رسائل الترحيب وخارج مواعيد العمل",
    SET_GREETING_AWAY:
      "قم بتعيين رسائل الترحيب ورسائل خارج الدوام لإرسالها إلى العملاء داخل/خارج ساعات العمل.",
    SELECT_TIME_ZONE: "اختر النطاق الزمني",
    UPDATE_ADR_ERROR: "حدث خطأ أثناء تحديث العنوان",
    ERROR_NO_BUSINESS_HOURS: "ساعات العمل مطلوبة لتحديث إعدادات رسائل الترحيب/الرسائل البعيدة",
    TIME_ERROR: "لا يمكن أن يكون وقت البدء بعد وقت قريب",
    INTERVAL_MIN_ERROR: "يجب أن يكون الفاصل الزمني أكبر من أو يساوي 1",
    INTERVAL_MAX_ERROR: "لا يمكن أن يكون الفاصل الزمني أكبر من ",
    INTERVAL_VALIDATION_ERROR: "يجب أن يكون الفاصل الزمني رقمًا",
    AWAY_TYPE_ERROR:
      "يجب أن يكون الفاصل الزمني البعيد من النوع \"رقم\"، ولكن القيمة النهائية كانت: `NaN` (مشتقة من القيمة `''`).",
    GREETING_TYPE_ERROR:
      "يجب أن يكون الفاصل الزمني للترحيب من النوع \"رقم\"، ولكن القيمة النهائية كانت: `NaN` (مشتقة من القيمة `''`).",
    DELAY_DESCRIBTION: "رسالة التأخير (بالثواني):",
    MSG_PLACE_HOLDER: "أكتب رسالتك...",
    SAVE: "حفظ",
    SAVING: "يتم...",
    SAVED: "تم",
    ERROR: "خطأ",
    SUNDAY: "الأحد",
    MONDAY: "الاثنين",
    TUESDAY: "يوم الثلاثاء",
    WEDNESDAY: "الأربعاء",
    THURSDAY: "يوم الخميس",
    FRIDAY: "جمعة",
    SATURDAY: "السبت",
    TIMEZONE_SET: "يجب عليك تحديد المنطقة الزمنية لإستخدام هذه الخاصية",
  },
  CONTACTS: {
    CONTACT: "اتصال",
    HEADER: "جهات الاتصال",
    SUBTITLE: "إدارة جهات الاتصال الخاصة بك",
    EMPTY_CONTACTS: "قائمة جهات الاتصال فارغة. ",
    FALSE_SEARCH_RESULTS: "لا يوجد جهات أتصال توافق عملية البحث",
    IMPORT_EXPORT_CONTACTS_BTN: "استيراد / تصدير",
    IMPORT_CONTACTS_BTN: "استيراد جهات الاتصال",
    EXPORT_CONTACTS_BTN: "تصدير جهات الاتصال",
    IMPORTS: "الواردات",
    EXPORTS: "صادرات",
    CREATE_CONTACT_BTN: "انشاء جهة اتصال",
    NO_CONTACTS: "ليس لديك أي اتصالات حتى الآن",
    CREATE_FIRST_CONTACT_BTN: "إنشاء جهة الاتصال الأولى الخاصة بي",
    SHOW_LIST: "عرض قائمة",
    EXPAND_TO_VIEW: "توسيع للعرض: ",
    IMPORT_POPUP: {
      TITLE: "استيراد جهات الاتصال",
      SUBTITLE: "قم بإعداد ملفك:",
      P1: "قم بتحسين عملية الاستيراد الخاصة بك عن طريق إعداد ملفك. ",
      P2: "لن يتم تكرار جهات الاتصال. ",
      DOWNLOAD_SAMPLE_BTN: "تنزيل نموذج XLSX",
      UPLOAD_FILE_BTN: "تحميل ملف .xlsx",
    },
    EXPORT_POPUP: {
      TITLE: "تصدير جهات الاتصال",
      SUBTITLE_1: "تنزيل جهات الاتصال الخاصة بك:",
      SUBTITLE_2: "يمكنك تنزيل جهات الاتصال بتنسيق CSV أو Excel",
      DOWNLOAD_CSV: "قم بالتنزيل كملف CSV",
      DOWNLOAD_EXCEL: "تنزيل بصيغة Excel",
    },
    TABLE: {
      NORMAL_TITLE: "جهات الاتصال",
      NORMAL_SUBTITLE: "إنشاء وإدارة جهات الاتصال الخاصة بك",
      IMPORT_TITLE: "استيراد جهات الاتصال",
      ACTIVITIES: "الأنشطة",
      MOST_USED_TAGS: "الأكثر استخدام",
      ALL_TAGS: "كل الوسوم",
      SEARCH_TAGS: "بحث الوسوم",
      HEADERS: {
        NAME: "اسم",
        DISPLAY_NAME: "اسم العرض",
        EMAIL: "بريد إلكتروني",
        PLATFORM_ID: "معرف المنصة",
        COUNTRY: "دولة",
        LANGUAGE: "لغة",
        ASSIGNED_TO: "مخصص ل",
        USER: "جهة الإتصال",
        TAGS: "الوسوم",
        CHANNEL: "القناة",
        CONVERSATION: "محادثة",
        ASSIGNEE: "المعين",
        LAST_MESSAGE: "اخر رسالة",
        ACTION: "إجراءات",
        FILE_NAME: "اسم الملف",
        NO_CONTACTS: "عدد جهات الاتصال",
        CREATED_BY: "منشأ من قبل",
        CREATED_AT: "انشئ في",
        STATUS: "حالة",
        REPORT: "تقرير",
        URL: "عنوان URL",
        DOWNLOAD: "تحميل",
        REASON: "سبب",
      },
      FOOTER: "يستورد {{total}} جهات الاتصال",
      INVALID_TAG_NAME: "اسم الوسم غير صالح",
      INVALID_AGENT_EMAIL: "بريد الوكيل غير صالح",
    },
    NOTIFICATIONS: {
      SUCCESS: "نجاح",
      IMPORT_SUCCESS: "تم استيراد كافة السجلات بنجاح",
    },
    CREATE_POPUP: {
      TITLE: "انشاء جهة اتصال جديدة",
      UPDATE_TITLE: "تحديث جهة الاتصال",
      TITLES: {
        PROFILE_PHOTO: "صورة الملف الشخصي",
        CONTACT_NAME: "اسم جهة الاتصال",
        CONTACT_EMAIL: "البريد الاكتروني",
        CONTACT_PHONE: "رقم الجوال",
        CHANNEL: "القناة",
        TAGS: "الوسوم",
      },
      BUTTONS: {
        UPLOAD_IMAGE: "رفع الصورة",
        CANCEL: "إلغاء",
        SAVE: "حفظ",
        SAVING: "إنقاذ...",
        SAVED: "أنقذ",
        EDIT: "تحرير",
        UPDATE: "تحديث",
        UPDATING: "جارٍ التحديث...",
        UPDATED: "محدث",
        ERROR: "خطأ",
        ARCHIVE: "أرشفة جهة الاتصال",
        DELETE: "حذف",
        MORE: "المزيد",
      },
      TAGS_OPTIONS: {
        TAG_1: "العلامة 1",
        TAG_2: "العلامة 2",
        TAG_3: "العلامة 3",
      },
      ERROR_MSG: {
        UNIQUENESS_VIOLATION :"خطأ بسبب التكرار",
        CONTACT_DUPLICATED : "لقد تم إضافة هذة الجهة من قبل." ,
      }
    },
    ACTIVITIES_IMPORT_POPUP: {
      TOTAL_CONTACTS: "إجمالي جهات الاتصال",
      FAILED_CONTACTS: "اتصالات فاشلة",
      WARNING_CONTACTS: "اتصالات التحذير",
      PROCESSED_CONTACTS: "جهات الاتصال المعالجة",
      NO_CONTACTS: "لا توجد بيانات جهات اتصال فاشلة لعرضها",
    },
  },
  SEARCH: {
    HEADER: "البحث",
    DIRECTION: "مصدر الرسالة",
    SEARCH_CONTACTS_PLACEHOLDER: "البحث عن جهات الاتصال",
    SEARCH_MESSAGES_PLACEHOLDER: "البحث عن الرسائل",
    NO_MSGS_FOUND: "لم يتم إيجاد أي رسائل.",
    TRY_AGAIN_WITH_OTHER_WORDS: "حاول البحث بكلمات أخرى.",
    CATEGORIES: {
      ALL: "الجميع",
      MESSAGES: "الرسائل",
      CONTACTS: "جهات الاتصال",
    },
    FILTERS: {
      MESSAGE_TYPE: {
        PLACEHOLDER: "نوع الرسالة",
        OPTIONS: {
          TEXT: "نص",
          IMAGE: "صورة",
          VIDEO: "فيديو",
          DOCUMENT: "وثيقة",
        },
      },
      READ_STATUS: {
        PLACE_HOLDER: "قراءة الحالة",
        OPTIONS: {
          READ: "يقرأ",
          UNREAD: "غير مقروءة",
        },
      },
      CHAT_STATUS: {
        PLACE_HOLDER: "حالة المحادثة",
        OPTIONS: {
          unanswered: "غير مقروءة",
          assigned: "مُكَلَّف",
          unassigned: "غير معينة",
          closed: "مغلق",
          snoozed: "تم التأجيل",
          archived: "مؤرشف",
        },
      },
      ASSIGNED_TO: {
        PLACEHOLDER: "مخصص ل",
      },
      CHANNEL: {
        PLACEHOLDER: "القناة",
        OPTIONS: {
          WHATSAPP: "واتساب",
          FACEBOOK: "فيسبوك",
          INSTAGRAM: "انستجرام",
        },
      },
      LAST_ACTIVITY: {
        PLACEHOLDER: "الوقت والتاريخ",
        OPTIONS: {
          LAST_7_DAYS: "اخر 7 ايام",
          LAST_30_DAYS: "آخر 30 يومًا",
          LAST_6_MONTHS: "آخر 6 أشهر",
          LAST_3_MONTHS: "آخر 3 أشهر",
          LAST_YEAR: "العام الماضي",
          CUSTOM_DATE_RANGE: " تخصيص تاريخ محدد",
        },
      },
      DATE_ADDED: "تم إضافة التاريخ",
      TAGS: {
        PLACEHOLDER: "الوسوم",
        OPTIONS: {
          TAG_1: "العلامة 1",
          TAG_2: "العلامة 2",
          TAG_3: "العلامة 3",
        },
      },
      MESSAGE_DIRECTION: {
        in: "الرسائل الصادرة",
        out: "الرسائل الواردة",
      },
    },
    TOOLTIPS: {
      ASSIGN_CHAT: "تعيين موظف",
      ADD_TAG: "إضافة علامة",
      MARK_CHAT_READ: "ضع إشارة مقروء",
      MARK_CHAT_UNREAD: "وضع علامة كغير مقروءة",
      CLOSE_CHAT: "إغلاق المحادثة",
    },
    START_SEARCHING: "لا توجد نتائج للبحث.. استخدم الفلتر للحصول على نتائج افضل",
    SELECT_ALL: "اختر الكل",
    GO_TO_CHAT: "اذهب إلى المحادثة",
    LIMIT_MSG: {
      SELECTED: "تم اختيار {{count}} {{type}}",
      SELECTED_TO_LIMIT: "تحذير ⚠️ لقد حددت أول {{count}} {{type}} للعمل الجماعي",
      SELECTED_TO_MAXLIMIT: "حدد أكثر من 256 جهة اتصال (بحد أقصى 1000 {{type}}). لا يمكن التراجع عن الإجراء الذي تم تطبيقه مرة واحدة",
    },
    BULK_ACTION_CONFIRMATION_TITLE: "رسالة تأكيد",
    BULK_ACTION_CONFIRMATION_DESCRIPTION: "هل انت متأكد من تنفيذ ",
    BULK_ACTION_FOR: " لـ ",
    PROCESSING  : "جارى التنفيذ...",
  },
  APPS: {
    HEADER: "تطبيقات",
    EXTERNAL_INTEGRATIONS: "External Integrations",
    SUBTITLE:
      "أطلق العنان للإمكانات الكاملة لشركتك من خلال التطبيقات وعمليات التكامل القوية",
    BROADCAST: {
      TITLE: "الحملات الإعلانية",
      DESCRIPTION:
        "نحن نقوم باستخدام الحملات الإعلانية الخاصة بالواتساب لإرسال رسائل إلي جهات اتصال متعددة - كلا من الرسائل المعاملية والرسائل التسويقية",
    },
    TEMPLATES: {
      TITLE: "قوالب",
      DESCRIPTION:
        " نماذج أو قوالب تُمكن المستخدمين من بدء المحادثات عن طريق إرسال رسائل ( تم الموافقة عليها عن طريق الواتساب ) وتكون لغرض المنفعة والمصادقة والتسويق ",
    },
    QUICK_REPLIES: {
      TITLE: "ردود سريعة",
      DESCRIPTION:
        "إختصارات من الرسائل التي تُرسل بشكل متكرر و الوسائط مثل الصور أو الفيديوهات للرد علي العملاء بشكل أوسع عبر استخدام الردود السريعة",
    },
    RULES: {
      TITLE: "قواعد",
      DESCRIPTION:
        "وضع قاعد لكلا من الرسائل الوارد والصادرة , اسمح لمحرك القواعد الخاص بنا أن يقوم بتبسي حياتك, وأن يقوم بعمل أتمتة للردود الألية لكي تستطيع أن تحدد الوقت المهم حقا بالنسبة لك",
    },
    GREETINGS_AWAY: {
      TITLE: "رسائل الترحيب وخارج مواعيد العمل",
      DESCRIPTION:
        "مصممة لكي تعزز من تفاعل العملاء فرسائل الترحيب تقوم بتحية العملاء بينما رسائل خارج أوقات العمل تقوم بإبلاغهم متي تكون متاحا",
    },
    TAGS: {
      TITLE: "الوسوم",
      DESCRIPTION:
        "تقوم بتصنيف العملاء وربطهم بناءً على مشاركتهم باستخدام 'الوسوم'. تقوم بفصل وتنظيم الفئات المختلفة لقاعدة عملائك بشكل فعال",
    },
    CHATGPT: {
      TITLE: "ChatGPT",
      DESCRIPTION:
        "قم بإحداث ثورة في مشاركة عملائك من خلال توفير حلول سياقية دقيقة وذات صلة وسريعة باستخدام ChatGPT",
    },
    DIALOG_FLOW: {
      TITLE: "Dialogflow",
      DESCRIPTION:
        "قم بإنشاء روبوتات محادثة ذكية تتعامل مع الاستعلامات بكفاءة، مما يحسن تجربة المستخدم من خلال تكامل Dialogflow",
    },
    GOOGLE_TRANSLATE: {
      TITLE: "جوجل المترجم",
      DESCRIPTION: "توفير ترجمة فورية لتحسين الرضا، دون الحاجة إلى ترجمة فورية",
    },
    HUBSPOT: {
      TITLE: "هوبسبوت",
      DESCRIPTION: "قم بتحسين عمليات عملك من خلال إدارة علاقات العملاء المبسطة",
    },
    ZAPIER: {
      TITLE: "زابير",
      DESCRIPTION: "قم بزيادة إنتاجيتك إلى أقصى حد مع Zapier. ",
    },
    CSML: {
      TITLE: "CSML",
      DESCRIPTION:
        "قم بدمج روبوتات المحادثة CSML في النظام الأساسي الخاص بك لأتمتة دعم العملاء وعمليات المبيعات",
    },
    GOOGLE_SHEETS: {
      TITLE: "جداول بيانات جوجل",
      DESCRIPTION:
        "قم بمزامنة بياناتك تلقائيًا مع جداول بيانات Google لإبقاء فريقك على اطلاع بأحدث المعلومات",
    },
    BOT_BUILDER: {
      TITLE: "تصميم روبوت المحادثه",
      DESCRIPTION:
        "قم بإنشاء روبوتات محادثة مخصصة لتلبية احتياجات عملك وتحسين تجربة العميل",
    },
    WHATSAPP_CATALOG: {
      TITLE: "كتالوج الواتساب",
      DESCRIPTION:
        "قم بإدارة منتجاتك وخدماتك بسهولة وكفاءة من خلال كتالوج الواتساب",
    },
    KNOWLEDGE_BASE: {
      TITLE: "قاعدة المعرفة",
      DESCRIPTION:
        "مركز معلومات يحتوي على مقالات وأدلة وأسئلة شائعة. فهو يمكّن المستخدمين من إيجاد الحلول والرؤى، وتشجيع المساعدة الذاتية وتقليل الحاجة إلى الدعم المباشر.",
    },
    MEDIA_LIBRARY: {
      TITLE: "مكتبة الوسائط",
      DESCRIPTION:
        "مصدر موحد للتعامل مع الوسائط المتنوعة مثل الصور ومقاطع الفيديو والصوت والمستندات وما إلى ذلك. تعزيز سهولة الاكتشاف والمشاركة والاستخدام في المشاريع والاتصالات ",
    },
    WHATSAPP_FLOW: {
      TITLE: "الرد الآلي الخاص بالواتساب",
      DESCRIPTION:
        "يقوم بتمكين الشركات من صياغة تجارب شاملة للعملاء من علي الواتساب وتقوم بإثراء تفاعلهم",
    },
    MOTTASL_BOT: {
      TITLE: "الرد الآلي الخاص بمتصل",
      DESCRIPTION:"عزز تجربة الدردشة الخاصة بك عن طريق تفعيل الرد الآلي الحصرى الخاص بمتصل.",
      CONFIGURATION: "اعدادات الرد الآلي",
      CONFIGURE: "فلنبدأ باعدادات الرد الآلي الخاص بمتصل من هنا",
      ADD_CONFIGURATION_INFO: "أدخل تفاصيل الاعدادات",
      CHANNEL_ID: "كود القناة - Channel ID",
      CHANNEL_TOKEN: "رمز مصادقة القناة",
      BOT_ID: "كود الرد الإلي - Bot ID",
      DEFAULT_AGENT: "حدد الوكيل",
      DEFAULT_TEAM: "حدد الفرق",
      DEFAULT_ASSIGNED_ACCOUNT_TYPE: "حدد تبعية الحساب",
      ERROR_MESSAGES: {
        CHANNEL_ID_IS_REQUIRED: "يجب إدخال كود القناة",
        CHANNEL_TOKEN_IS_REQUIRED: "يجب إدخال رمز مصادقة القناة",
        BOT_ID_IS_REQUIRED: "يجب إدخال الرد الآلي",
        DEFAULT_AGENT_IS_REQUIRED: "يجب اختيار وكيل",
        DEFAULT_TEAM_IS_REQUIRED: "يجب اختيار فريق",
        DEFAULT_ASSIGNED_ACCOUNT_TYPE_IS_REQUIRED: "يجب اختيار نوع تبعية الحساب",
      },
       CONNECTED_SUCCESSFULLY:  "تم توصيل الروبوت الآلي وتنشيطه بنجاح",
       ACTIVATED_AND_RUNNING:  "بوت الرد الآلي مفعل",
       START_RECEIVING_MESSAGES: "الآن ستبدأ في تلقي الرسائل من جهات الاتصال الخاصة بك",

       ACTIVE_STATUS_TITLE : "الرد الآلي مفعل الآن",
       ACTIVE_STATUS_DESCRIPTION : "بوت الرد الآلي مفعل ويعمل الآن",
       INACTIVE_STATUS_TITLE :  "بوت الرد الآلي معطل",
       INACTIVE_STATUS_DESCRIPTION: "بوت الرد الآلي معطل الآن ولا يعمل",

       DEACTIVE_STATUS_TITLE: "تعطيل البوت",
       DEACTIVE_QUESTION: "هل أنت متأكد أنك تريد تعطل بوت الرد الآلي في حسابك؟",
       DEACTIVE_STATUS_DESCRIPTION : "سيتم إيقاف جميع أنشطتك المستندة إلى الروبوتات",

    },
    ACTIVECAMPAIGN: {
      TITLE: "أكتيف كامبين",
      DESCRIPTION:
        "قم بإستخدام أكتيف كامبين لإدارة جهات الاتصال والصفقات والحملات الخاصة بك في أكتيف كامبين.",
    },
    PIPEDRIVE: {
      TITLE: "بيبدريف",
      DESCRIPTION:
        "قم بإستخدام حساب بيبدريف لمزامنة السجلات من نظام إدارة علاقات العملاء (CRM) الخاص بك في بيبدريف.",
    },
    "CUSTOM.ODOO": {
      TITLE : "أودو",
      DESCRIPTION : "قم بإستخدام حساب أودو لمزامنة السجلات من نظام إدارة علاقات العملاء (CRM) الخاص بك في أودو."
    },
    GOOGLESHEETS: {
      TITLE: "جداول بيانات جوجل",
      DESCRIPTION:
        "قم بمزامنة بياناتك تلقائيًا مع جداول بيانات Google لإبقاء فريقك على اطلاع بأحدث المعلومات",
    },
    BADGES: {
      DEV: "تحت التطوير",
      UPCOMING: "قادم",
      BETA: "بيتا",
    },
    UNDER_DEV: {
      COMMING_SOON: "قريبًا...",
      BODY1: "نحن نعمل بجد على هذه الخاصية، ترقبوا في خلال",
      BODY2: "الأسابيع القادمة وأستعدوا جيدًا!"
    },
    WHATSAPP_WIDGET: {
      TITLE: "اداة الواتساب",
      DESCRIPTION:
        "امنح العملاء طريقة للوصول إليك عبر الواتساب من موقع الويب الخاص بك",
    },
  },
  ECOMMERCE_APPS: {
    HEADER: "تطبيقات التجارة الإلكترونية",
    SUBTITLE: "تثبيت وإدارة  تطبيقات التجارة الإلكترونية",
    MANAGE_YOUR_STORE: "أدر متاجرك",
    MANAGE: "إدارة",
    ZID: {
      TITLE: "زد",
      DESCRIPTION:
        "تثبيت زد : تبسيط عمليات متجرك، وأتمتة الإشعارات، وإدارة حالات الطلب، واستعادة العربات المتروكة، وزيادة الإيرادات من خلال الحملات المستهدفة.",
    },
    SALLA: {
      TITLE: "سلة",
      DESCRIPTION:
        "تثبيت سلة: تبسيط عمليات متجرك، وأتمتة الإشعارات، وإدارة حالات الطلب، واستعادة العربات المتروكة، وزيادة الإيرادات من خلال الحملات المستهدفة.",
    },
    FOODICS: {
      TITLE: "فودكس",
      DESCRIPTION:
        "تثبيت فودكس: تبسيط عمليات متجرك، وأتمتة الإشعارات، وإدارة حالات الطلب، واستعادة العربات المتروكة، وزيادة الإيرادات من خلال الحملات المستهدفة.",
    },
    SHOPIFY: {
      TITLE: "شوبيفاي",
      DESCRIPTION:
        "تثبيت شوبيفاي: تبسيط عمليات متجرك، وأتمتة الإشعارات، وإدارة حالات الطلب، واستعادة العربات المتروكة، وزيادة الإيرادات من خلال الحملات المستهدفة.",
    },
    WOOCOMMERCE: {
      TITLE: "ووكومرس",
      DESCRIPTION:
        "تثبيت شوبيفاي: تبسيط عمليات متجرك، وأتمتة الإشعارات، وإدارة حالات الطلب، واستعادة العربات المتروكة، وزيادة الإيرادات من خلال الحملات المستهدفة.",
    },
    FOODER: {
      TITLE: "فودر",
      DESCRIPTION:
        "أنشئ قائمة طعام مطعمك في خلال دقائق معدودة ، وابدأ في استقبال طلباتك عبر فودر واتساب وامتلك أكبر قاعدة بيانات توفر لعملائك التجربة التي يستحقونها",
        INSTALL_TITLE: "يرجى التواصل مع مدير الحساب الخاص بك لتثبيت حساب فودر",
        INSTALL_DESCRIPTION: "اضغط الرابط التالي لمعرفه المزيد عن فودر.",
    },
    INSTALL_MODAL: {
      TITLE: "تثبيت {{platform}}",
      STORE_URL_LABEL: "رابط المتجر",
      STORE_URL_PLACEHOLDER: "https://my-store.com",
      CONSUMER_KEY_LABEL: "مفتاح الـ API ( consumer key)",
      CONSUMER_KEY_PLACEHOLDER: "xx-xx-xx-xx-xx-xx-xx",
      CONSUMER_SECRET_LABEL: "المفتاح السري للـ API ( consumer secret )",
      CONSUMER_SECRET_PLACEHOLDER: "xx-xx-xx-xx-xx-xx-xx",
      CONFIRM_MOTTASL_PLUGIN_INSTALL_LABEL: "تأكد من ضرورة تثبيت ملحق متصل في متجرك",
      VALIDATION_URL_MESSAGE: "Please enter valid https url",
      VALIDATION_CONSUMER_KEY_MESSAGE: "الرجاء إدخال مفتاح المستهلك صالح",
      VALIDATION_CONSUMER_SECRET_MESSAGE: "الرجاء إدخال المفتاح السري للمستهلك صالح",
      VALIDATION_INSTALL_MOTTASL_PLUGIN_MESSAGE: "الرجاء التأكد من تثبيت البرنامج المساعد لمتصل",
      MAIN_NOTE: "ستتم إعادة توجيهك إلى متجر {{platform}} للتثبيت",
      STEPS_HEADER: "لتثبيت التطبيق، اتبع هذه الخطوات البسيطة:",
      CLICK_INSTALL: "انقر على زر التثبيت أدناه:",
      REDIRECT_YOU: "سيؤدي هذا إلى إعادة توجيهك إلى متجر {{platform}}.",
      LOG_IN: "تسجيل الدخول:",
      USE_CREDENTIALS:
        "استخدم بيانات اعتماد تسجيل الدخول إلى المتجر للوصول إلى حسابك.",
      INSTALL_APP: "تثبيت التطبيق:",
      FIND_APP: "ابحث عن التطبيق وانقر فوق تثبيت.",
      RETURN_TO_AVC: "العودة إلى أفوكادو:",
      INSTALL_COMPLETE: "بمجرد اكتمال التثبيت، قم بالعودة إلى منصة أفوكادو.",
      ACTION: "تثبيت {{platform}}",
      SUCCEESS_INSTALL_MESSAGE: "تم تثبيت التطبيق بنجاح",
      SALLA: {
        COPY_BUSINESS: "نسخ مدير الأعمال",
        USE_BUSINESS_TO_CONFIG: '.اضغط على زر "نسخ رمز مدير الأعمال". سيتم استخدام هذا الرمز لتفعيل التطبيق',
        INSTALL_SALLA: "تثبيت تطبيق سلة:",
        INSTALL_IN_MARKETPLACE: '.اضغط على زر "تثبيت سلة" للانتقال إلى التطبيق في سوق تطبيقات سلة',
        ACCEPT_AUTHORIZATION: "الموفقة على الصلاحيات:",
        CLICK_TO_ACCEPT_AUTHORIZATION: 'اضغط  على زر "اوافق على الصلاحيات" في النافذة المنبثقة.',
        ACCESS_APP_SETTINGS: "الوصول إلى إعدادات التطبيق:",
        CLICK_TO_ACCESS_APP_SETTINGS: " اضغط على الأيقونة الحمراء الصغيرة على بطاقة التطبيق. ستظهر نافذة منبثقة بمجرد النقر على الأيقونة الحمراء.",
        ADD_BUSINESS_ID: "إضافة رمز مدير الأعمال:",
        PAST_BUSINESS_ID: "الصق رمز مدير الأعمال المنسوخ في المكان المخصص.",
        CONFIRM_BUSINESS_ID: "تأكيد رمز التفعيل:",
        CLICK_TO_ADD_BUSINESS_ID: ' اضغط على زر "إضافة رمز مدير الاعمال.',
        FOLLOW_STEPS_TO_INSTALL_SALLA:
          "بإتباع هذه الخطوات، سيتم تفعيل تطبيق سلة تلقائيًا على منصتك ✅.",
      },
      "INSTALL_STORE_SUCCESS": "تم تثبيت التطبيق بنجاح",
      "INSTALL_STORE_ERROR": {
        DEFAULT: "حدث خطأُ ما أثناء عملية التثبيت",
        IS_ALREADY_INSTALLED: "فشل في التثبيت - هذا المتجر مثبت بالفعل ومربوط بحساب آخر"
      }
    },
    SETTINGS: {
      TOTAL_INSTALLED_STORES: "المتاجر المثبتة",
      CHARTS_OPTIONS: {
        ABANDONED_CART_FIRST_SHOT: "المرحلة الأولى",
        ABANDONED_CART_SECOND_SHOT: "المرحلة الثانية",
        INSTANT_CAMPAIGN: "حملة فورية",
      },
      NO_DATA_AVAILABLE: "لا توجد بيانات متاحة",
      CLICK_ON_SHOW_ALL: "اضغط على زر 'عرض الكل'",
      ENABLE_CART_RECOVERY: "تفعيل السلات المتروكة",
      FILTER_ORDER_STATUS: {
        ORDER_CREATED: "طلبات جديدة",
        ORDER_READY: "الطلب جاهز",
        ORDER_PREPARING: "جاري تجهيز الطلب",
        ORDER_DELIVERING: "جاري توصيل الطلب",
        ORDER_COMPLETED: "تم اكتمال الطلب",
        ORDER_CANCELLED: "تم إلغاء الطلب",
        COD_CONFIRMATION: "تأكيد الدفع عند الاستلام",
        PAYMENT_PENDING: "الدفع معلق",
      },
      ABANDONED_CART_TABLE: {
        ID: "م",
        CUSTOMER: "العميل",
        CHECKOUT_URL: "رابط الدفع",
        TOTAL: "قيمة السلة",
        AGE: "زمن السلة (بالدقائق)",
        CREATED_ON: "وقت الإنشاء",
        CUSTOMER_PHONE: "رقم العميل",
        FIRST_SHOT: "الإشعار الأول",
        SECOND_SHOT: "الإشعار الثاني",
        PRODUCTS_COUNT: "عدد المنتجات",
      },
      ORDER_ANALYTICS: "تحليلات الطلبات",
      ORDERS: "طلبات",
      NEXT: "التالي",
      ABANDONED_CART_ANALYTICS: "تحليلات السلات المتروكة",
      FIRST_SHOT: "سلة متروكة مرحلة أولى",
      CART_RECOVERY: "السلات المتروكة",
      SECOND_SHOT: "السلة المتروكة مرحلة ثانية",
      INSTANT_CAMPAIGN: "حملة فورية",
      CAMPAIGN: "الحملة",
      VIEW_ORDERS: "أدر متاجرك من هنا",
      VIEW_ORDER_STATUS: "أدر متاجرك من هنا",
      TOTAL_ABANDONED_CARTS: "إجمالي السلات المتروكة",
      UNINSTALLED: "غير مٌثبّته",
      ACTIVE: "مُفعلة",
      ABANDONED_FIRST_CARTS: "إشعارات المرحلة الأولى",
      ABANDONED_SECOND_CARTS: "إشعارات المرحلة الثانية",
      FAILED_ABANDONED_CARTS: "الإشعارات الفاشلة",
      ABANDONED_INSTANT_NOTIFICATIONS: "الحملات الفورية للسلات",
      ABANDONED_CARTS_EMPTY_WARNING: "لا يوجد أي سلات متوفرة لهذه الحملة بناء على التصفيات والاختيارات الحالية",
      TEMPLATE_ATTACHED: "تم إرفاق القالب",
      ATTACHED_TEMPLATE: "القالب الملحق",
      AVAILABLE_TEMPLATES: "القوالب المتاحة",
      NO_AVAILABLE_TEMPLATES: "لا يوجد قوالب متاحة",
      ENTER_VARIABLE: "أدخل المتغير",
      ADD_COUPON_HERE: "أضف الكوبون هنا",
      TOTAL_REVENUE_GENERATED: "إجمالي الإيرادات الناتجة",
      ADD_COUPON: "إضافة كوبون",
      COUPON_ADDED: "تمت إضافة كوبون",
      IN_SAR: "بالريال السعودي",
      SAR: "ريال سعودي",
      IN_AVG: "في المتوسط",
      HALALA: "هللة",

      RUN_INSTANT_CAMPAIGN: "إطلاق حملة فورية",
      TOTAL_TRIGGERED_NOTIFICATIONS: "إجمالي الإشعارات التي تم تشغيلها",
      TOTAL_COMPLETED_NOTIFICATIONS: "إجمالي الإشعارات المكتملة",
      TOTAL_INCOMPLETED_NOTIFICATIONS: "إجمالي الإشعارات غير المكتملة",
      INSTANCES: "نموذج",
      ORDER_NOTIFICATION: "إشعارات الطلبات",
      EVENT_TYPE_PLACEHOLDER: "حدد نوع الحدث",
      STORE_INFO: "معلومات المتجر",
      CONTROL_STATUSES: "حالات التحكم",
      ATTACH_TEMPLATES: "إرفاق النماذج",
      ATTACH_TEMPLATE: "إرفاق قالب",
      ANALYTICS: "التحليلات",
      STORE: "المتجر",
      VIEW_STORE_INFO: "انظر إلى كل تفاصيل المتجر",
      CHANNEL_NAME: "اسم القناة",
      RUN_INSTANT_CART: "إرسال الحملة الفورية",
      CHANNEL_PLATFORM: "منصة القناة",
      STOREFRONT_PLATFORM: "المنصة",
      EMAIL: "البريد الالكتروني",
      STORE_URL: "رابط المتجر",
      STORE_ID: "كود الربط",
      STORE_SUBSCRIPTION_TITLE: "بيانات الاشتراك فى تطبيق",
      PACKAGE_TYPE: "نوع خطة الاشتراك ",
      SUBSCRIPTION_STATUS: "حالة الاشتراك",
      START_DATE: "بداية الاشتراك",
      END_DATE: "نهاية الاشتراك",
      ORDER_NOTIFICATION_TITLE: "إشعار الطلبات",
      ORDER_NOTIFICATION_SUBTITLE: "عرض تحليلات متجرك هنا",
      ABANDONED_CART_NOTIFICATION_SUBTITLE:
        "تحليلات السلات المتروكة بناء على الإشعارات في متجرك",
      SELECT_ABANDONED_CART: "حدد سلة متروكة",
      ANALYTICS_TITLE: "تحليلات متجرك",
      ANALYTICS_SUBTITLE: "قم بتعزيز اتخاذ القرارات و استخرج رؤى قيمة محفزة للنمو من خلال تحليلات البيانات المرئية.",
      ORDER_NOTIFICATION_SENT: "الإشعارات المرسلة",
      ABANDONED_CART: "سلة متروكة",
      IN_TOTAL: "الإجمالي",
      SELECT_TEMPLATE: "حدد القالب",
      FROM_DATE: "من تاريخ",
      TO_DATE: "إلى تاريخ",
      MIN_CART_TOTAL: "الحد الأدنى للسلة",
      ENTER_AMOUNT: "أدخل المبلغ",
      MIN_PRODUCT_TOTAL: "الحد الأدنى للمنتج",
      ENTER_QUANTITY: "أدخل الكمية",
      SEARCH_ABANDONED_CART: "البحث عن السلة المتروكة",
      FINANCIAL_METRICS: "المقاييس المالية",
      ESTIMATED_REVENUE: "الإيرادات المتوقعة",
      ESTIMATED_MAX_COST: "التكلفة القصوى المتوقعة",
      FINANCIAL_METRICS_NOTE:
        "*هذه هي المقاييس المالية لحملة السلات المتروكة المحددة",
      RUN_CAMPAIGN: "شغل الحملة",
      NO_ARGS_REQUIRED: "بإمكانك إرفاق النموذج الأن!",
      ATTACH: "إرفاق",
      ORDER_STATUSES: {
        TITLE: "حالات الطلب",
        ORDER_CREATED: "تم إنشاء الطلب",
        ORDER_PREPARING: "جاري تجهيز الطلب",
        COD_CONFIRMATION: "تأكيد الدفع عند الاستلام",
        ORDER_CANCELLED: "تم إلغاء الطلب",
        ORDER_COMPLETED: "تم اكتمال الطلب",
        ORDER_READY: "الطلب جاهز",
        ORDER_DELIVERING: "جاري توصيل الطلب",
        CUSTOMER_CREATED: "تم إنشاء العميل",
        ADMIN_INVENTORY_NOTIFICATION: "إشعار المخزون للمشرف",
        ORDER_RESTORED: "تم استعادة الطلب",
        ORDER_RESTORING: "جاري استعادة الطلب",
        ORDER_SHIPPED: "تم شحن الطلب",
        PAYMENT_PENDING: "الدفع معلق",
        RESERVATION_CREATED: "تم إنشاء الحجز",
        ORDER_FAILED: "فشل الطلب",
        ORDER_REFUNDED: "تم استرداد مبلغ الطلب",
        ORDER_REVERSED: "تم عكس الطلب",
        ORDER_CHARGEBACK: "تم رد الطلب",
        ORDER_EXPIRED: "انتهت صلاحية الطلب",
        ORDER_PROCESSED: "تمت معالجة الطلب",
        ORDER_VOIDED: "تم إبطال الطلب",
        ORDER_DENIED: "تم رفض الطلب",
        ORDER_PROCESSING_REVERSED: "تم عكس معالجة الطلب",
        ORDER_CANCELED_REVERSAL: "تم عكس إلغاء الطلب",
        CART_RECOVERY_FIRST: "السلة المتروكة المحاولة الأولى (بعد ~3 ساعات)",
        CART_RECOVERY_SECOND: "السلة المتروكة المحاولة الثانية (بعد ~24 ساعة)",
        ABANDONED_CART_FIRST_SHOT:
          "السلة المتروكة المحاولة الأولى (بعد ~3 ساعات)",
        ABANDONED_CART_SECOND_SHOT:
          "السلة المتروكة المحاولة الثانية (بعد ~24 ساعة)",
        INSTANT_CAMPAIGN: "حملة فورية",
      },
      TEMPLATES_VARIABLES: {
        MINIMUM_QUANTITY: "أقل كمية",
        PRODUCT_NAME: "اسم المنتج",
        CURRENT_QUANTITY: "الكمية الحالية",
        CUSTOMER_NAME: "اسم العميل",
        CUSTOMER_PHONE_NO: "رقم هاتف العميل",
        CUSTOMER_EMAIL: "البريد الإلكتروني للعميل",
        ORDER_ID: "رقم الطلب",
        ORDER_STATUS_AR: "حالة الطلب بالعربية",
        ORDER_STATUS_EN: "حالة الطلب بالإنجليزية",
        ORDER_URL: "رابط الطلب",
        RATING_LINK: "رابط التقييم",
        INVOICE_PDF: "فاتورة PDF",
        ORDER_TOTAL: "إجمالي الطلب",
        STORE_NAME: "اسم المتجر",
        STORE_URL: "رابط المتجر",
        SHIPPING_METHOD_NAME: "اسم طريقة الشحن",
        COUPON_CODE: "كود خصم",
        SHIPPING_TRACKING_LINK: "رابط تتبع الشحن",
        PAYMENT_METHOD_NAME: "اسم طريقة الدفع",
        BANNER_URL: "رابط البانل",
        CART_ID: "معرف العربة",
        PHASE: "مرحلة",
        CUSTOMER_ID: "معرف العميل",
        PRODUCTS_COUNT: "عدد المنتجات",
        CART_URL_FULL: "رابط العربة كامل",
        CART_URL_SEMI: "رابط العربة مصغر",
        FIRST_SHOT_CODE: "كود اول محاولة",
        SECOND_SHOT_CODE: "كود ثاني محاولة",
        PENDING_PAYMENT_LINK: "رابط الدفع",
      },
       SEND_CAMPAIGN: {
        START: "ابدء  إرسال الحملة",
        LOADING: "جاري الإرسال",
        SUCCESS: "تم الإرسال",
        FAIL: "حدث خطأ"
      }
    },
  },
  BROADCAST: {
    HEADER: "الحملات الإعلانية",
    SUBTITLE: "ارسال رسائل جماعيه الى جهات الاتصال الخاصه بك",
    CREATE_BROADCAST_BTN: "حملة تسويقية جديدة",
    ZERO_BROADCASTS: "أنت لا تملك أي حملات إعلانية",
    CONNECT_CHANNEL: "يجب إضافة قناة اتصال لتتمكن من استخدام الحملات الإعلانية.",
    ADD_CHANNEL: "إضافة قناة إتصال",
    STATUS_BOX: {
      MESSAGING_TIER: {
        KEY: "عدد الرسائل:",
        VALUE: "{{tier}}",
        DESCRIPTION: "(بحد أقصى {{count}} عميل خلال 24 ساعة)",
        UNLIMITED: "عدد لا نهائي من العملاء"
      },
      ACCOUNT_HEALTH: {
        KEY: "صحة الحساب:",
        VALUE: {
          GREEN: "أخضر",
          YELLOW: "أصفر",
          RED: "أحمر",
        },
      },
      ACCOUNT_STATUS: {
        KEY: "حالة الحساب:",
        VALUE: {
          CONNECTED: "متصل",
          LIVE: "مباشر",
          INACTIVE: "غير نشط"
        },
      },
      ACCOUNT_TIER: {
        "SANDBOX": "تجريبي",
        "TIER_1": "الفئة الأولى",
        "TIER_2": "الفئة الثانية",
        "TIER_3": "الفئة الثالثة",
        "UNLIMITED": "غير محدود",
      }
    },
    FILTERS: {
      STATUS: {
        PLACEHOLDER: "حدد الحالة",
        OPTIONS: {
          DRAFT: "مسودة",
          SCHEDULED: "المقرر",
          SUBMITTED: "مُقَدَّم",
          IN_REVIEW: "في مراجعة",
          PROCESSING: "يعالج",
          COMPLETED: "مكتمل",
          FAILED: "فشل",
          HALTED: "توقف",
          INVALIDATED: "غير صالح",
        },
      },
      DATE: "اختر حسب التاريخ",
      SEARCH: "بحث",
    },
    BROADCASTS_TABLE: {
      HEADERS: {
        BROADCAST_NAME: "اسم الحملة",
        CHANNEL: "قناة",
        RECIPIENTS: "المستلمون",
        STATUS: "حالة",
        SENT: "تاريخ الإرسال",
        MESSAGING_SUMMARY: "ملخص الرسائل",
        ACTION: "إجراءات",
      },
      N_DAYS_AGO: "{{days}} أيام مضت",
      RECIPIENTS: "المستلمون: {{count}}",
      TEMPLATE: "نموذج: {{template_id}}",
    },
    DETAILS: {
      SENT_ON: "أرسلت في {{date}}",
      BROADCAST_STATUS: "حالة الحملة الإعلانية",
      TARGET_SUMMARY: "ملخص الحملة الإعلانية",
      LAST_UPDATED: "آخر تحديث {{date}}",
      VIEW_DETAILS: "عرض التفاصيل",
      MESSAGING_METRICS: 'ملخص الرسائل',
      MESSAGING_SUMMARY: {
        TOTAL_BROADCASTS: 'عدد الإعلانات',
        DELIVERED_BROADCASTS: 'نسبة التوصيل',
        DELIVERED_PERCENTAGE: 'نسبة التوصيل',
        READ_BROADCASTS: 'نسبة القراءة',
        READ_PERCENTAGE: 'نسبة القراءة',
        ENGAGING_RESPONSES: 'نسبة الإستجابة',
        RESPONSE_PERCENTAGE: 'نسبة الاستجابة',
        SUB_DELIVERED_PERCENTAGE: 'نسبة التوصيل',
        SUB_READ_PERCENTAGE: 'نسبة القراءة',
        SUB_RESPONSE_PERCENTAGE: 'نسبة الإستجابة',
        DELIVERED_PERCENTAGE_DESC: "تم التوصيل",
        READ_PERCENTAGE_DESC: "تمت القراءة",
        RESPONSE_PERCENTAGE_DESC: "الصحة",
      },
      FILTERS: {
        STAGE: {
          PLACEHOLDER: "مرحلة واجهة برمجة التطبيقات",
          OPTIONS: {
            ACCEPTED: "مقبول (مرسل)",
            READ: "تمت القراءة",
            FAILED: "فشل",
            DELIVERED: "تم التوصيل",
          },
        },
        RESULTS: {
          PLACEHOLDER: "نتائج",
          OPTIONS: {
            RESPONDED: "استجاب",
            CLICKED: "تم النقر عليه",
            CONVERTED: "تحويلها",
            OPT_OUT: "انسحب",
            BLANKS: "الفراغات",
          },
        },
        DOWNLOAD_CONTACTS: "تحميل جهات الاتصال",
      },
      TABLE_HEADERS: {
        USERS: "فريق العمل",
        STATUS: "حالة",
        RESULTS: "نتائج",
        DATE: "تاريخ",
      },
      STATUS: {
        FAILED: "فشل",
        SENT: "مرسل",
        DELIVERED: "تم التوصيل",
        READ: "تمت القراءة",
      },
      RESULTS: {
        RESPONDED: "تم الرد",
        CLICKED: "تم النقر عليه",
        CONVERTED: "تم تحويله",
      },
      MOBILE_MESSAGE: {
        HEADER: "تم تحسين هذه الميزة لموقع سطح المكتب.",
        DESCRIPTION:
          "يعد عرض رسائل البث وتنزيلها أسهل بكثير على جهاز الكمبيوتر. ",
        VISIT: {
          1: "يزور ",
          2: "مٌتَّصِل",
          3: " على سطح المكتب الخاص بك",
        },
        SELECT: "قم بالإختيار",
        NAVIGATE: "التنقل",
        DONE: "منتهي",
      },
      STATUS_DESCRIPTION: {
        draft: "",
        scheduled: "تمت جدولة البث بنجاح",
        submitted: "تم إرسال البث بنجاح",
        in_review: "تتم مراجعة البث",
        processing: "تتم معالجة البث بنجاح",
        completed: "تم إرسال البث بنجاح",
        failed: "فشلت مراجعة البث",
        halted: "",
        invalidated: "",
      },
      SCHEDULED_IN: "تمت جدولة هذا البث في",
      SEND_DELETE: "يمكنك إما إرسال البث الآن أو حذفه من هنا",
      CANCEL_BROADCAST: "إلغاء البث",
      SEND_BROADCAST: "أرسل البث الآن",
      SCHEDULED_FOR: "المقررلـ{{date}}",
      BROADCAST: "إذاعة",
      HOURS_AGO: "{{hours}} منذ ساعات",
      GO_TO_CHAT: "اذهب للمحادثة",
    },
    CREATE_POPUP: {
      TITLE: "إنشاء البث",
      SUBTITLE: "أنشئ بثًا جديدًا لبدء استخدامه",
      EDIT_TITLE: "تحرير مسودة البث",
      EDIT_SUBTITLE: "قم بتحرير مسودة البث الخاصة بك لبدء استخدامها",
      BROADCAST_DETAILS: "تفاصيل البث",
      BROADCAST_DETAILS_SUBTITLE: "قم بتسمية البث الخاص بك وحدد اللغة والقالب.",
      RECIPIENT_SELECTION: "اختيار المستلم",
      RECIPIENT_SELECTION_SUBTITLE: "حدد أهداف البث الخاصة بك هنا",
      SCHEDULE_OR_SEND_BROADCAST: "جدولة أو إرسال البث",
      SCHEDULE_OR_SEND_BROADCAST_SUBTITLE:
        "أرسل البث الخاص بك أو قم بجدولته هنا",
      TEMPLATE_VARIABLES: "متغيرات القالب",
      NO_TEMPLATES_FOR_LANGUAGE: "لا توجد قوالب متاحةلـ{{language}} لغة",
      TITLES: {
        BROADCAST_NAME: "اسم البث",
        LANGUAGE: "لغة",
        SELECT_TEMPLATE: "حدد قالب",
        PREPARE_DRAFT: "هل تريد إعداد البث كمسودة؟",
      },
      SEARCH_TEMPLATES: "البحث عن طريق معرف القالب",
      SELECT_TARGET: "حدد الهدف",
      RESET_TARGETS: "إعادة تعيين الأهداف",
      CLEAR_WARNING:
        "سيؤدي حذف نوع الهدف المحدد إلى إزالة كافة الأهداف المحددة منه. ",
      ALL_CONTACTS: "كل الاتصالات",
      ARCHIVED_CONTACTS_NOT_INCLUDED: "لا تشمل الاتصالات المؤرشفة",
      CONTACTS: "جهات الاتصال",
      LAST_ASSIGNED_TO_AGENT: "آخر تعيين للوكيل",
      CUSTOMER_CREATED: "تم إنشاء العميل",
      ALL_CLOSED_CHATS: "جميع المحادثات المغلقة",
      TAGS: "الوسوم",
      LAST_ASSIGNED_TO_TEAMS: "آخر تعيين للفرق",
      COUNTRY: "دولة",
      ALL_UNREAD_CHATS: "جميع المحادثات غير المقروءة",
      CUSTOM_FIELDS: "الحقول المخصصة",
      LAST_ACTIVITY: "الوقت والتاريخ",
      WHATSAPP: "واتساب",
      FILTER_OPTED_OUT: "تم إلغاء الاشتراك في التصفية",
      FILTER_OPTED_OUT_TOOLTIP:
        "ما إذا كان سيتم تصفية العملاء الذين تم إلغاء اشتراكهم من البث",
      INFO_TEMPLATE_VARIABLES:
        "يمكنك استخدام المتغيرات التالية في القالب الخاص بك",
      SELECT_DO_NOT_TARGET: "حدد عدم الاستهداف",
      SELECT_CONTACTS: "حدد جهات الاتصال",
      SELECT_TAGS: "حدد الوسوم",
      SELECT_CUSTOM_FIELD: "حدد حقل مخصص",
      SELECT_AGENT: "حدد الوكيل",
      SELECT_TEAMS: "حدد الفرق",
      SELECT_COUNTRIES: "اختر البلدان",
      SEARCH: "بحث",
      YES: "نعم",
      NO: "لا",
      IF: "لو",
      IS: "يكون",
      ARE: "نكون",
      TEXT: "نص",
      URL: "عنوان URL",
      CHECKED: "التحقق",
      UNCHECKED: "غير محدد",
      TEAM_MEMBERS: "أعضاء الفريق",
      SELECT_TIME: "اختيار الوقت",
      SELECT_DATE: "حدد تاريخ",
      ON: "على",
      AT: "في",
      BETWEEN_DATES: "بين {{startDate}} و {{endDate}}",
      COUNTRIES: "بلدان",
      ALL_COUNTRIES: "كل البلدان",
      SELECTED_COUNTRIES: "بلدان مختارة",
      SEARCH_COUNTRIES: "بحث في البلدان",
      SEND_TO_TARGET: "إرسال إلى الهدف",
      NOT_SEND_TO_TARGET: "لا ترسل إلى الهدف",
      SCHEDULE_OR_SEND: "جدولة أو إرسال",
      SCHEDULE_TO_SENT_AT: "الجدول الزمني للإرسال في {{time}}",
      BROADCAST_SUMMARY: "ملخص البث",
      TAGED_CONTACTS: "{{tagged}} تم وضع علامة على جهات الاتصال",
      TOTAL_N_CONTACTS: "المجموع {{count}} جهات الاتصال",
      N_CONTACTS: "{{count}} جهات الاتصال",
      UPLOAD_IMAGE: "تحميل الصور",
      UPLOAD_VIDEO: "رفع فيديو",
      UPLOAD_DOCUMENT: "تحميل الوثيقة",
      CHANGE_IMAGE: "تغيير الصورة",
      CHANGE_VIDEO: "تغيير الفيديو",
      CHANGE_DOCUMENT: "تغيير المستند",
      CONFIRM_TITLE: "تأكيد إرسال البث",
      CONFIRM_BODY:
        "هل أنت متأكد أنك تريد إرسال القالب <0>{{template_name}}</0>لـ<0>{{count}} عملاء</0>؟",
      DELETED_TEMPLATE: "لم يتم العثور على القالب",
      DELETED_TEMPLATE_BODY:
        'القالب الذي حددته سابقًا إما تمت إزالته أو رفضه.  <0>"تفاصيل البث"</0> للمضي قدمًا.',
      ALERT_MOBILE: "يعد إنشاء البث أمرًا معقدًا من شاشة الهاتف المحمول. ",
      SELECT_FUTURE_DATE: "يجب عليك تحديد وقت في المستقبل",
      SUMMARY: {
        BROADCAST_NAME: "اسم البث: ",
        LANGUAGE: "لغة: ",
        BROADCAST_SCHEDULE: "من المقرر أن ترسل في ",
        TEMPLATE_NAME: "اسم القالب: ",
      },
      BUTTONS: {
        VIEW: "عرض",
        CONTINUE_DRAFT: "متابعة المسودة",
        CANCEL: "إلغاء",
        BACK: "خلف",
        NEXT: "التالي",
        SEND_NOW: "ارسل الان",
        SENDING: "إرسال...",
        SENT: "مرسل",
        CREATE: "إنشاء",
        CREATING: "يتم...",
        CREATED: "تم",
        SAVING: "يتم...",
        SAVED: "تم",
        ERROR: "خطأ",
        SCHEDULE: "جدول",
        RESCHEDULE: "إعادة جدولة",
        CONFIRM_SCHEDULE: "تأكيد",
        UNSCHEDULE: "إلغاء الجدول الزمني",
        ADD_TO_FILTERS: "أضف إلى المرشحات",
        SELECT_TEMPLATE: "حدد قالب",
      },
    },
    ESTIMATE_POPUP:{
      TITLE:'التقدير',
      SUB_TITLE:'عرض التكلفة المقدرة للبث',
      ESTIMATED_COST:'التكلفة المقدرة',
      WALLET_BALANCE:'رصيد المحفظة',
      COUNTRY:'الدولة',
      RECIPIENTS:'المستلمون',
      CHARGES:'الرسوم',
      SEND_NOW:'إرسال الآن',
      INSUFFICIENT_BALANCE:'رصيد ائتماني غير كافٍ!',
      ADD_CREDIT:'إضافة رصيد'
    },
    NO_CHANNEL:'لا توجد قناة نشطة لهذا الحساب'
  },
  QUICK_REPLIES: {
    HEADER: "ردود سريعة",
    SUBTITLE:
      "قم بالرد على العملاء بشكل أسرع من خلال إعداد الرسائل المعدة مسبقًا.",
    CREATE_BTN: "إنشاء الرد السريع",
    NO_TEMPLATES: "ليس لديك قوالب حتى الآن",
    TYPE_PLACEHOLDER: "جميع أنواع الردود السريعة",
    NO_QR_MATCH: "لا يوجد ردود تطابق المعلومات اليت قمت بإدخالها",
    TYPE_OPTIONS: {
      TEXT: "نص",
      VIDEO: "فيديو",
      IMAGE: "صورة",
      // AUDIO: "ملف صوتى",
      DOCUMENT: "وثيقة",
    },
    SEARCH_PLACEHOLDER: "بحث",
    TABLE_HEADERS: {
      SHORT_CODE: "رمز قصير",
      MESSAGE: "رسالة",
      KEYWORDS: "الكلمات الدالة",
      AVAILABLE_TO: "متاحة لل",
      ACTION: "إجراءات",
    },
    AVAILABLE_TO: {
      ALL: "الجميع",
      TEAMS: "فرق",
      CREATOR: "مٌنشئها فقط",
      AVAILABLE_TO_ALL: "متاحة للجميع",
      AVAILABLE_TO_CREATOR: "متاحة لمٌنشئها فقط",
      AVAILABLE_TO_TEAMS: "متاحة لفريق مٌحدد",
    },
    CREATE_POPUP: {
      MOBILE_TITLE: "إنشاء الرد السريع",
      TITLE: "إنشاء رد سريع جديد",
      EDIT_TITLE: "تحرير الرد السريع",
      LABELS: {
        SHORT_CODE: "رمز قصير",
        KEYWORDS: "الكلمات الدالة",
        BODY_TEXT: "نص الرسالة",
        AVAILABLE_TO: "متاحة لل",
      },
      SELECT_TEAMS: "حدد الفرق",
      ERRORS: {
        SHORT_CODE: "مطلوب رمز قصير",
        SHORT_CODE_LENGTH: "يجب أن يكون الرمز القصير 50 ​​حرفًا على الأكثر",
        BODY_TEXT: "النص الأساسي مطلوب",
        BODY_TEXT_LENGTH: "يجب ألا يزيد النص الأساسي عن 1024 حرفًا",
        KEYWORDS: "الكلمات الرئيسية مطلوبة",
        MAX_KEYWORDS: "لا يمكن إضافة أكثر من 10 كلمات رئيسية",
      },
      BUTTONS: {
        CANCEL: "إلغاء",
        SAVE: "حفظ",
        SAVING: "إنقاذ...",
        SAVED: "أنقذ",
        UPDATE: "تحديث",
        UPDATING: "جارٍ التحديث...",
        UPDATED: "محدث",
        ERROR: "خطأ",
      },
    },
  },
  TAGS: {
    HEADER: "الوسوم",
    SUBTITLE: "إنشاء وإدارة الوسوم هنا",
    CREATE_BTN: "إضافة علامة جديدة",
    ZERO_TAGS: "لا يوجد أي علامات",
    SEARCH_PLACEHOLDER: "البحث عن الوسوم",
    CANT_DELETE_ITEM: "لا يمكن إزالة هذا العنصر. يمكن أن يكون قيد الاستخدام بالفعل مع أحد أهداف البث أو متصلاً بالعميل.",
    TABLE_HEADERS: {
      NAME: "اسم",
      DESCRIPTION: "وصف",
      CREATED_BY: "انشأ من جانب",
      NO_OF_CONTACTS: "عدد جهات الاتصال",
      CREATED_ON: "تم إنشاؤها في",
      ACTION: "إجراءات",
    },
    CREATE_POPUP: {
      TITLE: "إضافة علامة",
      EDIT_TITLE: "تحديث العلامة",
      PICK_COLOR: "أختر لونًا",
      LABELS: {
        TAG_NAME: "اسم العلامة",
        DESCRIPTION: "وصف",
        COLOR: "لون",
      },
      PLACEHOLDERS: {
        TAG_NAME: "أدخل اسم العلامة هنا",
        DESCRIPTION: "أدخل وصف العلامة هنا",
        COLOR: "العلامة1",
      },
      ERRORS: {
        TAG_NAME: "اسم العلامة مطلوب",
        DESCRIPTION: "وصف العلامة مطلوب",
        COLOR: "اللون مطلوب",
      },
      BUTTONS: {
        CANCEL: "إلغاء",
        ADD_TAG: "إضافة علامة",
        ADDING: "جارٍ الإضافة...",
        ADDED: "تمت الإضافة",
        UPDATE_TAG: "تحديث العلامة",
        UPDATING: "جارٍ التحديث...",
        UPDATED: "محدث",
        ERROR: "خطأ",
        VIEW_CONTACT: "عرض جهات الاتصال",
      },
    },
  },
  RULES: {
    HEADER: "قواعد",
    SUBTITLE: "اتخاذ الإجراءات تلقائيًا بشأن الأحداث في مساحة العمل الخاصة بك",
    CREATE_BTN: "قاعدة جديدة",
    ZERO_RULES: "لا يوجد قواعد",
    ZERO_DISABLED_RULES: "لا يوجد قواعد معطلة",
    ACTIVE: "نشط",
    DISABLED: "غير نشط",
    SEARCH: "بحث",
    CLEAR_SEARCH: "مسح البحث",
    RULE_NAME: "اسم القاعدة",
    DESCRIPTION: "وصف",
    STATUS: "حالة",
    ACTION: "إجراءات",
    RULE: "قاعدة",
    CREATE_POPUP: {
      CREATE_TITLE: "إنشاء قاعدة جديدة",
      EDIT_TITLE: "تحديث القاعدة",
      VIEW_TITLE: "عرض القاعدة",
      SUBTITLE:
        "إدارة الفريق من خلال إعادة تسميته وتغيير وصفه وإضافة أعضاء الفريق أو إزالتهم.",
      START: "البداية",
      END: "النهاية",
      WA_NAME_TOOLTIP: "هذا الإسم يجب أن يكون نفس أسم العميل على واتساب",
      OUTGOING_MESSAGES_TOOLTIP: "يرجى العلم بأنه لن يتم إرسال أي رسالة صادرة من أي إعلان مفعّل.",
      LABELS: {
        RULE_NAME: "اسم القاعدة",
        RULE_DESCRIPTION: "وصف القاعدة",
        CHANNEL: "قناة",
        TRIGGERS: "محفزات",
        WHEN: "متى",
        IF: "لو",
        THEN: "ثم",
      },
      ERR_MSGS: {
        EMPTY_NAME: "يجب إدخال اسم للقاعدة",
        MIN_NAME: "اسم القاعدة لا يمكن أن يقل عن 3 أحرف",
        MAX_NAME: "اسم القاعدة يجب أن لا يزيد عن 50 حرف",
        EMPTY_DESCRIPTION: "يجب إدخال وصف للقاعدة",
        MIN_DESCRIPTION: "وصف القاعدة يجب ألا يقل عن 3 أحرف",
        MAX_DESCRIPTION: "وصف القاعدة يجب ألا يزيد عن 50 حرف",
        EMPTY_CHANNEL: "لا يمكن ترك القناة فارغة",
        SELECT_VALID_OPTION: "قم بإختيار إحدى الخيارات المتاحة",
        SELECT_VALID_DATES: "يرجى اختيار تاريخ نهاية صالح صالحة"
      },
      PLACEHOLDERS: {
        RULE_NAME: "أضف اسم القاعدة",
        RULE_DESCRIPTION: "إضافة وصف القاعدة",
        SELECT: "قم بالإختيار",
        ENTER_TEXT: "أدخل النص هنا...",
        SELECT_AGENT: "حدد الوكيل",
        ENTER_URL: "اختر ويب هوك",
        ENTER_REASON: "أدخل السبب",
        SELECT_PLATFORMS: "حدد المنصات",
        SELECT_TYPES: "حدد الأنواع",
      },
      CHANNELS: {
        WHATSAPP: "واتساب",
        INSTAGRAM: "انستجرام",
        FACEBOOK: "فيسبوك",
      },
      MESSAGE_TYPE: {
        INCOMING: "رسالة واردة",
        OUTGOING: "رسالة صادرة",
      },
      CHAT_TYPE: {
        OPENED: "فتح المحادثة",
        ASSIGNED: "المحادثة المخصصة",
        CLOSED: "محادثة مغلقة",
        SNOOZED: "المحادثة المؤجلة",
      },
      TIME_ZONE_MESSAGES: {
        TIMEZONE_AVAILABLE: "المنطقة الزمنية هي ",
        TIMEZONE_NOT_AVAILABLE:
          "لم يتم أختيار المنطقة الزمنية، يرجى إختيراها من بيانات مساحة العمل",
      },
      CONDITION_NAME_OPTIONS: {
        TEXT_IN_MESSAGE: "النص في الرسالة",
        MESSAGE_TYPE: "نوع الرسالة",
        MESSAGE_TIME: "وقت الرسالة",
        CUSTOMER_CHANNEL_ID: "معرف قناة العميل",
        CUSTOMER_PLATFORM: "منصة العملاء",
        CUSTOMER_DISPLAY_NAME: "الاسم المعروض للعميل",
        DATE_TIME: "تاريخ الرسالة",
      },
      PLATFORM_OPTIONS: {
        WHATSAPP: "واتساب",
        INSTAGRAM: "انستجرام",
        SMS: "رسالة قصيرة",
        TELEGRAM: "تيليجرام",
        RCS: "RCS",
        FACEBOOK: "فيسبوك",
        PHONE: "هاتف",
        AVOCADO: "مٌتَّصِل",
      },
      TYPE_OPTIONS: {
        TEXT: "نص",
        IMAGE: "صورة",
        AUDIO: "صوتي",
        VIDEO: "فيديو",
        STICKER: "ملصق",
        DOCUMENT: "وثيقة",
        LOCATION: "موقع",
        INTERACTIVE: "تفاعلية",
        TEMPLATE: "قالب",
        CONTACTS: "جهات الاتصال",
      },
      CONDITION_OPERATOR_OPTIONS: {
        EQ: "يساوي",
        "!EQ": "لا يساوي",
        GT: "أكثر من",
        LT: "أقل من",
        GTE: "أكبر من أو يساوي",
        LTE: "اصغر من او يساوي",
        SS: "يتضمن",
        "!SS": "لا يحتوي على",
        SW: "تبدأ بـ",
        "!SW": "لا يبدأ ب",
        RG: "في نطاق",
        RGR: "في نطاق",
        "!RG": "خارج نطاق",
        "!RGR": "خارج نطاق",
      },
      ACTION_OPTIONS: {
        ASSIGN_CHAT: "تعيين المحادثة",
        ADD_TAG: "إضافة علامة",
        REMOVE_TAG: "إزالة العلامة",
        MENTION: "أُذكر",
        WEBHOOK: "خطاف ويب",
        SNOOZE_CONVERSATION: "أجِل المحادثة",
        CLOSE_CONVERSATION: "أغلق المحادثة",
        ASSIGN_LAST_AGENT_TEAM: "تعيين لآخر عميل/فريق",
      },
      SNOOZE_CONVERSATION_OPTIONS: {
        NEXT_15_MINUTES: "الـ 15 دقيقة القادمة",
        NEXT_HOUR: "الساعة القادمة",
        NEXT_3_HOURS: "3 ساعات القادمة",
        NEXT_6_HOURS: "الـ 6 ساعات القادمة",
        NEXT_DAY: "اليوم التالي",
      },
      IN: "في",
      AND: "و",
      OR: "أو",
      BUTTONS: {
        ADD_TRIGGER: "إضافة أحداث الزناد",
        ADD_CONDITION: "إضافة شرط",
        ADD_ACTION: "أضف الإجراء",
        CANCEL: "إلغاء",
        CREATE: "إنشاء قاعدة جديدة",
        CREATING: "يتم...",
        CREATED: "تم",
        ERROR: "خطأ",
        VALIDATING: "جاري التحقق",
        NOT_VALID: "غير صالح",
      },
      INVALID_URL: "URL غير صالح",
      EXISTING_WEBHOOK: "الرد التلقائي على الويب موجود بالفعل",
      VALIDATION_FAILED: "فشل التحقق من الصحة",
      MISSING_OR_INCORRECT_FIELDS: "تم العثور على حقول مفقودة أو غير صحيحة أثناء التحقق من الصحة",
    },
  },
  CSAT: {
    HEADER: "تقارير رضا العملاء",
    DURATION: "مدة",
    DURATION_OPTIONS: {
      LAST_7_DAYS: "اخر 7 ايام",
      LAST_30_DAYS: "آخر 30 يومًا",
      LAST_6_MONTHS: "آخر 6 أشهر",
      LAST_3_MONTHS: "آخر 3 أشهر",
      LAST_YEAR: "العام الماضي",
      CUSTOM_DATE_RANGE: " تخصيص تاريخ محدد",
    },
    SELECT_BY_DATE: "اختر حسب التاريخ",
    SELECT_AGENTS: "حدد فريق العمل",
    AGENTS: {
      AGENT_1: "الوكيل 1",
      AGENT_2: "الوكيل 2",
      AGENT_3: "الوكيل 3",
      AGENT_4: "الوكيل 4",
      AGENT_5: "الوكيل 5",
    },
    DOWNLOAD_REPORTS: "تحميل التقارير",
    TOTAL_RESPONSES: "مجموع الردود",
    SATISFACTION_SCORE: "درجة الرضا",
    RESPONSE_RATE: "معدل الاستجابة",
    FEEDBACK: "تعليق",
    VERY_SAD: "حزين جدا",
    SAD: "حزين",
    NEUTRAL: "حيادي",
    GOOD: "جيد",
    HAPPY: "سعيد",
    CONVERSATION_TRAFFIC: "حركة المحادثة",
    CONTACT: "اتصال",
    ASSIGNED_AGENT: "الوكيل المعين",
    RATING: "تقييم",
    LAST_MESSAGE: "اخر رسالة",
    SATISFIED: "راضي",
    AWESOME: "مذهل",
    EXCELLENT: "ممتاز",
  },
  AGENTS_TEAMS: {
    AGENTS_HEADER: "نظرة عامة على فريق العمل",
    TEAMS_HEADER: "نظرة عامة على الفرق",
    SELECT_TEAM: "اختر فريق",
    COMING_SOON: "(قريبًا)",
    TIME_PLACEHOLDER: "4د 20 ث",
    TEAMS: {
      TEAM_1: "الفريق 1",
      TEAM_2: "الفريق 2",
      TEAM_3: "الفريق 3",
      TEAM_4: "الفريق 4",
      TEAM_5: "الفريق 5",
    },
    CONVERSATIONS: "المحادثات",
    TOTAL_CHATS: "المحادثات",
    TOTAL_IN_CONVERSATIONS: "الرسائل الواردة",
    TOTAL_OUT_CONVERSATIONS: "الرسائل الصادرة",
    TOTAL_CONVERSATIONS: "كل المحادثات",
    TOTAL_OUT_COUNT: "الرسائل الصادرة",
    TOTAL_IN_COUNT: "الرسائل الواردة",
    AVG_RESPONSE_TIME: "متوسط وقت الاستجابة",
    STATISTICS_TIME: '{{min}}د {{sec}}ث',
    FIRST_RESPONSE_TIME: "أول وقت للاستجابة",
    AVG_FIRST_RESPONSE_TIME: "متوسط أول وقت للاستجابة",
    RESOLUTION_TIME: "وفر الوقت",
    AVG_RESOLUTION_TIME: "متوسط وفر الوقت",
    RESOLUTION_COUNT: "عدد التقارير",
    TOTAL_RESOLUTION_COUNT: "عدد التقارير الكلي",
    TOTAL: "في المجموع",
    AVG: "في المتوسط",
    CHART_SUBTITLE: "على أساس الفترة المختارة",
    JAN: "يناير",
    FEB: "فبراير",
    MAR: "مارس",
    APR: "أبريل",
    MAY: "مايو",
    JUN: "يونيو",
    JUL: "يوليو",
    AUG: "أغسطس",
    SEP: "سبتمبر",
    OCT: "أكتوبر",
    NOV: "نوفمبر",
    DEC: "ديسمبر",
    COUNT: "عدد:",
  },
  WORKSPACE_SETTINGS: {
    WORKSPACE_PROFILE: "ملف مساحة العمل",
    SUBTITLE: "إدارة إعدادات مساحة العمل الخاصة بك",
    PROFILE_PHOTO: "صورة الملف الشخصي",
    UPLOAD_IMAGE: "رفع الصورة",
    ORGANIZE_NAME: "اسم المؤسسة",
    LEGAL_NAME: "الاسم القانوني",
    UPDATE: "تحديث",
    BUSINESS_TIMEZONE: "المنطقة الزمنية",
    TIMEZONE_TOOLTIP:
      "المنطقة الزمنية سيتم أستخدامها في القعواعد، رسائل الترحيب والردود التلقائية وبقية العناصر",
  },
  USERS_SETTINGS: {
    USERS: "فريق العمل",
    SUBTITLE: "بإمكانك تعديل كل ما يخص أعضاء فريقك من هنا.",
    ADD_AGENT: "اضافة موظف جديد",
    ROLES: "الصلاحيات",
    AGENTS:  "الموظفين",
    TEAMS: "فريق العمل",
    AVAILABLE: "متاح",
    ROLES_OPTIONS: {
      VIEW: {
        VALUE: "مشاهد فقط",
        SENIORITY_LEVEL: 6
      },
      AGENT: {
        VALUE: "وكيل",
        SENIORITY_LEVEL: 5
      },
      SUPERAGENT: {
        VALUE: "وكيل ثاني",
        SENIORITY_LEVEL: 4
      },
      FINANCE: {
        VALUE: "محاسب",
        SENIORITY_LEVEL: 3
      },
      DEVELOPER: {
        VALUE: "مطور",
        SENIORITY_LEVEL: 2
      },
      ADMIN: {
        VALUE: "مدير",
        SENIORITY_LEVEL: 1
      },
    },
    TEAMS_OPTIONS: {
      SALES: "فريق المبيعات",
      MARKETING: "فريق التسويق",
      SUPPORT: "يدعم",
    },
    AVAILABLE_OPTIONS: {
      ACTIVE: "نشط",
      INACTIVE: "غير نشط",
      OFFLINE: "غير متصل بالإنترنت",
      DELETED: 'تم الحذف',
    },
    EMPTY_NAME: "يجب ادخال إسم المستخدم",
    MIN_NAME: "يجب ألا يقل الاسم عن 5 أحرف",
    MAX_NAME: "يجب ألا يزيد الاسم عن 30 حرف",
    EMPTY_EMAIL: "بريد إلكتروني غير صالح",
    INVALID_EMAIL: "يجب إدخال بريد إلكتروني صحيح",
    INVALID_ACCES_LEVEL: "يجب إدخال مستوى الوصول",
    EMPTY_STATUS: "يجب إدخال حالة المستخدم",
    SEARCH_AGENTS: "بحث..",
    ROLE: "دور",
    LAST_SEEN_ON: "آخر ظهور",
    LAST_SEEN: "{{date}}",
    STATUS: "حالة الموظف",
    ACTION: "الإجراءات",
    EDIT: "تحرير",
    REVOKE: "إلغاء",
    ADD_USER: "إضافة موظف",
    ADDING: "جارٍ الإضافة...",
    ADDED: "تمت الإضافة",
    EDIT_USER: "تحرير العضو",
    ADD_USER_SUBTITLE: "إدارة ودعوة أعضاء الفريق إلى مساحة العمل الخاصة بك.",
    EDIT_USER_SUBTITLE: "تحرير أعضاء الفريق من مساحة العمل الخاصة بك.",
    USER_NAME: "اسم المستخدم",
    USER_EMAIL: "البريد الالكتروني للمستخدم",
    ACCESS_LEVEL: "دور العميل",
    OWNER_NOTE:
      "يمكن للمالك الوصول إلى كافة الوحدات داخل مساحة العمل ولديه التحكم الكامل في الإعدادات دون أي قيود.",
    MANAGER_NOTE:
      "يمكن للمديرين الوصول إلى جميع الوحدات المختلفة داخل مساحة العمل بما في ذلك الإعدادات. ",
    AGENT_NOTE:
      "يمكن للمديرين الوصول إلى جميع الوحدات المختلفة داخل مساحة العمل بما في ذلك الإعدادات. ",
    ADVANCED_RESTRUCTION: "إعادة الإعمار المتقدمة",
    RESTRICT_CONTACT_VISIBILITY: {
      TITLE: "تقييد رؤية جهة الاتصال",
      SUBTITLE: "يمكن لهذا المستخدم فقط عرض جهات الاتصال المخصصة له",
      OPTIONS: {
        ANYONE_IN_THE_USER_TEAM: "أي شخص في فريق المستخدم",
        USER: "مستخدم",
      },
    },
    RESTRICT_SHORTCUT_BUTTON: {
      TITLE: "تقييد زر الاختصار",
      SUBTITLE: "سيتم تعطيل زر الاختصارات في وحدة المراسلة لهذا المستخدم",
    },
    RESTRICT_DATA_EXPORT: {
      TITLE: "تقييد تصدير البيانات",
      SUBTITLE: "لن يتمكن هذا المستخدم من تصدير البيانات من مساحة العمل.",
    },
    RESTRICT_CONTACT_DELETION: {
      TITLE: "تقييد حذف جهة الاتصال",
      SUBTITLE: "لن يتمكن هذا المستخدم من حذف أية جهات اتصال من مساحة العمل.",
    },
    RESTRICT_WORKSPACE_SETTINGS_ACCESS: {
      TITLE: "تقييد الوصول إلى إعدادات مساحة العمل",
      SUBTITLE: "لن يتمكن هذا المستخدم من الوصول إلى إعدادات مساحة العمل.",
    },
    RESTRICT_INTEGRATION_SETTINGS_ACCESS: {
      TITLE: "تقييد الوصول إلى إعدادات التكامل",
      SUBTITLE: "لن يتمكن هذا المستخدم من الوصول إلى إعدادات التكامل.",
    },
    REVOKE_POPUP: {
      TITLE: "إبطال الوصول",
      SUBTITLE: "هل أنت متأكد أنك تريد إلغاء وصول هذا المستخدم؟",
      ASSIGN_TO_USER: "تعيين جهات الاتصال لمستخدم آخر",
      NOTE: "إذا قمت بإلغاء هذا المستخدم، فسيتم وضع علامة على جميع جهات الاتصال المخصصة لهذا المستخدم على أنها غير مخصصة.",
      CONFIRM: "تأكيد",
      REVOKING: "يتم الإلغاء",
      REVOKED: "تم اﻹلغاء",
    },
    CANCEL: "إلغاء",
    UPDATE: "تحديث",
    UPDATING: "جارٍ التحديث...",
    UPDATED: "محدث",
    ERROR: "خطأ",
    DELETE: "حذف",
    ADD_CREDIT: "إضافة رصيد",
    ADDING_AGENT: "إضافة عميل",
    ADD_AGENT_FAILED: "فشل إضافة العميل",
    ADD_AGENT_FAILED_DESC:
      "إضافة عميل إضافي ستكلف ${{cost}}، ليس لديك محفظة كافية للمتابعة",
      ADD_AGENT_DESC: "ستكلف إضافة عميل إضافي مبلغ ${{cost}}. هل تريد المتابعة؟",
      AGENT_ACTIVATED: "تم تنشيط العميل بنجاح",
      AGENT_ACTIVATED_DESC:
      "يرجى العثور على المستخدم من قسم العميل في إعدادات مساحة العمل",
      ADD_TEAM: "إضافة فريق",
      ADD_TEAM_FAILED: "فشل إضافة الفريق",
      ADD_TEAM_FAILED_DESC:
      "إضافة فريق إضافي ستكلف ${{cost}}، ليس لديك محفظة كافية للمتابعة",
      ADD_TEAM_DESC: "ستكلف إضافة فريق إضافي مبلغ ${{cost}}. هل تريد المتابعة؟",
      TEAM_ACTIVATED: "تم تنشيط الفريق بنجاح",
      TEAM_ACTIVATED_DESC:
      "يرجى ابحث عن الفريق من قسم الفرق في إعدادات مساحة العمل.",
      CONFIRM: "تأكيد",
      RENEW:'تجديد',
      SUBSCRIPTION_EXPIRED:'انتهى فترة الاشتراك الخاص بكم في {{endDate}}, جدد الاشتراك الخاص بكم',
  },
  TEAMS_SETTINGS: {
    TEAMS: "اقسام الموظفين",
    SUBTITLE:
      "قم بإنشاء فِرق وإدارتها لتجميع المستخدمين في مساحة العمل الخاصة بك.",
    ADD_TEAM: "إضافة فريق",
    ADDING: "جاري الإضافة...",
    ADDED: "تمت الإضافة",
    ERROR: "خطأ",
    SEARCH_TEAMS: "بحث الفِرق",
    TEAM_NAME: "اسم الفريق",
    TEAM_EMAIL: "البريد الإلكتروني",
    DESCRIPTION: "وصف",
    MEMBERS: "أعضاء",
    ACTION: "إجراءات",
    ERROR_MESSAGES: {
      EMPTY_NAME: "يجب إدخال اسم الفريق",
      MIN_NAME: "اسم الفريق لا يمكن أن يقل عن 5 أحرف",
      EMPTY_EMAIL: "يجب إدخال بريد إلكتروني للفريق",
      EMPTY_DESCRIPTION: "يدجب إدخال وصف للفريق",
      MIN_DESCRIPTION: "لا يمكن أن يقل الوصف عن 20 حرف",
      EMPTY_MEMBERS: "يجب إدخال أعضاء للفريق",
      MIN_MEMBERS: "لا يمكن أن يقل عدد الأعضاء عن 1"
    },
    ADD_TEAM_SUBTITLE:
      "إضافة فريق من خلال تسميته وتغيير وصفه وإضافة أعضاء الفريق.",
    TEAM_NAME_PLACEHOLDER: "فريق المبيعات",
    TEAM_DESCRIPTION: "وصف الفريق",
    TEAM_DESCRIPTION_PLACEHOLDER: "أضف وصف الفريق",
    TEAM_MEMBERS: "أعضاء الفريق",
    TEAM_MEMBERS_PLACEHOLDER: "إضافة أعضاء الفريق",
    MANAGE_TEAM: "إدارة الفريق",
    MANAGE_TEAM_SUBTITLE:
      "إدارة الفريق من خلال إعادة تسميته وتغيير وصفه وإضافة أعضاء الفريق أو إزالتهم.",
    CANCEL: "إلغاء",
    SAVE: "حفظ",
    SAVING: "يتم...",
    SAVED: "تم",
    REVOKE_POPUP: {
      SUBTITLE: "هل أنت متأكد من أنك تريد إلغاء إمكانية وصول هذا الفريق؟",
      ASSIGN_TO_TEAM: "تعيين جهات الاتصال إلى فريق آخر",
      NOTE: "إذا قمت بإلغاء هذا الفريق، فسيتم وضع علامة على جميع جهات الاتصال المعينة لهذا الفريق بأنها غير معينة.",
    },
    ZERO_TEAMS: "لا يوجد أي فريق",
    CREATE_TEAM_BTN:"إضافة فريق جديد",
  },
  CONTACTS_SETTINGS: {
    CONTACTS: "جهات الاتصال",
    AGENT: "موظف",
    TEAM: "فريق",
    SUBTITLE:
      "قم بإنشاء وإدارة حقول جهات الاتصال لحفظ معلومات إضافية حول جهات الاتصال الخاصة بك.",
    ADD_CONTACT: "إضافة جهة اتصال",
    ALL_CONTACTS: "كل جهات الاتصال",
    ARCHIVED: "الأرشيف",
    SEARCH_CONTACTS: "بحث",
    CHANNEL: {
      PLACEHOLDER: "قناة التواصل",
      OPTIONS: {
        WHATSAPP: "واتساب",
        FACEBOOK: "فيسبوك",
        INSTAGRAM: "انستجرام",
      },
    },
    TAGS: {
      PLACEHOLDER: "الوسوم",
      OPTIONS: {
        PREMIUM: "غالي",
        ASSIGNED: "مُكَلَّف",
        UNASSIGNED: "غير معينة",
        TAG_3: "العلامة 3",
        TAG_4: "العلامة 4",
        TAG_5: "العلامة 5",
      },
    },
    DATE: {
      PLACEHOLDER: "تخصيص التاريخ",
      OPTIONS: {
        LAST_7_DAYS: "اخر 7 ايام",
        LAST_30_DAYS: "آخر 30 يومًا",
        LAST_6_MONTHS: "آخر 6 أشهر",
        LAST_3_MONTHS: "آخر 3 أشهر",
        LAST_YEAR: "العام الماضي",
        CUSTOM_DATE_RANGE: " تخصيص تاريخ محدد",
      },
    },
    SELECT_BY_DATE: "اختر حسب التاريخ",
    UESRS: "المستخدمين",
    EMAIL: "بريد إلكتروني",
    ACTION: "إجراءات",
    UNARCHIVE: "إلغاء الأرشفة",
    DELETE: "حذف",
  },
  CUSTOM_ATTRIBUTES: {
    HEADER: "الوسوم المخصصه",
    SUBTITLE:
      "إنشاء واصفات فريدة والإشراف عليها لتعزيز المعلومات المرتبطة بالأصول، وتوسيع معناها إلى ما هو أبعد من السمات القياسية",
    CONCERSATION: "محادثة",
    ZERO_CUSTOMS: "لا يوجد وسوم لعرضها",
    CONTACT: "اتصال",
    TYPE: "النوع",
    KEY: "مفتاح",
    ADD_CUSTOM_ATTR: "اضافة وسم مخصص",
    SEARCH_PLACEHOLDER: "بحث",
    CANT_DELETE_ITEM: "لا يمكن إزالة هذا العنصر. يمكن أن يكون قيد الاستخدام بالفعل مع أحد أهداف البث أو متصلاً بالعميل.",
    CREATE_POPUP: {
      TITLE: "اضافة وسم مخصص",
      UPDATE_TITLE: "تحرير السمة المخصصة",
      APPLY_TO: "ينطبق على",
      DISPLAY_NAME: "اسم العرض",
      PLACEHOLDER_KEY: "أدخل مفتاح السمة المخصصة",
      PLACEHOLDER_DNAME: "أدخل اسم عرض السمة المخصصة",
      PLACEHOLDER_TYPE: "حدد نوع السمة المخصصة",
      PLACEHOLDER_D_DESCRIPTION: "أدخل الوصف هنا",
      PLACEHOLDER_APPLIES_TO: "حدد سمة مخصصة تنطبق على",
      DISCRIPTION_PH: "أدخل وصف السمة المخصصة",
      TEXT: "نص",
      URL: "عنوان URL",
      SWITCH: "يُحوّل",
      CHECKBOX: "خانة الاختيار",
      CHIPS: "رقائق",
      DATE: "تاريخ",
      TIME: "وقت",
      REQUIRED_NAME: "يجب إدخال اسم للسمة المميزة",
      TOOLTIP: {
        KEY: "لا يمكن تحديث المفتاح",
        TYPE: "لا يمكن تحديث النوع",
      },
      SUBMIT: {
        ADD: "اضافة وسم مخصص",
        UPDATE: "تحرير",
        ADDING: "اضافة وسم مخصص...",
        UPDATING: "جارٍ التحرير...",
        ADDED: "تمت إضافة السمة المخصصة",
        UPDATED: "تم التحرير",
        ERROR: "خطأ",
      },
      ERROR_MSG: {
        UNIQUENESS_VIOLATION : "خطأ بسبب التكرار",
        CUSTOM_DUPLICATED : "لقد تم إضافة هذة الوسم من قبل.",
        LIMIT_VIOLATION :"خطأ بسبب الحد الاقصى",
        CUSTOM_LIMIT_REACH : "لقد تم الوصول للحد الاقصى للوسوم المتاح اضافتها." ,
      }
    },
    TABLE: {
      URL: "رابط",
      TEXT: "نص",
      SWITCH: "مٌبدل",
      CHECKBOX: "خانة إختيار",
      CHIPS: "رقائق",
      DATE: "تاريخ",
      TIME: "وقت",
    },
    DELETE_CUSTOM_ATTRIBUTES_ERROR: "حدث خطأ أثناء حذف الوسم من قائمة الأتصال",
  },
  CONVERSATIONS: {
    CONVERSATIONS: "المحادثات",
    DURATION: "مدة",
    DURATION_OPTIONS: {
      LAST_7_DAYS: "اخر 7 ايام",
      LAST_30_DAYS: "آخر 30 يومًا",
      LAST_6_MONTHS: "آخر 6 أشهر",
      LAST_3_MONTHS: "آخر 3 أشهر",
      LAST_YEAR: "العام الماضي",
      CUSTOM_DATE_RANGE: " تخصيص تاريخ محدد",
    },
    EXPORT: "تصدير",
    SELECT_BY_DATE: "اختر حسب التاريخ",
    GROUP_BY: "",
    GROUP_BY_OPTIONS: {
      DAY: "يوم",
      WEEK: "أسبوع",
      MONTH: "شهر",
    },
    DOWNLOAD_REPORTS: "تحميل التقارير",
  },
  OVERVIEW: {
    OVERVIEW: "ملخص",
    CONVERSATIONS_STATS: "إحصائيات المحادثات",
    AGENTS_STATUS: "حالة فريق العمل",
    LIVE: "مباشر",
    DOWNLOAD_REPORTS: "تحميل التقارير",
    OPEN: "المحادثات المفتوحة",
    UNATTENDED: "غير المراقب",
    UNASSIGNED: "غير معينة",
    ONLINE: "متصل",
    CONVERSATION_TRAFFIC: "حركة المحادثة",
    CONVERSATIONS_BY_AGENTS: "المحادثات من قبل فريق العمل",
    USERS: "فريق العمل",
    ROLE: "دور",
    TEAM: "فريق",
    ROLES: {
      VIEW:"عرض فقط",
      AGENT: "موظف",
      SUPERAGENT: "موظف رئيسي",
      FINANCE: "محاسب",
      DEVELOPER: "مطور",
      ADMIN: "مدير",
    },
    TEAMS: {
      SALES: "فريق المبيعات",
      SUPPORT: "فريق الدعم",
      MARKETING: "فريق التسويق",
    },
  },
  DASHBOARD: {
    ANALYTICS: "التحليلات",
    SUBTITLE: "عرض تقارير النظام الأساسي الخاص بك هنا",
    OVERVIEW: "ملخص",
    CSAT_REPORTS: "تقارير رضا العملاء",
    AGENTS: "فريق العمل",
    TEAMS: "فرق",
    CONVERSATIONS: "المحادثات",
  },
  MEDIA_LIBRARY: {
    HEADER: "مكتبة الوسائط",
    ASSET: "أصل",
    SUBTITLE: "حمل و استعرض جميع ملفات الوسائط بمكان واحد",
    UPLOAD_1: "انقر للتحميل ",
    UPLOAD_2: "أو قم بادراج ملف جديد",
    UPLOAD_2_MOBILE: "ملف هنا",
    UPLOAD_SUBTITLE:
      "يجب ألا يزيد حجم ملفات الصور، الصوت، الفيديو، والمستندات عن 10 ميجابايت",
    SORT_BY: "ترتيب",
    OLDEST: "الأقدم",
    LATEST: "الأحدث",
    MEDIA_TYPES: {
      ALL: "الجميع",
      IMAGES: "صور",
      AUDIO: "صوتيات",
      VIDEO: "فيديو",
      DOCUMENTS: "الوثائق",
    },
    INFO: "معلومات",
    VIEW: "عرض",
    COPY_URL: "نسخ الرابط",
    DELETE: "حذف",
    B: "{{size}} ب",
    KB: "{{size}} كيلو بايت",
    MB: "{{size}} ميغابايت",
    GB: "{{size}} غيغابايت",
    CREATED_AT: "انشئ في",
    CREATED_BY: "منشأ من قبل",
    UPLOAD_FILE: "رفع ملف",
    MEDIA_TITLE: "عنوان الوسائط",
    CAPTION: "التسمية التوضيحية",
    REQUIRED_CAPTION: "يجب إدخال سمة توضيحية",
    UPLOADING: " • جارٍ التحميل...",
    TITLE: "عنوان",
    SIZE: "الحجم",
    MODIFIED_AT: "أخر تعديل",
    ACTIONS: "أجراءات",
    CANT_UPLOAD: "لا يمكن رفع هذا الملف",
    UPLOAD_WARNING: "حجم هذا الملف هو {{size}} ميجابايت، ويتجاوز الحد المسموح به (10 ميجابايت)",
    UPLOAD_FORMATE_WARNING: "لا يمكن رفع ذلك الملف نظرًا لان الامتداد الخاص بيه غير مدعوم",
    ZERO_MEDIA: "لا يوجد وسائط",
  },
  WHATSAPP_FLOW: {
    HEADER: "تدفق الواتساب",
    SUBTITLE: "قم بإنشاء وإدارة تدفق WhatsApp هنا",
    SEARCH_WA_FLOWS: "أبحث عن الردود",
    FILTERS: {
      STATUS: {
        OPTIONS: {
          DRAFT: "Draft",
          PUBLISHED: "Published",
        },
      },
    },
    TABLE: {
      FLOW_NAME: "إسم الرد",
      FLOW_ID: "مُعرف الرد",
      STATUS: "الحالة",
      LAST_UPDATE: "آخر تحديث",
    },
    CREATE_FLOW: "إنشاء الرد",
    CREATE_WHATSAPP_FLOW: "إنشاء رد WhatsApp",
    CREATE_WHATSAPP_FLOW_SUBTITLE: "قم بإنشاء رد WhatsApp هنا",
    NO_FLOW_YET: "ليس لديك ردود بعد",
    NO_FLOW_MATCH: "ليس لديك ردود توافق البيانات التي أدخلتها",
    SAMPLE_FLOW: "رد العينة",
    DRAFT: "مسودة",
    SELECT_CATEGORIES: "حدد الفئات",
    SELECT_TEMPLATE: "حدد القالب (اختياري)",
    CANCEL: "إلغاء",
    CREATE: "إنشاء",
    EDITOR: "محرر",
    RUN: "يجري",
    SAVE: "حفظ",
    DONE: "منتهي",
    CONTINUE: "متابعة",
    PREVIEW: "معاينة",
    FIRSTNAME: "الاسم الأول",
    LASTNAME: "اسم العائلة",
    PHONE_NUMBER: "رقم التليفون",
    SIGNUP_TITLE: "انضم إلى قائمة الانتظار الآن!",
    SIGNUP_SUBTITLE: "الرجاء إدخال التفاصيل أدناه",
    SIGNUP_TITLE_1: "قبل ان تذهب",
    SIGNUP_SUBTITLE_1: "كيف سمعت عنا؟",
    TEMPLATES: {
      NONE: "بدون",
      BOOK_TABLE: "طاولة كتب",
      COMPLETE_OUR_QUIZ: "أكمل اختبارنا",
      COMPLETE_SIGNUP: "الاشتراك الكامل",
      GET_FEEDBACK: "احصل على تعليقات",
      GET_QUOTE: "الحصول على الاقتباس",
      GET_SUPPORT: "احصل على الدعم",
      REGISTER_ACCOUNT: "سجل للحصول على حساب",
      REGISTER_EVENT: "سجل للحدث",
      SIGNIN: "تسجيل الدخول",
      UPDATE_PREFERENCES: "تحديث التفضيلات",
    },
    CHANGES_SAVED: "تم حفظ التعديلات",
  },
  COMMON: {
    ACTIONS_BTN: {
      VIEW: "عرض",
      EDIT: "تحرير",
      NEXT: "التالى",
      DONE: "تم",
      YES: "حذف",
      NO: "إلغاء",
      DELETE: "حذف",
      SHOW_ALL: "عرض الكل",
      DEACTIVATE: "إلغاء التفعيل",
      COPIED:"تم النسخ"
    },
    DELETE_CONFIRMATION_DIALOGUE: {
      TITLE: 'حذف <0>"{{name}}"</0>',
      BODY: 'هل أنت متأكد أنك تريد حذف <0>"{{name}}"</0> ؟ ',
    },
    DELETE__DIALOG_2: {
      TITLE: "هل أنت متأكد؟",
      BODY: " أنت على وشك حذف هذا {{item}}. ",
      CANCEL: "إلغاء",
      DELETE: "حذف",
    },
    PAGINATION: {
      TITLE: "عرض من {{start}} إلى {{end}} من الكل {{total}}",
      ZERO_ENTERIES: "عرض 0 من 0 إدخالات",
      TITLE_MOBILE: "{{start}}-{{end}} من {{total}} عنصر",
      ZERO_ENTERIES_MOBILE: ". من . إدخالات",
    },
    WARNING: {
      TITLE: "تحذير",
      CANCEL: "إلغاء",
      CONTINUE: "متابعة",
    },
    MSB: {
      SEARCH: "بحث...",
      SELECT: "قم بالإختيار",
    },
    STATUS: {
      COMPLETED: "مكتمل",
      FAILED: "فشل",
      IN_PROGRESS: "في تَقَدم",
    },
    FILTER: "بحث",
    CLOSE: "إغلاق",
    SEARCH: "بحث",
    RESET: "اعادة ضبط",
    SECONDS: "ثواني",
    MINUTES: "دقائق",
    HOURS: "ساعات",
    RE_INSTALL: "إعادة التثبيت",
    SECONDS_PLACEHOLDER: "ثانية = 60",
    MINUTES_PLACEHOLDER: "الدقائق=60",
    HOURS_PLACEHOLDER: "الساعات = 12",
    SUBMIT: "يُقدِّم",
    SHARE: "يشارك",
    CANCEL: "إلغاء",
    UPLOAD: "رفع",
    SUBMITTING: "تقديم...",
    SUBMITTED: "مُقَدَّم",
    ERROR: "خطأ",
    CLEAR: "واضح",
    CLOSE_WARNING:
      "أنت على وشك إغلاق النافذة الحالية، وسيتم فقدان كافة التغييرات. ",
    CONTINUE: "متابعة",
    SKIP: "يتخطى",
    CONFIRM: "تأكيد",
    CREATED_AT_DATE: "انشئ في {{date}}",
    CHANGE: "تغيير",
    UNAVAILABLE: "غير متوفره",
    CREATED_AT: "انشئ في{{date}}",
    EMAIL: "البريد الالكتروني",
    NAME: "اسم",
    SEND: "إرسال",
    BACK: "العودة",
    ADD: "إضافة",
    SAVE: "حفظ",
    UNINSTALL: "إلغاء التثبيت",
    ALL: "الكل",
    AGENTS: "الموظفين",
    TEAMS: "الفرق",
    TODAY: "اليوم",
    YESTERDAY: "الأمس",
    INSTALLED: "مُثبت",
    INSTALL: "تثبيت",
    NO_RESULTS_FOUND: "لا يوجد نتائج لما تبحث عنه",
    KINDLY_TRY_AGAIN: "جرب مرة أخرى ببحث مختلف",
    CANT_DELETE_ITEM: "لا يمكن حذف هذا العنصر.",
  },
  VALIDATIONS: {
    CONFIRM_PASSWORD:
      "كلمة المرور الخاصة بك وكلمة المرور التأكيدية غير متطابقتين.",
    INVALID_EMAIL_PASSWORD: "البريد الإلكتروني أو كلمة السر خاطئة",
    EMAIL_EXISTS: "البريد الالكتروني موجود مسبقا",
    TOKEN_EXPIRED_RESET_PASSWORD: "الرمز منتهي الصلاحية/غير صالح. ",
    NOT_REGISTERED: "لا توجد حسابات تطابق هذا البريد الإلكتروني. ",
    INVALID_EMAIL: "هذا بريد إلكتروني غير صالح",
    INVALID_MAX_EMAIL_LENGTH: "البريد الإلكتروني يجب أن لا يزيد عن 60 حرف",
    INVALID_PASSWORD: "كلمة السر يجب أن تتكون من 8 أحرف على الأقل",
    INVALID_MAX_PASSWORD_LENGTH: "كلمة السر يجب أن لا تزيد عن 30 حرف",
    EMPTY_FIELD: "لا يمكن ترك هذا الحقل فارغًا",
    CONTACTS: {
      MIN_NAME: "يجب أن يتكون الإسم من 5 حروف فأكثر",
      INVALID_PHONE_NUM: "رقم هاتف غير صالح",
    },
    TEMPLATES: {
      EMPTY_MEDIA_TYPE: "يرجى إختيار نوع الوسائط.",
      EMPTY_TOGGLE_BTNS: "يرجى إختيار من بين الخيارات المتاحة.",
      EMPTY_BTN_TYPE: "يرجى إختيار نوع الزر.",
      INVALID_TEMPLATE_ID:
        "مُعرِّف النموذج يجب أن يتكون من الحروف والأرقام فقط",
      TEMP_ID_LESS_THAN_5: "يجب أن لا يقل إسم مٌعرّف النموذج عن 5 أحرف",
      SIGNATURE_HASH_LENGHT: "يجب أن يتكون هذا الحقل من 11 حرف",
    },
    ACCOUNT_DELETED: 'لقد تم حذف هذا الحساب'
  },
  CHATGPT: {
    CREATE_POPUP: {
      TITLE: "ChatGPT",
      SUBTITLE:
        "يتيح لك ChatGPT اقتراح ردود سريعة بناءً على آخر 20 رسالة من المحادثات.",
      SUBTITLE1:
        "ويتم تدريبه أيضًا على قاعدة المعرفة لتقديم المزيد من الاستجابات السياقية للتعلم من بيانات المنظمة.",
      OPEN_API_KEY: "افتح واجهة برمجة التطبيقات",
      PLACEHOLDER_OPEN_API_KEY: "حامل yikk3k-4sdfhh-34fwef-XXXXXX-XXX",
      CHATGPT_CANT_BE_ENABLED: "* لا يمكن تفعيل ChatGPT بعد",
      OPTION_AGENT: {
        TITLE: "وكيل",
        PLACEHOLDER_AVAILABLE: " متاحة لل",
        AGENT: "عملاء",
        SUPERAGENT: "وكيل أعلى",
        ADMIN: "مدير",
      },
      BUTTONS: {
        SAVE: "حفظ",
        CANCEL: "إلغاء",
      },
    },
  },
  DIALOGFLOW: {
    CREATE_POPUP: {
      TITLE: "Dialogflow",
      SUBTITLE:
        "يتيح لك Dialogflow التكامل مع برنامج المحادثة الآلي المخصص الخاص بك. ",
      CONNECT: "روبوت المحادثة المخصص",
      DIALOGFLOW_CANT_BE_ENABLED: "* لا يمكن تمكين Dialogflow بعد",
      OPTION_CONNECT: {
        PLACEHOLDER_CONNECT: "كل الشات بوت",
        UNANSWERED: "المحادثات التي لم يتم الرد عليها",
        ASSIGNED_CHAT: "المحادثات المخصصة",
        UNASSIGNED_CONTACTS: "جهات الاتصال غير المعينة",
        CLOSED_CHATS: "المحادثات المغلقة",
        SNOOZED_CHATS: "المحادثات المؤجلة",
        ARCHIVED_CONTACTS: "جهات الاتصال المؤرشفة",
      },
      DRAG_DROP: {
        HEADER: "",
        TITLE: "قم بإسقاط تكوين حساب الخدمة الخاص بك JSON هنا",
        BUTTONS: {
          SELECTED_FILES: "اختر الملفات",
        },
      },
      BUTTONS: {
        SAVE: "حفظ",
        CANCEL: "إلغاء",
      },
    },
  },
  GOOGLE_TRANSLATE: {
    CREATE_POPUP: {
      TITLE: "جوجل المترجم",
      SUBTITLE: "قم بترجمة رسائل عملائك بسرعة بمساعدة ترجمة Google.",
      GOOGLE_API_KEY: "مفتاح واجهة برمجة تطبيقات جوجل",
      PLACEHOLDER_GOOGLE_API_KEY: "ayjkk3k-4sdfhh-34fwef-XXXXXX-XXX",
      TRANSLATE_CANT_BE_ENABLED: "* لا يمكن تمكين الترجمة بعد",
      BUTTONS: {
        SAVE: "حفظ",
        CANCEL: "إلغاء",
      },
    },
  },
  CSML: {
    HEADER: "CSML",
    SUBTITLE:
      "قم بدمج روبوتات المحادثة CSML في النظام الأساسي الخاص بك لأتمتة دعم العملاء وعمليات المبيعات",
    CSML_CANOT_BE_ENABLED: "* لا يمكن تفعيل CSML بعد",
    CSML_TABLE: {
      TITLE: "اسم البوت",
      BOT_DESCRIPTION: "وصف البوت",
    },
    BUTTONS: {
      SAVE: "التحقق من صحة وحفظ",
    },
  },
  WHATSAPP_INTEGRATION: {
    HEADER: "التكامل واتساب",
    SUBTITLE: "إدارة واجهة برمجة تطبيقات المراسلة الخاصة بـ WhatsApp",
    WHATSAPP_INFO: {
      HEADER: "معلومات واتساب",
      SUBTITLE: "عرض وتكوين حساب WhatsApp Business الخاص بك",
      ACCOUNT_CONFIG: {
        HEADER: "تكوين الحساب",
        LEGAL_BUSINESS_NAME: "اسم العمل القانوني:",
        BUSINESS_MANAGER_ID: "معرف مدير الأعمال:",
      },
      WHATSAPP_BUSINESS_PROFILE: {
        HEADER: "الملف الشخصي واتساب للأعمال",
        USER_PROFILE: {
          HEADER: "ملف تعريفي للمستخدم",
          PROFILE_PHOTO: "صور بفوريلي",
          ABOUT_TEXT: "حول النص",
        },
        BUSINESS_PROFILE: {
          HEADER: "الملف التجاري",
          ADDRESS: "عنوان",
          DESCRIPTION: "وصف",
          EMAIL: "بريد إلكتروني",
          WEBSITE_PRIMARY: "الموقع الإلكتروني (أساسي)",
          WEBSITE_SECONDARY: "موقع الكتروني (ثانوي)",
          BUSINESS_VERTICAL: "الأعمال العمودية",
          FILTER_BUSINESS_VERTICAL: {
            UNDEFINED: "غير معرف",
            OTHER: "آخر",
            AUTO: "آلي",
            BEAUTY: "الجمال",
            APPAREL: "الملابس",
            EDU: "تعليم",
            ENTERTAIN: "الترفيه",
            EVENT_PLAN: "خطة الحدث",
            FINANCE: "تمويل",
            GROCERY: "خضروات",
            GOVT: "الحكومة",
            HOTEL: "الفندق",
            HEALTH: "الصحة",
            NONPROFIT: "غير ربحية",
            PROF_SERVICES: "خدمات محترفة",
            RETAIL: "بيع بالتجزئة",
            TRAVEL: "السفر",
            RESTAURANT: "مطعم",
            NOT_A_BIZ: "ليست شركة تجارية",
          },
        },
        PROFILE_REVIEW: {
          HEADER: "الملف الشخصي",
          NAME: "اسم",
          WARN: "هذا ليس اسم المستخدم أو الرقم السري الخاص بك. ",
          ABOUT: "عن",
          PHONE: "هاتف",
        },
        REVIEW_BUTTON: "إرسال للمراجعة",
      },
      CHANNEL_ACCOUNT_EVENTS: {
        HEADER: "أحداث حساب القناة",
        EVENT_TYPE: "نوع الحدث",
        EVENT_ID: "معرف الحدث",
        DATE: "تاريخ",
        DETAILS: "تفاصيل",
        EVENT_LOG: "سجل الأحداث",
        VIEW_JSON_BTN: "عرض جسون",
        VIEW_JOSN_POPUP: {
          HEADER: "عرض جسون",
          CANCEL: "إلغاء",
          COPY_JSON: "انسخ ملف JSON",
          COPIED: "نسخ",
        },
      },
    },
    ACCOUNT_EVENTS: {
      HEADER: "أحداث الحساب",
      SUBTITLE: "أحداث في الوقت الفعلي على مستوى WhatsApp Business API لحسابك",
      REGISTRATION_PROGRSS: {
        HEADER: "تقدم التسجيل",
        CREATED: "مخلوق",
        SUBMITTED: "مُقَدَّم",
        REVOKED: "إلغاء - فسخ",
        LIVE: "مباشر",
      },
      CHART: {
        ALL_CONVERSATION: "جميع المحادثات",
        BUSINESS_INITIATED: "بدأ العمل",
        USER_INITIATED: "بدأ المستخدم",
        MARKETING: "تسويق",
        SERVICE: "خدمة",
        AUTHENTICATION: "المصادقة",
        UTILITY: "جدوى",
      },
    },
    CUSTOMIZE: "يعدل أو يكيف",
  },
  HUBSPOT: {
    HEADER: "HubSpot",
    SUBTITLE:
      "أرسل جهات الاتصال والمحادثات إلى HubSpot واستخدم HubSpot لإرسال الرسائل",
    HUBSPOT_INTEGRATION_TITLE: "كيف يعمل؟",
    HUBSPOT_INTEGRATION_SUBTITLE:
      "سيسمح لك تكامل Hubspot بربط محادثات Avocado بجهات اتصال HubSpot الخاصة بك. ",
    READ_MORE: "اقرأ أكثر",
    HUBSPOT_CONNECTION_GUIDE_TITLE:
      "فيما يلي دليل خطوة حول كيفية دمج مساحة عمل HubSpot الخاصة بك مع Avocoda",
    HUBSPOT_CONNECTION_GUIDE_BUTTON: "دليل اتصال HubSpot",
    HUBSPOT_WORKSPACE: "قم بتوصيل مساحة عمل HubSpot الخاصة بك",
    HUBSPOT_WORKSPACE_SUBTITLE: "انقر فوق الزر لتوصيل مساحة العمل الخاصة بك",
    CONNECT_BUTTON: "قم بتوصيل HubSpot",
  },
  LOGIN: {
    AVOCADO: "متصل",
    TWERLO_PRODUCT: "منتج تويرلو",
    SIGNUP_TITLE: "إنشاء حساب",
    LOGIN_TITLE: "تسجيل الدخول إلى حسابك",
    CHECK_EMAIL_TITLE: "تفقد بريدك الالكتروني من فضلك",
    FORGOT_PASSWORD_TITLE: "هل نسيت كلمة السر",
    WORKSPACE_NAME_TITLE: "قم بتسمية مساحة العمل الخاصة بك",
    WORKSPACE_NAME_SUBTITLE: "قم بتسمية مساحة العمل الخاصة بك للبدء",
    PHONE_NUMBER_TITLE: "احصل على إجابات الخبراء لأسئلتك",
    PHONE_NUMBER_SUBTITLE:
      "سوف نقوم بتوصيلك مرة أخرى على الواتساب.<0></0>(يمكنك إلغاء الاشتراك في أي وقت)",
    ROLE_TITLE: "ما هو دورك؟",
    ROLE_SUBTITLE: "اسمحوا لنا أن نعرف ما هو دورك في شركتك",
    TEAM_SIZE_TITLE: "ما هو حجم فريقك؟",
    TEAM_SIZE_SUBTITLE: "اسمحوا لنا أن نعرف ما هو دورك في شركتك",
    PURPOSE_TITLE: "لماذا تحتاج مٌتَّصِل",
    PURPOSE_SUBTITLE: "حدد خيارًا واحدًا أو أكثر",
    NEW_PASSWORD_TITLE: "إنشاء كلمة مرور جديدة",
    NEW_PASSWORD: "كلمة المرور الجديدة",
    CONFIRM_PASSWORD: "تأكيد كلمة المرور",
    NAME: "اسم",
    EMAIL_ADDRESS: "عنوان البريد الإلكتروني",
    PASSWORD: "كلمة المرور",
    SELECT_WORKSPACE: "اختيار الحساب",
    ENTER_NAME: "أدخل الاسم هنا",
    ROLE_PLACEHOLDER: "حدد الدور",
    REMEMBER_ME: "تذكرنى",
    CREATE_ACCOUNT: "إنشاء حساب",
    LOGIN: "تسجيل الدخول",
    REQUIRED_NAME: "يجب إدخال الإسم",
    REQUIRED_EMAIL: "يجب إدخال بريدك الإلكتروني",
    REQUIRED_PASSWORD: "يجب إدخال كلمة السر",
    REQUIRED_WORKSPACE: "يجب إختيار مساحة العمل",
    SEND_RESET_LINK: "إرسال رابط إعادة الضبط",
    FORGOT_PASSWORD: "هل نسيت كلمة السر؟",
    FOOTER_SIGNUP: "هل لديك حساب؟ <0>تسجيل الدخول</0>",
    FOOTER_LOGIN: "ليس لديك حساب؟ <0>تسجيل</0>",
    FOOTER_CHECK_EMAIL: "هل لديك حساب؟ <0>تسجيل الدخول</0>",
    FOOTER_FORGOT_PASSWORD: "ارجع إلى <0>تسجيل الدخول</0>",
    CONFIRMATION_SENT: "لقد أرسلنا رابط تأكيد إلى عنوان بريدك الإلكتروني",
    SOMETHING_ELSE: "شيء آخر",
    RESET_PASSWORD_NOTIFICATION:
      "تم إرسال رابط تعيين كلمة السر! يرجى التحقق من البريد الإلكتروني.",
    UNDEFINED_ORG: "هذه الشركة غير معروفة",
    ROLES: {
      FOUNDER_OWNER: "المؤسس / المالك",
      MARKETING_MANAGER: "مدير تسويق",
      SALES_BUSINESS_DEVELOPMENT: "المبيعات أو تطوير الأعمال",
      CUSTOMER_SUPPORT: "دعم العملاء",
    },
    SIZES: {
      FROM_1_5: "1-5 موظفين",
      FROM_6_15: "6-15 موظف",
      FROM_15_50: "15-50 موظف",
      FROM_50: "> 50 موظفًا",
    },
    PURPOSES: {
      SALES: "مبيعات",
      SUPPORT: "يدعم",
      MARKETING: "تسويق",
    },
    UNAUTHORIZED_MESSAGE: "401 غير مصرح به، إعادة التوجيه لتسجيل الدخول.",
    SESSION_TIMED_OUT: "انتهت مهلة الجلسة، جاري إعادة التوجيه لتسجيل الدخول..",
    USER_REQUESTED_ACCOUNT_DELETION: "تم حذف الحساب بنجاح. جاري تسجيل الخروج."
  },
  BILLING_SUBSCRIPTION: {
    TITLE: "ادارة الاشتراكات",
    FEAT_UNA:
      "الاشتراك والفوترة غير فعالين حاليًا لحسابك. يرجى الاتصال بمدير الحساب للحصول على مزيد من المعلومات.",
    SUBTITLE: "تحكم و مراجعه تفاصيل الاشتراك و الفواتير الخاصه بك",
    ACCOUNT_BLOCKED:
      "الحساب محظور: يرجى التواصل مع فريقنا على الفور لحل المشكلة. ",
    ACCOUNT_RESTRICTED: "الحساب مقيد: رصيد حسابك على وشك النفاد. ",
    BILLING_DASHBOARD: "لوحة تحكم الفواتير",
    USAGE_REPORT: "تقرير الاستخدام",
    INVOICE: "فاتورة",
    BILLING_SETUP: "إعداد الفواتير",
    SUBSCRIPTION: "تفاصيل الاشتراك",
    SUBSCRIPTION_DETAILS: "تفاصيل الاشتراك",
    CURRENT_PLAN: "الخطة الحالية:",
    CURRENT_BILLING_DATE: "تاريخ الفاتورة الحالية:",
    NEXT_BILLING_DATE: "موعد المحاسبة التالي:",
    PREMIUM_PLUS: "بريميوم بلس",
    AVAILABLE_WA_CREDIT: "رصيد WA المتاح",
    AVAILABLE_CONVERSATION_TARIFF: "تعرفة المحادثة المتاحة",
    NOT_AVAILABLE: "غير متاح",
    ADD_FUNDS: "إضافة الأموال",
    CREDIT_BALANCE: "الرصيد الدائن:",
    ADD_FUNDS_TITLE: "إضافة الأموال",
    ADD_FUNDS_SUBTITLE: "حدد المبلغ وأضف الأموال إلى محفظتك.",
    CHOOSE_PAYMENT_METHOD: "اختر وسيلة الدفع",
    ADD_FUNDS_TO: "إضافة أموال إلى",
    BANK_TRANSFER: "التحويل البنكي",
    BANK_TRANSFER_DETAILS:
      "سهّل عمليات الدفع الخاصة بك مع التحويلات البنكية الإلكترونية السهلة. تضمن هذه الطريقة الآمنة معالجة معاملاتك بسرعة وأمان. جميع التحويلات البنكية مقبولة.",
    ALL_ACCEPTED: "(جميع التحويلات البنكية مقبولة)",
    UPLOAD_TRANSACTION: "رفع إيصال التحويل",
    UPLOAD_TRANSACTION_DETAILS:
      "قم برفع إيصال التحويل أو لقطة الشاشة هنا لتأكيد مدفوعاتك.",
    UPLOAD_BTN: "تحميل jpg/pdf",
    THANKS_TITLE: "شكرا لتسديدك",
    THANKS_BODY:
      "سنؤكد دفعتك ونضيف الأموال إلى حسابك قريبًا.<0></0>يرجى إعادة زيارة صفحة الفواتير الخاصة بك في وقت ما.",
    USAGE_REPORTS: "تقارير الاستخدام",
    STARTING_BALANCE: "الرصيد الإفتتاحي",
    TOP_UPS: "عملية الشحن",
    KNOW_TOP_UP_REPORT: "عرض تقارير جميع عمليات الشحن التي خلال الفترة المحددة",
    TOP_UP_LOGS: "سجل عمليات الشحن",
    PAID: "مدفوعة",
    USAGE: "الرصيد المستخدم",
    ENDING_BALANCE: "الرصيد المتبقي",
    USAGE_DETAILS: "تفاصيل الاستخدام",
    USAGE_DETAILS_SUBTITLE: "تعرف على تقرير الاستخدام الكامل الخاص بك هنا",
    CONVERSATION_CHARGES: "رسوم المحادثة",
    WHATSAPP_CHARGES: "رسوم محادثة الواتساب",
    PLATFORM_CHARGES: "رسوم محادثة المنصة",
    MONTHLY_FAIR_LIMIT: "حد الاستخدام الشهرى العادل",
    ADD_ON: "اضافة",
    TOTAL_CONSUMPTION: "الاستهلاك الكلى",
    BALANCE: "التوازن",
    ADD_ON_LOGS: "لوجز الاضافة",
    VIEW_ADD_ON_LOGS: "رؤية لوجز الاضافة هنا",
    RATE_FOR_ADDITIONAL: "تقييم للاضافات",
    TOTAL_AMOUNT: "الكمية الكلية",
    CONVERSATION: "محادثة",
    TOTAL_QTY: "إجمالي الكمية",
    CHARGABLE_QTY: "الكمية القابلة للشحن",
    CHANNEL_RATE: "معدل القناة",
    CHARGES: "رسوم",
    SEARCH_INVOICES: "بحث في الفواتير",
    NEWEST_FIRST: "الأحدث",
    OLDEST_FIRST: "الأقدم",
    STATUS: "حالة الدفع",
    ALL: "الكل",
    DATE: "التاريخ",
    TYPE: "نوع المعاملة",
    AMOUNT: "المبلغ",
    FUNDS_ADDED: "الرصيد المضاف",
    DOWNLOAD: "تحميل",
    PENDING: "قيد الانتظار",
    CANCELLED: "ملغاه",
    OPEN: "مفتوحه",
    NOT_SETUP: "البدء بالاستخدام !",
    SUBSCRIBE_PLAN: "انت غير مشترك باحد الباقات, اختر احد الباقات لبدء استخدام الخدمات.",
    SETUP_BILLING: "إعداد الفواتير",
    SELECT_PLAN_ADD_FUNDS: "حدد خطة",
    CHOOSE_SUBSCRIPTION: "اختر اشتراكًا لك بناءً على احتياجاتك وأضف الأموال.",
    SELECT_PLAN: "اختيار الباقة",
    MONTHLY: "شهريا",
    QUARTERLY: "ربعي",
    HALF_YEARLY: "نصف سنوي",
    YEARLY: "سنوي",
    STARTER: "بداية",
    PER_MONTH: "${{price}} /شهر",
    PER_MONTHS: "${{price}} /{{number}}شهر",
    PER_CONVERSATION: "${{price}}/محادثة",
    WA_CHARGES: "رسوم الواتساب",
    STARTER_DESCRIPTION:
      "للفرق المتنامية التي تبدأ في التوسع إلى آفاق جديدة مع Mottasl",
    CHOOSE_PLAN: "اختر الخطة",
    TEAM: "فريق",
    TEAM_DESCRIPTION:
      "للفرق المتنامية التي بدأت في التوسع إلى آفاق جديدة مع Zoko",
    PRO: "طليعة",
    PRO_DESCRIPTION:
      "ارتقِ بمستوى هذه الخطة الاحترافية وإمكانية الوصول المتقدمة إليها",
    BUSINESS: "عمل",
    BUSINESS_DESCRIPTION: "عندما تحتاج إلى ضوابط مبسطة ومركزية",
    STILL_UNDECIDED: "لا تزال مترددة؟ <0>قارن خططنا بالتفصيل</0>",
    SELECTED: "المحدد",
    SELECT_AMOUNT: "حدد المبلغ",
    MINIMUM: "الحد الأدنى",
    AUTO_TOP_UP: "التعبئة التلقائية",
    AUTO_TOP_UP_DESCRIPTION: "اسمح لك بتعبئة رصيد محفظتك تلقائيًا عند انخفاضه.",
    WHEN_BALANCE_BELOW: "عندما يكون الرصيد أدناه:",
    AUTOMATICALLY_RECHARGE: "إعادة الشحن تلقائيًا:",
    DIRECT_PAYMENT: "دفع مباشر",
    DIRECT_PAYMENT_DESCRIPTION:
      "استمتع بمعاملات سلسة وآمنة وسريعة من خلال خيار الدفع المباشر الخاص بنا في منصتنا. ",
    ENTER_CARD_DETAILS: "أدخل تفاصيل البطاقة",
    CARD_NUMBER: "رقم البطاقة",
    EXPIRY_DATE: "تاريخ الانتهاء",
    CVV: "CVV",
    BILLING_DETAILS: "تفاصيل الفاتورة",
    COMPANY_NAME: "اسم الشركة",
    ADDRESS_LINE: "خط عنوان {{line}}",
    COUNTRY_REGION: "البلد/المنطقة",
    STATE_PROVINCE: "الولاية/المقاطعة",
    ZIP_CODE: "الرمز البريدي",
    CITY: "مدينة",
    TAX_ID: "الرقم الضريبي",
    EMAIL: "بريد إلكتروني",
    AVAILABLE_CREDIT: "حالة الرصيد",
    ADD_CREDIT: "إضافة رصيد",
    YOUR_CURRENT_PLAN: "باقة الإشتراك الحالية",
    SUBSCRIPTION_PLAN: "باقه الإشتراك:",
    MAX_PER_MONTH: "الحد الأقصى {{number}} / شهريا",
    MAX_PER_MONTHS: "الحد الأقصى {{number}} / {{months}} أشهر",
    MAX_PER_YEAR: "الحد الأقصى {{number}} / سنويا",
    RENEW_ON: "تاريخ التجديد:",
    CHANGE_PLAN: "تغيير الخطة",
    INVOICES: "الفواتير",
    ADD_CREDIT_TITLE: "إضافة رصيد",
    ADD_CREDIT_SUBTITLE: "حدد المبلغ وأضف رصيدًا إلى محفظتك.",
    TWERLO_SUBSCRIPTION_CHARGES:"رسوم اشتراك Twerlo",
    PLAN:"الخطة",
    TIME_PERIOD:"الفترة الزمنية",
    DAYS:"أيام",
    RATE:"السعر",
    CREDIT_USED:"الرصيد المستخدم",
    FAIR_USE_AGENTS:"رسوم الاستخدام العادل - الوكلاء",
    INCLUDED:'متضمن',
    ADDITIONAL:'إضافي',
    FAIR_USE_TEAMS:'رسوم الاستخدام العادل - الفرق',
    FAIR_USE_ROUTING:'رسوم الاستخدام العادل - قواعد التوجيه',
    FAIR_USE_API:'رسوم الاستخدام العادل - API',
    TWERLO_RATE:'معدل Twerlo',
    FREE_1000_CONVERSATION:'اول 1000 محادثة',
    MARKETING:'محادثات التسويق',
    UTILITY:'محادثات الإستخدام',
    SERVICE:'محادثات الخدمة',
    AUTHENTICATION:'محادثات المصادقة',
    CONVERSATION_OTHER_CHANNELS:'رسوم المحادثة (قنوات أخرى)',
    CREDIT_ADDED:'الرصيد المضاف',
    PAYMENT_MODE: "طريقة الدفع",
    DRAFT: "مسودة",
    NO_SETUP_ACCOUNT: "لم تقم بإعداد حسابك",
    WALLET_NOT_ACTIVE: "غير مفعله",
    WALLET_NOT_ACTIVE_DESC: "فعّل محفظتك لعرض الرصيد المتاح",
    SUBSCRIBE_A_PLAN: "انت غير مشترك باحد الباقات, اختر احد الباقات لبدء استخدام الخدمات.",
    BASIC: "أساسي",
    BASIC_DESCRIPTION:
      "للفرق الصغيرة التي ترغب في اختبار مياه البريد الوارد المشتركة",
    GROWTH: "نمو",
    GROWTH_DESCRIPTION: "عندما تحتاج إلى ضوابط مبسطة ومركزية",
    BILLING_NOTIFICATION: "اعدادات الإشعارات",
    BILLING_NOTIFICATION_DESC: "ضبط التنبيهات و اداره  تفضيلات الإشعارات للفواتير",
    NOTIFICATION_ALERT_BALANCE: "التذكير عندما يقل الرصيد عن",
    EMAIL_NOTIFICATION: "البريد الالكتروني لإرسال الإشعارات",
    SAVE_SETTINGS: "حفظ الإعدادات",
    SAVING: "جاري الحفظ",
    SAVED: "تم الحفظ",
    SAVED_ERROR: "خطأ",
    ONLINE: "اونلاين",
    OFFLINE: "اوفلاين",
    BLOCK_MESSAGE:
      "تم حظر الحساب: يرجى التواصل مع فريقنا على الفور لحل المشكلة.",
    API_CALLS: "مكالمات API",
    IN_PACKAGE: "في الحزمة",
    CONSUMED: "مستهلك",
    CONVERSATION_ADDON: "إضافة المحادثة",
    FAIR_BEYOND_API_CALLS: "مكالمات API العادلة",
    FAIR_USE_CONVERSATION: "رسوم المحادثة العادلة",
    PLATFORM_CONVERSATION: "رسوم محادثة المنصة",
    FAIR_BEYOND_PLATFORM: "محادثة عادلة خارج المنصة",
    VIEW_INVOICE:'عرض الفاتوره',
    NO_USAGE:'لا توجد تقارير استخدام متاحة للفترة المحددة',
    EMAIL_RQUIRED:'البريد الالكتروني مطلوب',
    EMAIL_INVALID:'البريد الالكتروني غير صحيح',
    LIMIT_REQUIRED:'رصيد المحفظة مطلوب',
    SENT:'تم الإرسال',
    OVERDUE:'متأخر',
    PARTIALLY_PAID:'تم الدفع جزئيا',
    VOID:'ملغاه',
    INVOICE_ID:'رقم الفاتورة',
    DUE_DATE:'تاريخ الاستحقاق',
    WLT_TOPUP:'عملية الشحن',
    UPGRADE_REFUND:'استرداد قيمة الترقية',
    DOWNGRADE_REFUND:'استرداد قيمة التخفيض'
  },
  ERROR: {
    "404_TITLE": "404: الصفحة التي تبحث عنها ليست هنا",
    "404_DESCRIPTION":
      "إما أنك حاولت الدخول على مسار بالخطأ أو أتيت إلى هنا عن طريق الخطأ. ",
    "500_TITLE": "500: خطأ داخلي في الخادم",
    "500_DESCRIPTION":
      "إما أنك حاولت الدخول على مسار بالخطأ أو أتيت إلى هنا عن طريق الخطأ. ",
    "401_TITLE": "401 التفويض مطلوب",
    "401_DESCRIPTION":
      "إما أنك حاولت الدخول على مسار بالخطأ أو أتيت إلى هنا عن طريق الخطأ. ",
    RELOAD_TITLE: "أُووبس! ",
    RELOAD_DESCRIPTION: "لا تقلق، فقط قم بإعادة تحميل الصفحة وسوف تعود.",
    BACK_BTN: "رجوع",
    RELOAD_BTN: "إعادة تحميل الصفحة",
    ERROR_OCCURRED:'حدث خطأ'
  },
  KNOWLEDGE_BASE: {
    HEADER: "قاعدة المعرفة",
    SUBTITLE: "إنشاء وإدارة قاعدة المعرفة هنا",
    BUTTON: {
      TRAIN: "يدرب",
      CREATE_KNOWLEDGE_BASE: "إنشاء قاعدة المعرفة",
    },
    TABLE_HEADERS: {
      NAME: "اسم",
      DESCRIPTION: "وصف",
      CREATED_BY: "منشأ من قبل",
      CREATED_ON: "تم إنشاؤها على",
      STATUS: "حالة",
      ACTION: "إجراءات",
    },
    CREATE_POPUP: {
      HEADER_TITLE: "إنشاء مستند جديد",
      DOCUMENT_NAME: "اسم الملف",
      DESCRIPTION: "وصف",
      KNOWLEDGE_TYPE: "نوع المعرفة",
      UPLOAD: "رفع",
      WEBSITE: "موقع إلكتروني",
      CONTENT_BODY: "نص المحتوى",
      SELECT_FILE_HERE: "حدد ملفًا أو قم بالسحب والإسقاط هنا",
      SELECT_FILE_PLACEHOLDER: "مستند أو PDF، حجم الملف لا يزيد عن 10 ميغابايت",
      SELECT_BUTTON: {
        BUTTON: "حدد الملف",
      },
      DOCUMENT_PLACEHOLDER: "عنوان URL للمستند",
      CONTENT_BODY_TEXT: "هيئة المحتوى",
      CONTENT_BODY_TEXT_PLACEHOLDER: "أدخل النص الأساسي هنا",
      BUTTON: {
        CANCEL: "إلغاء",
        CREATE: "يخلق",
      },
    },
  },
  ONBOARDING: {
    AVOCADO: "Avocado",
    BOOK_SLOT: "احجز اجتماع معنا",
    DESCRIPTION: "حدد موعدًا لعقد اجتماع معنا، لكي نتواصل معك مرة أخرى.",
    SCHEADULE_MEETING: "دعونا لجدولة الاجتماع",
    THANKS_SCHEDULE: "شكرا لك على تلبية دعوتنا بتحديد اللقاء",
    MAIL_INVITATION: "تم إرسال تفاصيل الدعوة إلى عنوان بريدك الإلكتروني.",
    DATE_TIME: "التاريخ والزمن:",
    USE_GOOGLE_MEET: "أستخدم هذا الرابط للالتحاق بالاجتماع",
    KNOW_PRODUCT: "هل ترغب بمعرفة المزيد عن المنتج؟",
    TAKE_TOUR: "فلنأخد جوله",
  },
  WHATSAPP_WIDGET: {
    HEADER: "زر النقر للدردشة",
    SUBTITLE:
      "امنح العملاء طريقة للوصول إليك على WhatsApp أو Facebook أو Instagram.",
    CHAT_BUBBLE: "زر الدردشة",
    SELECT_BUTTON_TYPE: "حدد نوع الزر الذي تريده",
    DEFAULT: "افتراضي",
    AVATAR: "صورة رمزية",
    BUBBLE_BACKGROUND_COLOR: "لون خلفية الزر",
    CHAT_HEADER: "رأس الدردشة",
    CHANGE: "تغيير",
    NAME: "الأسم",
    CAPTION: "تسمية توضيحية",
    WELCOME_MESSAGE: "رسالة الترحيب",
    TEXT: "نص",
    BUTTON_TEXT: "نص الزر",
    BUTTUN_COLOR: "لون الزر",
    PRE_FILLED_MESSAGE: "رسالة مملوءة مسبقًا",
    PRE_FILLED_MESSAGE_DESC:
      "اجعل من السهل على الزوار بدء محادثة من خلال ملء رسالة ترحيب مسبقًا لإرسالها إليك",
    ENTER_GREETING_MESSAGE: "أدخل نص الترحيب الخاص بك",
    POSITION_AND_VISABILITY: "الموضع والرؤية",
    POSITION_AND_VISABILITY_DESC:
      "يمكنك تحديد الموضع الافتراضي لزر الدردشة باستخدام الإعدادات أدناه.",
    WEB: "الويب",
    ALIGNMENT: "المحاذاة",
    MARGIN_BOTTOM: "الهامش السفلي (بكسل)",
    MARGIN_LEFT: "الهامش الأيسر (بكسل)",
    MOBILE: "الجوال",
    SAVE_AND_VIEW: "حفظ وعرض رمز التضمين",
    CHAT_POWERED_BY: "مدعوم بواسطة",
    BOTTOM_RIGHT: "أسفل يمين",
    BOTTOM_LEFT: "أسفل يسار",
    ICON: "أيقونة",
    EMBEDDED_CODE: 'كود تضمين زر "انقر للدردشة"',
    COPY_EMBEDDED: "نسخ كود التضمين",
    COPIED: "تم النسخ",
    COPIED_FAILED: "فشل النسح",
    ERROR_ON_SAVE: "حدث خطأ في حفظ البيانات",
    CUSTOM:'قم بتحميل خاصتك',
    UPLOAD:'رفع',
    NO_FILE_CHOSEN:'لم يتم اختيار أي ملف',
    FILE_NOT_SUPPORTED:'الملف غير مدعوم',
    SAVING:'جاري الحفظ',
    SAVED:'تم الحفظ',
    ERROR:'خطأ'
  },
};

export default AR_TRANSLATIONS;
