import React from 'react';
import { Route, Routes } from 'react-router-dom';

//  components
import { Lazy } from '../../components/Common/Lazy';
import ProtectedRoute from '../../Guards/ProtectedRoute';
import { AvcModulesKeys } from '../../helpers/constants';

//  pages
const Channels = React.lazy(() => import('../../layouts/Settings/DevTools/Channels'));
const MessageLogs = React.lazy(() => import('../../layouts/Settings/DevTools/MessageLogs'));
const Webhooks = React.lazy(() => import('../../layouts/Settings/DevTools/Webhooks_APIs'));
const Error = React.lazy(() => import('../../pages/Error'));

export const DevToolsSettings = (): JSX.Element => {
    return (
        <Routes>
            <Route path='/channels' element={<ProtectedRoute moduleKey={AvcModulesKeys.CHANNELS} path="/channels"> <Lazy component={<Channels />} /> </ProtectedRoute>} />
            <Route path='/webhooks' element={<ProtectedRoute moduleKey={AvcModulesKeys.WEBHOOKS_AND_APIS} path="/settings/workspace/account-profile"><Lazy component={<Webhooks />} /></ProtectedRoute>} />
            <Route path='/message-logs' element={<ProtectedRoute moduleKey={AvcModulesKeys.MESSAGE_LOGS} path="/settings/workspace/account-profile"><Lazy component={<MessageLogs />} /></ProtectedRoute>} />
            <Route path="*" element={<Lazy component={<Error type={404} />} />} />
        </Routes>
    );
}
