import React, { useEffect } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Stack } from '@mui/material';

// capacitor
import { App as CapacitorApp } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';

//  components
import { Lazy } from '../../components/Common/Lazy';
import { AnalyticsButtons } from '../../layouts/Analytics/Reports/AnalyticsButtons';
import { SdkRouter } from '../../components/Analytics/SdkRouter';
import { Header } from '../../layouts/Common/Header';

//  translations
import { useTranslation } from 'react-i18next';

//  redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { ACTIONS, ACTIONS_TYPE, AvcModulesKeys } from '../../helpers/constants';
import { isPermitted } from '../../helpers/auth';
import { changeCanDownloadReport } from '../../redux/analytics';

//  pages
const Conversations = React.lazy(() => import('./Conversations'));
const Overview = React.lazy(() => import('./Overview'));
const CSATreports = React.lazy(() => import('./CSAT'));
const Agents = React.lazy(() => import('./Agents'));
const Teams = React.lazy(() => import('./Teams'));
const Error = React.lazy(() => import('../Error'));



const Analytics = (): JSX.Element => {
    //  hooks
    const { t } = useTranslation();
    const isMobileApp: boolean = Capacitor.getPlatform() !== 'web';
    const dispatch = useDispatch();
    
    //  redux states
    const mode = useSelector((state: RootState) => state.sdk.mode);

      // permissions
    const role: string = useSelector((state: RootState) => state.auth.userRole);
    const module: string = AvcModulesKeys.ANALYTICS.toUpperCase();
    const canDownloadReports: boolean = isPermitted({role, module, action: ACTIONS.ANALYTICS.DOWNLOAD_REPORT, returnType: ACTIONS_TYPE.BOOLEAN}) as boolean;

    useEffect(() => {
        dispatch(changeCanDownloadReport(canDownloadReports))
    }, [canDownloadReports]);


    if(isMobileApp) {
        CapacitorApp.exitApp();
        return <></>;
    } else {
        return (
            <Stack width='100%' sx={{ overflowY: 'auto' }}>
                <Header title={t('DASHBOARD.ANALYTICS')} description={t('DASHBOARD.SUBTITLE')} hideBackButton={true} />
                <Stack flex={1} flexDirection='row' overflow='hidden'>
                    <AnalyticsButtons />
                    { mode === 'host' && !isMobileApp ?
                    <Routes>
                        <Route path="/" element={<Navigate to='/dashboard/overview' replace />} />
                        <Route path='/overview' element={<Lazy component={<Overview />} />} />
                        <Route path='/conversations' element={<Lazy component={<Conversations />} />} />
                        <Route path='/reports' element={<Lazy component={<CSATreports />} />} />
                        <Route path='/agents' element={<Lazy component={<Agents />} />} />
                        <Route path='/teams' element={<Lazy component={<Teams />} />} />
                        <Route path="*" element={<Lazy component={<Error type={404} />} />} />
                    </Routes> :
                    <SdkRouter />
                    }
                </Stack>
            </Stack>
        )
    }
}

export default React.memo(Analytics);