// models
import { client_factory } from "../graphql/client";

//  gql
import { ApolloClient } from "@apollo/client";
import { ASSIGN_CHAT, CHAT_HISTORY, CHAT_LIST_ALL, CHAT_LIST_BY_AGENT, CHAT_LIST_BY_STATUS, GET_CONTACT_PROFILE, SEND_PRIVATE_MESSAGE, UPDATE_CHAT_STATUS, UPDATE_CONTACT_META } from "../graphql/queries/chats";
import { UPDATE_CONTACT } from "../graphql/queries/contacts";

//  models
import { ChatHistoryOpts, ChatList, ChatListByAgentOpts, ChatListByStatusOpts, ProfileOpts, SearchOpts } from "../models/sdk";

//  utils
import { v4 as uuid } from 'uuid';
import { fetchMessages } from "../helpers/search";
import { send_message } from "../helpers/chat";
import { ISendMessageParams } from "../models/Chat";

import { StorageService } from "../storage";

/**
 * Chat class is responsible for chat functions on the sdk
 */
class Chat {
  private apolloClient: ApolloClient<any> | null = null;

  constructor() {
    this.apolloClient = client_factory()
  }

  async getChatListByAgent(chatListByAgentOpts: ChatListByAgentOpts) {
    return await this.apolloClient?.query({
      query: CHAT_LIST_BY_AGENT,
      variables: {
        business_id: window.business_id,
        ...chatListByAgentOpts
      }
    });
  }

  async getChatListByStatus(chatListByStatusOpts: ChatListByStatusOpts) {
    return await this.apolloClient?.query({
      query: CHAT_LIST_BY_STATUS,
      variables: {
        business_id: window.business_id,
        ...chatListByStatusOpts
      }
    });
  }

  async getChatListQueue(chatListQueueOpts: ChatList) {
    return await this.apolloClient?.query({
      query: CHAT_LIST_ALL,
      variables: {
        business_id: window.business_id,
        ...chatListQueueOpts
      }
    });
  }

  async getChatHistoryByContact(chatHistoryOpts: ChatHistoryOpts) {
    return await this.apolloClient?.query({
      query: CHAT_HISTORY,
      variables: {
        business_id: window.business_id,
        ...chatHistoryOpts
      }
    });
  }

  async pinChat(contactId: number) {
    return await this.apolloClient?.mutate({
      mutation: UPDATE_CONTACT_META,
      variables: {
        contact_id: contactId,
        meta_data: { is_chat_pinned: true }
      }
    });
  }

  async unpinChat(contactId: number) {
    return await this.apolloClient?.mutate({
      mutation: UPDATE_CONTACT_META,
      variables: {
        contact_id: contactId,
        meta_data: { is_chat_pinned: false }
      }
    });
  }

  async markChatUnread(contactId: number) {
    return await this.apolloClient?.mutate({
      mutation: UPDATE_CONTACT_META,
      variables: {
        contact_id: contactId,
        meta_data: { unread_msgs_count: 1 }
      }
    });
  }

  async markChatRead(contactId: number) {
    return await this.apolloClient?.mutate({
      mutation: UPDATE_CONTACT_META,
      variables: {
        contact_id: contactId,
        meta_data: { unread_msgs_count: 0 }
      }
    });
  }

  private systemMessage(contactId: number, contact_platform_id: string, msg: string) {
    return {
      contact_id: contactId,
      business_id: window.business_id,
      message_id: uuid(),
      from_id: StorageService.getUserEmail(),
      to_id: contact_platform_id,
      timestamp: new Date().toISOString(),
      channel: "whatsapp",
      level: "agent",
      direction: "out",
      type: "system",
      agent_id: StorageService.getUserId(),
      body: {
        text: {
          body: msg
        }
      }
    }
  }

  async muteChat(contact_id: number, contact_platform_id: string) {
    return await this.apolloClient?.mutate({
      mutation: UPDATE_CHAT_STATUS,
      variables: {
        contact_id,
        chat_status: 'snoozed',
        system_msg: this.systemMessage(contact_id, contact_platform_id, `The chat was muted by ${StorageService.getUserEmail()}`)
      }
    });
  }

  async closeChat(contact_id: number, contact_platform_id: string) {
    return await this.apolloClient?.mutate({
      mutation: UPDATE_CHAT_STATUS,
      variables: {
        contact_id,
        chat_status: 'closed',
        system_msg: this.systemMessage(contact_id, contact_platform_id, `The chat was closed by ${StorageService.getUserEmail()}`)
      }
    });
  }

  async assignChatToAgent(contact_id: number, agent_id: number, contact_platform_id: string, agent_email: string) {
    return await this.apolloClient?.mutate({
      mutation: ASSIGN_CHAT,
      variables: {
        timestamp: new Date().toISOString(),
        business_id: window.business_id,
        contact_id,
        agent_id,
        team_id: null,
        is_team: false,
        system_msg: this.systemMessage(contact_id, contact_platform_id, `The chat was assigned to ${agent_email} by ${StorageService.getUserEmail()}`)
      }
    });
  }
  
  async assignChatToTeam(contact_id: number, team_id: number, contact_platform_id: string, team_name: string) {
    return await this.apolloClient?.mutate({
      mutation: ASSIGN_CHAT,
      variables: {
        timestamp: new Date().toISOString(),
        business_id: window.business_id,
        contact_id,
        agent_id: null,
        team_id,
        is_team: true,
        system_msg: this.systemMessage(contact_id, contact_platform_id, `The chat was assigned to ${team_name} by ${StorageService.getUserEmail()}`)
      }
    });
  }

  async searchChatMessages(searchQuery: SearchOpts) {
    return await fetchMessages({ queryKey: [
      'search',
      searchQuery.text,
      searchQuery.type,
      searchQuery.direction,
      searchQuery.message_type,
      {
        from: searchQuery.from,
        to: searchQuery.to
      },
      searchQuery.status,
      searchQuery.assigned_to,
      searchQuery.tags || [],
      searchQuery.limit || 15,
      searchQuery.offset || 0,
      searchQuery.contact_id
    ] })
  }

  async getContactProfile(contact_id: number) {
    return await this.apolloClient?.query({
      query: GET_CONTACT_PROFILE,
      variables: {
        contact_id
      }
    });
  }

  async updateContactProfile(contact_id: number, profile: ProfileOpts) {
    return await this.apolloClient?.mutate({
      mutation: UPDATE_CONTACT,
      variables: {
        contact_id,
        ...profile
      }
    });
  }

  async sendChannelMessage(message: ISendMessageParams) {
    return await send_message(message);
  }

  async sendPrivateMessage(contact_id: number, contact_platform_id: string, message: string, mentioned_id?: number) {
    return await this.apolloClient?.mutate({
      mutation: SEND_PRIVATE_MESSAGE,
      variables: {
        private_msg: {
          message_mentions: mentioned_id ? {
            data: {
              contact_id: mentioned_id
            }
          } : undefined,
          contact_id,
          to_id: contact_platform_id,
          agent_id: StorageService.getUserId(),
          from_id: StorageService.getUserEmail(),
          business_id: window.business_id,
          message_id: uuid(),
          timestamp: new Date().toISOString(),
          channel: "whatsapp",
          level: "agent",
          direction: "out",
          type: "mentions",
          body: {
            text: {
              body: message
            }
          }    
        }
      }
    });
  }
}

export default Chat;