import React from 'react';
import { Route, Routes } from 'react-router-dom';

//  components
import { Lazy } from '../../components/Common/Lazy';
import { AvcModulesKeys } from '../../helpers/constants';
import ProtectedRoute from '../../Guards/ProtectedRoute';

//  pages
const AccountProfile = React.lazy(() => import('./AccountProfile'));
const Users = React.lazy(() => import('./Users'));
const Teams = React.lazy(() => import('./Teams'));
const Error = React.lazy(() => import('../../pages/Error'));

export const WorkSpace = (): JSX.Element => {
    return (
        <Routes>
            <Route path='/account-profile' element={<ProtectedRoute moduleKey={AvcModulesKeys.WORKSPACE_PROFILE} path="/account-profile"> <Lazy component={<AccountProfile />} /> </ProtectedRoute>} />
            <Route path='/users' element={<ProtectedRoute moduleKey={AvcModulesKeys.USERS} path="/users"> <Lazy component={<Users />} /> </ProtectedRoute>} />
            <Route path='/teams' element={<ProtectedRoute moduleKey={AvcModulesKeys.TEAMS} path="/teams"> <Lazy component={<Teams />} /> </ProtectedRoute>} />
            <Route path="*" element={<Lazy component={<Error type={404} />} />} />
        </Routes>
    )
}
