import React, { ChangeEvent, useCallback, useRef, useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Autocomplete, Button, Checkbox, InputProps, Stack, TextField, Typography } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

//  utils
import { useTranslation } from 'react-i18next';
import { debounce } from 'lodash';
import CryptoJS from "crypto-js";

//  helpers
import { get_businesses, login, register } from '../../helpers/auth';
import { publish } from '../../helpers/events';

//  validations
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

//  redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { setAuthenticated, setCurrentRoute } from '../../redux/sdk';

import { StorageService } from '../../storage';
import * as Sentry from "@sentry/react";
import { MAX_EMAIL_LENGTH, MAX_PASSWORD_LENGTH } from '../../helpers/constants';
import environment from '../../environment';
import { updateUserRole } from '../../redux/auth';

const inputProps: InputProps = {
  disableUnderline: true,
  sx: { borderRadius: 2.5, backgroundColor: 'transparent !important', border: '1px solid', borderColor: 'light.gray8' },
};

const emailRegEx: RegExp = new RegExp(
  /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/,
);

export const Form = ({ currentTab, setTab }): JSX.Element => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const installCode = queryParams.get('code');
  const [emailOrPassNotValid, setEmailOrPassNotValid ] = useState(false);

  const [showPassword, setShowPassword] = React.useState(false);

  const mode = useSelector((state: RootState) => state.sdk.mode);

  const [workspaces, setWorkspaces] = useState<any[]>([]);

  const rememberMe = useRef<HTMLInputElement | null>(null);

  const forgotPasswordTab: number = 3;

  const isSignup = currentTab === 'signup';
  const schema = yup.object().shape({
    name: isSignup ? yup.string().required('LOGIN.REQUIRED_NAME') : yup.string(),
    email: yup.string().max(MAX_EMAIL_LENGTH,"VALIDATIONS.INVALID_MAX_EMAIL_LENGTH")
    .email('VALIDATIONS.INVALID_EMAIL')
    .required('LOGIN.REQUIRED_EMAIL')
    .matches(/^[\w\.-]+(\+\w+)?@[a-zA-Z\d\.-]+\.[a-zA-Z]{2,}$/,'VALIDATIONS.INVALID_EMAIL'),
    password: yup.string().required('LOGIN.REQUIRED_PASSWORD').min(8, 'VALIDATIONS.INVALID_PASSWORD')
    .max(MAX_PASSWORD_LENGTH,"VALIDATIONS.INVALID_MAX_PASSWORD_LENGTH"),
    workspace: workspaces.length ? yup.string().required('LOGIN.REQUIRED_WORKSPACE') : yup.string()
  });

  const {
    control,
    getValues,
    setValue,
    setError,
    clearErrors,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    clearErrors();
  }, [currentTab]);

  useEffect(() => {
    if (!emailOrPassNotValid) return;
    trigger('email');
    trigger('password');
    setEmailOrPassNotValid(false);
  }, [watch("email"), watch("password")])

  const checkWorkspaces = useCallback(
    debounce(async (e: ChangeEvent<HTMLInputElement>) => {
      let {email, password} = getValues()
      let isEmailValid = email && (emailRegEx.test(email))
      let isPasswordValid = password && (8 <= password.length && password.length <= MAX_PASSWORD_LENGTH);
      if (!isSignup && (!isEmailValid || !isPasswordValid)) return;

        try {
          const data = await get_businesses(email);
          setWorkspaces(data?.businesses);
          if (!data.businesses.find((b: any) => b.business_id === getValues('workspace')) && !isSignup) {
            setValue('workspace', undefined);
          } else {
            if(isSignup) setError('email', { message: 'VALIDATIONS.EMAIL_EXISTS' });
          }
        } catch (error) {
          setWorkspaces([]);
          setValue('workspace', undefined);
          clearErrors('email')
        }
    }, 500),
    [isSignup],
  );

  const submit = async (payload: any) => {
    try {
      if (isSignup) {
        await register(payload);
        setTab(2);
      } else {
        const data = await login(payload);

        if (rememberMe.current?.checked === false) {
          window.onbeforeunload = () => {
            StorageService.clearUserDetails();
            Sentry.setUser(null)
            Sentry.setTag("avc-business_id", null);
            Sentry.setTag("avc-token", null);
          };
        }
        const role: string = data?.user_details?.role;
        const cipherRole: string = CryptoJS.AES.encrypt(role, environment.REACT_APP_SECRET_ENCRYPTION_KEY).toString();

        StorageService.setAuthToken(data.headers.Authorization);
        StorageService.setAvcBillingSource(data.billing_source);
        StorageService.setBusinessId(getValues('workspace') as string);
        StorageService.setUserId(data.user_details.id);
        StorageService.setUserEmail(payload.email);
        StorageService.setUserDetailsAsJson(data.user_details);
        StorageService.setCipherRole(cipherRole);
        StorageService.setBusinessesAsJson(workspaces);
        StorageService.setChannelsAsJson(data.channels);
        StorageService.setCipherRole(cipherRole);

        dispatch(updateUserRole(role));
        // if(!(data?.channels.length === 1 && data?.channels[0].id === 0)) {
        //   StorageService.setChannelsAsJson(data.channels);
        // }
        Sentry.setUser({ email: payload.email , id: data.user_details.id });
        Sentry.setTag("avc-business_id", getValues('workspace') as string);
        Sentry.setTag("avc-token", data.headers.Authorization);

        window.sdk.authorize();
        publish('LOGIN', {});
        dispatch(setAuthenticated(true));

        // const home = window.screen.width <= 786 ? '/chat' : '/dashboard/overview';
      if (installCode) {
          // append the query parameters to the url
          const params = new URLSearchParams(location.search).toString();
          const installRoute = `/ecommerce-apps?${params}`;
          if (mode === 'host') navigate(installRoute);
          dispatch(setCurrentRoute(installRoute));
          } else {
              const home = '/chat';
            if (mode === 'host') navigate(home);
            dispatch(setCurrentRoute(home));
          }
      }
    } catch (error: any) {
      const code = error.toJSON().status;
      if (code === 404) {
        setError('email', { message: 'VALIDATIONS.INVALID_EMAIL_PASSWORD' });
        setError('password', { message: 'VALIDATIONS.INVALID_EMAIL_PASSWORD' });
        setEmailOrPassNotValid(true);
      } else if (code === 409) {
        if(isSignup) setError('email', { message: 'VALIDATIONS.EMAIL_EXISTS' });
      } else if (code === 400) {
        setError('email', { message: 'VALIDATIONS.NOT_REGISTERED' });
      } else if (code === 401) {
        setError('email', { message: 'VALIDATIONS.ACCOUNT_DELETED' });
      }
      if (code >= 500) Sentry.captureException(error);
    }
  };

  const getOrganizationName = () : string => {
    const choosedBusiness =  workspaces.find((w: any) => w.business_id === getValues('workspace'));
    if(choosedBusiness) return choosedBusiness.org_name;
    else return t('LOGIN.UNDEFINED_ORG');
  }

  return (
    <>
      <Stack alignItems="center" gap={1.5} width="100%" mt={2}>
        { isSignup &&
          <Controller name='name' control={control} render={({ field: { value, onChange, onBlur } }) => (
            <TextField label={t('LOGIN.NAME')} name='name' variant="filled" className='devias-textbox-filled' InputProps={inputProps} fullWidth value={value || ''} onChange={onChange} onBlur={onBlur} error={Boolean(errors.name)} helperText={t(errors.name?.message || '')} />
          )} />
        }
        <Controller name='email' control={control} render={({ field: { value, onChange, onBlur } }) => (
          <TextField label={t('LOGIN.EMAIL_ADDRESS')} type="email" name='email' variant="filled" className='devias-textbox-filled' InputProps={inputProps} fullWidth value={value || ''} onChangeCapture={checkWorkspaces} onChange={onChange} onBlur={onBlur} error={Boolean(errors.email)} helperText={t(errors.email?.message || '')} />
        )} />
        <Controller name='password' control={control} render={({ field: { value, onChange, onBlur } }) => (
          <TextField label={t('LOGIN.PASSWORD')} name='password' type={showPassword ? 'text' : 'password'} autoComplete="current-password" variant="filled" className='devias-textbox-filled' onChangeCapture={checkWorkspaces} InputProps={{...inputProps, endAdornment: <InputAdornment position="end">
          <IconButton aria-label="toggle password visibility" onClick={()=> setShowPassword(show => !show)}>
            {showPassword ? <VisibilityOff /> : <Visibility />}</IconButton></InputAdornment>}} fullWidth value={value || ''} onChange={onChange} onBlur={onBlur} error={Boolean(errors.password)} helperText={t(errors.password?.message || '')}
          />
        )} />
        { workspaces.length > 0 && !isSignup &&
          <Controller name='workspace' control={control} render={({ field: { value, onChange, onBlur}})=>(
            <Autocomplete
              options={workspaces}
              value={value}
              fullWidth
              onChange={(_: any, newValue: any) => onChange(newValue.business_id)}
              sx={{ flex: 1, '.MuiInputBase-root': { py: 0, paddingInlineStart: 0 } }}
              disableClearable
              getOptionLabel={getOrganizationName}
              renderOption={(props, business: any) => (
                <Typography component={'li'} key={business.business_id} {...props} py={'10px !important'} mx={0.5} sx={{ borderRadius: 2 }}>{ business.org_name }</Typography>
              )}
              renderInput={(params) => <TextField onBlur={onBlur} {...params} className="devias-textbox" label={t('LOGIN.SELECT_WORKSPACE')} error={Boolean(errors.workspace)} helperText={t(errors.workspace?.message || '')} />}
              filterOptions={(workspaces, { inputValue }) => workspaces.filter(workspace => workspace?.org_name?.toLowerCase()?.includes(inputValue.toLowerCase())) }
            />
          )} />
        }
      </Stack>
      {!isSignup && (
        <Stack flexDirection='row' alignItems='center' width='100%' mt={1}>
          <Checkbox inputRef={rememberMe} size='small' defaultChecked />
          <Typography fontSize={{md:14, xs:12, sm:13}} fontWeight={400} color="text.secondary">{t('LOGIN.REMEMBER_ME')}</Typography>
        </Stack>
      )}
      <Button
        onClick={handleSubmit(submit)}
        disableElevation
        variant='contained'
        disabled={errors.email || errors.password || errors.name || errors.workspace}
        sx={{
          width: '100%',
          mt: { md: 3, xs: 2 },
          mb: { md: 2, xs: 1 },
          borderRadius: 2.5,
          fontSize: { md: 15, xs: 13, sm: 14 },
          fontWeight: 600,
          alignItems: { md: 'flex-start', xs: 'center' },
        }}>
        {t(`LOGIN.${currentTab === 'signup' ? 'CREATE_ACCOUNT' : 'LOGIN'}`)}
      </Button>
      <Stack sx={{ alignItems: { md: 'flex-start', xs: 'center' }, mt: !isSignup ? 3 : 1 }}>
        {!isSignup && (
          <Typography
            onClick={() => setTab(3)}
            component={Link}
            to={`/login?tab=${forgotPasswordTab}`}
            fontSize={{ md: 14, xs: 12, sm: 13 }}
            fontWeight={500}
            color='primary'
            sx={{ textDecoration: 'none', mt: -3 }}>
            {t('LOGIN.FORGOT_PASSWORD')}
          </Typography>
        )}
      </Stack>
    </>
  );
};
