export const swDev = () => {
  // let swUrl = `/sw.js`;
  // navigator.serviceWorker.register(swUrl).then((response) => {
  //   console.log("working :", response);
  // });

  navigator.serviceWorker
    .register(`/firebase-messaging-sw.js`)
    .then((registration) => {
      console.log("Registration successful, scope is:", registration.scope);
    })
    .catch((err) => {
      console.log("Service worker registration failed, error:", err);
    });
};
