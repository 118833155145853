import i18next from "i18next";
import { StorageService } from "../storage";

const patterns = {
   af: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  sq: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  ar: /^[\u0600-\u06FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  az: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  bn: /^[\u0980-\u09FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  bg: /^[\u0400-\u04FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  ca: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  zh_CN: /^[\u4E00-\u9FFF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  zh_HK: /^[\u4E00-\u9FFF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  zh_TW: /^[\u4E00-\u9FFF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  hr: /^[\u0100-\u017F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  cs: /^[\u0100-\u017F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  da: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  nl: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  en: /^[\u0000-\u007F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  en_GB: /^[\u0000-\u007F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  en_US: /^[\u0000-\u007F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  et: /^[\u0100-\u017F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  fil: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  fi: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  fr: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  de: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  el: /^[\u0386-\u03CE0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  gu: /^[\u0A80-\u0AFF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  he: /^[\u0590-\u05FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  hi: /^[\u0900-\u097F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  hu: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  id: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  it: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  ja: /^[\u3040-\u309F\u30A0-\u30FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  ko: /^[\uAC00-\uD7AF\u1100-\u11FF\u3130-\u318F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  lv: /^[\u0100-\u017F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  lt: /^[\u0100-\u017F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  mk: /^[\u0400-\u04FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  ms: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  ml: /^[\u0D00-\u0D7F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  mr: /^[\u0900-\u097F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  nb: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  pl: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  pt_BR: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  pt_PT: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  pa: /^[\u0A00-\u0A7F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  ro: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  ru: /^[\u0400-\u04FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  sr: /^[\u0400-\u04FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  sk: /^[\u0100-\u017F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  sl: /^[\u0100-\u017F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  es: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  es_AR: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  es_ES: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  es_MX: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  sw: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  sv: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  ta: /^[\u0B80-\u0BFF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  te: /^[\u0C00-\u0C7F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  th: /^[\u0E00-\u0E7F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  tr: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  uk: /^[\u0400-\u04FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  ur: /^[\u0600-\u06FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  uz: /^[\u0400-\u04FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  vi: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  zu: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  ka: /^[\u10A0-\u10FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  ha: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  ga: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  kn: /^[\u0C80-\u0CFF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  kk: /^[\u0400-\u04FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  rw_RW: /^[\u00C0-\u00FF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  ky_KG: /^[\u0400-\u04FF\u0451\u04D9\u0452\u0453\u0454\u0455\u0456\u0457\u0458\u0459\u045A\u045B\u045C\u045E\u045F\u0401\u04D8\u0402\u0403\u0404\u0405\u0406\u0407\u0408\u0409\u040A\u040B\u040C\u040E\u040F0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  lo: /^[\u0E80-\u0EFF0-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
  fa: /^[\u0600-\u06FF\uFB8A\u067E\u0686\u06AF\u200C\u200F\u06A9\u06CC\u06F00-9\s\u0020-\u002F\u003A-\u0040\u005B-\u0060\u007B-\u007E\p{Emoji}]+$/u,
};

export const testLanguage = (inputText: string, language: string): boolean => {
    return patterns[language].test(inputText);
}

interface languageProps {
    label: string,
    value: string
}
export const LANGUAGES: languageProps[] = [
    {
        label: 'Select language',
        value: ''
    },
    {
        label: 'Afrikaans',
        value: 'af'
    },
    {
        label: 'Albanian',
        value: 'sq'
    },
    {
        label: 'Arabic',
        value: 'ar'
    },
    {
        label: 'Azerbaijani',
        value: 'az'
    },
    {
        label: 'Bengali',
        value: 'bn'
    },
    {
        label: 'Bulgarian',
        value: 'bg'
    },
    {
        label: 'Catalan',
        value: 'ca'
    },
    {
        label: 'Chinese (CHN)',
        value: 'zh_CN'
    },
    {
        label: 'Chinese (HKG)',
        value: 'zh_HK'
    },
    {
        label: 'Chinese (TAI)',
        value: 'zh_TW'
    },
    {
        label: 'Croatian',
        value: 'hr'
    },
    {
        label: 'Czech',
        value: 'cs'
    },
    {
        label: 'Danish',
        value: 'da'
    },
    {
        label: 'Dutch',
        value: 'nl'
    },
    {
        label: 'English',
        value: 'en'
    },
    {
        label: 'English (UK)',
        value: 'en_GB'
    },
    {
        label: 'English (US)',
        value: 'en_US'
    },
    {
        label: 'Estonian',
        value: 'et'
    },
    {
        label: 'Filipino',
        value: 'fil'
    },
    {
        label: 'Finnish',
        value: 'fi'
    },
    {
        label: 'French',
        value: 'fr'
    },
    {
        label: 'Georgian',
        value: 'ka'
    },
    {
        label: 'German',
        value: 'de'
    },
    {
        label: 'Greek',
        value: 'el'
    },
    {
        label: 'Gujarati',
        value: 'gu'
    },
    {
        label: 'Hausa',
        value: 'ha'
    },
    {
        label: 'Hebrew',
        value: 'he'
    },
    {
        label: 'Hindi',
        value: 'hi'
    },
    {
        label: 'Hungarian',
        value: 'hu'
    },
    {
        label: 'Indonesian',
        value: 'id'
    },
    {
        label: 'Irish',
        value: 'ga'
    },
    {
        label: 'Italian',
        value: 'it'
    },
    {
        label: 'Japanese',
        value: 'ja'
    },
    {
        label: 'Kannada',
        value: 'kn'
    },
    {
        label: 'Kazakh',
        value: 'kk'
    },
    {
        label: 'Kinyarwanda',
        value: 'rw_RW'
    },
    {
        label: 'Korean',
        value: 'ko'
    },
    {
        label: 'Kyrgyz (Kyrgyzstan)',
        value: 'ky_KG'
    },
    {
        label: 'Lao',
        value: 'lo'
    },
    {
        label: 'Latvian',
        value: 'lv'
    },
    {
        label: 'Lithuanian',
        value: 'lt'
    },
    {
        label: 'Macedonian',
        value: 'mk'
    },
    {
        label: 'Malay',
        value: 'ms'
    },
    {
        label: 'Malayalam',
        value: 'ml'
    },
    {
        label: 'Marathi',
        value: 'mr'
    },
    {
        label: 'Norwegian',
        value: 'nb'
    },
    {
        label: 'Persian',
        value: 'fa'
    },
    {
        label: 'Polish',
        value: 'pl'
    },
    {
        label: 'Portuguese (BR)',
        value: 'pt_BR'
    },
    {
        label: 'Portuguese (POR)',
        value: 'pt_PT'
    },
    {
        label: 'Punjabi',
        value: 'pa'
    },
    {
        label: 'Romanian',
        value: 'ro'
    },
    {
        label: 'Russian',
        value: 'ru'
    },
    {
        label: 'Serbian',
        value: 'sr'
    },
    {
        label: 'Slovak',
        value: 'sk'
    },
    {
        label: 'Slovenian',
        value: 'sl'
    },
    {
        label: 'Spanish',
        value: 'es'
    },
    {
        label: 'Spanish (ARG)',
        value: 'es_AR'
    },
    {
        label: 'Spanish (SPA)',
        value: 'es_ES'
    },
    {
        label: 'Spanish (MEX)',
        value: 'es_MX'
    },
    {
        label: 'Swahili',
        value: 'sw'
    },
    {
        label: 'Swedish',
        value: 'sv'
    },
    {
        label: 'Tamil',
        value: 'ta'
    },
    {
        label: 'Telugu',
        value: 'te'
    },
    {
        label: 'Thai',
        value: 'th'
    },
    {
        label: 'Turkish',
        value: 'tr'
    },
    {
        label: 'Ukrainian',
        value: 'uk'
    },
    {
        label: 'Urdu',
        value: 'ur'
    },
    {
        label: 'Uzbek',
        value: 'uz'
    },
    {
        label: 'Vietnamese',
        value: 'vi'
    },
    {
        label: 'Zulu',
        value: 'zu'
    }
]


export enum AppLanguage {
  ARABIC = 'ar',
  ENGLISH = 'en',
}

export const setAppLanguage = (langCode : AppLanguage) => {
  const newLanguage = langCode;
  i18next.changeLanguage(newLanguage);
  StorageService.setLanguage(newLanguage);
  document.dir = i18next.dir();
};
