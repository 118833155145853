import React, { MouseEvent } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import IconButton from '@mui/material/IconButton';

//  icons
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';

//  translations
import i18next from 'i18next';

//  models
import { BackButtonProps } from '../../models/Common';

//  redux
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../redux/store';
import { navigateBack } from '../../redux/sdk';
import { changeChatId } from '../../redux/chatsPage';
import { StorageKeys, StorageService } from '../../storage';

export const BackButton = ({ confirmBack, backNavigateTo, style, isContactDrawer }: BackButtonProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const mode = useSelector((state: RootState) => state.sdk.mode);

  const handleClick = async (e: MouseEvent<HTMLButtonElement>) => {    
    e.stopPropagation();
    if(pathname === '/chat') {
      if(isContactDrawer && confirmBack){
        await confirmBack()
        return;
      }
      if(Boolean(StorageService.getChatId())) {
        dispatch(changeChatId(null));
        StorageService.removeItem(StorageKeys.CHAT_ID);
      }
    }
    try {
      if (backNavigateTo) {
        navigate({
          pathname: backNavigateTo
        });
      } else {
        if (confirmBack) await confirmBack();
        //  go back is confirmed
        if (mode === 'host') navigate(-1);
        else dispatch(navigateBack());
      }
    } catch (err) {
      // go back is cancelled
    }
  }

  return (
    <IconButton data-testid="back-button" onMouseDown={e => e.stopPropagation()} onClick={handleClick} sx={{
      borderRadius: '8px', border: '1px solid', borderColor: 'primary.main', width: {md:35, xs:22}, height: {md:35, xs:22}, p: 0, marginInlineEnd: 2,
      '& svg': { color: 'primary.main' },
      '&:hover': {
        'svg': { color: 'light.main' },
        backgroundColor: 'primary.main',
        boxShadow: 33,
      }, ...style,
    }}>
      <ArrowBackIosNewRoundedIcon sx={{ fontSize: '0.8rem', scale: i18next.language === 'ar' ? '-1' : '1' }} />
    </IconButton>
  )
}
