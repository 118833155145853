/*
import { connectedButtonStyles } from './helpers/channels';
* This file is used to store the local storage and to be used across the app
*/
import environment from "./environment";
import CryptoJS from "crypto-js";
import { InstallEcommerceAppStorageType } from "./helpers/types/ecommerceApps";


/**
 * Exporting the keys, will allow us to use them in the app
 */
export enum StorageKeys {
    AUTH_TOKEN = "avc-token",
    USER_ID = "avc-user_id",
    USER_EMAIL = "avc-user_email",
    USER_DETAILS = "avc-user_details",
    BUSINESS_ID = "avc-business_id",
    BUSINESSES = "avc-businesses",
    CHANNELS = "avc-channels",
    LANGUAGE = "avc-language",
    LANDBOT_CONNECTED_ID = "avc-landbot-connected-id",
    CHAT_ID = "avc-chat-id",
    CHAT_LIST_TYPE = "avc-chat-list-type",
    TEMPLATE_DATA = "template-data",
    PLATFORM_ID = "avc-platform-id",
    PLATFORM_TYPE = "avc-platform-type",
    INTEGRATION_APP = "avc-integration-app",
    USER_ROLE = "avc-user-role",

    UI_VIEW_TEMPLATES = "avc-ui_view_templates",
    UI_VIEW_BROADCAST = "avc-ui_view_broadcast",
    UI_VIEW_QUICKREPLY = "avc-ui_view_quickreply",
    UI_VIEW_TAGS = "avc-ui_view_tags",
    UI_LATEST_MESSAGES_CODES = "avc-ui_latest_notification_msgs_codes",
    UI_META_CALLBACK_URL = "avc-ui_meta_callback_url",
    UI_META_VERIFICATION_TOKEN = "avc-ui_meta_verification_token",
    UI_CHAT_PLATFORM_ID = "avc-ui_chat_platform_id",
    UI_META_FCM_TOKEN = "avc-ui_meta_fcm_token",
    UI_PLAY_SOUND_NOTIFICATION = "avc-ui_play_sound_notification",
    UI_DESKTOP_NOTIFICATION = "avc-desktop_notification",
    INSTALL_APP_INFO = "avc-install_ecommerce_app",
    AVC_BILLING_SOURCE="avc_billing_source"
}

/**
 * The StorageService class is used to interact with the local storage.
 * Acts a StorageKey enum wrapper
 */
export class StorageService {
    static businessId = 'default-business-id'; // replace with actual business id
    static userId = 'default-user-id'; // replace with actual user id

    // To be used if using businessId in the key
    private static generateKey(key: StorageKeys): string {
        return `${key}-${this.businessId}`;
    }

    /**
     *
     * @param key
     * @returns the value of the key from the local storage
     */
    static getCustomItem(key: StorageKeys): string | null {
        return localStorage.getItem(key);
    }

    /**
     * Get the auth token/x-hasura-admin-secret/authorization token from the local storage
     * @returns
     */
    static getAuthToken(): string | null {
        return localStorage.getItem(StorageKeys.AUTH_TOKEN);
    }

    /**
     * Get the user/agent id (core.contact.id, where core.contact.is_agent == true) from the local storage
     * @returns
     *
    */
    static getUserId(): string | null {
        return localStorage.getItem(StorageKeys.USER_ID);
    }

    /**
     *
     * @returns the user/agent email (core.contact.platform_id, where core.contact.platform == 'avocado')
     */
    static getUserEmail(): string | null {
        return localStorage.getItem(StorageKeys.USER_EMAIL);
    }

    /**
     *
     * @returns the user/agent details as string (NOT JSON, use getJSONItem for JSON data or use JSON.parse() on the result of this method
     */
    static getUserDetails(): string | null {
        return localStorage.getItem(StorageKeys.USER_DETAILS);
    }


    /**
     *
     * @returns the business id (core.business.id) of a given business/workspace
     */
    static getBusinessId(): string | null {
        return localStorage.getItem(StorageKeys.BUSINESS_ID);
    }

    /**
     *
     * @returns the businesses list for the user/agent email (core.contact.platform_id, where core.contact.platform == 'avocado')
     */
    static getBusinesses(): string | null {
        return this.getJSONItem(StorageKeys.BUSINESSES) || [];
    }

    /**
     *
     * @returns the available channels for a given business/workspace (NOT JSON, use getJSONItem for JSON data or use JSON.parse() on the result of this method
     */
    static getChannels(): string | null {
        return localStorage.getItem(StorageKeys.CHANNELS);
    }

     /**
     *
     * @returns the AVC_BILLING_SOURCE of a given business/workspace
     */
     static getAvcBillingSource(): string | null {
        return localStorage.getItem(StorageKeys.AVC_BILLING_SOURCE);
    }

    static getChannelsAsJsonArr(): any[] {
        return this.getJSONItem(StorageKeys.CHANNELS) || [];
    }

    static isChannelConnected(): boolean {
        let whatsappChannels = this.getChannelsAsJsonArr();
        if (whatsappChannels.length > 0 && whatsappChannels[0]?.type === 'cloud_meta') {
            return whatsappChannels[0].is_callback_verified || false ;
        } else if (whatsappChannels.length > 0 && whatsappChannels[0]?.type === 'cloud_meta_360d') {
            return true;
        }
        return false
    }

    /**
     *
     * @returns the language from the local storage, if not found, returns 'en'
     */
    static getLanguage(): string {
        return localStorage.getItem(StorageKeys.LANGUAGE) || 'en';
    }

    /**
     *
     * @returns the role from the local storage
     */
    static getUserRole(): string {
        const lsItem: string = localStorage.getItem(StorageKeys.USER_ROLE) || '';
        const cipherRole: string = CryptoJS.AES.encrypt(JSON.stringify({ lsItem }), 'secret').toString() || '';
        const decryptedRole = CryptoJS.AES.decrypt(cipherRole, environment.REACT_APP_SECRET_ENCRYPTION_KEY);
        const role: string = decryptedRole.toString(CryptoJS.enc.Utf8);
        return role;
    }

    /**
     *
     * @returns the UI view for templates, either grid/list
     */
    static getUIViewTemplates(): string | null {
        return localStorage.getItem(StorageKeys.UI_VIEW_TEMPLATES);
    }

    /**
     *
     * @returns the UI view for broadcast, either grid/list
     */
    static getUIViewBroadcast(): string | null {
        return localStorage.getItem(StorageKeys.UI_VIEW_BROADCAST);
    }

    /**
     *
     * @returns the UI view for quick reply, either grid/list
     */
    static getUIViewQuickReply(): string | null {
        return localStorage.getItem(StorageKeys.UI_VIEW_QUICKREPLY);
    }

    /**
     *
     * @returns the UI view for tags, either grid/list
     */
    static getUIViewTags(): string | null {
        return localStorage.getItem(StorageKeys.UI_VIEW_TAGS);
    }

    /**
     *
     * @returns the latest messages code, for show toast notifications.
    */
    static getLatestMessagesCodes(): string[] | null {
        const item = localStorage.getItem(StorageKeys.UI_LATEST_MESSAGES_CODES);
        return item ? JSON.parse(item) : null;
    }

    /**
     *
     * @returns the Landbot connected id.
     */
    static getLandbotConnectedId(): string | null {
        return localStorage.getItem(StorageKeys.LANDBOT_CONNECTED_ID);
    }

    /**
     *
     * @returns the Chat contact id.
     */
    static getChatId(): number | null {
        const chatId = localStorage.getItem(StorageKeys.CHAT_ID);
        return chatId !== null ? Number(chatId) : null;
    }

    /**
     *
     * @returns the fcm token.
     */
    static getFcmToken(): string | null {
        return localStorage.getItem(StorageKeys.UI_META_FCM_TOKEN);
    }

    static getPlaySoundNotification(): boolean {
        const value = localStorage.getItem(StorageKeys.UI_PLAY_SOUND_NOTIFICATION) || false;
        return value === 'true';
    }

    static getDesktopNotification(): boolean {
        const value = localStorage.getItem(StorageKeys.UI_DESKTOP_NOTIFICATION);
        return value === 'true';
    }

    /**
     *
     * @returns the Chat list type.
     */
    static getChatListType(): string | null {
        return localStorage.getItem(StorageKeys.CHAT_LIST_TYPE);
    }

    static getMetaCallbackUrl(businessId: string): string | null {
        return localStorage.getItem(StorageKeys.UI_META_CALLBACK_URL + "-" + businessId);
    }

    static removeMetaCallbackUrl(businessId: string): void {
        localStorage.removeItem(StorageKeys.UI_META_CALLBACK_URL + "-" + businessId);
    }

    static getMetaVerificationToken(businessId: string): string | null {
        return localStorage.getItem(StorageKeys.UI_META_VERIFICATION_TOKEN + "-" + businessId);
    }

    static removeMetaVerificationToken(businessId: string): void {
        localStorage.removeItem(StorageKeys.UI_META_VERIFICATION_TOKEN + "-" + businessId);
    }


    /**
   *
   * @returns the platform id.
   */
    static getPlatformId(): string | null {
        const platformId = localStorage.getItem(StorageKeys.PLATFORM_ID);
        return platformId !== null ? platformId : null;
    }

    /**
   *
   * @returns the  platform type.
   */
    static getPlatformType(): string | null {
        const platformType = localStorage.getItem(StorageKeys.PLATFORM_TYPE);
        return platformType !== null ? platformType : null;
  }

  /**
   *
   * @returns the AVC Integration App .
   */
    static getIntegrationApp(): string | null {
        const integrationApp = localStorage.getItem(StorageKeys.INTEGRATION_APP);
        return integrationApp !== null ? integrationApp : null;
  }

  /**
   *
   * @returns the install app store info.
   */
   static getInstallAppInfo(): InstallEcommerceAppStorageType | null {
      return this.getJSONItem(StorageKeys.INSTALL_APP_INFO) ;
    }

  /**
   *
   * @returns the current chat platform id.
   */
    static getChatPlatformID(): string | null {
        const platformID = localStorage.getItem(StorageKeys.UI_CHAT_PLATFORM_ID);
        return platformID !== null ? platformID : null;
    }

    /**
     * Set the custom item in the local storage using the StorageKeys enum
     * @param key
     * @param value
     */
    static setCustomItem(key: StorageKeys, value: string): void {
        localStorage.setItem(key, value);
    }

    /**
     * Set the auth token/x-hasura-admin-secret/authorization token in the local storage
     * @param value
     */
    static setAuthToken(value: string): void {
        localStorage.setItem(StorageKeys.AUTH_TOKEN, value);
    }

     /**
     * Set the AVC_BILLING_SOURCE value in the local storage
     * @param value
     */
     static setAvcBillingSource(value: string): void {
        localStorage.setItem(StorageKeys.AVC_BILLING_SOURCE, value);
    }

    /**
     * Set the user/agent id (core.contact.id, where core.contact.is_agent == true) in the local storage
     * @param value
     */
    static setUserId(value: string): void {
        this.userId = value;
        localStorage.setItem(StorageKeys.USER_ID, value);
    }

    /**
     * Set the user/agent email (core.contact.platform_id, where core.contact.platform == 'avocado')
     * @param value
     */
    static setUserEmail(value: string): void {
        localStorage.setItem(StorageKeys.USER_EMAIL, value);
    }

    /**
     * Set the business id (core.business.id) of a given business/workspace
     * @param value
     */
    static setBusinessId(value: string): void {
        this.businessId = value;
        localStorage.setItem(StorageKeys.BUSINESS_ID, value);
    }

    /**
     * Set businesses list for the user/agent email (core.contact.platform_id, where core.contact.platform == 'avocado')
     * @param value
     */
    static setBusinessesAsJson(value: any): void {
        this.setJSONItem(StorageKeys.BUSINESSES, value);
    }

    /**
     * Set the user/agent details
     * @param value
     */
    static setUserDetailsAsJson(value: any): void {
        this.setJSONItem(StorageKeys.USER_DETAILS, value);
    }

    /**
     * Set the available channels for a given business/workspace
     * @param value
     */
    static setChannelsAsJson(value: any): void {
        this.setJSONItem(StorageKeys.CHANNELS, value);
    }

    /**
     * Set the preferred language for the user/agent
     * @param value
     */
    static setLanguage(value: string): void {
        localStorage.setItem(StorageKeys.LANGUAGE, value);
    }

    /**
     * Set the role of the current logged in user
     * @param value
     */
    static setCipherRole(value: string): void {
        localStorage.setItem(StorageKeys.USER_ROLE, value);
    }


    /**
     * Set the UI view for templates, either grid/list
     * @param value
     */
    static setUIViewTemplates(value: string): void {
        localStorage.setItem(StorageKeys.UI_VIEW_TEMPLATES, value);
    }


    /**
     * Set the UI view for broadcast, either grid/list
     * @param value
    */
    static setUIViewBroadcast(value: string): void {
        localStorage.setItem(StorageKeys.UI_VIEW_BROADCAST, value);
    }

    /**
     * Set the UI view for quick reply, either grid/list
     * @param value
     */
    static setUIViewQuickReply(value: string): void {
        localStorage.setItem(StorageKeys.UI_VIEW_QUICKREPLY, value);
    }

    /**
     * Set the UI view for quick Tags, either grid/list
     * @param value
     */
    static setUIViewTags(value: string): void {
        localStorage.setItem(StorageKeys.UI_VIEW_TAGS, value);
    }

    /**
     * Set the UI view for quick reply, either grid/list
     * @param value
     */
    static setTemplateParam(value: any): void {
        localStorage.setItem(StorageKeys.TEMPLATE_DATA, value);
    }

    /**
     *
     * @returns Set the latest messages code, for show toast notifications.
     */
    static setLatestMessagesCodes(latestMessagesCodes: string[]): void {
        localStorage.setItem(StorageKeys.UI_LATEST_MESSAGES_CODES, JSON.stringify(latestMessagesCodes));
    }

    /**
    *
    * @returns the Landbot connected id.
    */
    static setLandbotConnectedId(landbotConnectedId: string): void {
        localStorage.setItem(StorageKeys.LANDBOT_CONNECTED_ID, landbotConnectedId);
    }

    /**
    *
    * @returns the Chat contact id.
    */
    static setChatId(chatId: number): void {
        localStorage.setItem(StorageKeys.CHAT_ID, String(chatId));
    }


    /**
    *
    * @returns the Chat list type.
    */
    static setChatListType(chatListType: string): void {
        localStorage.setItem(StorageKeys.CHAT_LIST_TYPE, chatListType);
    }

    /**
    *
    * @returns the platform id.
    */
    static setPlatformId(platformId: string): void {
        localStorage.setItem(StorageKeys.PLATFORM_ID, platformId);
    }

    /**
    *
    * @returns the platform type.
    */
    static setPlatformType(platformType: string): void {
        localStorage.setItem(StorageKeys.PLATFORM_TYPE, platformType);
    }

    /**
    *
    * @returns the platform type.
    */
    static setIntegrationApp(integrationApp: string): void {
        localStorage.setItem(StorageKeys.INTEGRATION_APP, integrationApp);
    }

    static setMetaCallbackUrl(businessId: string, metaCallbackUrl: string): void {
        localStorage.setItem(StorageKeys.UI_META_CALLBACK_URL + "-" + businessId, metaCallbackUrl);
    }

    static setMetaVerificationToken(businessId: string, metaVerificationToken: string): void {
        localStorage.setItem(StorageKeys.UI_META_VERIFICATION_TOKEN + "-" + businessId, metaVerificationToken);
    }

    static setFcmToken(fcmToken: string): void {
        localStorage.setItem(StorageKeys.UI_META_FCM_TOKEN, fcmToken);
    }
    static playSoundNotification(isSoundOn: string): void {
        if(isSoundOn){
            localStorage.setItem(StorageKeys.UI_PLAY_SOUND_NOTIFICATION, isSoundOn);
        } else {
            localStorage.removeItem(StorageKeys.UI_PLAY_SOUND_NOTIFICATION);
        }
    }

    static desktopNotification(isOn: string): void {
        if(isOn){
            localStorage.setItem(StorageKeys.UI_DESKTOP_NOTIFICATION, isOn);
        } else {
            localStorage.removeItem(StorageKeys.UI_DESKTOP_NOTIFICATION);
        }
    }

    static setChatPlatformID(chatPlatformID: string): void {
        localStorage.setItem(StorageKeys.UI_CHAT_PLATFORM_ID, chatPlatformID);
    }

    static playSoundNotification(isSoundOn: string): void {
        if(isSoundOn){
            localStorage.setItem(StorageKeys.UI_PLAY_SOUND_NOTIFICATION, isSoundOn);
        } else {
            localStorage.removeItem(StorageKeys.UI_PLAY_SOUND_NOTIFICATION);
        }
    }

    static desktopNotification(isOn: string): void {
        if(isOn){
            localStorage.setItem(StorageKeys.UI_DESKTOP_NOTIFICATION, isOn);
        } else {
            localStorage.removeItem(StorageKeys.UI_DESKTOP_NOTIFICATION);
        }
    }

    /**
     * Set the install ecommerce info
     * @param storeInfo
     */
    static setInstallAppInfo(storeInfo: InstallEcommerceAppStorageType): void {
        this.setJSONItem(StorageKeys.INSTALL_APP_INFO, storeInfo);
    }

    /**
     * Clear the user details from the local storage
     */
    static clearUserDetails(): void {
        localStorage.removeItem(StorageKeys.AUTH_TOKEN);
        localStorage.removeItem(StorageKeys.USER_ID);
        localStorage.removeItem(StorageKeys.USER_EMAIL);
        localStorage.removeItem(StorageKeys.USER_DETAILS);
        localStorage.removeItem(StorageKeys.BUSINESS_ID);
        localStorage.removeItem(StorageKeys.CHANNELS);
        localStorage.removeItem(StorageKeys.CHAT_ID);
        localStorage.removeItem(StorageKeys.CHAT_LIST_TYPE);
        localStorage.removeItem(StorageKeys.PLATFORM_ID);
        localStorage.removeItem(StorageKeys.PLATFORM_TYPE);
        localStorage.removeItem(StorageKeys.TEMPLATE_DATA);
        localStorage.removeItem(StorageKeys.INTEGRATION_APP);
        localStorage.removeItem(StorageKeys.BUSINESSES)
        localStorage.removeItem(StorageKeys.USER_ROLE);
        localStorage.removeItem(StorageKeys.UI_META_FCM_TOKEN);
        localStorage.removeItem(StorageKeys.INSTALL_APP_INFO);
        localStorage.removeItem(StorageKeys.AVC_BILLING_SOURCE);
        localStorage.removeItem(StorageKeys.UI_CHAT_PLATFORM_ID);
    }

    static removeItem(key: StorageKeys): void {
        localStorage.removeItem(key);
    }

    static setJSONItem(key: StorageKeys, value: any): void {
        localStorage.setItem(key, JSON.stringify(value));
    }

    static getJSONItem(key: StorageKeys): any {
        const item = localStorage.getItem(key);
        return item ? JSON.parse(item) : null;
    }



    static clearAllItems(): void {
        localStorage.clear();
    }

    static hasItem(key: StorageKeys): boolean {
        return localStorage.getItem(key) !== null;
    }

    static getAllItems(): Record<string, any> {
        let items: Record<string, any> = {};
        for (let i = 0; i < localStorage.length; i++) {
            const key = localStorage.key(i);
            if (key) {
                items[key] = localStorage.getItem(key);
            }
        }
        return items;
    }
}

