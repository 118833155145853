import { createSlice } from '@reduxjs/toolkit';
import { Dispatch } from 'redux';
import { durationsMapReversed } from '../helpers/rule';

import { RuleType } from '../models/rules';

const { WHEN, IF, THEN } = RuleType;

const defaultTrigger = { messageType: '', chatType: '', error: 'RULES.CREATE_POPUP.ERR_MSGS.SELECT_VALID_OPTION' as any };
const defaultCondition = { lhs: '', operator: '', rhs: '' as any, _rhs: '' as any, logical_operator: 'and', error: 'RULES.CREATE_POPUP.ERR_MSGS.SELECT_VALID_OPTION' as any };
const defaultAction = { type: 'add_tag', value: {} as any, error: 'RULES.CREATE_POPUP.ERR_MSGS.SELECT_VALID_OPTION' as any };

// Slice
const slice = createSlice({
  name: 'rules',
  initialState: {
    showCreatePopup: false,
    editRule: null as any,
    mode: 'create',
    submited: false,
    triggersChanged: false,
    whenTriggers: [ defaultTrigger ],
    ifTriggers: [ defaultCondition ],
    thenTriggers: [ defaultAction ],
  },
  reducers: {
    //  for popup visibility
    statusChanged: (state, action) => {
      state.showCreatePopup = action.payload.showCreatePopup;
    },
    editRuleChange: (state, { payload }) => {
      state.editRule = payload;
      if (!payload) return;

      const trigger = payload.trigger.split('_');
      state.whenTriggers[0] = { messageType: `${trigger[0]}_${trigger[1]}`, chatType: `${trigger[2]}_${trigger[3]}`, error: null };
      const compiled_ui: any[] = JSON.parse(payload.compiled_ui);
      const conditions: any[] = [];
      compiled_ui.forEach((condition: any, i: number) => {
        if (i % 2 === 0) {
          conditions.push({ lhs: condition.operand_lhs, operator: condition.operator, rhs: condition.operator_rhs, logical_operator: compiled_ui[i-1]?.toLowerCase(), error: null })
          if(condition.operator__rhs) conditions[i] = {...conditions[i], _rhs: condition.operator__rhs}
        }
      });
      state.ifTriggers = conditions;
      const actions: any[] = [];
      payload.rule_actions.forEach(({ action }: any) => {
        const metadata = (action.metadata);
        if (action.type === 'snooze_chat') metadata.duration = durationsMapReversed.get(metadata.duration);
        actions.push({ type: action.type, value: metadata, error: null });
      });
      state.thenTriggers = actions;
    },
    modeChanged: (state, action) => {
      state.mode = action.payload;
    },
    submitClicked: (state) => {
      state.submited = true;
    },
    //  triggers
    conditionRemoved: (state, action) => {
      state.triggersChanged = true;
      if (action.payload.type === WHEN) state.whenTriggers.splice(action.payload.index, 1);
      else if (action.payload.type === IF) state.ifTriggers.splice(action.payload.index, 1);
      else if (action.payload.type === THEN) state.thenTriggers.splice(action.payload.index, 1);
    },
    conditionAdded: (state, action) => {
      state.triggersChanged = true;
      if (action.payload.type === WHEN) state.whenTriggers.push(defaultTrigger);
      else if (action.payload.type === IF) state.ifTriggers.push(defaultCondition);
      else if (action.payload.type === THEN) state.thenTriggers.push(defaultAction);
    },
    conditionChanged: (state, { payload: { type, index, data } }) => {
      state.triggersChanged = true;
      if (type === WHEN) {
        state.whenTriggers[index] = data;
        if (!data.messageType || !data.chatType) state.whenTriggers[index].error = 'RULES.CREATE_POPUP.ERR_MSGS.SELECT_VALID_OPTION';
        else state.whenTriggers[index].error = null;
      }
      else if (type === IF) {
        state.ifTriggers[index] = data;
        const operator: string = state.ifTriggers[index].operator;
        const rangeOp: boolean = operator === 'RGR' || operator === 'RG' || operator === '!RG'
        if (!data.lhs || !data.operator || !data.rhs) state.ifTriggers[index].error = 'RULES.CREATE_POPUP.ERR_MSGS.SELECT_VALID_OPTION';
        if(rangeOp && !data._rhs) state.ifTriggers[index].error = 'RULES.CREATE_POPUP.ERR_MSGS.SELECT_VALID_DATES';
        else state.ifTriggers[index].error = null;
      }
      else if (type === THEN) {
        state.thenTriggers[index] = data;
        const triggerType: string = data.type;
        const nullValue = data.value ? Object.keys(data.value).some((key) => data.value[key] === undefined || data.value[key] === null) : true;
        if ((!triggerType || !data.value || nullValue || !Object.keys(data.value).length) && triggerType !== 'last_agent_assign') state.thenTriggers[index].error = 'RULES.CREATE_POPUP.ERR_MSGS.SELECT_VALID_OPTION';
        else if (data._error) state.thenTriggers[index].error = data._error;
        else state.thenTriggers[index].error = null;
      }
    },
    formReset: (state) => {
      state.editRule = null;
      state.mode = 'create';
      state.submited = false;
      state.triggersChanged = false;
      state.whenTriggers = [ defaultTrigger ];
      state.ifTriggers = [ defaultCondition ];
      state.thenTriggers = [ defaultAction ];
    },
  },
});

export default slice.reducer;

// Actions
const { statusChanged, editRuleChange, modeChanged, submitClicked, conditionRemoved, conditionAdded, conditionChanged, formReset } = slice.actions;

export const changeStatus = (showCreatePopup: boolean): any => (dispatch: Dispatch): void => {
  dispatch(statusChanged({ showCreatePopup }));
}

export const setEditRule = (rule: any): any => (dispatch: Dispatch): void => {
  dispatch(editRuleChange(rule));
}

export const changeMode = (mode: string): any => (dispatch: Dispatch): void => {
  dispatch(modeChanged(mode));
}

export const clickSubmit = (): any => (dispatch: Dispatch): void => {
  dispatch(submitClicked());
}

export const removeCondition = (index: number, type: string): any => (dispatch: Dispatch): void => {
  dispatch(conditionRemoved({ index, type }));
}

export const addCondition = (type: string): any => (dispatch: Dispatch): void => {
  dispatch(conditionAdded({ type }));
}

export const changeCondition = (index: number, data: any, type: string): any => (dispatch: Dispatch): void => {
  dispatch(conditionChanged({ index, data, type }));
}

export const resetForm = (): any => (dispatch: Dispatch): void => {
  dispatch(formReset());
}
